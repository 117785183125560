import { Component, OnInit, ViewChild } from '@angular/core';
import { TodoService } from 'src/app/services/todo.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { SnotifyService, SnotifyToast } from 'ng-snotify';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { ExportToCsv } from 'export-to-csv-file';
import { MatPaginator} from '@angular/material/paginator';
import * as _ from 'lodash';
import { element } from 'protractor';
import { resolve } from 'dns';
import { reject } from 'lodash';
import * as moment from 'moment';

export interface PointManagementElement{
  no: number;
  blockName: string;
  pointName: string;
  imsi: string;
  adc0: string;
  adc1: string;
  ch1: string;
  ch2: string;
  ch3: string;
  voltage: string;
  rs232c: string;
  lat: any;
  lng: any;
}

export interface PortManagementElement{
  port: string;
  sensorName: string;
  outputVal: string;
  minVVal: number;
  maxVVal: number;
  outputValUnit: string;
  capacity: string;
  minMeasurable: number;
  maxMeasurable: number;
  capacityUnit: string;
  sortNum: number;
}

@Component({
  selector: 'app-point-management',
  templateUrl: './point-management.component.html',
  styleUrls: ['./point-management.component.scss']
})

export class PointManagementComponent implements OnInit{
  currentCityId: any;
  lang: any;
  dataPointMappingResponse: any;
  dataPointName: any;
  mappedTo: any;
  adc0Value: any;
  adc1Value: any;
  ch1Value: any;
  ch2Value: any;
  ch3Value: any;
  voltageValue: any;
  areaName: any;
  pointName: any;
  mainSensorId: any;
  rs232c: any;
  //以下CSV用
  adc0Capacity: any;
  adc0OutputVal: any;
  adc0MinMeasurable: any;
  adc0MaxMeasurable: any;
  adc0MinVVal: any;
  adc0MaxVVal: any;
  adc1Capacity: any;
  adc1OutputVal: any;
  adc1MinMeasurable: any;
  adc1MaxMeasurable: any;
  adc1MinVVal: any;
  adc1MaxVVal: any;
  header1: any;
  header2: any;
  //4/14追加
  ch1MinVVal: any;
  ch2MinVVal: any;
  ch3MinVVal: any;
  voltageMinVVal: any;
  ch1MaxVVal: any;
  ch2MaxVVal: any;
  ch3MaxVVal: any;
  voltageMaxVVal: any;
  rs232cMinVVal: any;
  rs232cMaxVVal: any;
  lat: any;
  lng: any;
  //ここまでCSV用
  pointManagementDataList: PointManagementElement[] = [];
  pointManagementDataSource: any;
  csvDataList: PointManagementElement[] = [];
  portManagementDataList: PortManagementElement[] = [];
  portManagementDataSource: any;
  selectedIndex: number = 0;
  readText: string = "";
  headersArray: any[] = [];
  csvViewData: any[] = [];
  deleteItemsArray: any[] = [];
  inputArray: any[] = [];
  dataPointMappingArray: any;
  @ViewChild('nanagementPaginator', {read: MatPaginator}) pointPaginator?: MatPaginator;
  pointDataLength: any;
  pointPagesize: any = [];
  perPage: number = 10;
  isNSCAdmin: boolean = false;
  role: any;
  enableMap: boolean = false;

  selectedRowIndex: number = -1;

  constructor(private translate: TranslateService,
    public cookieService: CookieService,
    private snotifyService: SnotifyService,
    private spinner: NgxSpinnerService,
    public todoService: TodoService,
  ){}

  ngOnInit(): void{
    this.lang = this.cookieService.get('language');
    this.currentCityId = sessionStorage.getItem('cityId');
    this.role = sessionStorage.getItem('role');
    // 設定ファイル情報
    this.enableMap = sessionStorage.getItem('enableMap') == 'true';
    if(this.role == "nscadmin"){
      this.isNSCAdmin = true;
    }

    this.dataPointMapping();
  }

  //displayedColumns: string[] = ["no", "blockName", "pointName", "imsi", "adc0", "adc1", "ch1", "ch2", "ch3", "ch4", "rs232c"];
  displayedColumns: string[] = ["no", "blockName", "pointName", "imsi", "adc0", "adc1", "ch1", "ch2", "ch3", "voltage", "rs232c"];
  portManagementColumns: string[] = ["port", "sensorName", "minVVal", "maxVVal", "outputValUnit", "minMeasurable", "maxMeasurable", "capacityUnit"];

  dataPointMapping(): void{

    this.todoService.getDataPointMappingByCityId(this.currentCityId).subscribe((response: any) => {
      this.dataPointMappingResponse = response;
      this.dataPointMappingArray = _.cloneDeep(response);
      this.pointManagementDataList.length = 0;
      //let a = this.translate.instant("Nissuicon");

      if(this.dataPointMappingResponse != undefined){

        for(let index = 0; index < this.dataPointMappingResponse.length; index++){

          for(let i = 0; i < this.dataPointMappingResponse[index].length; i++){
            this.dataPointName = this.dataPointMappingResponse[index][i].dataPointName;
            this.mappedTo = this.dataPointMappingResponse[index][i].mappedTo;

            if(this.dataPointName == "adc0"){
              this.adc0Value = this.mappedTo === "S001" ? "Water\r\nLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "Battery\r\nVoltage" : this.mappedTo === "S004" ? "ECFresh\r\nWater"
              : this.mappedTo === "S005" ? "Water\r\nQuantity" : this.mappedTo === "S006" ? "ECSea\r\nWater"
              : this.mappedTo === "S007" ? "ECWater\r\nTemprature" : "";
            }
            else if(this.dataPointName == "adc1"){
              this.adc1Value = this.mappedTo === "S001" ? "Water\r\nLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "Battery\r\nVoltage" : this.mappedTo === "S004" ? "ECFresh\r\nWater"
              : this.mappedTo === "S005" ? "Water\r\nQuantity" : this.mappedTo === "S006" ? "ECSea\r\nWater"
              : this.mappedTo === "S007" ? "ECWater\r\nTemprature" : "";
            }
            else if(this.dataPointName == "ch1"){
              this.ch1Value = this.mappedTo === "S001" ? "Water\r\nLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "Battery\r\nVoltage" : this.mappedTo === "S004" ? "ECFresh\r\nWater"
              : this.mappedTo === "S005" ? "Water\r\nQuantity" : this.mappedTo === "S006" ? "ECSea\r\nWater"
              : this.mappedTo === "S007" ? "ECWater\r\nTemprature" : "";
            }
            else if(this.dataPointName == "ch2"){
              this.ch2Value = this.mappedTo === "S001" ? "Water\r\nLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "Battery\r\nVoltage" : this.mappedTo === "S004" ? "ECFresh\r\nWater"
              : this.mappedTo === "S005" ? "Water\r\nQuantity" : this.mappedTo === "S006" ? "ECSea\r\nWater"
              : this.mappedTo === "S007" ? "ECWater\r\nTemprature" : "";
            }
            else if(this.dataPointName == "ch3"){
              this.ch3Value = this.mappedTo === "S001" ? "Water\r\nLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "Battery\r\nVoltage" : this.mappedTo === "S004" ? "ECFresh\r\nWater"
              : this.mappedTo === "S005" ? "Water\r\nQuantity" : this.mappedTo === "S006" ? "ECSea\r\nWater"
              : this.mappedTo === "S007" ? "ECWater\r\nTemprature" : "";
            }
            else if(this.dataPointName == "ch4"){
              this.voltageValue = this.mappedTo === "S001" ? "Water\r\nLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "Battery\r\nVoltage" : this.mappedTo === "S004" ? "ECFresh\r\nWater"
              : this.mappedTo === "S005" ? "Water\r\nQuantity" : this.mappedTo === "S006" ? "ECSea\r\nWater"
              : this.mappedTo === "S007" ? "ECWater\r\nTemprature" : "";
            }
            else{
              this.rs232c = "";
            }

            this.areaName = this.dataPointMappingResponse[index][i].areaName;
            this.pointName = this.dataPointMappingResponse[index][i].pointName;
            this.mainSensorId = this.dataPointMappingResponse[index][i].mainSensorId;
            //this.rs232c =  this.dataPointMappingResponse[index][i].rS232C;
            this.lat = this.dataPointMappingResponse[index][i].lat == null ? "" : this.dataPointMappingResponse[index][i].lat;
            this.lng = this.dataPointMappingResponse[index][i].lng == null ? "" : this.dataPointMappingResponse[index][i].lng;
          }

          const pointManagementData: PointManagementElement = {
            no: index + 1,
            blockName: this.areaName,
            pointName: this.pointName,
            imsi: this.mainSensorId,
            adc0: this.adc0Value,
            adc1: this.adc1Value,
            ch1: this.ch1Value,
            ch2: this.ch2Value,
            ch3: this.ch3Value,
            voltage: this.voltageValue,
            rs232c: this.rs232c,
            lat: this.lat,
            lng: this.lng
          }

          pointManagementData.blockName = isNull(pointManagementData.blockName);
          pointManagementData.pointName = isNull(pointManagementData.pointName);
          pointManagementData.imsi = isNull(pointManagementData.imsi);
          pointManagementData.adc0 = isNull(pointManagementData.adc0);
          pointManagementData.adc1 = isNull(pointManagementData.adc1);
          pointManagementData.ch1 = isNull(pointManagementData.ch1);
          pointManagementData.ch2 = isNull(pointManagementData.ch2);
          pointManagementData.ch3 = isNull(pointManagementData.ch3);
          pointManagementData.voltage = isNull(pointManagementData.voltage);
          pointManagementData.rs232c = isNull(pointManagementData.rs232c);

          this.pointManagementDataList.push(pointManagementData);
        }

        var areaSortData = this.pointManagementDataList.sort(function(a, b){
          return (a.blockName < b.blockName) ? -1: 1;
        })

        var pointSortData = areaSortData.sort(function(a, b){
          return (a.pointName < b.pointName) ? -1: 1;
        })

        this.pointManagementDataSource = new MatTableDataSource<PointManagementElement>(pointSortData);
        this.pointDataLength = pointSortData.length;
        this.pointPagesize = [10, 50, 100];
        this.pointManagementDataSource.paginator = this.pointPaginator;
      }
    })
  }

  //選ばれた行のデータの詳細を下に表示する
  selectPoint(selectedIndex: number, object: any){
    let selectedRow = (document.getElementById(`rows`) as HTMLTableRowElement);
    selectedRow.bgColor = 'black';

    this.selectedRowIndex = selectedIndex;

    let index = selectedIndex;
    this.selectedIndex = selectedIndex;
    this.portManagementDataList = [];
    this.portManagementDataList.length = 0;
    const selectedSensorId = object.imsi;
    const copyDataPointMappingData = _.cloneDeep(this.dataPointMappingResponse);
    const selectedMappingData = copyDataPointMappingData.filter((x: any) => x[0].mainSensorId == selectedSensorId);

    for(let index = 0; index < selectedMappingData[0].length; index++){
      //小数点以下の桁数制御(項目毎に変える)
      let fixNum = 3;

      if(selectedMappingData[0][index].mappedTo == "S005" || selectedMappingData[0][index].mappedTo == "S001"){
        fixNum = 3;
      }
      else if(selectedMappingData[0][index].mappedTo == "S006" || selectedMappingData[0][index].mappedTo == "S007"){
        fixNum = 2;
      }
      else{
        fixNum = 1;
      }

      //小数点の調整
      if(selectedMappingData[0][index].minVValue !== null){
        selectedMappingData[0][index].minVValue = selectedMappingData[0][index].minVValue.toFixed(fixNum);
      }

      if(selectedMappingData[0][index].maxVValue !== null){
        selectedMappingData[0][index].maxVValue = selectedMappingData[0][index].maxVValue.toFixed(fixNum);
      }

      if(selectedMappingData[0][index].minMeasurable !== null){
        selectedMappingData[0][index].minMeasurable = selectedMappingData[0][index].minMeasurable.toFixed(fixNum);
      }

      if(selectedMappingData[0][index].maxMeasurable !== null){
        selectedMappingData[0][index].maxMeasurable = selectedMappingData[0][index].maxMeasurable.toFixed(fixNum);
      }

      //ポートを表示用のものに切り替える
      if(selectedMappingData[0][index].dataPointName == "ch1"){
        selectedMappingData[0][index].dataPointName = "ch1-1";
      }
      else if(selectedMappingData[0][index].dataPointName == "ch2"){
        selectedMappingData[0][index].dataPointName = "ch1-2";
      }
      else if(selectedMappingData[0][index].dataPointName == "ch3"){
        selectedMappingData[0][index].dataPointName = "ch1-3";
      }
      else if(selectedMappingData[0][index].dataPointName == "ch4"){
        selectedMappingData[0][index].dataPointName = "Voltage";
      }

      let forUnit = "";
      let forUnit2 = "";

      //表示用のセンサー名を入れる
      if(selectedMappingData[0][index].mappedTo == "S001"){
        selectedMappingData[0][index].dataPointNameToDisplay = "WaterLevel";
        forUnit = "m";
        forUnit2 = "V";
      }
      else if(selectedMappingData[0][index].mappedTo == "S002"){
        selectedMappingData[0][index].dataPointNameToDisplay = "Turbidity";
        forUnit = "FTU";
        forUnit2 = "mA";
      }
      else if(selectedMappingData[0][index].mappedTo == "S003"){
        selectedMappingData[0][index].dataPointNameToDisplay = "BatteryVoltage";
        forUnit = "V";
        forUnit2 = "V";
        selectedMappingData[0][index].minMeasurable = "-";
        selectedMappingData[0][index].maxMeasurable = "-";
      }
      else if(selectedMappingData[0][index].mappedTo == "S004"){
        selectedMappingData[0][index].dataPointNameToDisplay = "ECFreshwater";
        forUnit = "μS/cm";
        forUnit2 = "μS/cm";
        selectedMappingData[0][index].maxVValue = "-";
        selectedMappingData[0][index].minMeasurable = "-";
        selectedMappingData[0][index].maxMeasurable = "-";
      }
      else if(selectedMappingData[0][index].mappedTo == "S005"){
        selectedMappingData[0][index].dataPointNameToDisplay = "WaterQuantity";
        forUnit = "㎥/sec";
        forUnit2 = "mA";
      }
      else if(selectedMappingData[0][index].mappedTo == "S006"){
        selectedMappingData[0][index].dataPointNameToDisplay = "ECSeawater";
        forUnit = "mS/cm";
        forUnit2 = "mS/cm";
        selectedMappingData[0][index].minVValue = "-";
        selectedMappingData[0][index].minMeasurable = "-";
        selectedMappingData[0][index].maxMeasurable = "-";
      }
      else if(selectedMappingData[0][index].mappedTo == "S007"){
        selectedMappingData[0][index].dataPointNameToDisplay = "ECWaterTemperature";
        forUnit = "℃";
        forUnit2 = "℃";
        selectedMappingData[0][index].minMeasurable = "-";
        selectedMappingData[0][index].maxMeasurable = "-";
      }
      else{
        selectedMappingData[0][index].minMeasurable = "-";
        selectedMappingData[0][index].maxMeasurable = "-";
        selectedMappingData[0][index].minVValue = "-";
        selectedMappingData[0][index].maxVValue = "-";
      }

      const portManagementData: PortManagementElement = {
        port: selectedMappingData[0][index].dataPointName,
        sensorName: selectedMappingData[0][index].dataPointNameToDisplay,
        outputVal: selectedMappingData[0][index].outputValue,
        minVVal: selectedMappingData[0][index].minVValue,
        maxVVal: selectedMappingData[0][index].maxVValue,
        outputValUnit: forUnit2,
        capacity: selectedMappingData[0][index].capacity,
        maxMeasurable: selectedMappingData[0][index].maxMeasurable,
        minMeasurable: selectedMappingData[0][index].minMeasurable,
        capacityUnit: forUnit,
        sortNum: 99999
      }

      portManagementData.port = isNull(portManagementData.port);
      portManagementData.sensorName = isNull(portManagementData.sensorName);
      portManagementData.minVVal = isNull(portManagementData.minVVal);
      portManagementData.maxVVal = isNull(portManagementData.maxVVal);
      portManagementData.outputValUnit = isNull(portManagementData.outputValUnit);
      portManagementData.maxMeasurable = isNull(portManagementData.maxMeasurable);
      portManagementData.minMeasurable = isNull(portManagementData.minMeasurable);
      portManagementData.capacityUnit = isNull(portManagementData.capacityUnit);

      // 並び順指定
      if (portManagementData.port == "adc0")
      {
        portManagementData.sortNum = 1;
      }
      else if (portManagementData.port == "adc1")
      {
        portManagementData.sortNum = 2;
      }
      else if (portManagementData.port == "ch1-1")
      {
        portManagementData.sortNum = 3;
      }
      else if (portManagementData.port == "ch1-2")
      {
        portManagementData.sortNum = 4;
      }
      else if (portManagementData.port == "ch1-3")
      {
        portManagementData.sortNum = 5;
      }
      else if (portManagementData.port == "Voltage")
      {
        portManagementData.sortNum = 6;
      }
      else if (portManagementData.port == "RS232C")
      {
        portManagementData.sortNum = 7;
      }

      this.portManagementDataList.push(portManagementData);
    }

    // 指定順にソート
    const sortedPortManagementDataList = this.portManagementDataList.sort((a, b) => {
      return a.sortNum - b.sortNum;
      // return (a.port < b.port) ? -1: 1;
    });

    // //RS232Cを一番最後に持ってくる
    // const copyList = _.cloneDeep(sortedPortManagementDataList);
    // sortedPortManagementDataList.splice(0, 1);
    // sortedPortManagementDataList.push(copyList[0]);
    this.portManagementDataSource = new MatTableDataSource<PortManagementElement>(sortedPortManagementDataList);
    //object.backgroundColor = "rgb(255, 255, 0)"
    //return object;
    //HTMLTableElement.
  }

  //表示内容をCSV出力する
  clickCSVExport(): void{
    this.lang = sessionStorage.getItem('lang');
    this.csvViewData.length = 0;
    var date = new Date();

    const options = {
      //filename: this.cookieService.get('language') === 'en' ? 'Converted value' + this.currentAreaName : '測定値（A/D変換値）' + this.currentAreaName,
      filename: `point-Management-${date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
        + '-' + ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2)}`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: "",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false
      //headers: this.headersArray
    };

    //日本語が選択されている場合
    if(this.lang == "jp"){

      //データを改行なしのものに変える
      for(let index = -2; index < this.dataPointMappingResponse.length; index++){

        //最初の二行はヘッダー情報を入れる

        //一行目
        if(index == -2){

          const csvData = {
            no: "No",
            blockName: "ブロック名",
            pointName: "地点名",
            imsi: "SIM ID",
            adc0SensorName: "adc0",
            adc0OutputVal: "",
            adc0MinVVal: "",
            adc0MaxVVal: "",
            adc0Capacity: "",
            adc0MinMeasurable: "",
            adc0MaxMeasurable: "",
            adc1SensorName: "adc1",
            adc1OutputVal: "",
            adc1MinVVal: "",
            adc1MaxVVal: "",
            adc1Capacity: "",
            adc1MinMeasurable: "",
            adc1MaxMeasurable: "",
            ch1: "RS485",
            ch1MinVVal: "",
            ch1MaxVVal: "",
            ch2: "",
            ch2MinVVal: "",
            ch2MaxVVal: "",
            ch3: "",
            ch3MinVVal: "",
            ch3MaxVVal: "",
            voltage: "",
            voltageMinVVal: "",
            voltageMaxVVal: "",
            rs232c: "RS232C",
            rs232cMinVVal: "",
            rs232cMaxVVal: "",
            latlng: "LatLng",
            lat: "",
            lng: ""
          }
          this.header1 = csvData;
        }

        //二行目
        else if(index == -1){

          const csvData = {
            no: "",
            blockName: "",
            pointName: "",
            imsi: "IMSI",
            adc0SensorName: "センサー名",
            adc0OutputVal: "出力",
            adc0MinVVal: "最小値",
            adc0MaxVVal: "最大値",
            adc0Capacity: "性能",
            adc0MinMeasurable: "最小値",
            adc0MaxMeasurable: "最大値",
            adc1SensorName: "センサー名",
            adc1OutputVal: "出力",
            adc1MinVVal: "最小値",
            adc1MaxVVal: "最大値",
            adc1Capacity: "性能",
            adc1MinMeasurable: "最小値",
            adc1MaxMeasurable: "最大値",
            ch1: "ch1-1",
            ch1MinVVal: "最小値",
            ch1MaxVVal: "最大値",
            ch2: "ch1-2",
            ch2MinVVal: "最小値",
            ch2MaxVVal: "最大値",
            ch3: "ch1-3",
            ch3MinVVal: "最小値",
            ch3MaxVVal: "最大値",
            voltage: "ch1-4",
            voltageMinVVal: "最小値",
            voltageMaxVVal: "最大値",
            rs232c: "",
            rs232cMinVVal: "最小値",
            rs232cMaxVVal: "最大値",
            latlng: "",
            lat: "Lat",
            lng: "Lng"
          }
          this.header2 = csvData;
        }

        //三行目以降はデータ本体を入れる
        else{

          for(let i = 0; i < this.dataPointMappingResponse[index].length; i++){
            this.dataPointName = this.dataPointMappingResponse[index][i].dataPointName;
            this.mappedTo = this.dataPointMappingResponse[index][i].mappedTo;

            if(this.dataPointName == "adc0"){
              this.adc0Value = this.mappedTo === "S001" ? "水位計" : this.mappedTo === "S002" ? "濁度計" : this.mappedTo === "S003" ? "バッテリー電圧"
              : this.mappedTo === "S004" ? "EC淡水" : this.mappedTo === "S005" ? "流量計" : this.mappedTo === "S006" ? "EC海水"
              : this.mappedTo === "S007" ? "EC水温" : "";

              this.adc0MinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.adc0MaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
              this.adc0MinMeasurable = this.dataPointMappingResponse[index][i].minMeasurable;
              this.adc0MaxMeasurable = this.dataPointMappingResponse[index][i].maxMeasurable;
              this.adc0OutputVal = this.dataPointMappingResponse[index][i].outputValue;
              this.adc0Capacity = this.dataPointMappingResponse[index][i].capacity;
            }
            else if(this.dataPointName == "adc1"){
              this.adc1Value = this.mappedTo === "S001" ? "水位計" : this.mappedTo === "S002" ? "濁度計" : this.mappedTo === "S003" ? "バッテリー電圧"
              : this.mappedTo === "S004" ? "EC淡水" : this.mappedTo === "S005" ? "流量計" : this.mappedTo === "S006" ? "EC海水"
              : this.mappedTo === "S007" ? "EC水温" : "";

              this.adc1MinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.adc1MaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
              this.adc1MinMeasurable = this.dataPointMappingResponse[index][i].minMeasurable;
              this.adc1MaxMeasurable = this.dataPointMappingResponse[index][i].maxMeasurable;
              this.adc1OutputVal = this.dataPointMappingResponse[index][i].outputValue;
              this.adc1Capacity = this.dataPointMappingResponse[index][i].capacity;
            }
            else if(this.dataPointName == "ch1"){
              this.ch1Value = this.mappedTo === "S001" ? "水位計" : this.mappedTo === "S002" ? "濁度計" : this.mappedTo === "S003" ? "バッテリー電圧"
              : this.mappedTo === "S004" ? "EC淡水" : this.mappedTo === "S005" ? "流量計" : this.mappedTo === "S006" ? "EC海水"
              : this.mappedTo === "S007" ? "EC水温" : "";
              this.ch1MinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.ch1MaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
            }
            else if(this.dataPointName == "ch2"){
              this.ch2Value = this.mappedTo === "S001" ? "水位計" : this.mappedTo === "S002" ? "濁度計" : this.mappedTo === "S003" ? "バッテリー電圧"
              : this.mappedTo === "S004" ? "EC淡水" : this.mappedTo === "S005" ? "流量計" : this.mappedTo === "S006" ? "EC海水"
              : this.mappedTo === "S007" ? "EC水温" : "";
              this.ch2MinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.ch2MaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
            }
            else if(this.dataPointName == "ch3"){
              this.ch3Value = this.mappedTo === "S001" ? "水位計" : this.mappedTo === "S002" ? "濁度計" : this.mappedTo === "S003" ? "バッテリー電圧"
              : this.mappedTo === "S004" ? "EC淡水" : this.mappedTo === "S005" ? "流量計" : this.mappedTo === "S006" ? "EC海水"
              : this.mappedTo === "S007" ? "EC水温" : "";
              this.ch3MinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.ch3MaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
            }
            else if(this.dataPointName == "ch4"){
              this.voltageValue = this.mappedTo === "S001" ? "水位計" : this.mappedTo === "S002" ? "濁度計" : this.mappedTo === "S003" ? "バッテリー電圧"
              : this.mappedTo === "S004" ? "EC淡水" : this.mappedTo === "S005" ? "流量計" : this.mappedTo === "S006" ? "EC海水"
              : this.mappedTo === "S007" ? "EC水温" : "";
              this.voltageMinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.voltageMaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
            }
            else if(this.dataPointName == "RS232C"){
              this.rs232cMinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.rs232cMaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
            }

            this.areaName = this.dataPointMappingResponse[index][i].areaName;
            this.pointName = this.dataPointMappingResponse[index][i].pointName;
            this.mainSensorId = this.dataPointMappingResponse[index][i].mainSensorId;
            this.rs232c = this.dataPointMappingResponse[index][i].rS232C;
            this.lat = this.dataPointMappingResponse[index][i].lat == null ? "" : this.dataPointMappingResponse[index][i].lat;
            this.lng = this.dataPointMappingResponse[index][i].lng == null ? "" : this.dataPointMappingResponse[index][i].lng;
          }

          let translatedAreaName = this.translate.instant(this.areaName);
          let translatedPointName = this.translate.instant(this.pointName);

          const csdData = {
            no: index + 1,
            blockName: translatedAreaName,
            pointName: translatedPointName,
            imsi: this.mainSensorId,
            adc0SensorName: this.adc0Value,
            adc0OutputVal: this.adc0OutputVal,
            adc0MinVVal: this.adc0MinVVal,
            adc0MaxVVal: this.adc0MaxVVal,
            adc0Capacity: this.adc0Capacity,
            adc0MinMeasurable: this.adc0MinMeasurable,
            adc0MaxMeasurable: this.adc0MaxMeasurable,
            adc1SensorName: this.adc1Value,
            adc1OutputVal: this.adc1OutputVal,
            adc1MinVVal: this.adc1MinVVal,
            adc1MaxVVal: this.adc1MaxVVal,
            adc1Capacity: this.adc1Capacity,
            adc1MinMeasurable: this.adc1MinMeasurable,
            adc1MaxMeasurable: this.adc1MaxMeasurable,
            ch1: this.ch1Value,
            ch1MinVVal: this.ch1MinVVal,
            ch1MaxVVal: this.ch1MaxVVal,
            ch2: this.ch2Value,
            ch2MinVVal: this.ch2MinVVal,
            ch2MaxVVal: this.ch2MaxVVal,
            ch3: this.ch3Value,
            ch3MinVVal: this.ch3MinVVal,
            ch3MaxVVal: this.ch3MaxVVal,
            voltage: this.voltageValue,
            voltageMinVVal: this.voltageMinVVal,
            voltageMaxVVal: this.voltageMaxVVal,
            rs232c: "",
            rs232cMinVVal: this.rs232cMinVVal,
            rs232cMaxVVal: this.rs232cMaxVVal,
            latlng: "",
            lat: this.lat,
            lng: this.lng
          }
          this.csvViewData.push(csdData);
        }
      }

      var areaSortData = this.csvViewData.sort(function(a, b){
        return (a.blockName < b.blockName) ? -1: 1;
      })

      var pointSortData = areaSortData.sort(function(a, b){
        return (a.pointName < b.pointName) ? -1: 1;
      })

      for(let i = 0; i < pointSortData.length; i++){
        pointSortData[i].no = i + 1;
      }

      pointSortData.unshift(this.header2);
      pointSortData.unshift(this.header1);

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(pointSortData);
      this.snotifyService.success(this.translate.instant('Data exported successfully'), '');
    }
    //英語が選択されている場合
    else{

      //データを改行なしのものに変える
      for(let index = -2; index < this.dataPointMappingResponse.length; index++){

        //最初の二行はヘッダー情報を入れる
        //一行目
        if(index == -2){

          const csvData = {
            no: "No",
            blockName: "BlockName",
            pointName: "PointName",
            imsi: "SIM ID",
            adc0SensorName: "adc0",
            adc0OutputVal: "",
            adc0MinVVal: "",
            adc0MaxVVal: "",
            adc0Capacity: "",
            adc0MinMeasurable: "",
            adc0MaxMeasurable: "",
            adc1SensorName: "adc1",
            adc1OutputVal: "",
            adc1MinVVal: "",
            adc1MaxVVal: "",
            adc1Capacity: "",
            adc1MinMeasurable: "",
            adc1MaxMeasurable: "",
            ch1: "RS485",
            ch1MinVVal: "",
            ch1MaxVVal: "",
            ch2: "",
            ch2MinVVal: "",
            ch2MaxVVal: "",
            ch3: "",
            ch3MinVVal: "",
            ch3MaxVVal: "",
            voltage: "",
            voltageMinVVal: "",
            voltageMaxVVal: "",
            rs232c: "RS232C",
            rs232cMinVVal: "",
            rs232cMaxVVal: "",
            latlng: "LatLng",
            lat: "",
            lng: ""
          }
          this.header1 = csvData;
        }
        //二行目
        else if(index == -1){

          const csvData = {
            no: "",
            blockName: "",
            pointName: "",
            imsi: "IMSI",
            adc0SensorName: "SensorName",
            adc0OutputVal: "Output",
            adc0MinVVal: "MinValue",
            adc0MaxVVal: "MaxValue",
            adc0Capacity: "Capacity",
            adc0MinMeasurable: "MinValue",
            adc0MaxMeasurable: "MaxValue",
            adc1SensorName: "SensorName",
            adc1OutputVal: "Output",
            adc1MinVVal: "MinValue",
            adc1MaxVVal: "MavValue",
            adc1Capacity: "Capacity",
            adc1MinMeasurable: "MinValue",
            adc1MaxMeasurable: "MaxValue",
            ch1: "ch1-1",
            ch1MinVVal: "MinValue",
            ch1MaxVVal: "MaxValue",
            ch2: "ch1-2",
            ch2MinVVal: "MinValue",
            ch2MaxVVal: "MaxValue",
            ch3: "ch1-3",
            ch3MinVVal: "MinValue",
            ch3MaxVVal: "MaxValue",
            voltage: "ch1-4",
            voltageMinVVal: "MinValue",
            voltageMaxVVal: "MaxValue",
            rs232c: "",
            rs232cMinVVal: "MinValue",
            rs232cMaxVVal: "MaxValue",
            latlng: "",
            lat: "Lat",
            lng: "Lng"
          }
          this.header2 = csvData;
        }
        //三行目以降はデータ本体を入れる
        else{

          for(let i = 0; i < this.dataPointMappingResponse[index].length; i++){
            this.dataPointName = this.dataPointMappingResponse[index][i].dataPointName;
            this.mappedTo = this.dataPointMappingResponse[index][i].mappedTo;

            if(this.dataPointName == "adc0"){
              this.adc0Value = this.mappedTo === "S001" ? "WaterLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "BatteryVoltage" : this.mappedTo === "S004" ? "ECFreshWater"
              : this.mappedTo === "S005" ? "WaterQuantity" : this.mappedTo === "S006" ? "ECSeaWater"
              : this.mappedTo === "S007" ? "ECWaterTemprature" : "";

              this.adc0MinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.adc0MaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
              this.adc0MinMeasurable = this.dataPointMappingResponse[index][i].minMeasurable;
              this.adc0MaxMeasurable = this.dataPointMappingResponse[index][i].maxMeasurable;
              this.adc0OutputVal = this.dataPointMappingResponse[index][i].outputValue;
              this.adc0Capacity = this.dataPointMappingResponse[index][i].capacity;
            }
            else if(this.dataPointName == "adc1"){
              this.adc1Value = this.mappedTo === "S001" ? "WaterLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "BatteryVoltage" : this.mappedTo === "S004" ? "ECFreshWater"
              : this.mappedTo === "S005" ? "WaterQuantity" : this.mappedTo === "S006" ? "ECSeaWater"
              : this.mappedTo === "S007" ? "ECWaterTemprature" : "";

              this.adc1MinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.adc1MaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
              this.adc1MinMeasurable = this.dataPointMappingResponse[index][i].minMeasurable;
              this.adc1MaxMeasurable = this.dataPointMappingResponse[index][i].maxMeasurable;
              this.adc1OutputVal = this.dataPointMappingResponse[index][i].outputValue;
              this.adc1Capacity = this.dataPointMappingResponse[index][i].capacity;
            }
            else if(this.dataPointName == "ch1"){
              this.ch1Value = this.mappedTo === "S001" ? "WaterLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "BatteryVoltage" : this.mappedTo === "S004" ? "ECFreshWater"
              : this.mappedTo === "S005" ? "WaterQuantity" : this.mappedTo === "S006" ? "ECSeaWater"
              : this.mappedTo === "S007" ? "ECWaterTemprature" : "";

              this.ch1MinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.ch1MaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
            }
            else if(this.dataPointName == "ch2"){
              this.ch2Value = this.mappedTo === "S001" ? "WaterLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "BatteryVoltage" : this.mappedTo === "S004" ? "ECFreshWater"
              : this.mappedTo === "S005" ? "WaterQuantity" : this.mappedTo === "S006" ? "ECSeaWater"
              : this.mappedTo === "S007" ? "ECWaterTemprature" : "";

              this.ch2MinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.ch2MaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
            }
            else if(this.dataPointName == "ch3"){
              this.ch3Value = this.mappedTo === "S001" ? "WaterLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "BatteryVoltage" : this.mappedTo === "S004" ? "ECFreshWater"
              : this.mappedTo === "S005" ? "WaterQuantity" : this.mappedTo === "S006" ? "ECSeaWater"
              : this.mappedTo === "S007" ? "ECWaterTemprature" : "";

              this.ch3MinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.ch3MaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
            }
            else if(this.dataPointName == "ch4"){
              this.voltageValue = this.mappedTo === "S001" ? "WaterLevel" : this.mappedTo === "S002" ? "Turbidity"
              : this.mappedTo === "S003" ? "BatteryVoltage" : this.mappedTo === "S004" ? "ECFreshWater"
              : this.mappedTo === "S005" ? "WaterQuantity" : this.mappedTo === "S006" ? "ECSeaWater"
              : this.mappedTo === "S007" ? "ECWaterTemprature" : "";

              this.voltageMinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.voltageMaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
            }
            else if(this.dataPointName == "RS232C"){
              this.rs232cMinVVal = this.dataPointMappingResponse[index][i].minVValue;
              this.rs232cMaxVVal = this.dataPointMappingResponse[index][i].maxVValue;
            }

            this.areaName = this.dataPointMappingResponse[index][i].areaName;
            this.pointName = this.dataPointMappingResponse[index][i].pointName;
            this.mainSensorId = this.dataPointMappingResponse[index][i].mainSensorId;
            this.rs232c = this.dataPointMappingResponse[index][i].rS232C;
            this.lat = this.dataPointMappingResponse[index][i].lat == null ? "" : this.dataPointMappingResponse[index][i].lat;
            this.lng = this.dataPointMappingResponse[index][i].lng == null ? "" : this.dataPointMappingResponse[index][i].lng;
          }

          const csvData = {
            no: index + 1,
            blockName: this.areaName,
            pointName:  this.pointName,
            imsi: this.mainSensorId,
            adc0SensorName: this.adc0Value,
            adc0OutputVal: this.adc0OutputVal,
            adc0MinVVal: this.adc0MinVVal,
            adc0MaxVVal: this.adc0MaxVVal,
            adc0Capacity: this.adc0Capacity,
            adc0MinMeasurable: this.adc0MinMeasurable,
            adc0MaxMeasurable: this.adc0MaxMeasurable,
            adc1SensorName: this.adc1Value,
            adc1OutputVal: this.adc1OutputVal,
            adc1MinVVal: this.adc1MinVVal,
            adc1MaxVVal: this.adc1MaxVVal,
            adc1Capacity: this.adc1Capacity,
            adc1MinMeasurable: this.adc1MinMeasurable,
            adc1MaxMeasurable: this.adc1MaxMeasurable,
            ch1: this.ch1Value,
            ch1MinVVal: this.ch1MinVVal,
            ch1MaxVVal: this.ch1MaxVVal,
            ch2: this.ch2Value,
            ch2MinVVal: this.ch2MinVVal,
            ch2MaxVVal: this.ch2MaxVVal,
            ch3: this.ch3Value,
            ch3MinVVal: this.ch3MinVVal,
            ch3MaxVVal: this.ch3MaxVVal,
            voltage: this.voltageValue,
            voltageMinVVal: this.voltageMinVVal,
            voltageMaxVVal: this.voltageMaxVVal,
            rs232c: "",
            rs232cMinVVal: this.rs232cMinVVal,
            rs232cMaxVVal: this.rs232cMaxVVal,
            latlng: "",
            lat: this.lat,
            lng: this.lng
          }
          this.csvViewData.push(csvData);
        }
      }

      var areaSortData = this.csvViewData.sort(function(a, b){
        return (a.blockName < b.blockName) ? -1: 1;
      })

      var pointSortData = areaSortData.sort(function(a, b){
        return (a.pointName < b.pointName) ? -1: 1;
      })

      for(let i = 0; i < pointSortData.length; i++){
        pointSortData[i].no = i + 1;
      }

      pointSortData.unshift(this.header2);
      pointSortData.unshift(this.header1);

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(pointSortData);
      this.snotifyService.success(this.translate.instant('Data exported successfully'), '');
    }
  }

  //CSV形式のファイルをインポートする
  importCSVFile(event: any): void{
    const file = event.target.files[0];
    //console.log(file);
    this.fileToText(file)
    .then(text => {
      this.readText = text;
      const csvArray = this.parseToArray(text);
      csvArray.splice(0, 2);
      csvArray.pop();
      this.judgeCSVArray(csvArray);
      // console.log(csvArray);
    })
    .catch(err => {
      console.log(err);
      alert("csvファイルのインポートに失敗しました。ファイルの形式または入力内容を再度確認してください。");
      return;
    });
    //console.log(this.readText);

    //  let input = (document.getElementById("01") as HTMLInputElement);
    //  input.addEventListener('change', (e: any) => {
    //    let result = e.target.files[0];
    //    let file_reader = new FileReader();
    //    let text = file_reader.readAsText(result);
    //    console.log(text);
    //   })

    //let inputItem = input?.item(0);
    //console.log(input);
    //  let selectedFile = (document.getElementById("01") as HTMLInputElement);
    //  selectedFile.addEventListener("change", e => {
    //  let input = e.target;
    //  if(input){}
    //  })

    //  if(event.target != null){
   //    //let fileData = selectedFile.files[0].text();
    //   }
  }

  //CSVファイルの中身を文字列に変換する
  fileToText(file: any): Promise<any>{
    const reader = new FileReader();
    reader.readAsText(file);

    return new Promise((resolve, reject) => {

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = () => {
        reject(reader.error);
      }
    });
  }

  //文字列を配列に変換する
  parseToArray(text: any){
    return text.split('\r\n').map((row: any) => row.split(','))
  }

  //データの形式をチェックする
  judgeCSVArray(csvArray: any): void{
    for(let index1 = 0; index1 < csvArray.length; index1++){
      let csvElement = csvArray[index1];

      //データの個数の判定(33個ジャスト)
      // 緯度経度2つ追加
      if(csvElement.length !== 36){
        alert(`${index1 + 1}件目のデータの個数に過不足があります。\r\n一行に含まれるデータの個数は36個である必要があります。`);
        return;
      }

      //データの形式の判定
      for(let index2 = 0; index2 < csvElement.length; index2++) {
        //adc0のデータ名チェック(水位計のみ)
        let sAdc0: string = csvElement[4].replace(/"/g, "").trim();
        if (sAdc0 != "水位計" && sAdc0 != "WaterLevel" && sAdc0 != "") {
          alert(`${index1 + 1}件目のデータ形式に問題があります。`);
          return;
        }

        //adc1のデータ名チェック(流量計または濁度計)
        let sAdc1: string = csvElement[11].replace(/"/g, "").trim();
        if (sAdc1 != "流量計" && sAdc1 != "WaterQuantity" && sAdc1 != "濁度計" && sAdc1 != "Turbidity" && sAdc1 != "") {
          alert(`${index1 + 1}件目のデータ形式に問題があります。`);
          return;
        }

        //ch1のデータ名チェック
        let sCh1: string = csvElement[18].replace(/"/g, "").trim();
        if (sCh1 != "EC水温" && sCh1 != "ECWaterTemprature" && sCh1 != "") {
          alert(`${index1 + 1}件目のデータ形式に問題があります。`);
          return;
        }

        //ch2のデータ名チェック
        let sCh2: string = csvElement[21].replace(/"/g, "").trim();
        if (sCh2 != "EC淡水" && sCh2 != "ECFreshWater" && sCh2 != "") {
          alert(`${index1 + 1}件目のデータ形式に問題があります。`);
          return;
        }

        //ch3のデータ名チェック
        let sCh3: string = csvElement[24].replace(/"/g, "").trim();
        if (sCh3 != "EC海水" && sCh3 != "ECSeaWater" && sCh3 != "") {
          alert(`${index1 + 1}件目のデータ形式に問題があります。`);
          return;
        }

        //voltageのデータ名チェック
        let sVoltage: string = csvElement[27].replace(/"/g, "").trim();
        // if (sVoltage != "EC電圧" && sVoltage != "ECBatteryVoltage" && sVoltage != ""){
        //   alert(`${index1 + 1}件目のデータ形式に問題があります。`);
        //   return;
        // }
        if (sVoltage != "バッテリー電圧" && sVoltage != "BatteryVoltage" && sVoltage != ""){
          alert(`${index1 + 1}件目のデータ形式に問題があります。`);
          return;
        }

        // RS232C
        let sRS232C: string = csvElement[30].replace(/"/g, "").trim();

        //数値型項目のチェック
        //吉原対応_項番130(電圧の最大値・最小値であるcsvElement[28]とcsvElement[29]をチェック項目から削除)
        if ((sAdc0 != "" && (Number.isNaN(parseFloat(csvElement[6])) || Number.isNaN(parseFloat(csvElement[7])) || Number.isNaN(parseFloat(csvElement[9])) || Number.isNaN(parseFloat(csvElement[10]))))
        || (sAdc1 != "" && (Number.isNaN(parseFloat(csvElement[13])) || Number.isNaN(parseFloat(csvElement[14])) || Number.isNaN(parseFloat(csvElement[16])) || Number.isNaN(parseFloat(csvElement[17]))))
        || (sCh1 != "" && (Number.isNaN(parseFloat(csvElement[19])) || Number.isNaN(parseFloat(csvElement[20]))))
        || (sCh2 != "" && (Number.isNaN(parseFloat(csvElement[22])) || Number.isNaN(parseFloat(csvElement[23]))))
        || (sCh3 != "" && (Number.isNaN(parseFloat(csvElement[25])) || Number.isNaN(parseFloat(csvElement[26]))))
        || (sRS232C != "" && (Number.isNaN(parseFloat(csvElement[31])) || Number.isNaN(parseFloat(csvElement[32]))))
        ) {
          alert(`${index1 + 1}件目のデータ形式に問題があります。\r\n数値を想定している項目の入力内容を再度確認してください。`);
          return;
        }

        // if(Number.isNaN(parseFloat(csvElement[6])) || Number.isNaN(parseFloat(csvElement[7])) || Number.isNaN(parseFloat(csvElement[9]))
        // || Number.isNaN(parseFloat(csvElement[10])) || Number.isNaN(parseFloat(csvElement[13])) || Number.isNaN(parseFloat(csvElement[14]))
        // || Number.isNaN(parseFloat(csvElement[16])) || Number.isNaN(parseFloat(csvElement[17])) || Number.isNaN(parseFloat(csvElement[19]))
        // || Number.isNaN(parseFloat(csvElement[20])) || Number.isNaN(parseFloat(csvElement[22])) || Number.isNaN(parseFloat(csvElement[26]))
        // || Number.isNaN(parseFloat(csvElement[28])) || Number.isNaN(parseFloat(csvElement[29])) || Number.isNaN(parseFloat(csvElement[31]))
        // || Number.isNaN(parseFloat(csvElement[32]))){
        //   alert(`${index1 + 1}件目のデータ形式に問題があります。\r\n数値を想定している項目の入力内容を再度確認してください。`);
        //   return;
        // }

        // 緯度
        let sLat = csvElement[34].replace(/"/g, "").trim();
        // 経度
        let sLng = csvElement[35].replace(/"/g, "").trim();

        //ゼロ除算対策チェック
        if((sAdc0 != "" && parseFloat(csvElement[7]) == 0) || (sAdc1 != "" && parseFloat(csvElement[14]) == 0)) {
          alert(`${index1 + 1}件目のデータ形式に問題があります。\r\n0が適用できない項目に0が入力されています。`);
          return;
        }

        //以下固定値チェック
        //水温の最小値
        if(sCh1 != "" && parseFloat(csvElement[19]) < -5) {
          alert(`${index1 + 1}件目のデータに問題があります。\r\nch1の最小値は-5より大きい必要があります。`);
          return;
        }

        //水温の最大値
        if(sCh1 != "" && parseFloat(csvElement[20]) > 45) {
          alert(`${index1 + 1}件目のデータに問題があります。\r\nch1の最大値は45より小さい必要があります。`);
          return;
        }

        //EC淡水の最小値
        if(sCh2 != "" && parseFloat(csvElement[22]) < 0) {
          alert(`${index1 + 1}件目のデータに問題があります。\r\nch2の最小値は0より大きい必要があります。`);
          return;
        }

        //EC淡水の最大値(オフ)
        //  if((csvElement[23].replace(/"/g, "")).trim() != "-"){
        //    alert(`${index1 + 1}件目のデータに問題があります。\r\nch2の最大値は-にする必要があります。`);
        //    return;
        //   }

        //EC海水の最小値(オフ)
        //  if((csvElement[25].replace(/"/g, "")).trim() != "-"){
        //    alert(`${index1 + 1}件目のデータに問題があります。\r\nch3の最小値は-にする必要があります。`);
        //    return;
        //   }

        //EC海水の最大値
        if(sCh3 != "" && parseFloat(csvElement[26]) > 60){
          alert(`${index1 + 1}件目のデータに問題があります。\r\nch3の最大値は60より小さい必要があります。`);
          return;
        }

        //吉原対応_項番130
        //電圧の最小値
        if(sVoltage != ""){

          if(!Number.isNaN(parseFloat(csvElement[28]))){

            if(parseFloat(csvElement[28]) < 10.8){
              alert(`${index1 + 1}件目のデータに問題があります。\r\n電圧の最小値は10.8より大きい必要があります。`);
              return;
            }
          }
          // alert(`${index1 + 1}件目のデータに問題があります。\r\n電圧の最小値は10.8より大きい必要があります。`);
          // return;
        }

        //吉原対応_項番130
        //電圧の最大値
        if(sVoltage != ""){

          if(!Number.isNaN(parseFloat(csvElement[29]))){

            if(parseFloat(csvElement[29]) > 26.2){
              alert(`${index1 + 1}件目のデータに問題があります。\r\n電圧の最大値は26.2より小さい必要があります。`);
              return;
            }
          }
          // alert(`${index1 + 1}件目のデータに問題があります。\r\n電圧の最大値は26.2より小さい必要があります。`);
          // return;
        }

        // 緯度経度
        if (this.enableMap && ((sLat != "" && parseFloat(csvElement[34]) <= 0) || (sLng != "" && parseFloat(csvElement[35]) <= 0)))
        {
          alert(`${index1 + 1}件目のデータに問題があります。緯度経度値である必要があります。`);
          return;
        }
      }
    }

    let isSave = window.confirm('読み込んだ地点情報を保存します。\r\n よろしいですか？');

    if(isSave == true){
      this.assignColumnData(csvArray);
    }
  }

  //バックエンドに整形したデータを一行分ずつ送る
  assignColumnData(csvArray: any){
    let mappedToCh1 = "";
    let mappedToCh2 = "";
    let mappedToCh3 = "";
    let mappedToVoltage = "";
    let mappedToAdc0 = "";
    let mappedToAdc1 = "";
    let mappedToRs232c = "";

    let sensorNameArray: any[] = [];
    let mainSensorArray: any[] = [];

    //一度csvファイルの内容を上下逆にする(後述の重複回避のため)
    let reversedArray = csvArray.reverse();

    //前もってセンサーIDとセンサー名を正式な形式に変換
    for(let index = 0; index < reversedArray.length; index++){
      reversedArray[index][2] = (reversedArray[index][2].replace(/"/g, "")).trim();
      reversedArray[index][3] = (reversedArray[index][3].replace(/"/g, "")).trim();
    }

    //センサーIdかセンサー名が同じデータは削除する
    let formedArray = reversedArray.filter((i: any) => {

      if(mainSensorArray.indexOf(i[3]) === -1){
        mainSensorArray.push(i[3]);
        return i;
      }

      if(sensorNameArray.indexOf(i[2]) === -1){
        sensorNameArray.push(i[2]);
        return i;
      }
    })

    //20230803 追記 ↓↓↓
    let operatorId = sessionStorage.getItem('operatorId');
    let userId = sessionStorage.getItem('userId');
    let logDate = moment(new Date()).format("YYYY/MM/DD HH:mm");
    let funcName = "CSV-Import";

    let messageText = logDate.toString() + " " + operatorId + " " + userId + " " + funcName;
    this.todoService.outputLogs(messageText).subscribe((response: any) => {}); //インポートの変更履歴をDBにログとして保存する
    //20230803 追記 ↑↑↑

    //項目毎に正式な形式に変換
    //20230705 csvArrayからformedArrayに変更
    for(let index = 0; index < formedArray.length; index++){
      formedArray[index][1] = (formedArray[index][1].replace(/"/g, "")).trim();
      //formedArray[index][2] = (formedArray[index][2].replace(/"/g, "")).trim();
      //csvArray[index][3] = (csvArray[index][3].replace(/"/g, "")).trim();
      formedArray[index][4] = (formedArray[index][4].replace(/"/g, "")).trim();
      formedArray[index][5] = (formedArray[index][5].replace(/"/g, "")).trim();
      formedArray[index][6] = parseFloat(formedArray[index][6]);
      formedArray[index][7] = parseFloat(formedArray[index][7]);
      formedArray[index][8] = (formedArray[index][8].replace(/"/g, "")).trim();
      formedArray[index][9] = parseFloat(formedArray[index][9]);
      formedArray[index][10] = parseFloat(formedArray[index][10]);
      formedArray[index][11] = (formedArray[index][11].replace(/"/g, "")).trim();
      formedArray[index][12] = (formedArray[index][12].replace(/"/g, "")).trim();
      formedArray[index][13] = parseFloat(formedArray[index][13]);
      formedArray[index][14] = parseFloat(formedArray[index][14]);
      formedArray[index][15] = (formedArray[index][15].replace(/"/g, "")).trim();
      formedArray[index][16] = parseFloat(formedArray[index][16]);
      formedArray[index][17] = parseFloat(formedArray[index][17]);
      formedArray[index][18] = (formedArray[index][18].replace(/"/g, "")).trim();
      formedArray[index][19] = parseFloat(formedArray[index][19]);
      formedArray[index][20] = parseFloat(formedArray[index][20]);
      formedArray[index][21] = (formedArray[index][21].replace(/"/g, "")).trim();
      formedArray[index][22] = parseFloat(formedArray[index][22]);
      formedArray[index][23] = 0;//暫定
      formedArray[index][24] = (formedArray[index][24].replace(/"/g, "")).trim();
      formedArray[index][25] = 0;//暫定
      formedArray[index][26] = parseFloat(formedArray[index][26]);
      formedArray[index][27] = (formedArray[index][27].replace(/"/g, "")).trim();

      //吉原対応_項番130
      if(Number.isNaN(parseFloat(formedArray[index][28]))){
        formedArray[index][28] = null;
      }
      else{
        formedArray[index][28] = parseFloat(formedArray[index][28]);
      }
      // formedArray[index][28] = parseFloat(formedArray[index][28]);
      if(Number.isNaN(parseFloat(formedArray[index][28]))){
        formedArray[index][29] = null;
      }
      else{
        formedArray[index][29] = parseFloat(formedArray[index][29]);
      }
      // formedArray[index][29] = parseFloat(formedArray[index][29]);

      formedArray[index][30] = "";//暫定
      formedArray[index][31] = parseFloat(formedArray[index][31]);
      formedArray[index][32] = parseFloat(formedArray[index][32]);
      formedArray[index][33] = "";//暫定
      formedArray[index][34] = parseFloat(formedArray[index][34].replace(/"/g, "").trim());
      formedArray[index][35] = parseFloat(formedArray[index][35].replace(/"/g, "").trim());

      //英語にも対応
      //各入力項目に応じてDB用に値を変換する
      //adc0は水位のみ
      mappedToAdc0 = formedArray[index][4] === "水位計" ? "S001" : formedArray[index][4] === "WaterLevel" ? "S001" : "";

      //adc1は流量か濁度
      mappedToAdc1 = formedArray[index][11] === "流量計" ? "S005" : formedArray[index][11] === "濁度計" ? "S002"
      : formedArray[index][11] === "WaterQuantity" ? "S005" : formedArray[index][11] === "Turbidity" ? "S002" : "";

      //ch1は水温のみ
      mappedToCh1 = formedArray[index][18] === "EC水温" ? "S007" : formedArray[index][18] === "ECWaterTemprature" ? "S007" : "";

      //ch2は淡水電気伝導度のみ
      mappedToCh2 = formedArray[index][21] === "EC淡水" ? "S004" : formedArray[index][21] === "ECFreshWater" ? "S004" : "";

      //ch3は海水電気伝導度のみ
      mappedToCh3 = formedArray[index][24] === "EC海水" ? "S006" : formedArray[index][24] === "ECSeaWater" ? "S006" : "";

      //voltageはバッテリー電圧のみ
      // mappedToVoltage = formedArray[index][27] === "EC電圧" ? "S003" : formedArray[index][27] === "ECBatteryVoltage" ? "S003" : "";
      mappedToVoltage = formedArray[index][27] === "バッテリー電圧" ? "S003" : formedArray[index][27] === "BatteryVoltage" ? "S003" : "";

      //RS232Cには暫定的に空文字を入れる
      mappedToRs232c = "";

      //デバッグ用
      // return;

      //ch1～adc1の六項目とRS232Cにデータを分けてバックエンドに送る
      this.inputArray.length = 0;

      for(let index2 = 0; index2 < 7; index2++){
        let mappedTo = index2 === 0? mappedToAdc0 : index2 === 1? mappedToAdc1 : index2 === 2? mappedToCh1 : index2 === 3? mappedToCh2
        : index2 === 4? mappedToCh3 : index2 === 5? mappedToVoltage: mappedToRs232c;

        let dataPointName = index2 === 0? "adc0" : index2 === 1? "adc1" : index2 === 2? "ch1" : index2 === 3? "ch2"
        : index2 === 4? "ch3" : index2 === 5? "ch4": "RS232C";

        //adc0の場合
        if(dataPointName == "adc0"){

          const dataPointMappingData = {
            CityId: this.currentCityId,
            AreaName: formedArray[index][1],
            SensorName: formedArray[index][2],
            MainSensorId: formedArray[index][3],
            OutputVal: formedArray[index][5],
            MinVVal: formedArray[index][6],
            MaxVVal: formedArray[index][7],
            Capacity: formedArray[index][8],
            MinMeasurable: formedArray[index][9],
            MaxMeasurable: formedArray[index][10],
            Rs232c: "",
            DataPointName: dataPointName,
            MappedTo: mappedTo,
            Lat: formedArray[index][34],
            Lng: formedArray[index][35]
          }
          this.inputArray.push(dataPointMappingData);
        }
        //adc1の場合
        else if(dataPointName == "adc1"){

          const dataPointMappingData = {
            CityId: this.currentCityId,
            AreaName: formedArray[index][1],
            SensorName: formedArray[index][2],
            MainSensorId: formedArray[index][3],
            OutputVal: formedArray[index][12],
            MinVVal: formedArray[index][13],
            MaxVVal: formedArray[index][14],
            Capacity: formedArray[index][15],
            MinMeasurable: formedArray[index][16],
            MaxMeasurable: formedArray[index][17],
            Rs232c: "",
            DataPointName: dataPointName,
            MappedTo: mappedTo,
            Lat: formedArray[index][34],
            Lng: formedArray[index][35]
          }
          this.inputArray.push(dataPointMappingData);
        }
        //ch1の場合
        else if(dataPointName == "ch1"){

          const dataPointMappingData = {
            CityId: this.currentCityId,
            AreaName: formedArray[index][1],
            SensorName: formedArray[index][2],
            MainSensorId: formedArray[index][3],
            OutputVal: "",
            MinVVal: formedArray[index][19],
            MaxVVal: formedArray[index][20],
            Capacity: "",
            MinMeasurable: 0,
            MaxMeasurable: 0,
            Rs232c: "",
            DataPointName: dataPointName,
            MappedTo: mappedTo,
            Lat: formedArray[index][34],
            Lng: formedArray[index][35]
          }
          this.inputArray.push(dataPointMappingData);
        }
        //ch2の場合
        else if(dataPointName == "ch2"){

          const dataPointMappingData = {
            CityId: this.currentCityId,
            AreaName: formedArray[index][1],
            SensorName: formedArray[index][2],
            MainSensorId: formedArray[index][3],
            OutputVal: "",
            MinVVal: formedArray[index][22],
            MaxVVal: formedArray[index][23],
            Capacity: "",
            MinMeasurable: 0,
            MaxMeasurable: 0,
            Rs232c: "",
            DataPointName: dataPointName,
            MappedTo: mappedTo,
            Lat: formedArray[index][34],
            Lng: formedArray[index][35]
          }
          this.inputArray.push(dataPointMappingData);
        }
        //ch3の場合
        else if(dataPointName == "ch3"){

          const dataPointMappingData = {
            CityId: this.currentCityId,
            AreaName: formedArray[index][1],
            SensorName: formedArray[index][2],
            MainSensorId: formedArray[index][3],
            OutputVal: "",
            MinVVal: formedArray[index][25],
            MaxVVal: formedArray[index][26],
            Capacity: "",
            MinMeasurable: 0,
            MaxMeasurable: 0,
            Rs232c: "",
            DataPointName: dataPointName,
            MappedTo: mappedTo,
            Lat: formedArray[index][34],
            Lng: formedArray[index][35]
          }
          this.inputArray.push(dataPointMappingData);
        }
        //voltageの場合
        else if(dataPointName == "ch4"){

          const dataPointMappingData = {
            CityId: this.currentCityId,
            AreaName: formedArray[index][1],
            SensorName: formedArray[index][2],
            MainSensorId: formedArray[index][3],
            OutputVal: "",
            MinVVal: formedArray[index][28],
            MaxVVal: formedArray[index][29],
            Capacity: "",
            MinMeasurable: 0,
            MaxMeasurable: 0,
            Rs232c: "",
            DataPointName: dataPointName,
            MappedTo: mappedTo,
            Lat: formedArray[index][34],
            Lng: formedArray[index][35]
          }
          this.inputArray.push(dataPointMappingData);
        }
        //RS232Cの場合
        else if(dataPointName == "RS232C"){

          const dataPointMappingData = {
            CityId: this.currentCityId,
            AreaName: formedArray[index][1],
            SensorName: formedArray[index][2],
            MainSensorId: formedArray[index][3],
            OutputVal: "",
            MinVVal: formedArray[index][31],
            MaxVVal: formedArray[index][32],
            Capacity: "",
            MinMeasurable: 0,
            MaxMeasurable: 0,
            Rs232c: "",
            DataPointName: dataPointName,
            MappedTo: mappedTo,
            Lat: formedArray[index][34],
            Lng: formedArray[index][35]
          }
          this.inputArray.push(dataPointMappingData);
        }
      }

      this.todoService.processTotalDataPointMapping(this.inputArray).subscribe((response: any) => {

        //全てのデータを保存した後のみ有効
        if(index + 1 == formedArray.length){
          let deleteBasisArray: any[] = new Array;

          for(let index3 = 0; index3 < formedArray.length; index3++){

            const deleteBasis = {
              CityId: this.currentCityId,
              AreaName: formedArray[index3][1],
              SensorName: formedArray[index3][2],
              MainSensorId: formedArray[index3][3]
            }
            deleteBasisArray.push(deleteBasis);
          }

          //既存データ配列にあって新規データ配列にはないデータを削除する
          this.todoService.deleteDataPointMappingAndOthers(deleteBasisArray).subscribe((response: any) => {
            //更新データ読み込み
            this.dataPointMapping();
            window.alert("地点情報の保存が完了しました。");
          })
        }
      });
    }
  }
}

function isNull(value: any): any
{
  if (typeof value !== "string" && (null == value || value == "NaN" || isNaN(value))) {
    return "-";
  }
  else
  {
    return value == "" ? "-" : value;
  }
}
