import { Component, OnInit } from '@angular/core';
import { TodoService } from 'src/app/services/todo.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { SnotifyService, SnotifyToast } from 'ng-snotify';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DatePipe, DecimalPipe } from '@angular/common';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { ActivatedRoute } from '@angular/router';
import { exit } from 'process';

@Component({
  selector: 'app-userdata-editor-add',
  templateUrl: './userdata-editor-add.component.html',
  styleUrls: ['./userdata-editor-add.component.scss']
})

export class UserdataEditorAddComponent implements OnInit {
  userId: string = "";
  userName: string = "";
  password: string = "";
  password2: string = "";
  role: any;
  mailAddress1: string = "";
  mailAddress2: string = "";
  level1: boolean = false;
  level2: boolean = false;
  level3: boolean = false;
  machineIrregular: boolean = false;
  cityId: any;
  firstName: string = "-";
  lastName: string = "-";
  phoneNumber: string = "-";
  id: any;
  //operatorId: any;
  modeMaster: any;
  isAdminUser: boolean = false;
  displayedMode: string = "Add new user";
  authority: any;
  isEditAuthority: boolean = false;
  isNormalUser: boolean = true;
  isNSCAdmin: boolean = false;
  defaultAuthority: any;

  constructor(private detailRouter: Router,
    public todoService: TodoService,
    private spinner: NgxSpinnerService, 
    public http: HttpClient,
    public cookieService: CookieService, 
    private snotifyService: SnotifyService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private dateTimeAdapter: DateTimeAdapter<any>){}

  ngOnInit(): void {
    this.authority = sessionStorage.getItem('role');

    if(this.authority == "nscadmin"){
      this.isNSCAdmin = true;
    }

    //this.operatorId = sessionStorage.getItem('operatorId');
    this.activatedRoute.queryParams.pipe().subscribe(params => {
      this.modeMaster = params.mode;

      if(params.mode == 'addMode'){
        this.displayedMode = "Add new user";
        this.cityId = params.cityid;
        this.addNewUser();
      }
      else if(params.mode == 'editMode'){
        const sessionData: any = sessionStorage.getItem('editUserData');
        const userData = JSON.parse(sessionData);
        this.displayedMode = "Update user";
        this.editUser(userData);
      }
    })
  }

  //ユーザ管理画面に戻るボタン押下
  backUserListPage(): void{

    this.detailRouter.navigate(['db/user-management']), {
      queryparams: {}
    }
  }

  //新規ユーザ追加のための表示初期化
  addNewUser(): void{

    if(this.authority == "normal"){
      this.isEditAuthority = true;
    }

    if(this.authority == "admin"){
      this.role = 0;
      this.isAdminUser = true;
      this.isNormalUser = false;
    }
    else if(this.authority == "normal"){
      this.role = 1;
      this.isAdminUser = false;
      this.isNormalUser = true;

      (document.getElementById(`userid`) as HTMLInputElement).disabled = true;
      (document.getElementById(`username`) as HTMLInputElement).disabled = true;
      (document.getElementById(`role`) as HTMLSelectElement).disabled = true;
      (document.getElementById(`level1`) as HTMLInputElement).disabled = true;
      (document.getElementById(`level2`) as HTMLInputElement).disabled = true;
      (document.getElementById(`level3`) as HTMLInputElement).disabled = true;
      (document.getElementById(`machineirregular`) as HTMLInputElement).disabled = true;
    }
    else if(this.authority == "nscdmin"){
      this.role = 2;
      this.isAdminUser = true;
      this.isNormalUser = false;
    }
   
    this.userId = "";
    this.userName = "";
    this.password = "";
    this.password2 = "";
    this.role = 0;
    this.mailAddress1 = "";
    this.mailAddress2 = "";
    this.level1 = false;
    this.level2 = false;
    this.level3 = false;
    this.machineIrregular = false;
    this.isAdminUser = true;

    if(this.authority == "normal"){
      this.isEditAuthority = true;
    }
  }

  //ユーザ情報編集のためのデータの準備
  editUser(userData: any): void{
    this.id = userData.id;
    this.userId = userData.userid;
    this.userName = userData.username;
    this.password = userData.password;
    this.defaultAuthority = userData.role;

    //this.authority = userData.role;
    if(this.authority == "normal"){
      this.isEditAuthority = true;
    }

    if(this.authority == "admin"){
      this.role = 0;
      this.isAdminUser = true;
      this.isNormalUser = false;
    }
    else if(this.authority == "normal"){
      this.role = 1;
      this.isAdminUser = false;
      this.isNormalUser = true;
      
      (document.getElementById(`userid`) as HTMLInputElement).disabled = true;
      (document.getElementById(`username`) as HTMLInputElement).disabled = true;
      (document.getElementById(`role`) as HTMLSelectElement).disabled = true;
      (document.getElementById(`level1`) as HTMLInputElement).disabled = true;
      (document.getElementById(`level2`) as HTMLInputElement).disabled = true;
      (document.getElementById(`level3`) as HTMLInputElement).disabled = true;
      (document.getElementById(`machineirregular`) as HTMLInputElement).disabled = true;
    }
    else if(this.authority == "nscdmin"){
      this.role = 2;
      this.isAdminUser = true;
      this.isNormalUser = false;
    }
    
    this.mailAddress1 = userData.email;
    this.mailAddress2 = userData.email2;
    this.level1 = userData.alertLevel1;
    this.level2 = userData.alertLevel2;
    this.level3 = userData.alertLevel3;
    this.machineIrregular = userData.alertMachineIrregular;
    this.cityId = userData.cityid;
    this.firstName = userData.firstname;
    this.lastName = userData.lastname;
    this.phoneNumber = userData.phonenumber;
  }

  //入力されたユーザ情報を保存するボタン押下
  keepUserData(): void{
    //let selectedRole = (document.getElementById('role') as HTMLInputElement).value;

    //未入力の項目がないかのチェック
    if((document.getElementById(`userid`) as HTMLInputElement).value == "" || (document.getElementById(`userid`) as HTMLInputElement).value == undefined
    || (document.getElementById(`password`) as HTMLInputElement).value == "" || (document.getElementById(`password`) as HTMLInputElement).value == undefined
    || (document.getElementById(`username`) as HTMLInputElement).value == "" || (document.getElementById(`username`) as HTMLInputElement).value == undefined){
      alert('ユーザID、ユーザ名またはパスワードが入力されていません');
      return;
    }

    //半角英数字かどうかのチェック
    if(!(document.getElementById(`userid`) as HTMLInputElement).value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/) 
    || !(document.getElementById('password') as HTMLInputElement).value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)){
      alert('ユーザID及びパスワードは半角英数字のみ有効です');
      return;
    }

    //確認用パスワードが入力されているかどうかのチェック
    if((document.getElementById(`password2`) as HTMLInputElement).value == "" || (document.getElementById(`password2`) as HTMLInputElement).value == undefined){
      alert('確認用パスワードを入力してください');
      return;
    }

    //入力されたパスワードと二つ目のパスワードが一致しているかのチェック
    if((document.getElementById(`password`) as HTMLInputElement).value !== (document.getElementById(`password2`) as HTMLInputElement).value){
      alert('入力されたパスワードと確認用パスワードが一致しません');
      return;
    }

    //  //パスワード暗号化部分
    //  //小文字アルファベット参照用
    //  let smallLetterAlphabet = "abcdefghijklmnopqrstuvwxyz";
    //  //大文字アルファベット参照用
    //  let capitalLetterAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    //  let newPassword = (document.getElementById(`password`) as HTMLInputElement).value;
    //  let convertedItem = "";
    //  let convertedPassword = "";
    //  const passwordLength = newPassword.length;

    //  //パスワード文字列を一文字ずつ取り出しそれを変換する
    //  for(let index = 0; index < passwordLength; index++){
    //    let item = newPassword.slice(index, index + 1);
  
    //    let smallLetterResult = item.match(/[a-z]/);
  
    //    //aからzまでの小文字だった場合
    //    if(smallLetterResult != null){
    //      //以下の場合は固定で変換
    //      if(smallLetterResult[0] == "v"){
    //        convertedItem = "a";
    //       }
    //      else if(smallLetterResult[0] == "w"){
    //        convertedItem = "b";
    //       }
    //      else if(smallLetterResult[0] == "x"){
    //        convertedItem = "c";
    //       }
    //      else if(smallLetterResult[0] == "y"){
    //        convertedItem = "d";
    //       }
    //      else if(smallLetterResult[0] == "z"){
    //        convertedItem = "e"
    //       }
    //      //上記以外の場合
    //      else{
    //        //後ろに5つずらしたアルファベット(小文字)に変換する
    //        const result = (smallLetterAlphabet.indexOf(smallLetterResult[0])) + 5;
    //        convertedItem = smallLetterAlphabet.slice(result, result + 1);
    //       }
    //     }
    //    else{
    //      let capitalLetterResult = item.match(/[A-Z]/);
    
    //      //AからZまでの大文字だった場合
    //      if(capitalLetterResult != null){
    //        //そこから後ろに5つずらしたアルファベット(大文字)に変換する
    //        //以下の場合は固定で変換
    //        if(capitalLetterResult[0] == "V"){
    //          convertedItem = "A";
    //         }
    //        else if(capitalLetterResult[0] == "W"){
    //          convertedItem = "B";
    //         }
    //        else if(capitalLetterResult[0] == "X"){
    //          convertedItem = "C";
    //         }
    //        else if(capitalLetterResult[0] == "Y"){
    //          convertedItem = "D";
    //         }
    //        else if(capitalLetterResult[0] == "Z"){
    //          convertedItem = "E"
    //         }
    //        //上記以外の場合
    //        else{
    //          //後ろに5つずらしたアルファベット(大文字)に変換する
    //          const result = (capitalLetterAlphabet.indexOf(capitalLetterResult[0])) + 5;
    //          convertedItem = capitalLetterAlphabet.slice(result, result + 1);
    //         }
    //       }
    //      else{
    //        let numberResult = item.match(/[0-9]/);
      
    //        //0から9までの数字だった場合
    //        if(numberResult != null){
    //          //以下の場合は固定で変換
    //          if(numberResult[0] == "7"){
    //            convertedItem = "0";
    //           }
    //          else if(numberResult[0] == "8"){
    //            convertedItem = "1";
    //           }
    //          else if(numberResult[0] == "9"){
    //            convertedItem = "2";
    //           }
    //          //上記以外の場合
    //          else{
    //            //後ろに3ずらした数字に変換する
    //            let num = parseFloat(numberResult[0]);
    //            convertedItem = (num + 3).toString();
    //           }
    //         }
    //        //上記以外(記号など)
    //        else{
    //          //記号はそのまま
    //          convertedItem = item;
    //         }
    //       }
    //     }
    //    //変換後パスワードに追加する
    //    convertedPassword += convertedItem;
    //   }

    //新規登録の場合
    if(this.modeMaster == 'addMode'){
      let mode = 'add';

      const data = {
        Userid: (document.getElementById(`userid`) as HTMLInputElement).value,
        Username: (document.getElementById(`username`) as HTMLInputElement).value,
        Password: (document.getElementById('password') as HTMLInputElement).value,
        Role: (document.getElementById('role') as HTMLInputElement).value,
        Email: (document.getElementById('mailaddress1') as HTMLInputElement).value,
        Email2: (document.getElementById('mailaddress2') as HTMLInputElement).value,
        AlertLevel1: (document.getElementById('level1') as HTMLInputElement).checked,
        AlertLevel2: (document.getElementById('level2') as HTMLInputElement).checked,
        AlertLevel3: (document.getElementById('level3') as HTMLInputElement).checked,
        AlertMachineIrregular: (document.getElementById('machineirregular') as HTMLInputElement).checked,
        Cityid: this.cityId,
        Firstname: this.firstName,
        Lastname: this.lastName,
        Phonenumber: this.phoneNumber
      }

      this.todoService.checkDuplicatedUser(data.Userid, data.Cityid, mode).subscribe((checkResponse: any) => {
        if(checkResponse.isAlreadyRegistered == true){
          alert('重複するIDを持つユーザがすでに登録されています。異なるユーザIDを設定してください。');
          return;
        }
        else{

          this.todoService.addUser(data).subscribe((response: any) => {
            this.backUserListPage();
          })
        }
      })
    }
    //編集の場合
    else if(this.modeMaster == 'editMode'){
      let mode = 'edit';

      const data = {
        id: this.id,
        userid: (document.getElementById(`userid`) as HTMLInputElement).value,
        username: (document.getElementById(`username`) as HTMLInputElement).value,
        password: (document.getElementById('password') as HTMLInputElement).value,
        role: (document.getElementById('role') as HTMLInputElement).value,
        email: (document.getElementById('mailaddress1') as HTMLInputElement).value,
        email2: (document.getElementById('mailaddress2') as HTMLInputElement).value,
        alertLevel1: (document.getElementById('level1') as HTMLInputElement).checked,
        alertLevel2: (document.getElementById('level2') as HTMLInputElement).checked,
        alertLevel3: (document.getElementById('level3') as HTMLInputElement).checked,
        alertMachineIrregular: (document.getElementById('machineirregular') as HTMLInputElement).checked,
        cityid: this.cityId,
        firstname: this.firstName,
        lastname: this.lastName,
        phonenumber: this.phoneNumber
      }

      // ユーザーIDの変更があった場合、重複チェック
      if(data.userid != this.userId) {
        this.todoService.checkDuplicatedUser(data.userid, data.cityid, mode).subscribe((checkResponse: any) => {
          // 重複があった場合、変更を却下
          if(checkResponse.isAlreadyRegistered == true){
            alert('重複するIDを持つユーザがすでに登録されています。異なるユーザIDを設定してください。');
            return;
          }
          // 重複がなければ、変更を実行
          else{
            this.todoService.updateUser(this.id, data).subscribe((response: any) => {
              this.backUserListPage();
            })
          }
        })
      }
      // ユーザーIDの変更がなければ、変更を実行
      else{
        this.todoService.updateUser(this.id, data).subscribe((response: any) => {
          this.backUserListPage();
        })
      }
    }
  }
}
