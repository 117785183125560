import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { AlarmComponent } from './components/alarm/alarm.component';
import { GraphComponent } from './components/graph/graph.component';
import { GraphTurbidityComponent } from './components/graph-turbidity/graph-turbidity.component';
import { SensorReportsComponent } from './components/sensor-reports/sensor-reports.component';
import { TrendComponent } from './components/trend/trend.component';
import { MapComponent } from './components/map/map.component';
import { MapTurbidityComponent } from './components/map-turbidity/map-turbidity.component'; // 20240423 濁度地図
import { PointDetailComponent } from './components/point-detail/point-detail.component';
import { PointDetailTurbidityComponent } from './components/point-detail-turbidity/point-detail-turbidity.component';
import { PointManagementComponent } from './components/point-management/point-management.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UserdataEditorAddComponent } from './components/userdata-editor-add/userdata-editor-add.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "point-selection",
        pathMatch: "full"
      },
      {
        path: 'alarm-summary',
        component: AlarmComponent
      },
      {
        path: 'point-selection',
        component: GraphComponent
      },
      {
        path: 'point-selection-turbidity',
        component: GraphTurbidityComponent
      },
      {
        path: 'trend',
        component: TrendComponent
      },
      {
        path: 'map',
        component: MapComponent
      },
      { path: 'map-turbidity', component: MapTurbidityComponent }, // 20240423 濁度地図
      {
        path: 'sensor-reports',
        component: SensorReportsComponent
      },
      {
        path: 'db/point-detail',
        component: PointDetailComponent
      },
      {
        path: 'db/point-detail-turbidity',
        component: PointDetailTurbidityComponent
      },
      {
        path: 'point-management',
        component: PointManagementComponent
      },
      {
        path: 'user-management',
        component: UserManagementComponent
      },
      {
        path: 'userdata-Addor-Edit',
        component: UserdataEditorAddComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TodoRoutingModule { }
