import {  HttpClient, HttpHeaders } from '@angular/common/http';
import {  Injectable  } from '@angular/core';
import {  BehaviorSubject, Observable, Subject  } from 'rxjs';
import {  Todo  } from '../model/todo.model';

//自前モジュール
import {  environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class TodoService {
  private subject = new Subject<any>();
  private language = new Subject<any>();
  currentCityName = ''; /*testing code*/
  userSelectedPoint: any;
  cityName = '';
  areaName = '';
  pointName = '';
  pointId = 0;
  areaId = 0;
  cityId = 0;
  selectedLanguage = '';
  selectedAreaData: any;
  selectedAreaType: any;
  selectedPointData: any;
  selectedPointType: any;

  // 接続先自動切替対応
  baseurl = environment.BACKEND_BASE_URL + '/api';
  // 本番URL(リリース時はこっちが有効)
  //baseurl = 'https://d1rh4b0dx4fns2.cloudfront.net/api';
  // ローカルURL
  //baseurl = "https://localhost:44392/api";

  headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
  languageData : BehaviorSubject<any>;

  constructor(private http: HttpClient){
    this.languageData = new BehaviorSubject<any>({});
  }

  /* Api calls for handling the city state subjects */
  getClickEvent(): Observable<any>{
    return this.subject.asObservable();
  }

  sendClickEvent(): void{
    this.subject.next();
  }

  getLangEvent(): Observable<any>{
    return this.language.asObservable();
  }

  sendLangEvent(): void{
    this.language.next();
  }

  /* get and set selected city and city id */
  getSelectedCity(): any{
    return this.cityName;
  }
  getSelectedCityID(){
    return this.cityId ;
  }

  getCityId(): any{
    return this.cityId;
  }

  setSelectedCity(cityName: string, cityId: any): void{
    this.cityName = cityName;
    this.cityId = cityId;
  }

  setSelectedLanguage(langugae: any): void{
    this.selectedLanguage = langugae;
  }

 getSelectedLanguage(): any{
   return this.selectedLanguage;
  }

 /* get data by sensor id with limit */
 getDataBySensorId(sensorId: number, data: any): Observable<any>{
   return this.http.post<any>(`${this.baseurl}/WaterFlow/getByMainSensorId/?mainsensorId=${sensorId}`, data, {headers: this.headers});
  }

 /* get data by sensor id and time stamp */

 // tslint:disable-next-line:typedef
 getAllBySensorIdAndTimeStamp(data: any){
   return this.http.get<any>(`${this.baseurl}/WaterFlow/getAllByMainSensorIdAndTimeStamp?mainsensorId=${data.sensorId}&fromDateTime=${data.fromDateTime}&toDateTime=${data.toDateTime}`, {headers: this.headers});
  }

 /* get all data by sensor id */
 getAllBySensorId(sensorId: any, date: any){
   return this.http.get<any>(`${this.baseurl}/WaterFlow/getAllByMainSensorId?mainsensorId=${sensorId}&date=${date}`, {headers: this.headers});
  }

 /* get api calls for binding cities,areas and area sensors start */

 // tslint:disable-next-line:typedef
 getCityByUserId(userId: any){
   return this.http.get(`${this.baseurl}/UserCity/getUserCitiesByUserId?userId=${userId}`);
  }

 // tslint:disable-next-line:typedef
 getAllCityAreas(){
   return this.http.get(`${this.baseurl}/CityArea/getAllCityAreas`);
  }

 // tslint:disable-next-line:typedef
 getAreaByCity(cityId: any){
   return this.http.get(`${this.baseurl}/CityArea/getCityAreasByCityId?cityId=${cityId}`);
  }

 // tslint:disable-next-line:typedef
 getSensorsByAreaId(areaId: any){
   return this.http.get(`${this.baseurl}/AreaSensor/getAreaSensorsByAreaId?areaId=${areaId}`);
  }

 /* get api calls for maintenance setting of a sensor */

 // tslint:disable-next-line:typedef
 getMaintenanceByMainSensorId(mainSensorId: any){
   return this.http.get(`${this.baseurl}/Maintenance/getMaintenanceByMainSensorId?mainSensorId=${mainSensorId}`);
  }

 /* get api calls for threshold setting of a sensor */

 // tslint:disable-next-line:typedef
 getThresholdValuesByMainSensorId(mainSensorId: any){
   return this.http.get(`${this.baseurl}/ThresholdValues/getThresholdValuesByMainSensorId?mainSensorId=${mainSensorId}`);
  }

 getThresholdValuesByCityId(cityId: any){
   return this.http.get(`${this.baseurl}/ThresholdValues/getThresholdValuesByCityId?cityId=${cityId}`);
  }

 /* get api calls for alarms history for all sensors */

 // tslint:disable-next-line:typedef
 updateMaintenance(currentMaintenaceid: any, data: any){
   // tslint:disable-next-line:max-line-length
   return this.http.put(`${this.baseurl}/Maintenance/UpdateMaintenance?id=${currentMaintenaceid}`, data);
  }

 /* get api calls for alarms history for all sensors */

 // tslint:disable-next-line:typedef
 getAlarmsHistory(){
   return this.http.get(`${this.baseurl}/Alerts/getAllAlerts`);
  }

 getAlertsBySensorId(mainSensorId: any): any{
   return this.http.get(`${this.baseurl}/Alerts/getAlertsByMainSensorId?mainSensorId=${mainSensorId}`);
  }
 getAlertsByFilterDate(sendData : any){
   return this.http.get(`${this.baseurl}/Alerts/getAlertsByMainSensorIdAndDate?mainSensorId=${sendData.mainSensorId}&fromDate=${sendData.fromDate}&toDate=${sendData.toDate}`);
  }

 getAlertsByCityIdAndDate(sendData:any){
   return this.http.get(`${this.baseurl}/Alerts/getAlertsByCityIdAndDate?cityId=${sendData.cityId}&fromDate=${sendData.fromDate}&toDate=${sendData.toDate}`);
  }

 // tslint:disable-next-line:typedef
 getUnreadAlerts(status: any){
   return this.http.get(`${this.baseurl}/Alerts/getAllReadUnreadAlerts?isRead=${status}`);
  }

 // tslint:disable-next-line:typedef
 updateAlert(object: any, id: any){
   return this.http.put(`${this.baseurl}/Alerts/UpdateAlert?id=${id}`, object);
  }

 /* calls to be deleted */

 // tslint:disable-next-line:typedef
 fetchTodos(){
   return this.http.get<Todo[]>('https://jsonplaceholder.typicode.com/todos');
  }

 // tslint:disable-next-line:typedef
 deleteTodo(id: number){
   return this.http.delete(`https://jsonplaceholder.typicode.com/todos/${id}`);
  }

  // tslint:disable-next-line:typedef
 addTodo(payload: Todo){
   return this.http.post<Todo>('https://jsonplaceholder.typicode.com/todos', payload);
  }

  // tslint:disable-next-line:typedef
 updateTodo(payload: Todo, id: number){
   return this.http.put<Todo>(`https://jsonplaceholder.typicode.com/todos/${id}`, payload);
  }

 // tslint:disable-next-line:typedef
 updateThresholdValues(id: any, body: any){
   // tslint:disable-next-line:max-line-length
   return this.http.put(`${this.baseurl}/ThresholdValues/UpdateThresholdValues?id=${id}`, body);
  }

 /* get sensor data by main sensor id and date*/
 getAllByMainSensorId(mainSensorID: any, date: any): any{
   return this.http.get(`${this.baseurl}/WaterFlow/getAllByMainSensorId?mainsensorId=${mainSensorID}&date=${date}`);
  }

 /* get city name */
 setAreaName(name: any): any{
   this.areaName = name;
   // return this.areaName;
  }

 setAreaId(areaId: any): any{
   this.areaId = areaId;
  }

 getSelectedAreaId(): any{
   return this.areaId;
  }

 getSelectedAreaName(): any{
   return this.areaName;
  }

 /* get point name */
 setPointName(name: any): any{
   this.pointName = name;
   // return this.areaName;
  }

 setPointId(areaId: any): any{
   this.pointId = areaId;
  }

 setSelectedAreaData(data: any, type: string): any{
   this.selectedAreaData = data;
   this.selectedAreaType = type;
  }
 getSelectedAreaData(): any{
   return [this.selectedAreaData, this.selectedAreaType];
  }

 setCurrentCity(currentCity: any): any{
   this.currentCityName = currentCity.cityname;
  }

 getCurrentCity(): any{
   return this.currentCityName;
  }

 getAllByMainSensorIdAndMultipleDates(filteredDateData: any): any{
   return this.http.get(`${this.baseurl}/WaterFlow/getAllByMainSensorIdAndMultipleDates?mainsensorId=${filteredDateData.mainSensorID}&fromDate=${filteredDateData.fromDate}&toDate=${filteredDateData.toDate}`);
  }

 //追記12/12
 getAllByCityIdAndMultipleDates(filteredDateData: any): any{
   return this.http.get(`${this.baseurl}/WaterFlow/getAllByCityIdAndMultipleDates?cityid=${filteredDateData.cityId}&fromDate=${filteredDateData.fromDate}&toDate=${filteredDateData.toDate}`);
  }
 getAllSensorTypes(): any{
   return this.http.get(`${this.baseurl}/SensorTypes/GetAllSensorTypes`);
  }

 addThresholdValues(data: any): any{
   return this.http.post(`${this.baseurl}/ThresholdValues/AddThresholdValues`, data, {headers: this.headers});
  }

 addOrUpdateThresholdValues(id: any, data: any): any{
   return this.http.post(`${this.baseurl}/ThresholdValues/AddOrUpdateThresholdValues?id=${id}`, data);
  }

 setDropDownSelectedPoint(pointData: any): any{
   this.userSelectedPoint = pointData;
  }

 getDropDownSelectedPoint(): any{
   return this.userSelectedPoint;
  }

 //新規作成12/22
 getDataPointMappingByCityId(cityId: any): any{
   return this.http.get(`${this.baseurl}/DataPointMapping/getDataPointMappingByCityId?cityId=${cityId}`);
  }

 //新規作成1/5
 getAllUsersByCityId(cityId: any): any{
   return this.http.get(`${this.baseurl}/User/getAllUsersByCityId?cityId=${cityId}`);
  }

 //新規作成1/6
 deleteUser(Id: string): Observable<any>{
   return this.http.post<any>(`${this.baseurl}/User/DeleteUser?Id=${Id}`, {headers: this.headers});
  }

 //新規作成1/6
 addUser(addUserData: any): Observable<any>{
   return this.http.post<any>(`${this.baseurl}/User/AddUser`, addUserData, {headers: this.headers});
  }

 //新規作成1/6
 updateUser(id: any, editedData: any): Observable<any>{
   return this.http.post<any>(`${this.baseurl}/User/UpdateUser?id=${id}`, editedData, {headers: this.headers});
  }

 //新規作成2/28
 getDataPointMappingByMainSensorId(MainSensorid: any): any{
   return this.http.get(`${this.baseurl}/DataPointMapping/getDataPointMappingByMainSensorId?mainSensorId=${MainSensorid}`);
  }

 //新規作成3/1
 updateDataPointMapping(id: string, requestData: any){
   return this.http.put(`${this.baseurl}/DataPointMapping/UpdateDataPointMapping?id=${id}`, requestData);
  }

 //新規作成3/7
 getSensorNamesAndAreaNamesByCityId(cityId: string){
   return this.http.get(`${this.baseurl}/DataPointMapping/getSensorNamesAndAreaNamesByCityId?cityId=${cityId}`)
  }

 //新規作成3/23
 processTotalDataPointMapping(request: any): Observable<any>{
   return this.http.post<any>(`${this.baseurl}/DataPointMapping/processPointManagementData`, request, {headers: this.headers});
  }

 //新規作成3/24
 deleteDataPointMappingAndOthers(request: any): Observable<any>{
   return this.http.post<any>(`${this.baseurl}/DataPointMapping/deleteDataPointMappingAndOthers`, request, {headers: this.headers});
  }

 //新規作成4/19
 getLatestData(lastDateList: any){
   return this.http.post<any>(`${this.baseurl}/WaterFlow/getLatestData`, lastDateList, {headers: this.headers});
  }

 //新規追加7/4
 checkDuplicatedUser(userId: string, cityId: string, mode: string){
   return this.http.get(`${this.baseurl}/User/checkDuplicatedUser?userId=${userId}&cityId=${cityId}&mode=${mode}`);
  }

 //新規作成2024/3/29
 getForecastByMainsensoridTimestamp(sendData: any){
   return this.http.get(`${this.baseurl}/RainfallForecast/getForecastByMainsensoridTimestamp?mainSensorId=${sendData.mainSensorid}&timestamp=${sendData.toDate}`);
  }
 
 //新規作成2024/3/29
 getAnalyticalByMainsensoridTimestamp(sendData: any){
   return this.http.get(`${this.baseurl}/RainfallAnalytical/getAnalyticalByMainsensoridTimestamp?mainSensorId=${sendData.mainSensorid}&timestamp=${sendData.toDate}`);
  }

  //新規作成2024/4/8
 getTurbidityPredictByMainsensoridTimestamp(sendData: any){
  return this.http.get(`${this.baseurl}/TurbidityPredict/getTurbidityPredictByMainsensoridTimestamp?mainSensorId=${sendData.mainSensorid}&timestamp=${sendData.toDate}`);
 }

  //新規作成2024/4/8
  checkAllLatestTimeByMainsensoridAndTimeStamp(sendData: any){
  return this.http.get(`${this.baseurl}/TurbidityPredict/checkAllLatestTimeByMainsensoridAndTimeStamp?mainSensorId=${sendData.mainSensorid}&timestamp=${sendData.toDate}`);
 }

 //新規作成2024/4/11
 getAllTurbidityByCityIdAndBaseDate(sendData: any){
  return this.http.get(`${this.baseurl}/TurbidityPredict/getAllTurbidityByCityIdAndBaseDate?cityId=${sendData.cityId}&baseDate=${sendData.baseDate}`);
 }

 //新規作成2024/4/19
 getAllTurbidityByMainSensorIdAndMultipleDates(sendData: any){
  return this.http.get(`${this.baseurl}/TurbidityPredict/getAllTurbidityByMainSensorIdAndMultipleDates?mainsensorId=${sendData.mainSensorID}&fromDate=${sendData.fromDate}&toDate=${sendData.toDate}`);
 }

 //ベルマークアイコン部分に表示するアラートの取得をおこなう
 getRecentAlerts(isRead: string, cityId: string){
   return this.http.get(`${this.baseurl}/Alerts/getRecentAlerts?isRead=${isRead}&cityId=${cityId}`);
  }

 //諸変更履歴をログで保存する
 outputLogs(message: string){
   return this.http.get(`${this.baseurl}/User/outputLogs?message=${message}`); //現在は「User」でおこなっているが将来的には「Logs」で実装したい
  }

 //閾値超過取得(詳細画面)
 getThresholdAlertsByMainSensorId(sendDataArray: any){
   return this.http.get(`${this.baseurl}/ThresholdValues/getThresholdAlertsByMainSensorId?mainSensorId=${sendDataArray.mainSensorId}&fromDate=${sendDataArray.fromDate}&toDate=${sendDataArray.toDate}`)
  }

 //閾値超過取得(アラート一覧画面)
 getThresholdAlertsByCityId(sendDataArray: any){
   return this.http.get(`${this.baseurl}/ThresholdValues/getThresholdAlertsByCityId?cityId=${sendDataArray.cityId}&fromDate=${sendDataArray.fromDate}&toDate=${sendDataArray.toDate}`)
  }

  //吉原対応_項番121
  //全てのアラートを既読にする
  markAllAlertAsRead(cityId: string, timestamp: number){
    return this.http.get(`${this.baseurl}/Alerts/MarkAsReadAllAlerts?timestamp=${timestamp}&cityId=${cityId}`);
  }

 //  updateDataPointMapping(request: any): Observable<any>{
 //   return this.http.post<any>('${this.baseurl}/DataPointMapping/AddDataPointMapping', request, {headers: this.headers});
 //  }

 private _showLogin$ = new BehaviorSubject<any>('');
 showLogin$ = this._showLogin$.asObservable();

 setMenu(loginValue: string){
   this._showLogin$.next(loginValue)
  }

  // 20240423 濁度地図 ↓↓↓
  getAllTurbidityThresholdValues() {
    return this.http.get(`${this.baseurl}/TurbidityMap/getAllTurbidityThresholdValues`);
  }
  getAllTurbidityAndRainfallListByCityid(cityId: any, date:any) {
    return this.http.get(`${this.baseurl}/TurbidityMap/getAllTurbidityAndRainfallListByCityid?cityId=${cityId}&date=${date}`);
  }
  // 20240423 濁度地図 ↑↑↑
}



