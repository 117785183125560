//共通モジュール
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

//自前モジュール
import { TodoService } from 'src/app/services/todo.service';

//必要モジュール
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { BaseChartDirective } from 'ng2-charts';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { SnotifyService, SnotifyToast } from 'ng-snotify';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from "lodash";
import { MatTableDataSource } from '@angular/material/table';

import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { GoogleMap, MapMarker, MapInfoWindow } from '@angular/google-maps';


//インターフェイス
export interface PointData {
  checkedOrNot: boolean;
  PointDetail: string;
  cityName: string;
  areaName: string;
  sensorName: string;
  dateTime: string;
  waterQuantity: string;
  waterLevel: number | string;
  freshwaterConductivity: number | string;
  saltwaterConductivity: number | string;
  waterTemprature: number | string;
  batteryVoltage: number | string;
  turbidity: string;
  mainSensorId: string;
}

export interface prepareData {
  cityName: string;
  areaName: string;
  sensorName: string;
}

export interface SessionData {
  cityName: string;
  areaName: string;
  sensorName: string;
  mainSensorId: string;
}

export interface MarkerInfo {
  areaName: string;
  mainSensorId: string;
  pointName: string;
  lat: number;
  lng: number;
}

//定数定義
const pd = { PointDetail: "" };
const cb = { checkedOrNot: true };

const sortObjectsArray = require('sort-objects-array');

const ONEBLOCKPX: number = 216;       //グラフの6時間毎の横幅
const BORDERWIDTH: number = 1;        //グラフの線の太さ
const INITIALBLOCKVALUE: number = 5;  //グラフ初期画面のブロック数 ( 1ブロック6時間 )
const FULLHDWIDTH: number = 1080;     //fullHdの横px数

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})

export class MapComponent implements OnInit {
  // GoogleMap コンポーネントへの参照を得る.
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapMarker, { static: false }) marker!: MapMarker;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

  // InfoWindow
  public infoContent: string = "";
  public openInfo(marker: MapMarker, content: any) {
    this.infoContent = `<div>地区：${content.areaName}</div>` +
      `<div>地点：${content.pointName}</div>` +
      `<div>センサーID：${content.mainSensorId}</div>` +
      `<div>緯度経度：${content.lat}, ${content.lng}</div>`;
    this.sanitizer.bypassSecurityTrustHtml(this.infoContent);
    this.infoWindow.close();
    this.infoWindow.open(marker);
  }

  // 地図のオプション
  public options: google.maps.MapOptions = {
    // 東新宿駅の座標
    center: {
      lat: 35.697695,
      lng: 139.707354
    },
    zoom: 13,
    disableDefaultUI: false,
    streetViewControl: false,
    mapTypeControl: false,
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT
    },
    streetViewControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    },
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    },
    gestureHandling: "greedy",
    scaleControl: true
  };

  // マーカー
  public markers: google.maps.Marker[] = [];

  // マーカー表示非表示
  public visibleMarker(visible: boolean, mainSensorId: string = "") {
    console.log(visible);
    for (let i = 0; i < this.markers.length; i++) {
      let id: any = this.markers[i].getLabel();
      if ((mainSensorId == null || mainSensorId.length <= 0) || (mainSensorId != null && id == mainSensorId)) {
        this.markers[i].setVisible(visible);
      }
    }
  }

  // マーカーの中心座標
  public getMarkersLatLng(markers: google.maps.Marker[]): google.maps.LatLngLiteral {
    if (markers == null || markers.length <= 0) {
      return { lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng() };
    }
    let minLat: number = 0;
    let minLng: number = 0;
    let maxLat: number = 0;
    let maxLng: number = 0;
    for (let mk of markers) {
      let mkLat: number = mk.getPosition() ? mk.getPosition()!.lat() : 0;
      let mkLng: number = mk.getPosition() ? mk.getPosition()!.lng() : 0;
      if (minLat == 0 || minLat >= mkLat) {
        minLat = mkLat;
      }
      if (minLng == 0 || minLng >= mkLng) {
        minLng = mkLng;
      }
      if (maxLat == 0 || maxLat <= mkLat) {
        maxLat = mkLat;
      }
      if (maxLng == 0 || maxLng <= mkLng) {
        maxLng = mkLng;
      }
    }
    return { lat: minLat + ((maxLat - minLat) / 2), lng: minLng + ((maxLng - minLng) / 2)};
  }

  // マーカー情報
  public markerInfos: MarkerInfo[] = [];

  // マーカーのオプション
  public markerOption: google.maps.MarkerOptions = {
    icon: {
      url: "assets/point.png",
      scaledSize: new google.maps.Size(32, 32)
    }
  };

  // マーカーの座標
  public markerPositions: google.maps.LatLngLiteral[] = [];

  // // マーカー追加
  // addMarker(event: google.maps.MapMouseEvent) {
  //   this.markerPositions.push(event.latLng.toJSON());
  // }

  /*test code starts*/
  // ↓グラフ関連処理不要
  // checked: boolean = true;//12/15
  // pointValue: any | undefined;
  // dropDownData: any | undefined;
  // sensorMeasurementValue = 0; /*testing*/
  // rateOfChangeValue = 0; /*testing*/
  // alarmDeadZoneValue = 0; /*testing*/
  // displayValue = 0; /*testing*/
  // alarmSetValueUpper = 0; /*testing*/
  // alarmSetValueLower = 0; /*testing*/
  // rc = 0;
  // charCode: any;
  // statusBoolean = true;
  // thresholdRateOfChange: any = 0;
  // thresholdDeadZone: any = 0;
  // thresholdCorrectionWidth: any = 0;
  // dataPropertyGridArray: any = [];
  // updateToggle = false;
  // addToggle = false;
  // selectedPropertyValue = '';
  // thresholdAPIResponse: any;
  // selectedSensorPropertyArray: any = [];
  /*testing code ends*/
  // ↑グラフ関連処理不要

  // tabIndex: any;
  dataFormGroup = new FormGroup({});
  // fdtime: any = [];
  // tdtime: any = [];
  // currentTime = new Date();
  currentCityId: any;
  // selectedSensor: any = '';
  // handleSelectionData: any;
  // handleSelectionType: any;
  // selectedAreaData: any;
  isPointSelected = false;
  globalAreaId: any;
  isArrowDown = true;
  globalAreaName: string | null = '';
  currentLang = ''; // SAMPLE TEST CODE
  @ViewChild(MatSort) sort?: MatSort;
  /* View child properties */
  @ViewChild(BaseChartDirective)
  chart!: BaseChartDirective;
  /*Settings properties initialised */
  presentMin = 0;
  presentMax = 0;
  // dataPointName: any;
  // flag = true;
  upperUpper = 0;
  upper = 0;
  lower = 0;
  lowerLower = 0;
  changeOfRate = 0;
  alarmDeadMin = 0;
  // alarmDeadMax = 0;
  upperLower = 0;
  // upperLowerMax = 0;
  // presentValueArray: any;
  // deadZoneArray: any;
  updateThresholdArray: any = [];
  // updateThresholdArray1: any = [];
  pointArray: PointData[] = [];
  sessionData: SessionData[] = [];//12/14
  sessionDataArray: SessionData[] = [];//12/14
  prepareData: prepareData[] = [];//12/14
  prepareDataArray: prepareData[] = [];//12/14
  @ViewChild(MatPaginator) paginator1?: MatPaginator;
  @ViewChild(MatSort) sort2?: MatSort;
  // @ViewChild('idDom') idDom: ElementRef | undefined;
  /*Table properties and initialisations */

  displayedColumns = ['Time Stamp', 'Battery Voltage', 'Freshwater Electrical Conductivity', 'Water Quantity', 'Water Level',
    'Turbidity', 'Seawater Electrical Conductivity', 'Water Temperature'];

  displayedColumns2 = ['select', 'PointDetail', 'cityName', 'areaName', 'sensorName', 'dateTime', 'waterQuantity', 'waterLevel',
    'freshwaterConductivity', 'saltwaterConductivity', 'waterTemprature', 'batteryVoltage', 'turbidity'];

  pointViewData: PointData[] = [];

  sampleSource3: any;

  sampleSource3Length: any;  //20240620追加 // ページ機能用のlength


  selection = new SelectionModel<PointData>(true, []);//仮
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.sampleSource3.data.length;
    return numSelected === numRows;
  }

  // masterToggle() {
  //   this.isAllSelected() ?
  //   this.selection.clear() :
  //   this.sampleSource3.data.forEach((row: any) => this.selection.select(row));
  // }

  dataSource?: any;
  newSource?: any;
  AllDataSource?: any = []//12/14
  receiveSource?: any;//12/09
  // start = 0;
  // limit = 10;
  // public pointSelection = false;
  // end: number = this.limit + this.start;
  // sensorPrimaryId = '';
  // pointName: any = '';
  // currentMaintenaceid: any = '';
  // recordingCycle = 0;
  // recordingCycleInitial: any = 0;
  // transmissionCycle = 0;
  // transmissionCycleInitial: any = 0;
  // alertLevel = 0;
  // upperUpperLimit = '';
  // upperLimit = '';
  // lowerLowerLimit = '';
  // lowerLimit = '';
  // rateOfChange = '';
  // deadZone = '';
  // upperLowerLimitCorrectionWidth = '';
  // sensorSettingsArray: any = [];
  // clickEVentSubscription?: Subscription;
  // langEVentSubscription?: Subscription;
  // dt: any;
  // selectedHour = '1 Day';
  // batteryVoltageAverage = 0;
  // freshwaterConductivityAverage: any = 0;
  // waterQuantityAverage: any = 0;
  // waterLevelAverage = 0;
  // turbidityAverage: any = 0
  currentSensorId: any;
  currentArea = 0;
  // saltwaterConductivityAverage: any = 0; /*testing*/
  // seaWaterElectricalConductivityArray: any = []; /*testing*/
  // waterTemperatureAvearage: any = 0
  // waterTemperatureArray: any = []; /*testing*/
  mainArray: any = [];
  // turbidityArray: any = [];
  // waterLevelArray: any = [];
  // waterQuantityArray: any = [];
  // freshwaterConductivityArray: any = [];
  // batteryVoltageArray: any = [];
  // batteryVoltageDataArray: any = [];
  // freshwaterConductivityDataArray: any = [];
  // waterQuantityDataArray: any = [];
  // waterLevelDataArray: any = [];
  // turbidityDataArray: any = [];
  sensorsFilteredData: any[] = [];
  graphDataSource: any[] = [];
  widgetsData: any = [];
  // alertsArray: any = [];
  points: any[] = [];
  areas: any[] = [];
  // selectedAreaId: any;
  selectedCityData: any;
  // selectedAreaData1: any;
  // selectedPointData: any;
  selectedMainsensorId: any;
  passData: any[] = [];
  // backupDataSource: any;//グラフ用
  checkedGraphData: any[] = [];
  filterationObject: any = {
    dateFilter: null
  };
  filteredDate: any;

  // public barChartOptions = {
  //   scaleShowVerticalLines: false,
  //   responsive: true
  // };

  // options: any;
  // viewType = 'graph';
  // areaName = 'Selected Area';
  currentAreaName = '';
  currentPointName = '';
  // currentView = 'graph'; /*testing code*/
  // selectedArea = false;
  selectedPoint = false;
  loading = false;
  // loadingSetting = false;
  cityChanged = false;
  browserLang: any;
  cityArray: any = [];//以下追記分プロパティ
  // areaArray: any = [];
  // mainSensorArray: any = [];
  // viewArray: any[] = [];
  intervalId: number = 0;
  isAutoRedraw: boolean = false;
  // sessionstorageData: any;
  // cityAreasName: any;//12/09
  // newAreaId: any;//12/09
  // selectedAreaInfo: any;//12/09
  // areaSensorName: any;//12/09
  // newSensorId: any;//12/09
  // selectedSensorInfo: any;//12/09
  lastData: any;
  // sessionNameArray: any;
  // sessionMainSensorId: any;
  // checkBoxIndex: number = 0;//チェックボックス用のインデックス
  dateFilterMode: boolean = false;//12/15 todo
  sessionCityNameArray: any;//12/15
  sessionAreaNameArray: any;//12/15
  sessionSensorNameArray: any;//12/15
  // wlDataArray: any = [];
  // wqDataArray: any = [];
  // fwcDataArray: any = [];
  // swcDataArray: any = [];
  // bvDataArray: any = [];
  // tDataArray: any = [];
  // wtDataArray: any = [];
  // isAllSelect: boolean = true;
  // isWaterQuantitySelect: boolean = true;
  // isWaterLevelSelect: boolean = true;
  // isFreshwaterConductivitySelect: boolean = true;
  // isSaltwaterConductivitySelect: boolean = true;
  // isBatteryVoltageSelect: boolean = true;
  // isWaterTemperatureSelect: boolean = true;
  // isTurbiditySelect: boolean = true;
  // displayDialog: boolean = false;
  // checkCount: any;//有効なデータのまとまりの個数(グラフ用)
  behaveJustOne: boolean = true;//ロード時に一度だけ行いたい処理用(自動更新回避)
  mainSensorsArray: any = [];
  backUpMainSensorsArray: any = [];
  selectedMainSensorIds: any = []; //選択されているセンサーIdの配列
  allMainSensorIds: any = []; //現在表示中の全てのセンサーIdの配列
  // correctionValueArray: any = [];
  parseData: any = [];//流量と水位のデータを文字列から数値に変換するための入れ物
  copyParseData: any = [];//parseDataのディープコピー
  copyResponseData: any = [];
  // complementedGraphData: any[] = [];
  IsdateFilterClear: boolean = false;
  pointSortData: any;
  headerCheckBox: boolean = true;
  isMainSensorId: any;
  // array: any = {};
  // blockCount: number = INITIALBLOCKVALUE;//グラフのブロック数
  // public graphWidth: number = FULLHDWIDTH;//グラフの幅
  // graphHeight: number = 400;//グラフの高さ
  // baseWidth: number = 1200;
  // yAxisWidth: number = 65; //40
  // timespan: number = 10;

  // ctx: any;
  // context01?: CanvasRenderingContext2D;
  // context02?: CanvasRenderingContext2D;
  // @ViewChild("canvas01") canvas01?: ElementRef;
  // @ViewChild("canvas02") canvas02?: ElementRef;

  // multipleLineChartData: any[] = [];
  // multipleLineChartDataJP: any[] = [];
  //y軸固定表示用のグラフデータ(既存のグラフに上からかぶせる)
  // subLineChartData: any[] = [];
  // subLineChartDataJP: any[] = [];

  // yaxisArray: any[] = [];

  // subLineChartOptions = {
  //   legend: false,
  //   showpoint: false,
  //   responsive: false,
  //   elements: {
  //     point: {
  //       radius: 0
  //     }
  //   },
  //   plugins: {
  //   },
  //   scales: {
  //     xAxes: [{
  //       ticks: {
  //         autoSkip: true,
  //         maxTicksLimit: 5,
  //       },
  //       grid: {
  //         drawBorder: false
  //       },
  //       scaleLabel: {
  //         display: false,
  //         labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : this.translate.instant('日時'),
  //       },
  //     }],
  //     yAxes: [{
  //       gridLines: {
  //         display: false
  //       },
  //       scaleLabel: {
  //         display: true,
  //         labelString: this.cookieService.get('language') === 'en' ? 'Value' : this.translate.instant('値'),
  //         padding:{
  //           top: 0,
  //           bottom: 40
  //         }
  //       },
  //       ticks: {
  //         showLabelBackdrop: true,
  //         backdropColor: "#fff",
  //         padding: -35
  //       },
  //     }]
  //   }
  // };
  /*TESTING SAMPLE CODE ENDS*/

  // lineChartLabels: any[] = [];

  //制約を解除するため初期設定は一旦なし→エラー多発のため復活
  // lineChartOptions = {
  //   legend: false,
  //   showpoint: false,
  //   responsive: false,
  //   elements: {
  //     point: {
  //       radius: 0
  //     }
  //   },
  //   plugins: {
  //   },
  //   scales: {
  //     xAxes: [{
  //       ticks: {
  //         autoSkip: true,
  //         maxTicksLimit: 5,
  //         maxRotation: 0,
  //         minRotation: 0
  //       },
  //       gridLines: {
  //         color: '#FFF'
  //       },
  //       scaleLabel: {
  //         display: false,
  //         labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : this.translate.instant('日時'),
  //       }
  //     }],
  //     yAxes: [{
  //       scaleLabel: {
  //         display: true,
  //       },
  //       ticks: {
  //         display: false
  //       }
  //     }]
  //   }
  // };

  // lineChartLegend = false;
  // lineChartPlugins = [{
  //   beforeDraw(chart: { ctx: any; chartArea: any; }, easing: any) {
  //     const ctx = chart.ctx;
  //     const chartArea = chart.chartArea;
  //     const top = chartArea.top;
  //     ctx.save();
  //     ctx.fillStyle = 'gray'; //2.グラフ背景の色を変更(詳細不明)
  //     ctx.fillRect(chartArea.left, top, chartArea.right - chartArea.left, chartArea.bottom - top);
  //     ctx.restore();
  //   }
  // }];

  // subLineChartPlugins = [{
  //   beforeDraw(chart: { ctx: any; chartArea: any; }, easing: any) {
  //     const ctx = chart.ctx;
  //     const chartArea = chart.chartArea;
  //     const top = chartArea.top;
  //     ctx.save();
  //     ctx.fillStyle = 'rgba(40, 39, 39, 0)';
  //     ctx.fillRect(chartArea.left, top, chartArea.right - chartArea.left, chartArea.bottom - top);
  //     ctx.restore();
  //   }
  // }];
  // lineChartType = 'line';

  pointDetail: any = {
    areaSensor: 0,
    cityArea: 0
  };

  // newArray: any = [];
  // widgetsDataArray: any;
  // dateFormat = 'y/M/d';
  // date: any;


  // コンストラクタ
  constructor(
    private detailRouter: Router,
    public todoService: TodoService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService, public http: HttpClient,
    private router: Router, private toastr: ToastrService,
    public cookieService: CookieService, private snotifyService: SnotifyService,
    private translate: TranslateService,
    private datePipe: DatePipe, private dateTimeAdapter: DateTimeAdapter<any>, iconRegistry: MatIconRegistry, public sanitizer: DomSanitizer) {

    // iconRegistry.addSvgIcon(
    //   'thumb_up',
    //   sanitizer.bypassSecurityTrustResourceUrl('assets/thumb_up.svg')
    // );

    // this.clickEVentSubscription = this.todoService.getClickEvent().subscribe(() => {
    //   this.callFun();
    // });
    // this.langEVentSubscription = this.todoService.getLangEvent().subscribe(() => {
    //   this.setChartTranslations();
    // });

    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          // this.clearCookies();
        }
      });

    // /*TESTING SAMPLE CODE START*/

    translate.onLangChange.subscribe(lang => {
      this.browserLang = lang;
    });

    this.dataFormGroup = new FormGroup({
      fromdatetime: new FormControl(),
      todatetime: new FormControl()
    });

    this.datePickerlang();
  }

  datePickerlang() {
    let lang = sessionStorage.getItem("lang");

    if(lang == null || lang == "" || lang == undefined){
      lang = this.cookieService.get('language');
    }

    this.todoService.languageData.subscribe((data: any) => {

      if (data == 'jp') {
        this.dateTimeAdapter.setLocale('ja-JP');
      }
      else if (data == 'en') {
        this.dateTimeAdapter.setLocale('en');
      }
      else {

        if (lang) {

          if (lang == 'en') {
            this.dateTimeAdapter.setLocale('en');
          }

          if (lang == 'jp') {
            this.dateTimeAdapter.setLocale('ja-JP');
          }
        }
      }
    });
  }

  get city() {
    return this.todoService.getSelectedCity();
  }

  get cityId() {
    return this.todoService.getCityId();
  }

  getAreaByCity(): void {
    const cityId = this.todoService.getCityId();

    if (cityId > 0) {
      this.todoService.getAreaByCity(cityId).subscribe((response: any) => {
        this.areas = response;
      });
    }
    else {
      return;
    }
  }


  ngOnInit(): void {
    // console.log("---------------start");
    this.spinner.show();
    this.currentCityId = sessionStorage.getItem('cityId');
    this.getDataByCity();

    this.getSensorsLocation();

    this.intervalId = Number(setInterval(() => {

      if (this.isAutoRedraw == false) {
        return;
      }
      else {
        this.getLatestData();
      }
    }, 600 * 1000));

    const userId = this.cookieService.get('userId');
    this.isAutoRedraw = true;

    this.globalAreaName = this.todoService.getSelectedAreaName();
    this.currentLang = this.cookieService.get('language'); // TESTING CODE
    this.updateThresholdArray = [];
    // this.startConnection();
    const areas = sessionStorage.getItem('cityAreas'); // session has city areas stored which were previously selected;
    const dataExists = sessionStorage.getItem('lastSelected'); // area , sensor selected last time is stored in this;
    const sensors: any = sessionStorage.getItem('currentSensors'); // session has sensors list stored in this;
    const mainData: any = sessionStorage.getItem('mainData'); // session has sensors data stored in this;
    const selectedCity = this.todoService.getSelectedCity();
    const selectedCityId = this.todoService.getCityId();
    const locationData: any = sessionStorage.getItem('lastSelected');
    const date: any = sessionStorage.getItem('selectedDate');
    const data = JSON.parse(locationData);

    if (data && data.cityId === selectedCityId) {

      if (areas && selectedCity !== '') {
        this.areas = JSON.parse(areas);

        if (sensors) {
          this.points = JSON.parse(sensors);
        }
        else {
          this.points = [];
        }

        if (mainData) {
          this.mainArray = JSON.parse(mainData);
        }
        else {
          this.mainArray = [];
          this.filterationObject.dateFilter = null;
        }

        if (date != null) {
          this.filterationObject.dateFilter = date;
        }
        else {
          this.filterationObject.dateFilter = moment(new Date()).format('YYYY-MM-DD');
        }

        if (dataExists) {
          const locationData: any = sessionStorage.getItem('lastSelected');
          const data = JSON.parse(locationData);
          this.currentAreaName = data.areaName ? data.areaName : '';
          this.currentPointName = data.pointName ? data.pointName : '';
          this.currentArea = data.areaId ? data.areaId : 0;
          this.pointDetail.cityArea = this.currentArea;
          this.selectedPoint = data.areaId > 0 ? true : false;
          this.currentSensorId = data.sensorId ? data.sensorId : 0;

          if (this.globalAreaId) {
            this.selectedPoint = true;
            this.pointDetail.areaSensor = '';
            this.isPointSelected = false;
            this.currentSensorId = 0;
          }

          if (this.currentSensorId > 0) {
            this.pointDetail.areaSensor = this.currentSensorId;
          }
          else {
            return;
          }
        }
        else {
          return;
        }
      }
      else {
        return;
      }
    }
    else {
      this.getAreaByCity();
    }

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.browserLang = event.lang;
    });
  }

  getSensorsLocation(): void {
    this.todoService.getDataPointMappingByCityId(this.currentCityId).subscribe((response: any) => {
      if (response != undefined)
      {
        this.markers = [];
        this.markerInfos = [];
        this.markerPositions = [];
        for (let sensor of response)
        {
          let sensorInfo = sensor[0];

          let pointData: any = this.pointViewData.filter((x: any) => x.mainSensorId == sensorInfo.mainSensorId);
          if (pointData != null && pointData.length > 0 && pointData.checkedOrNot == false) {
            continue;
          }
          // console.log(pointData);

          // 緯度経度登録のない場合はスキップ
          if (!sensorInfo.lat || !sensorInfo.lng) {
            continue;
          }

          // センサーマーカー表示
          let marker: any = new google.maps.Marker({
            position: { lat: sensorInfo.lat, lng: sensorInfo.lng },
            visible: true,
            label: sensorInfo.mainSensorId
          });

          // this.marker.marker.position = { lat: sensorInfo.lat, lng: sensorInfo.lng };
          this.markers.push(marker);
          this.markerInfos.push({
            areaName: sensorInfo.areaName,
            mainSensorId: sensorInfo.mainSensorId,
            pointName: sensorInfo.pointName,
            lat: sensorInfo.lat,
            lng: sensorInfo.lng
          });
          // let marker!: MapMarker;
          this.markerPositions.push({ lat: sensorInfo.lat, lng: sensorInfo.lng });
        }
        let p: google.maps.LatLngLiteral = this.getMarkersLatLng(this.markers);
        this.map.panTo(p);
      }
      // console.log(this.markers);
    });
  }

  jumpPointDetail(mainSensorId: string) {
    console.log(mainSensorId);
    var sessionSendData = this.sessionDataArray.filter((x: any) => x.mainSensorId == mainSensorId);
    var sessionCityNameData = sessionSendData[0]['cityName'];
    var sessionAreaNameData = sessionSendData[0]['areaName'];
    var sessionSensorNameData = sessionSendData[0]['sensorName'];
    var sessionSensorData = sessionSendData[0]['mainSensorId'];
    sessionStorage.setItem('sessionCityNameData', sessionCityNameData);
    sessionStorage.setItem('sessionAreaNameData', sessionAreaNameData);
    sessionStorage.setItem('sessionSensorNameData', sessionSensorNameData);
    sessionStorage.setItem('sessionSensorData', sessionSensorData);
    sessionStorage.setItem('locationHistory', 'db/map');  //20240619追加　戻るボタン対応

    this.detailRouter.navigate(['db/point-detail']), {
      queryParams: {
        pointDataSource: this.newSource//todo
      }
    }
  }

  onClick(mainSensorId: string) {
    this.jumpPointDetail(mainSensorId);
  }

  // bindWidgets(): void {
  //   this.waterQuantityAverage = []
  //   this.freshwaterConductivityAverage = []
  //   this.saltwaterConductivityAverage = [];
  //   this.waterTemperatureAvearage = []
  //   this.turbidityAverage = []

  //   const mainSensorID = this.currentSensorId;

  //   const date = moment(new Date).format('YYYY-MM-DD');

  //   this.todoService.getAllBySensorId(mainSensorID, date).subscribe((response: any) => {
  //     const data = response.waterFlowResponse;
  //     this.widgetsDataValues = data[data.length - 1];

  //     this.waterQuantityAverage = this.widgetsDataValues.waterQuantity
  //     this.waterLevelAverage = this.widgetsDataValues.waterLevel
  //     this.freshwaterConductivityAverage = this.widgetsDataValues.freshwaterConductivity
  //     this.saltwaterConductivityAverage = this.widgetsDataValues.saltwaterConductivity
  //     this.waterTemperatureAvearage = this.widgetsDataValues.waterTemprature
  //     this.turbidityAverage = this.widgetsDataValues.turbidity
  //     this.batteryVoltageAverage = this.widgetsDataValues.batteryVoltage

  //     this.widgetsData = [
  //       { name: 'Water Quantity', value: this.waterQuantityAverage, unit: '㎥/sec' },
  //       { name: 'Water Level', value: this.waterLevelAverage, unit: 'm' },
  //       { name: 'Freshwater Electrical Conductivity', value: this.freshwaterConductivityAverage, unit: 'μS/cm' },
  //       { name: 'Seawater Electrical Conductivity', value: this.saltwaterConductivityAverage, unit: 'mS/cm' },
  //       { name: 'Water Temperature', value: this.waterTemperatureAvearage, unit: '°C' },
  //       { name: 'Turbidity', value: this.turbidityAverage, unit: 'FTU ' },
  //       { name: 'Battery Voltage', value: this.batteryVoltageAverage, unit: 'V' },
  //     ];
  //   });
  // }

  // widgetsDataValues: any

  // ↓グラフ関連処理不要
  //日英訳切り替え
  // setChartTranslations(): void {

  //   if (this.chart && this.chart.chart && this.chart.chart.config) {

  //     this.chart.chart.options.scales = {
  //       yAxes: [{
  //         ticks: {
  //           display: false
  //         },
  //         scaleLabel: {
  //           display: true,
  //         }
  //       }],
  //       xAxes: [{
  //         ticks: {
  //           autoSkip: true,
  //           maxTicksLimit: this.blockCount,
  //           maxRotation: 0,
  //           minRotation: 0
  //         },
  //         gridLines: {
  //           color: '#FFF'
  //         },
  //         scaleLabel: {
  //           display: true,
  //           labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : '日時',
  //         }
  //       }]
  //     };

  //     //Y軸だけのグラフ用
  //     this.subLineChartOptions = {
  //       legend: false,
  //       showpoint: false,
  //       responsive: false,
  //       elements: {
  //         point: {
  //           radius: 0
  //         }
  //       },
  //       plugins: {
  //       },
  //       scales: {
  //         xAxes: [{
  //           ticks: {
  //             autoSkip: true,
  //             maxTicksLimit: this.blockCount,
  //           },
  //           grid: {
  //             drawBorder: false
  //           },
  //           scaleLabel: {
  //             display: false,
  //             labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : this.translate.instant('日時'),
  //           },
  //         }],
  //         yAxes: [{
  //           gridLines: {
  //             display: false
  //           },
  //           scaleLabel: {
  //             display: true,
  //             labelString: this.cookieService.get('language') === 'en' ? 'Value' : this.translate.instant('値'),
  //             padding:{
  //               top: 0,
  //               bottom: 40
  //             }
  //           },
  //           ticks: {
  //             showLabelBackdrop: true,
  //             backdropColor: "#fff",
  //             padding: -35 //-30
  //           },
  //         }]
  //       }
  //     };
  //     this.chart.chart.update();
  //   }
  // }
  // ↑グラフ関連処理不要



  // ↓グラフ関連処理不要
  // multipleChartData() { //データ名
  //   this.multipleLineChartData.length = 0;
  //   this.multipleLineChartDataJP.length = 0;
  //   this.subLineChartData.length = 0;
  //   this.subLineChartDataJP.length = 0;

  //   for (let index = 0; index < this.checkCount; index++) {
  //     //グラフ描画
  //     if (this.isWaterQuantitySelect == true) {
  //       this.multipleLineChartData.push
  //         ({ data: this.wqDataArray[index], label: 'Water Quantity(㎥/sec)', borderColor: '#7a7afb', fill: false, borderWidth: BORDERWIDTH });
  //       this.multipleLineChartDataJP.push
  //         ({ data: this.wqDataArray[index], label: '流量(㎥/sec)', borderColor: '#7a7afb', fill: false, borderWidth: BORDERWIDTH });

  //       //Y軸用
  //       this.subLineChartData.push
  //         ({ data: this.wqDataArray[index], label: 'a', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //       this.subLineChartDataJP.push
  //         ({ data: this.wqDataArray[index], label: 'b', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //     }

  //     if (this.isWaterLevelSelect == true) {
  //       this.multipleLineChartData.push
  //         ({ data: this.wlDataArray[index], label: 'Water Level(m)', borderColor: '#fc6f8f', fill: false, borderWidth: BORDERWIDTH });
  //       this.multipleLineChartDataJP.push
  //         ({ data: this.wlDataArray[index], label: '水深(m)', borderColor: '#fc6f8f', fill: false, borderWidth: BORDERWIDTH });

  //       //Y軸用
  //       this.subLineChartData.push
  //         ({ data: this.wlDataArray[index], label: 'e', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //       this.subLineChartDataJP.push
  //         ({ data: this.wlDataArray[index], label: 'f', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //     }

  //     if (this.isFreshwaterConductivitySelect == true) {
  //       this.multipleLineChartData.push
  //         ({ data: this.fwcDataArray[index], label: 'Freshwater Electrical Conductivity EC25(μS/cm)', borderColor: '#40ccbd', fill: false, borderWidth: BORDERWIDTH });
  //       this.multipleLineChartDataJP.push
  //         ({ data: this.fwcDataArray[index], label: '淡水電気伝導度EC25(μS/cm)', borderColor: '#40ccbd', fill: false, borderWidth: BORDERWIDTH });

  //       //Y軸用
  //       this.subLineChartData.push
  //         ({ data: this.fwcDataArray[index], label: 'e', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //       this.subLineChartDataJP.push
  //         ({ data: this.fwcDataArray[index], label: 'f', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //     }

  //     if (this.isSaltwaterConductivitySelect == true) {
  //       this.multipleLineChartData.push
  //         ({ data: this.swcDataArray[index], label: 'Seawater Electrical Conductivity EC25(mS/cm)', borderColor: '#a12727', fill: false, borderWidth: BORDERWIDTH });
  //       this.multipleLineChartDataJP.push
  //         ({ data: this.swcDataArray[index], label: '海水電気伝導度EC25(mS/cm)', borderColor: '#a12727', fill: false, borderWidth: BORDERWIDTH });

  //       //Y軸用
  //       this.subLineChartData.push
  //         ({ data: this.swcDataArray[index], label: 'g', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //       this.subLineChartDataJP.push
  //         ({ data: this.swcDataArray[index], label: 'h', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //     }

  //     if (this.isBatteryVoltageSelect == true) {
  //       this.multipleLineChartData.push
  //         ({ data: this.bvDataArray[index], label: `Battery Voltage(V)`, borderColor: '#ffa07a', fill: false, borderWidth: BORDERWIDTH });
  //       this.multipleLineChartDataJP.push
  //         ({ data: this.bvDataArray[index], label: 'バッテリー電圧(V)', borderColor: '#ffa07a', fill: false, borderWidth: BORDERWIDTH });

  //       //Y軸用
  //       this.subLineChartData.push
  //         ({ data: this.bvDataArray[index], label: `i`, borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //       this.subLineChartDataJP.push
  //         ({ data: this.bvDataArray[index], label: 'j', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //     }

  //     if (this.isWaterTemperatureSelect == true) {
  //       this.multipleLineChartData.push
  //         ({ data: this.wtDataArray[index], label: 'Water Temperature(°C)', borderColor: '#bd8432', fill: false, borderWidth: BORDERWIDTH });
  //       this.multipleLineChartDataJP.push
  //         ({ data: this.wtDataArray[index], label: '温度(°C)', borderColor: '#bd8432', fill: false, borderWidth: BORDERWIDTH });

  //       //Y軸用
  //       this.subLineChartData.push
  //         ({ data: this.wtDataArray[index], label: 'k', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //       this.subLineChartDataJP.push
  //         ({ data: this.wtDataArray[index], label: 'l', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //     }

  //     if (this.isTurbiditySelect == true) {
  //       this.multipleLineChartData.push
  //         ({ data: this.tDataArray[index], label: 'Turbidity(FTU)', borderColor: '#4e82ae', fill: false, borderWidth: BORDERWIDTH });
  //       this.multipleLineChartDataJP.push
  //         ({ data: this.tDataArray[index], label: '濁度(FTU)', borderColor: '#4e82ae', fill: false, borderWidth: BORDERWIDTH });

  //       //Y軸用
  //       this.subLineChartData.push
  //         ({ data: this.tDataArray[index], label: 'm', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //       this.subLineChartDataJP.push
  //         ({ data: this.tDataArray[index], label: 'n', borderColor: 'rgba(0, 0, 0, 0)', fill: false });
  //     }
  //   }
  //   this.spinner.hide();
  // }
  // ↑グラフ関連処理不要

  // clearCookies(): void {
  // }

  // ngAfterViewinit() {
  // }

  /* Function for getting real time data */
  // // 本番URL(リリース時はこっちが有効)
  // baseurl = 'https://d1rh4b0dx4fns2.cloudfront.net';
  // // ローカルURL
  // // baseurl = "https://localhost:44392";
  // startConnection(): void {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .configureLogging(signalR.LogLevel.Information)
  //     // .withUrl(`https://d1rh4b0dx4fns2.cloudfront.net/StartUpLiveDataUrl`)
  //     .withUrl(`${this.baseurl}/StartUpLiveDataUrl`)
  //     .build();
  //   connection.start().then(function () {
  //   }).catch(function (err) {
  //   });

  //   connection.on('BroadcastLiveData', () => {
  //     this.getLiveData();
  //   });
  //   const connection2 = new signalR.HubConnectionBuilder()
  //     .configureLogging(signalR.LogLevel.Information)
  //     // .withUrl(`https://d1rh4b0dx4fns2.cloudfront.net/StartUpNotificationUrl`)
  //     .withUrl(`${this.baseurl}/StartUpNotificationUrl`)
  //     .build();

  //   connection2.start().then(function () {
  //   }).catch(function (err) {
  //   });

  //   connection2.on('BroadcastAlertData', (messageText) => {
  //     this.getAlertData(messageText);
  //   });
  // }

  callFun(): void {
    /*-------------------*/
    this.currentPointName = '';
    this.upperLower = 0;
    this.upper = 0;
    this.presentMin = 0;
    this.presentMax = 0;
    this.upperUpper = 0;
    this.lowerLower = 0;
    this.lower = 0;
    this.changeOfRate = 0;
    this.alarmDeadMin = 0;
    /*-------------------*/

    this.cityChanged = true;
    const data: any = sessionStorage.getItem('allAreas');
    const areaData = JSON.parse(data);
    this.areas = areaData.filter((x: { cityid: any; }) => x.cityid == this.cityId);
    sessionStorage.setItem('cityAreas', JSON.stringify(this.areas));
    this.points = [];
    this.selectedPoint = false;
    this.currentSensorId = 0;
    this.currentSensorId = 0;
    this.widgetsData = [];
    this.filterationObject.dateFilter = null;
    this.pointDetail.areaSensor = 0;
    this.pointDetail.cityArea = 0;
    this.sensorsFilteredData = [];
  }

  //ユーザの都市IDを元にエリアを取得
  getDataByCity(): void {
    const today = new Date();
    const compareToday = new Date();
    //取得データの始点(データ取得後に使用する)
    let fromTime = new Date(today.setHours(0, 0, 0, 0));
    //取得データの終点(データ取得後に使用する)
    let toTime = new Date(today.setHours(0, 0, 0, 0));

    let timeAxisCount = 0;

    //0時から6時間ずつ追加する(現在時刻を超えたタイミングでループ終了)
    while (compareToday > toTime) {
      toTime = new Date(toTime.setHours(toTime.getHours() + 6));
      timeAxisCount++;
    }

    //5はグラフの表示ブロック数、1ブロック6時間分
    timeAxisCount = INITIALBLOCKVALUE - timeAxisCount;

    //0時から6時間ずつ減算する(一日前のデータをどこまで取得するか)
    for (let i = 0; i < timeAxisCount; i++) {
      fromTime = new Date(fromTime.setHours(fromTime.getHours() - 6))
    }

    const sendData = {
      cityId: this.currentCityId,
      fromDate: moment(new Date(today)).subtract(30, 'h').format("YYYY-MM-DD"),
      toDate: moment(new Date(today)).add(1, 'd').format("YYYY-MM-DD"),
    };
    this.todoService.getAllByCityIdAndMultipleDates(sendData).subscribe((response: any) => {
      if(response.length == 0){
        this.spinner.hide();
        return;
      }

      let equalCount = 0;

      for (let i = 0; i < response.length; i++) {

        if (this.copyResponseData.length != 0) {

          if (response[i].waterFlowResponse.length == this.copyResponseData[i].waterFlowResponse.length) {
            equalCount = equalCount + 1;
          }
        }
      }

      if (equalCount == response[0].count && this.IsdateFilterClear == false) {
        this.spinner.hide();
        return;
      }

      this.copyResponseData = _.cloneDeep(response);
      this.pointViewData.length = 0;
      this.newSource = [];
      this.newSource.length = 0;
      this.receiveSource = response;

      for (let index = 0; index < response.length; index++) {
        this.parseData.length = 0;
        const cityElement = this.receiveSource[index].waterFlowResponse;

        //データがない場合
        if (cityElement.length == 1) {
          this.lastData = cityElement[0];
          this.lastData.dateTime = "-";
          this.newSource.push(cityElement[0]);
        }

        //データがある場合
        else {
          for (let index = 0; index < cityElement.length; index++) {
            var tmpFrom: string;
            var tmpTo: string;
            var tmpVal: string;

            tmpFrom = moment(new Date(fromTime)).format("YYYY-MM-DD HH:mm");
            tmpTo = moment(new Date(toTime)).format("YYYY-MM-DD HH:mm"),

            sessionStorage.setItem("filterFromDate", tmpFrom);
            sessionStorage.setItem("filterToDate", tmpTo);

            tmpVal = cityElement[index]["dateTime"];

            if (tmpVal >= tmpFrom) {

              if (tmpVal <= tmpTo) {
                this.newSource.push(cityElement[index]);
                this.parseData.push(cityElement[index]);
                this.copyParseData = _.cloneDeep(this.parseData);
              }
            }
          }

          // this.graphDataSource.push(this.copyParseData);
          this.lastData = cityElement[cityElement.length - 1];
        }

        Object.assign(this.lastData, pd, cb);
        this.pointArray.length = 0;
        this.pointArray.push(this.lastData);
        this.prepareData.length = 0;
        this.prepareData.push(this.lastData);
        this.sessionData.length = 0;
        this.sessionData.push(this.lastData);

        if (this.behaveJustOne == true) {
          this.mainSensorsArray.push(this.pointArray[0]['mainSensorId']);
          this.selectedMainSensorIds.push(this.pointArray[0]['mainSensorId']);
        }

        this.isMainSensorId = this.selectedMainSensorIds.includes(this.pointArray[0]['mainSensorId']);

        this.pointArray[0]['waterLevel'] = isNull(this.pointArray[0]['waterLevel']);
        this.pointArray[0]['freshwaterConductivity'] = isNull(this.pointArray[0]['freshwaterConductivity']);
        this.pointArray[0]['saltwaterConductivity'] = isNull(this.pointArray[0]['saltwaterConductivity']);
        this.pointArray[0]['waterTemprature'] = isNull(this.pointArray[0]['waterTemprature']);
        this.pointArray[0]['batteryVoltage'] = isNull(this.pointArray[0]['batteryVoltage']);

        if (this.isMainSensorId == true) {

          if (this.pointArray != null) {
            const pointData: PointData = {
              checkedOrNot: true,
              PointDetail: this.pointArray[0]['PointDetail'],
              cityName: this.pointArray[0]['cityName'],
              areaName: this.pointArray[0]['areaName'],
              sensorName: this.pointArray[0]['sensorName'],
              dateTime: this.pointArray[0]['dateTime'],
              waterQuantity: parseFloat(this.pointArray[0]['waterQuantity']).toFixed(3),
              waterLevel: parseFloat(this.pointArray[0]['waterLevel'].toString()).toFixed(3),
              freshwaterConductivity: parseFloat(this.pointArray[0]['freshwaterConductivity'].toString()).toFixed(1),
              saltwaterConductivity: parseFloat(this.pointArray[0]['saltwaterConductivity'].toString()).toFixed(2),
              waterTemprature: parseFloat(this.pointArray[0]['waterTemprature'].toString()).toFixed(2),
              batteryVoltage: parseFloat(this.pointArray[0]['batteryVoltage'].toString()).toFixed(3),
              turbidity: parseFloat(this.pointArray[0]['turbidity']).toFixed(1),
              mainSensorId: this.pointArray[0]['mainSensorId']
            };

            forGetDataByCity(pointData);

            this.pointViewData.push(pointData);
          }
        }
        else {

          if (this.pointArray != null) {

            const pointData: PointData = {
              checkedOrNot: false,
              PointDetail: this.pointArray[0]['PointDetail'],
              cityName: this.pointArray[0]['cityName'],
              areaName: this.pointArray[0]['areaName'],
              sensorName: this.pointArray[0]['sensorName'],
              dateTime: this.pointArray[0]['dateTime'],
              waterQuantity: parseFloat(this.pointArray[0]['waterQuantity']).toFixed(3),
              waterLevel: parseFloat(this.pointArray[0]['waterLevel'].toString()).toFixed(3),
              freshwaterConductivity: parseFloat(this.pointArray[0]['freshwaterConductivity'].toString()).toFixed(1),
              saltwaterConductivity: parseFloat(this.pointArray[0]['saltwaterConductivity'].toString()).toFixed(2),
              waterTemprature: parseFloat(this.pointArray[0]['waterTemprature'].toString()).toFixed(2),
              batteryVoltage: parseFloat(this.pointArray[0]['batteryVoltage'].toString()).toFixed(3),
              turbidity: parseFloat(this.pointArray[0]['turbidity']).toFixed(1),
              mainSensorId: this.pointArray[0]['mainSensorId']
            };

            forGetDataByCity(pointData);

            this.pointViewData.push(pointData);
          }
        }
        this.sessionCityNameArray = this.sessionData[0]['cityName'];
        this.sessionAreaNameArray = this.sessionData[0]['areaName'];
        this.sessionSensorNameArray = this.sessionData[0]['sensorName'];

        const sData = {
          mainSensorId: this.sessionData[0]['mainSensorId'],
          cityName: this.sessionData[0]['cityName'],
          areaName: this.sessionData[0]['areaName'],
          sensorName: this.sessionData[0]['sensorName'],
        };
        this.sessionDataArray.push(sData);
      }

      var areaSortData = this.pointViewData.sort(function (a, b) {
        return (a.areaName < b.areaName) ? -1 : 1;
      })
      this.pointSortData = areaSortData.sort(function (a, b) {
        return (a.sensorName < b.sensorName) ? -1 : 1;
      })

      this.sampleSource3 = new MatTableDataSource<PointData>(this.pointSortData);
      this.sampleSource3.paginator = this.paginator1;       //20240620追加 
      this.sampleSource3Length = this.pointSortData.length; //20240620追加
      this.sensorsFilteredData = this.newSource;
      this.currentSensorId = this.sessionDataArray[0]['mainSensorId'];

      if (this.behaveJustOne == true) {
        this.selectedMainSensorIds = _.cloneDeep(this.mainSensorsArray);
        this.allMainSensorIds = _.cloneDeep(this.mainSensorsArray);
        this.checkedGraphData.length = 0;

        // for (let index = 0; index < this.graphDataSource.length; index++) {

        //   if (this.graphDataSource[index].length != 0) {
        //     let isChecked = this.selectedMainSensorIds.includes(this.graphDataSource[index][0].mainSensorId);

        //     if (isChecked) {
        //       this.checkedGraphData.push(this.graphDataSource[index]);
        //     }
        //   }
        // }
      }
      else {
        // this.checkedGraphData.length = 0;

        // for (let index = 0; index < this.graphDataSource.length; index++) {
        //   let isChecked = this.selectedMainSensorIds.includes(this.graphDataSource[index][0].mainSensorId);

        //   if (isChecked) {
        //     this.checkedGraphData.push(this.graphDataSource[index]);
        //   }
        // }
      }
      this.behaveJustOne = false;
      this.spinner.hide();
    }, (error: any) => {
      console.log(error);
      this.spinner.hide();
    })
  }














  // ↓グラフ関連処理不要
  // setChartTranslations() {
  //   if (this.flagCurrentPropertyName === '') {
  //     this.flagCurrentPropertyName = localStorage.getItem('yAxisValue');
  //   }
  //   if (this.cookieService.get('language') === 'en') {

  //     if (this.userSelectedProperty === 'Battery Voltage'
  //       || this.userSelectedProperty === 'バッテリー電圧') {
  //       this.userSelectedProperty = 'Battery Voltage';
  //     } else if (this.userSelectedProperty === 'Freshwater Electrical Conductivity'
  //       || this.userSelectedProperty === '淡水電気伝導度') {
  //       this.userSelectedProperty = 'Freshwater Electrical Conductivity';
  //     } else if (this.userSelectedProperty === 'Water Quantity'
  //       || this.userSelectedProperty === '流量') {
  //       this.userSelectedProperty = 'Water Quantity';
  //     } else if (this.userSelectedProperty === 'Water Level'
  //       || this.userSelectedProperty === '水深') {
  //       this.userSelectedProperty = 'Water Level';
  //     } else if (this.userSelectedProperty === 'Turbidity'
  //       || this.userSelectedProperty === '濁度') {
  //       this.userSelectedProperty = 'Turbidity';
  //     } else if (this.userSelectedProperty === 'Saltwater Conductivity'
  //       || this.userSelectedProperty === '塩水の導電率') {
  //       this.userSelectedProperty = 'Saltwater Conductivity';
  //     } else if (this.userSelectedProperty === 'Water Temperature'
  //       || this.userSelectedProperty === '温度') {
  //       this.userSelectedProperty = 'Water Temperature';
  //     }
  //   } else {


  //     if (this.userSelectedProperty === 'Battery Voltage'
  //       || this.userSelectedProperty === 'バッテリー電圧') {
  //       this.userSelectedProperty = 'バッテリー電圧';
  //     } else if (this.userSelectedProperty === 'Freshwater Electrical Conductivity'
  //       || this.userSelectedProperty === '淡水電気伝導度') {
  //       this.userSelectedProperty = '淡水電気伝導度';
  //     } else if (this.userSelectedProperty === 'Water Quantity'
  //       || this.userSelectedProperty === '流量') {
  //       this.userSelectedProperty = '流量';
  //     } else if (this.userSelectedProperty === 'Water Level'
  //       || this.userSelectedProperty === '水深') {
  //       this.userSelectedProperty = '水深';
  //     } else if (this.userSelectedProperty === 'Turbidity'
  //       || this.userSelectedProperty === '濁度') {
  //       this.userSelectedProperty = '濁度';
  //     } else if (this.userSelectedProperty === 'Saltwater Conductivity'
  //       || this.userSelectedProperty === '塩水の導電率') {
  //       this.userSelectedProperty = '塩水の導電率';
  //     } else if (this.userSelectedProperty === 'Water Temperature'
  //       || this.userSelectedProperty === '温度') {
  //       this.userSelectedProperty = '温度';
  //     }
  //   }

  //   /*TESTING SAMPLE CODE ENDS*/
  //   if (this.chart && this.chart.chart && this.chart.chart.config) {
  //     this.chart.chart.options.title = {
  //       /*text: this.translate.instant('Sensor comparison graph'),*/
  //       text: this.cookieService.get('language') === 'en' ? this.titleEnglish : this.titleJapanese,
  //       display: true
  //     };
  //     this.chart.chart.options.scales = {
  //       yAxes: [{
  //         scaleLabel: {
  //           display: true,
  //           labelString: this.userSelectedProperty, /*this.flagCurrentPropertyName*/
  //           /*labelString: this.translate.instant(this.currentPropertyName),*/
  //         }
  //       }],
  //       xAxes: [{
  //         scaleLabel: {
  //           display: true,
  //           /*labelString: this.translate.instant('Time Stamp')*/
  //           labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : 'タイムスタンプ'
  //         }
  //       }]
  //     };

  //     this.currentPropertyName = this.userSelectedProperty;
  //     this.chart.chart.update();

  //   }
  // }
  // ↑グラフ関連処理不要

  /* Function call to handle the area/point selection*/
  // techerror code
  // sensorNameData: any;
  // pointCompareArray: any = []
  // selectedDateData: any
  // chartDataArray: any = [];
  // propertyNameData: any = 'Water Quantity';
  // sensorIdData: any;
  // propertyLabelData: any = 'Water Quantity';
  // pointConditionArray: any = [];
  // selectedValue: any = [];


  handleSelection(data: any, type: string) {
    // ↓グラフ関連処理不要
    // if (type === 'Point') {
    //   this.helperArray.push(data);
    //   this.sensorIdData = data.mainSensorid
    //   this.sensorNameData = data.sensorname
    //   this.helperChipArray.push({ mainSensorid: data.mainSensorid, sensorname: data.sensorname });

    //   this.sensornames.push(data.mainSensorid);
    //   this.sensorNamesHelperArray.push(data.mainSensorid);

    //   if (this.comparePoint.sensorProperty === null) {
    //     this.comparePoint.sensorProperty = 0;
    //   }
    //   if (this.dateFilter.fromDateTime === null) {
    //     this.dateFilter.fromDateTime = moment(new Date()).format('YYYY-MM-DD');
    //     this.selectedDateData = this.dateFilter.fromDateTime = moment(new Date()).format('YYYY-MM-DD');
    //     this.selectedValue = 'Water Quantity';
    //   }

    // }
    // if (type === 'Area') {
    //   this.chartData = [];
    //   this.chartLabels = [];
    //   this.sensorNamesHelperArray = [];
    //   this.helperChipArray = [];
    //   this.dataArr1 = [];
    //   this.dataArr2 = [];
    //   this.helperArray = [];
    //   this.sensornames = []
    //   this.handleSelectionData = data;
    //   this.handleSelectionType = type;
    //   this.todoService.setSelectedAreaData(data, type);
    // }
    // this.todoService.setAreaName(data.areaname);
    // this.todoService.setAreaId(data.areaid);
    // if (data.areaname == this.currentAreaName && this.cityChanged == false) {
    //   return;
    // } else {
    //   switch (type) {

    //     case 'Area':
    //       this.currentArea = data.areaid;
    //       this.currentAreaName = data.areaname;
    //       this.todoService.setAreaId(this.currentArea);
    //       this.todoService.setAreaName(this.currentAreaName);
    //       this.todoService.getSelectedAreaId();
    //       this.selectedPoint = true;
    //       this.cityChanged = false;
    //       this.getSensorsByArea(data.areaid);
    //       this.moveCity = false;
    //       break;
    //     case 'Point':
    //       this.currentSensorId = data.mainSensorid;
    //       this.currentPointName = data.sensorname;
    //       this.moveArea = false;
    //       break;
    //     case 'Hour':
    //       this.selectedHour = data.name;
    //       this.moveHour = false;
    //       break;
    //   }
    // }
    // this.setChartTranslations();
  }
  /* this function gets the data of all sensor in an area */
  // getSensorsByArea(areaId: any): void {
  //   this.todoService.getSensorsByAreaId(areaId).subscribe((response: any) => {
  //     this.points = response;
  //     this.realSensors = [...this.points];
  //     this.getAllSensorsData(this.realSensors);
  //     this.currentSensorId = 0;
  //     this.allData = [];
  //     this.barChartData = [];
  //     this.barChartLabels = [];
  //     this.selectedSensorData = [];
  //     this.accordianArray = [];
  //     this.sensorFinal = [];
  //     this.labelsTrack = [];
  //     this.propertyArray = [];
  //     this.dataArray = [];
  //     this.comparePoint.sensorProperty = 0;
  //   },
  //     error => {

  //     });
  // }

  /* function that will call api to get all areas sensor data and store in one place */
  fromDate: any;
  toDate: any;
  getAllSensorsData(sensorsArr: any): void {

    if (sensorsArr.length > 0) {
      this.loading = true;
      this.mainArray = [];
      let date: any;
      let arr: any = [];

      if (this.filterationObject.dateFilter == null) {
        date = moment(new Date()).format('MM/DD/YYYY 00:00:00');
        this.filteredDate = date;
      }
      else {
        date = new Date();
        this.filteredDate = date;
      }

      let itemsProcessed = 0;

      sensorsArr.map(async (element: any) => {
        const dataArr = {
          mainSensorID: this.selectedMainsensorId,
          fromDate: moment().subtract(1, "days").format("MM-DD-YYYY HH:mm"),
          toDate: moment(new Date()).format('MM-DD-YYYY HH:mm')
        }

        this.todoService.getAllByMainSensorIdAndMultipleDates(dataArr).subscribe((response: any) => {
          arr = response.waterFlowResponse;//arr = responseのみ
          const lastElement = arr[arr.length - 1];//後で削除
          this.mainArray.push({ data: arr, id: element.mainSensorid });
        })

        if (arr) {
          itemsProcessed++;
        }

        if (itemsProcessed === sensorsArr.length) {
          this.loading = false;
          sessionStorage.setItem('mainData', JSON.stringify(this.mainArray));
        }

        if (itemsProcessed === sensorsArr.length && this.currentSensorId > 0) {
          this.loading = false;
        }
      });
    }
    else {
      return;
    }
  }

  // onChange(dateValue: string): void {
  //   this.fdtime = '';
  //   this.tdtime = '';

  //   if (this.currentSensorId === 0) {
  //     this.sensorsFilteredData = [];
  //     this.snotifyService.warning(this.translate.instant('Choose a sensor to filter data'), '', {
  //       timeout: 2000,
  //       showProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true
  //     });
  //   }
  //   else {

  //     this.todoService.getAllByMainSensorId(`${this.currentSensorId}`, this.filterationObject.dateFilter).subscribe((response: any) => {
  //       this.sensorsFilteredData = [];

  //       if (response.waterFlowResponse == null) {
  //         this.sensorsFilteredData = [];
  //         this.filterationObject.dateFilter = new Date(dateValue);
  //       }
  //       else {
  //         this.lineChartLabels = []; /*testing new code*/
  //         this.batteryVoltageDataArray = []; /*testing new code*/
  //         this.freshwaterConductivityDataArray = []; /*testing new code*/
  //         this.waterQuantityDataArray = []; /*testing new code*/
  //         this.turbidityDataArray = []; /*testing new code*/
  //         this.dataSource = response.waterFlowResponse; /*testing new code*/
  //         this.sensorsFilteredData = this.newSource;
  //         this.filterationObject.dateFilter = new Date(dateValue);
  //         this.batteryVoltageDataArray = this.sensorsFilteredData.map(x => x.batteryVoltage);
  //         this.freshwaterConductivityDataArray = this.sensorsFilteredData.map(x => x.freshwaterConductivity);
  //         this.waterQuantityDataArray = this.sensorsFilteredData.map(x => x.waterQuantity);
  //         this.waterLevelDataArray = this.sensorsFilteredData.map(x => x.waterLevel);
  //         this.turbidityDataArray = this.sensorsFilteredData.map(x => x.turbidity);

  //         this.sensorsFilteredData.map(element => {
  //           this.lineChartLabels.push(element.dateTime);

  //           this.lineChartOptions = {
  //             legend: false,
  //             showpoint: false,
  //             responsive: false,
  //             elements: {
  //               point: {
  //                 radius: 0
  //               }
  //             },
  //             plugins: {
  //             },
  //             scales: {
  //               xAxes: [{
  //                 ticks: {
  //                   autoSkip: true,
  //                   maxTicksLimit: this.blockCount,
  //                   maxRotation: 0,
  //                   minRotation: 0
  //                 },
  //                 gridLines: {
  //                   color: '#FFF'
  //                 },
  //                 scaleLabel: {
  //                   display: true,
  //                   labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : this.translate.instant('日時'),
  //                 }
  //               }],
  //               yAxes: [{
  //                 scaleLabel: {
  //                   display: true,
  //                 },
  //                 ticks: {
  //                   display: false
  //                 }
  //               }]
  //             }
  //           };
  //         });
  //       }
  //     });
  //   }
  // }

  // ↓グラフ関連処理不要
  // getLiveData(): void {

  //   if (this.points.length > 0) {

  //     if (this.pointSelection == true) {
  //       this.fdtime = [];
  //       this.tdtime = [];
  //       this.tdtime = new Date();
  //       this.fdtime = new Date(Date.now() - 86400 * 1000).toISOString();

  //       this.dateFilter();
  //       this.bindWidgets()
  //     }
  //   }
  //   else {
  //     return;
  //   }
  // }
  // ↑グラフ関連処理不要

  // ↓グラフ関連処理不要
  //最新のアラートをベルマークに表示する
  // getAlertData(messageText: any): void {

  //   if (messageText === 'AlertData') {

  //     this.todoService.getRecentAlerts("false", this.currentCityId).subscribe((response: any) => {
  //       this.alertsArray = response.alertResponses;

  //       if (this.alertsArray.length > 0) {
  //         this.snotifyService.confirm(this.translate.instant('Mark this notification as read?')
  //           , this.translate.instant('Threshold exceeded'), {
  //           buttons: [
  //             {
  //               text: this.cookieService.get('language') === 'en' ? 'Yes' : 'はい', action: toast => {
  //                 this.markRead(toast);
  //               },
  //             },

  //             {
  //               text: this.cookieService.get('language') === 'en' ? 'No' : '番号', action: toast => {
  //                 this.snotifyService.remove(toast.id);
  //               },
  //             },

  //             {
  //               text: this.cookieService.get('language') === 'en' ? 'Close' : '閉じる',
  //               action: toast => {
  //                 this.snotifyService.remove(toast.id);
  //               },
  //               bold: true
  //             }
  //           ],

  //           timeout: 10000,
  //           showProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           titleMaxLength: 50
  //         });
  //       }
  //     },
  //     (error: any) => {
  //       /*DO NOTHING*/
  //     });
  //   }

  //   if (messageText === 'IncorrectData') {

  //     this.todoService.getUnreadAlerts(false).subscribe((response: any) => {
  //       this.alertsArray = response.alertResponses;

  //       if (this.alertsArray.length > 0) {

  //         this.snotifyService.confirm(this.translate.instant('Mark this notification as read?')
  //           , this.translate.instant('Incorrect Data Format'), {
  //           buttons: [
  //             {
  //               text: this.cookieService.get('language') === 'en' ? 'Yes' : 'はい', action: toast => {
  //                 this.markRead(toast);
  //               },
  //             },

  //             {
  //               text: this.cookieService.get('language') === 'en' ? 'No' : '番号', action: toast => {
  //                 this.snotifyService.remove(toast.id);
  //               },
  //             },

  //             {
  //               text: this.cookieService.get('language') === 'en' ? 'Close' : '閉じる',
  //               action: toast => {
  //                 this.snotifyService.remove(toast.id);
  //               },
  //               bold: true
  //             }
  //           ],

  //           timeout: 10000,
  //           showProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           titleMaxLength: 50
  //         });
  //       }
  //     },
  //     (error: any) => {
  //       /*DO NOTHING*/
  //     });
  //   }
  // }
  // ↑グラフ関連処理不要

  // ↓グラフ関連処理不要
  // markRead(toast: SnotifyToast): void {
  //   let id: any;
  //   let request: any;

  //   this.alertsArray.map((element: any) => {
  //     id = element.id;

  //     request = {
  //       mainSensorId: element.mainSensorId,
  //       dated: element.dated,
  //       dataTimeStamp: element.dataTimeStamp,
  //       pointName: element.pointName,
  //       dataName: element.dataName,
  //       dataNameToDisplay: element.dataNameToDisplay,
  //       isRead: true,
  //       status: element.status
  //     };
  //   });

  //   this.todoService.updateAlert(request, id).subscribe((response: any) => {
  //     this.snotifyService.remove(toast.id);
  //   },
  //   (error: any) => {
  //   });
  // }
  // ↑グラフ関連処理不要

  get isSensor() {

    if (this.currentSensorId != undefined) {
      this.currentSensorId = parseInt(this.currentSensorId);
    }
    return this.currentSensorId > 0 ? false : true;
  }

  sorting(propertyName: string): void {

    if (propertyName === 'Time Stamp') {

      if (this.isArrowDown === true) {
        this.dataSource = sortObjectsArray(this.dataSource, 'timeStamp');
        this.isArrowDown = !this.isArrowDown;
      }
      else {
        this.dataSource = sortObjectsArray(this.dataSource, 'timeStamp', 'desc');
        this.isArrowDown = !this.isArrowDown;
      }
    }

    if (propertyName === 'Battery Voltage') {

      if (this.isArrowDown === true) {
        this.dataSource = sortObjectsArray(this.dataSource, 'batteryVoltage');
        this.isArrowDown = !this.isArrowDown;
      }
      else {
        this.dataSource = sortObjectsArray(this.dataSource, 'batteryVoltage', 'desc');
        this.isArrowDown = !this.isArrowDown;
      }
    }
  }
  dataWidget: any = [];

  //日付指定
  dateFilter(): any {
    this.spinner.show();

    const data = {
      cityId: this.currentCityId,
      fromDate: moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD'),
      toDate: moment(new Date(this.dataFormGroup.value.todatetime)).add(1, "d").format('YYYY-MM-DD')
    };

    //日付入力欄のチェック(リリース時コメントアウト)
    if(data.fromDate == 'Invalid date' || data.toDate == 'Invalid date' || this.dataFormGroup.value.fromdatetime == null || this.dataFormGroup.value.todatetime == null){

      if(data.fromDate == 'Invalid date' || this.dataFormGroup.value.fromdatetime == null){
        alert("日付(From)が入力されていません。");
      }
      else if(data.toDate == 'Invalid date' || this.dataFormGroup.value.todatetime == null){
        alert("日付(To)が入力されていません。");
      }

      this.spinner.hide();
      return;
    }

    // this.chart.chart.destroy();
    // this.blockCount = 4;
    this.dateFilterMode = true;
    this.IsdateFilterClear = false;
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 2500);

    //取得データの日時fromtoを取得
    let fromDate = this.dataFormGroup.value.fromdatetime;
    let toDate = this.dataFormGroup.value.todatetime
    let sumdate = toDate - fromDate;

    // ↓グラフ処理不要
    // //ブロック数を決める
    // this.blockCount = ((sumdate / 86400000) * 4) + 4;

    // //グラフの幅を決める
    // //216 = 1ブロックあたりの幅
    // this.baseWidth = ONEBLOCKPX * this.blockCount + 50;
    // this.graphWidth = ONEBLOCKPX * this.blockCount;
    // (document.getElementById('chart') as HTMLCanvasElement).width = ONEBLOCKPX * this.blockCount;
    // let a = (document.getElementById('chart') as HTMLCanvasElement);
    // let cssText = a.style.cssText;
    // a.style.cssText = cssText + `width: ${ONEBLOCKPX * this.blockCount}px !important`;
    // a.style.setProperty('width', `${ONEBLOCKPX * this.blockCount}px`, 'important');
    // ↑グラフ処理不要

    this.newSource = [];
    this.newSource.length = 0;

    sessionStorage.setItem("filterFromDate", data.fromDate);
    sessionStorage.setItem("filterToDate", data.toDate);

    if (data.fromDate === undefined && data.toDate === undefined) {
      this.isAutoRedraw = true;
    }
    else {
      this.isAutoRedraw = false;
    }

    if ((this.dataFormGroup.value.fromdatetime !== undefined && this.dataFormGroup.value.todatetime !== undefined)
      && (this.dataFormGroup.value.fromdatetime !== '' && this.dataFormGroup.value.todatetime !== '')) {

      if (data.fromDate > moment(data.toDate).add(-1, "d").format('YYYY-MM-DD')) {
        this.snotifyService.error(this.translate.instant('To Date should be greater than or equal to From Date'), '');
        this.spinner.hide();
        return;
      }
      else {
        this.passData.length = 0;
        this.passData.push(data);

        this.todoService.getAllByCityIdAndMultipleDates(data).subscribe((response: any) => {
          this.dataSource = [];
          this.newSource = [];
          this.pointViewData.length = 0;
          this.newSource.length = 0;
          this.receiveSource = response;

          // this.graphDataSource.length = 0;

          for (let index = 0; index < response.length; index++) {
            this.parseData.length = 0;
            const cityElement = this.receiveSource[index].waterFlowResponse;

            //データがない場合
            if (cityElement.length == 1) {
              this.lastData = cityElement[0];
              console.log(this.lastData.dateTime);
              this.lastData.dateTime = "-";
              this.newSource.push(cityElement[0]);
              // this.graphDataSource.push(cityElement);
            }

            //データがある場合
            else {
              //バックエンドの修正により今後不要
              //cityElement.pop();

              for (let index = 0; index < cityElement.length; index++) {
                var tmpFrom: string;
                var tmpTo: string;
                var tmpVal: string;

                tmpFrom = moment(data.fromDate, 'YYYY-MM-DD').startOf('day').format("YYYY-MM-DD HH:mm");
                tmpTo = moment(data.toDate, 'YYYY-MM-DD').startOf('day').format("YYYY-MM-DD HH:mm"),

                sessionStorage.setItem("filterFromDate", tmpFrom);
                sessionStorage.setItem("filterToDate", tmpTo);

                tmpVal = cityElement[index]["dateTime"];

                if (tmpVal >= tmpFrom) {

                  if (tmpVal <= tmpTo) {
                    this.newSource.push(cityElement[index]);
                    this.parseData.push(cityElement[index]);
                    this.copyParseData = _.cloneDeep(this.parseData);
                  }
                }
              }
              // this.graphDataSource.push(this.copyParseData);
              this.lastData = cityElement[cityElement.length - 1];
            }

            Object.assign(this.lastData, pd, cb);
            this.pointArray.length = 0;
            this.pointArray.push(this.lastData);
            this.prepareData.length = 0;
            this.prepareData.push(this.lastData);
            this.sessionData.length = 0;
            this.sessionData.push(this.lastData);
            this.isMainSensorId = this.selectedMainSensorIds.includes(this.pointArray[0]['mainSensorId']);

            this.pointArray[0]['waterLevel'] = isNull(this.pointArray[0]['waterLevel']);
            this.pointArray[0]['freshwaterConductivity'] = isNull(this.pointArray[0]['freshwaterConductivity']);
            this.pointArray[0]['saltwaterConductivity'] = isNull(this.pointArray[0]['saltwaterConductivity']);
            this.pointArray[0]['waterTemprature'] = isNull(this.pointArray[0]['waterTemprature']);
            this.pointArray[0]['batteryVoltage'] = isNull(this.pointArray[0]['batteryVoltage']);

            if (this.isMainSensorId == true) {

              if (this.pointArray != null) {

                const pointData: PointData = {
                  checkedOrNot: true,
                  PointDetail: this.pointArray[0]['PointDetail'],
                  cityName: this.pointArray[0]['cityName'],
                  areaName: this.pointArray[0]['areaName'],
                  sensorName: this.pointArray[0]['sensorName'],
                  dateTime: this.pointArray[0]['dateTime'],
                  waterQuantity: parseFloat(this.pointArray[0]['waterQuantity']).toFixed(3),
                  waterLevel: parseFloat(this.pointArray[0]['waterLevel'].toString()).toFixed(3),
                  freshwaterConductivity: parseFloat(this.pointArray[0]['freshwaterConductivity'].toString()).toFixed(1),
                  saltwaterConductivity: parseFloat(this.pointArray[0]['saltwaterConductivity'].toString()).toFixed(2),
                  waterTemprature: parseFloat(this.pointArray[0]['waterTemprature'].toString()).toFixed(2),
                  batteryVoltage: parseFloat(this.pointArray[0]['batteryVoltage'].toString()).toFixed(3),
                  turbidity: parseFloat(this.pointArray[0]['turbidity']).toFixed(1),
                  mainSensorId: this.pointArray[0]['mainSensorId']
                };

                forGetDataByCity(pointData);

                this.pointViewData.push(pointData);
              }
            }
            else {

              if (this.pointArray != null) {

                const pointData: PointData = {
                  checkedOrNot: false,
                  PointDetail: this.pointArray[0]['PointDetail'],
                  cityName: this.pointArray[0]['cityName'],
                  areaName: this.pointArray[0]['areaName'],
                  sensorName: this.pointArray[0]['sensorName'],
                  dateTime: this.pointArray[0]['dateTime'],
                  waterQuantity: parseFloat(this.pointArray[0]['waterQuantity']).toFixed(3),
                  waterLevel: parseFloat(this.pointArray[0]['waterLevel'].toString()).toFixed(3),
                  freshwaterConductivity: parseFloat(this.pointArray[0]['freshwaterConductivity'].toString()).toFixed(1),
                  saltwaterConductivity: parseFloat(this.pointArray[0]['saltwaterConductivity'].toString()).toFixed(2),
                  waterTemprature: parseFloat(this.pointArray[0]['waterTemprature'].toString()).toFixed(2),
                  batteryVoltage: parseFloat(this.pointArray[0]['batteryVoltage'].toString()).toFixed(3),
                  turbidity: parseFloat(this.pointArray[0]['turbidity']).toFixed(1),
                  mainSensorId: this.pointArray[0]['mainSensorId']
                };

                forGetDataByCity(pointData);

                this.pointViewData.push(pointData);
              }
            }
          }

          var areaSortData = this.pointViewData.sort(function (a, b) {
            return (a.areaName < b.areaName) ? -1 : 1;
          })
          this.pointSortData = areaSortData.sort(function (a, b) {
            return (a.sensorName < b.sensorName) ? -1 : 1;
          })

          this.sampleSource3 = new MatTableDataSource<PointData>(this.pointSortData);
          this.sampleSource3.paginator = this.paginator1;       //20240620追加 
          this.sampleSource3Length = this.pointSortData.length; //20240620追加

          // this.checkedGraphData.length = 0;

          // for (let index = 0; index < this.graphDataSource.length; index++) {
          //   let isChecked = this.selectedMainSensorIds.includes(this.graphDataSource[index][0].mainSensorId);

          //   if (isChecked) {
          //     this.checkedGraphData.push(this.graphDataSource[index]);
          //   }
          // }
          // this.filterationObject.dateFilter = ''; /*testing code*/

          this.spinner.hide();

          // ↓グラフ関連処理不要
          // if (this.checkedGraphData.length !== 0) {
          //   this.wlDataArray.length = 0;
          //   this.wqDataArray.length = 0;
          //   this.fwcDataArray.length = 0;
          //   this.swcDataArray.length = 0;
          //   this.bvDataArray.length = 0;
          //   this.wtDataArray.length = 0;
          //   this.tDataArray.length = 0;
          //   this.checkCount = 0;
          //   let complementedArray: any[] = [];

          //   //元になるタイムスパン
          //   let baseTimespan = new Date(this.dataFormGroup.value.fromdatetime);
          //   let formedTimespan = moment(new Date(data.fromDate)).subtract(9, 'h').format("MM-DD HH:mm");
          //   //タイムスパンの始点
          //   let timespanStart = moment(new Date(this.dataFormGroup.value.fromdatetime)).format("MM-DD HH:mm");
          //   //タイムスパンの終点
          //   let timespanEnd = moment(new Date(this.dataFormGroup.value.todatetime)).add(1, 'd').format("MM-DD HH:mm");

          //   //最適化されたタイムスパン
          //   let graphTimespan = 0;

          //   for (let mainIndex = 0; mainIndex < this.checkedGraphData.length; mainIndex++) {

          //     if (this.checkedGraphData[mainIndex].length >= 2) {

          //       for (let subIndex = 0; subIndex < this.checkedGraphData[mainIndex].length - 1; subIndex++) {
          //         let fromTimeStamp = this.checkedGraphData[mainIndex][subIndex].timeStamp;
          //         let toTimeStamp = this.checkedGraphData[mainIndex][subIndex + 1].timeStamp;

          //         let fromTime = new Date(fromTimeStamp * 1000);
          //         let toTime = new Date(toTimeStamp * 1000);

          //         //一つ目のデータの最初の時間はタイムスパンを記録するだけ
          //         if (subIndex == 0 && mainIndex == 0) {
          //           graphTimespan = (toTime.getTime() - fromTime.getTime()) / (60 * 1000);
          //         }
          //         //二回目以降は保存されたタイムスパンとの最大公約数を求める
          //         else {
          //           let currentTimespan = (toTime.getTime() - fromTime.getTime()) / (60 * 1000);

          //           let pre = graphTimespan;
          //           let post = currentTimespan;

          //           while (post != 0) {
          //             let result = pre % post;
          //             pre = post;
          //             post = result;
          //           }

          //           graphTimespan = pre;

          //           if (graphTimespan == 1) {
          //             break;
          //           }
          //         }
          //       }
          //     }
          //   }

          //   //既存の時間軸をクリア
          //   this.lineChartLabels.length = 0;

          //   //タイムスタンプ作成
          //   while (formedTimespan < timespanEnd) {

          //     //最初に始点となる日時を入れる
          //     if (this.lineChartLabels.length == 0) {
          //       this.lineChartLabels.push(timespanStart);
          //     }
          //     else {
          //       let calcTimespan = baseTimespan.setMinutes(baseTimespan.getMinutes() + graphTimespan);
          //       let timestamp = moment(new Date(calcTimespan)).format("MM-DD HH:mm");
          //       //時間軸に追加
          //       this.lineChartLabels.push(timestamp);
          //       formedTimespan = timestamp;
          //     }
          //   }

          //   //データソースのインデックス
          //   let dataSourceIndex = 0;

          //   this.complementedGraphData.length = 0;

          //   //補完回数のカウント
          //   let correctCount = 0;

          //   for (let mainIndex = 0; mainIndex < this.checkedGraphData.length; mainIndex++) {
          //     complementedArray.length = 0;
          //     dataSourceIndex = 0;

          //     if (this.checkedGraphData[mainIndex].length >= 2) {

          //       for (let subIndex = 0; subIndex < this.lineChartLabels.length; subIndex++) {

          //         //データソースにあるデータを全て入力し終えた場合、時間軸の足りない部分にデータを追加する
          //         if (this.checkedGraphData[mainIndex].length == dataSourceIndex) {
          //           correctCount = this.lineChartLabels.length - subIndex;

          //           if (correctCount !== 0) {

          //             for (let correctIndex = 0; correctIndex < correctCount; correctIndex++) {
          //               const data = {
          //                 waterLevel: null,
          //                 waterQuantity: null,
          //                 freshwaterConductivity: null,
          //                 saltwaterConductivity: null,
          //                 batteryVoltage: null,
          //                 waterTemprature: null,
          //                 turbidity: null
          //               }
          //               complementedArray.push(data);
          //               subIndex++;
          //             }
          //             correctCount = 0;
          //             break;
          //           }
          //         }

          //         //ラベルの時間軸とデータソースの時間が等しい場合にデータを配列に追加
          //         if (this.lineChartLabels[subIndex] == moment(new Date(this.checkedGraphData[mainIndex][dataSourceIndex].timeStamp * 1000)).format("MM-DD HH:mm")) {

          //           //一つ目のデータのときはただ配列に追加するのみ
          //           if (dataSourceIndex == 0) {

          //             const data = {
          //               waterLevel: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterLevel),
          //               waterQuantity: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterQuantity),
          //               freshwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].freshwaterConductivity,
          //               saltwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].saltwaterConductivity,
          //               batteryVoltage: this.checkedGraphData[mainIndex][dataSourceIndex].batteryVoltage,
          //               waterTemprature: this.checkedGraphData[mainIndex][dataSourceIndex].waterTemprature,
          //               turbidity: this.checkedGraphData[mainIndex][dataSourceIndex].turbidity
          //             }
          //             complementedArray.push(data);
          //             dataSourceIndex++;
          //             correctCount = 0;
          //           }

          //           //カウントしていた飛ばした回数をもとにデータを補完する
          //           else {
          //             let sensorId = this.checkedGraphData[mainIndex][0].mainSensorId;
          //             dataComplement(subIndex, mainIndex, dataSourceIndex, this.checkedGraphData, correctCount, graphTimespan, complementedArray, sensorId, this.lineChartLabels, true);

          //             //補完後に実際の順番のデータを配列に追加してデータソース側のインデックスを1増やす
          //             const data = {
          //               waterLevel: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterLevel),
          //               waterQuantity: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterQuantity),
          //               freshwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].freshwaterConductivity,
          //               saltwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].saltwaterConductivity,
          //               batteryVoltage: this.checkedGraphData[mainIndex][dataSourceIndex].batteryVoltage,
          //               waterTemprature: this.checkedGraphData[mainIndex][dataSourceIndex].waterTemprature,
          //               turbidity: this.checkedGraphData[mainIndex][dataSourceIndex].turbidity
          //             }
          //             complementedArray.push(data);
          //             dataSourceIndex++;
          //             correctCount = 0;
          //           }
          //         }

          //         //ラベルの時間軸とデータソースの時間が等しくない場合
          //         else {

          //           //二つ目以降はタイムスパンを飛ばした回数をカウントする
          //           if (dataSourceIndex !== 0) {
          //             correctCount++;
          //           }
          //           //一つ目が見つかるまでnullを入れ続ける
          //           else {

          //             const data = {
          //               waterLevel: null,
          //               waterQuantity: null,
          //               freshwaterConductivity: null,
          //               saltwaterConductivity: null,
          //               batteryVoltage: null,
          //               waterTemprature: null,
          //               turbidity: null
          //             }
          //             complementedArray.push(data);
          //           }
          //         }
          //       }
          //     }
          //     const copy = _.cloneDeep(complementedArray);
          //     this.complementedGraphData.push(copy);
          //     dataSourceIndex = 0;
          //   }

          //   for (let i = 0; i < this.complementedGraphData.length; i++) {
          //     this.wlDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterLevel));
          //     this.wqDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterQuantity));
          //     this.fwcDataArray.push(this.complementedGraphData[i].map((x: any) => x.freshwaterConductivity));
          //     this.swcDataArray.push(this.complementedGraphData[i].map((x: any) => x.saltwaterConductivity));
          //     this.bvDataArray.push(this.complementedGraphData[i].map((x: any) => x.batteryVoltage));
          //     this.wtDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterTemprature));
          //     this.tDataArray.push(this.complementedGraphData[i].map((x: any) => x.turbidity));
          //     this.checkCount = this.checkCount + 1;
          //   }
          // }

          // this.lineChartOptions = {
          //   legend: false,
          //   showpoint: false,
          //   responsive: false,
          //   elements: {
          //     point: {
          //       radius: 0
          //     }
          //   },
          //   plugins: {
          //   },
          //   scales: {
          //     xAxes: [{
          //       ticks: {
          //         autoSkip: true,
          //         maxTicksLimit: this.blockCount,
          //         maxRotation: 0,
          //         minRotation: 0
          //       },
          //       gridLines: {
          //         color: '#FFF'
          //       },
          //       scaleLabel: {
          //         display: true,
          //         labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : this.translate.instant('日時'),
          //       }
          //     }],
          //     yAxes: [{
          //       scaleLabel: {
          //         display: true,
          //       },
          //       ticks: {
          //         display: false
          //       }
          //     }]
          //   }
          // };

          // if (response != null) {
          //   this.multipleChartData();
          // }
          // else {
          //   return
          // }
          // ↑グラフ関連処理不要
        });
      }
    }
  }

  orderData: number = 0

  getAllUserCities(userId: any): void {

    this.todoService.getCityByUserId(userId).subscribe((response: any) => {
      this.cityArray = response;
      this.selectedCityData = this.cityArray[3];
    },
    error => {
    });
  }

  dateFilterClear() {
    // this.chart.chart.destroy();
    this.spinner.show();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 2500);
    this.dateFilterMode = false;
    this.IsdateFilterClear = true;

    // ↓グラフ関連処理
    // this.graphWidth = FULLHDWIDTH;
    // (document.getElementById('chart') as HTMLCanvasElement).width = FULLHDWIDTH;
    // let a = (document.getElementById('chart') as HTMLCanvasElement);
    // let cssText = a.style.cssText;
    // a.style.cssText = cssText + 'width: 1080px !important;';
    // a.style.setProperty('width', '1080px', 'important');
    // a.setAttribute("width", "1080px");
    // this.chart.chart.width = FULLHDWIDTH;
    // this.blockCount = INITIALBLOCKVALUE;
    // ↑グラフ関連処理

    this.getDataByCity();
  }

  selectGraph() {
    var dialog = (document.getElementById('dialog') as HTMLDialogElement);
    dialog.showModal();
  }

  // ↓グラフ関連処理
  // // グラフ表示項目全選択
  // selectAllGraph() {
  //   this.isAllSelect = (document.getElementById('selectall') as HTMLInputElement).checked;

  //   if (this.isAllSelect == true) {
  //     (document.getElementById('waterquantity') as HTMLInputElement).checked = true;
  //     (document.getElementById('waterlevel') as HTMLInputElement).checked = true;
  //     (document.getElementById('freshwaterconductivity') as HTMLInputElement).checked = true;
  //     (document.getElementById('saltwaterconductivity') as HTMLInputElement).checked = true;
  //     (document.getElementById('watertemperature') as HTMLInputElement).checked = true;
  //     (document.getElementById('batteryvoltage') as HTMLInputElement).checked = true;
  //     (document.getElementById('turbidity') as HTMLInputElement).checked = true;
  //     // this.isWaterQuantitySelect = true;
  //     // this.isWaterLevelSelect = true;
  //     // this.isFreshwaterConductivitySelect = true;
  //     // this.isSaltwaterConductivitySelect = true;
  //     // this.isBatteryVoltageSelect = true;
  //     // this.isWaterTemperatureSelect = true;
  //     // this.isTurbiditySelect = true;
  //   }
  //   else
  //   {
  //     (document.getElementById('waterquantity') as HTMLInputElement).checked = false;
  //     (document.getElementById('waterlevel') as HTMLInputElement).checked = false;
  //     (document.getElementById('freshwaterconductivity') as HTMLInputElement).checked = false;
  //     (document.getElementById('saltwaterconductivity') as HTMLInputElement).checked = false;
  //     (document.getElementById('watertemperature') as HTMLInputElement).checked = false;
  //     (document.getElementById('batteryvoltage') as HTMLInputElement).checked = false;
  //     (document.getElementById('turbidity') as HTMLInputElement).checked = false;
  //     // this.isWaterQuantitySelect = false;
  //     // this.isWaterLevelSelect = false;
  //     // this.isFreshwaterConductivitySelect = false;
  //     // this.isSaltwaterConductivitySelect = false;
  //     // this.isBatteryVoltageSelect = false;
  //     // this.isWaterTemperatureSelect = false;
  //     // this.isTurbiditySelect = false;
  //   }
  // }
  // ↑グラフ関連処理

  // ↓グラフ関連処理
  // // 表示項目個別選択
  // selectDataGraph() {
  //   let chkWaterQuantity: boolean = (document.getElementById("waterquantity") as HTMLInputElement).checked;
  //   let chkWaterLevel: boolean = (document.getElementById("waterlevel") as HTMLInputElement).checked;
  //   let chkFreshwaterConductivity: boolean = (document.getElementById("freshwaterconductivity") as HTMLInputElement).checked;
  //   let chkSaltwaterConductivity: boolean = (document.getElementById("saltwaterconductivity") as HTMLInputElement).checked;
  //   let chkWaterTemperature: boolean = (document.getElementById("watertemperature") as HTMLInputElement).checked;
  //   let chkBatteryVoltage: boolean = (document.getElementById("batteryvoltage") as HTMLInputElement).checked;
  //   let chkTurbidity: boolean = (document.getElementById("turbidity") as HTMLInputElement).checked;

  //   if (this.isAllSelect == true)
  //   {
  //     if (chkWaterQuantity == false || chkWaterLevel == false || chkFreshwaterConductivity == false || chkSaltwaterConductivity == false || chkWaterTemperature == false || chkBatteryVoltage == false || chkTurbidity == false)
  //     {
  //       (document.getElementById('selectall') as HTMLInputElement).checked = false;
  //       this.isAllSelect = false;
  //     }
  //   }
  //   else if (this.isAllSelect == false)
  //   {
  //     if (chkWaterQuantity == true && chkWaterLevel == true && chkFreshwaterConductivity == true && chkSaltwaterConductivity == true && chkWaterTemperature == true && chkBatteryVoltage == true && chkTurbidity == true)
  //     {
  //       (document.getElementById('selectall') as HTMLInputElement).checked = true;
  //       this.isAllSelect = true;
  //     }
  //   }
  // }
  // ↑グラフ関連処理

  // ↓グラフ関連処理
  // clickOKBtn() {
  //   var dialog = (document.getElementById('dialog') as HTMLDialogElement);
  //   this.isAllSelect = (document.getElementById('selectall') as HTMLInputElement).checked;
  //   this.isWaterQuantitySelect = (document.getElementById('waterquantity') as HTMLInputElement).checked;
  //   this.isWaterLevelSelect = (document.getElementById('waterlevel') as HTMLInputElement).checked;
  //   this.isFreshwaterConductivitySelect = (document.getElementById('freshwaterconductivity') as HTMLInputElement).checked;
  //   this.isSaltwaterConductivitySelect = (document.getElementById('saltwaterconductivity') as HTMLInputElement).checked;
  //   this.isBatteryVoltageSelect = (document.getElementById('batteryvoltage') as HTMLInputElement).checked;
  //   this.isWaterTemperatureSelect = (document.getElementById('watertemperature') as HTMLInputElement).checked;
  //   this.isTurbiditySelect = (document.getElementById('turbidity') as HTMLInputElement).checked;
  //   dialog.close();
  //   // this.multipleChartData();
  // }
  // ↑グラフ関連処理

  //チェックボックス操作イベント
  checkedOrNot(checked: boolean, cbindex: number, mainSensorId: string) {
    // this.backupDataSource = _.cloneDeep(this.graphDataSource);
    this.backUpMainSensorsArray = _.cloneDeep(this.mainSensorsArray);

    // マーカー表示切り替え
    this.visibleMarker(checked, mainSensorId);

    if (checked == true) {

      for (let i = 0; i < this.pointSortData.length; i++) {
        if(this.pointSortData[i].mainSensorId == mainSensorId) this.pointSortData[i].checkedOrNot = true;
      }

      var addData = this.graphDataSource.filter((x: any) => x[0].mainSensorId == mainSensorId);
      this.checkedGraphData.push(addData[0]);
      var addmsId = this.mainSensorsArray.filter((x: any) => x == mainSensorId);
      this.selectedMainSensorIds.push(addmsId[0]);

      if(this.allMainSensorIds.length == this.selectedMainSensorIds.length){
        this.headerCheckBox = true;
      }
    }
    else {

      for (let i = 0; i < this.pointSortData.length; i++) {

        if(this.pointSortData[i].mainSensorId == mainSensorId) this.pointSortData[i].checkedOrNot = false;
      }

      this.checkedGraphData = this.checkedGraphData.filter((x: any) => x[0].mainSensorId !== mainSensorId);
      this.selectedMainSensorIds = this.selectedMainSensorIds.filter((x: any) => x !== mainSensorId);

      if(this.selectedMainSensorIds.length == 0){
        this.headerCheckBox = false;
      }
    }

    // ↓グラフ関連処理不要
    // if (this.checkedGraphData.length !== 0) {
    //   //x軸ラベルの再描画イベント
    //   this.makeXAxisLabel();
    //   //グラフデータ作成イベント
    //   this.organizeGraphData();
    //   //y軸ラベルの再描画イベント(ベースのグラフ)
    //   this.setCanvasOptions();
    //   //y軸ラベルの再描画イベント(移動可能なy軸ラベル用)
    //   this.setYAxisOptions();
    //   //データの格納イベント
    //   this.multipleChartData();
    // }
    // else {
    //   this.headerCheckBox = false;
    //   this.multipleLineChartData.length = 0;
    //   this.multipleLineChartDataJP.length = 0;
    //   this.wlDataArray.length = 0;
    //   this.wqDataArray.length = 0;
    //   this.fwcDataArray.length = 0;
    //   this.swcDataArray.length = 0;
    //   this.bvDataArray.length = 0;
    //   this.wtDataArray.length = 0;
    //   this.tDataArray.length = 0;
    // }
    // ↑グラフ関連処理不要
  }

  // makeGraphData(): void {
  //   let drawCount = 0; //グラフの描画カウント(0ならLoading解除)

  //   if (this.checkedGraphData.length !== 0) {
  //     this.wlDataArray.length = 0;
  //     this.wqDataArray.length = 0;
  //     this.fwcDataArray.length = 0;
  //     this.swcDataArray.length = 0;
  //     this.bvDataArray.length = 0;
  //     this.wtDataArray.length = 0;
  //     this.tDataArray.length = 0;
  //     this.checkCount = 0;
  //     let complementedArray: any[] = [];
  //     this.complementedGraphData.length = 0;
  //     //最適化されたタイムスパン
  //     let graphTimespan = 0;

  //     //日付指定がされている場合
  //     if (this.dateFilterMode == false) {
  //       this.blockCount = INITIALBLOCKVALUE;
  //       //新規タイムスパン
  //       let today = new Date();
  //       let compareToday = new Date();
  //       //時間軸(初期設定は当日の0時0分)
  //       let timeAxis = new Date(today.setHours(0, 0, 0, 0));

  //       //時間軸の始点格納用変数
  //       let fromTimeAxis = new Date(today.setHours(0, 0, 0, 0));
  //       //時間軸の終点格納用変数
  //       let toTimeAxis = new Date(today.setHours(0, 0, 0, 0));

  //       //ブロック数を操作するため
  //       let timeAxisCount = 0;

  //       //0時から6時間ずつ追加して表示ブロックの終点を決める
  //       while (compareToday > toTimeAxis) {
  //         toTimeAxis = new Date(toTimeAxis.setHours(toTimeAxis.getHours() + 6));
  //         timeAxisCount++;
  //       }

  //       timeAxisCount = INITIALBLOCKVALUE - timeAxisCount;

  //       //0時から6時間ずつ減算して表示ブロックの始点を決める
  //       for (let i = 0; i < timeAxisCount; i++) {
  //         fromTimeAxis = new Date(fromTimeAxis.setHours(fromTimeAxis.getHours() - 6))
  //       }

  //       //タイムスパンの整形(始点)
  //       let formedFromTimeAxis = moment(fromTimeAxis).format("MM-DD HH:mm");
  //       //タイムスパンの整形(終点)
  //       let formedToTimeAxis = moment(toTimeAxis).format("MM-DD HH:mm");

  //       for (let mainIndex = 0; mainIndex < this.checkedGraphData.length; mainIndex++) {

  //         if (this.checkedGraphData[mainIndex].length >= 2) {
  //           drawCount++;

  //           for (let subIndex = 0; subIndex < this.checkedGraphData[mainIndex].length - 1; subIndex++) {
  //             let fromTimeStamp = this.checkedGraphData[mainIndex][subIndex].timeStamp;
  //             let toTimeStamp = this.checkedGraphData[mainIndex][subIndex + 1].timeStamp;

  //             let fromTime = new Date(fromTimeStamp * 1000);
  //             let toTime = new Date(toTimeStamp * 1000);

  //             //一つ目のデータの最初の時間はタイムスパンを記録するだけ
  //             if (subIndex == 0 && mainIndex == 0) {
  //               graphTimespan = (toTime.getTime() - fromTime.getTime()) / (60 * 1000);
  //             }
  //             //二回目以降は保存されたタイムスパンとの最大公約数を求める
  //             else {
  //               let currentTimespan = (toTime.getTime() - fromTime.getTime()) / (60 * 1000);

  //               let pre = graphTimespan;
  //               let post = currentTimespan;

  //               while (post != 0) {
  //                 let result = pre % post;
  //                 pre = post;
  //                 post = result;
  //               }

  //               graphTimespan = pre;

  //               if (graphTimespan == 1) {
  //                 break;
  //               }
  //             }
  //           }
  //         }
  //       }

  //       if(drawCount == 0){
  //         this.spinner.hide();
  //         return;
  //       }

  //       //タイムスパンをコピー
  //       this.timespan = _.cloneDeep(graphTimespan);

  //       //既存の時間軸をクリア
  //       this.lineChartLabels.length = 0;

  //       //時間軸作成
  //       while (formedFromTimeAxis < formedToTimeAxis) {

  //         //最初に始点となる日時を入れる
  //         if (this.lineChartLabels.length == 0) {
  //           this.lineChartLabels.push(formedFromTimeAxis);
  //         }
  //         else {
  //           let calcTimespan = fromTimeAxis.setMinutes(fromTimeAxis.getMinutes() + graphTimespan);
  //           let timestamp = moment(new Date(calcTimespan)).format("MM-DD HH:mm");
  //           //時間軸に追加
  //           this.lineChartLabels.push(timestamp);
  //           formedFromTimeAxis = timestamp;
  //         }
  //       }
  //     }

  //     //日付指定がされていない場合
  //     else {
  //       let timeStampStart = _.cloneDeep(this.dataFormGroup.value.fromdatetime);
  //       //タイムスパンの整形(始点)
  //       let formedFromTimeAxis = moment(this.dataFormGroup.value.fromdatetime).format("MM-DD HH:mm");
  //       //タイムスパンの整形(終点)
  //       let formedToTimeAxis = moment(this.dataFormGroup.value.todatetime).add(1, 'd').format("MM-DD HH:mm");

  //       for (let mainIndex = 0; mainIndex < this.checkedGraphData.length; mainIndex++) {

  //         if (this.checkedGraphData[mainIndex].length >= 2) {
  //           drawCount++;

  //           for (let subIndex = 0; subIndex < this.checkedGraphData[mainIndex].length - 1; subIndex++) {
  //             let fromTimeStamp = this.checkedGraphData[mainIndex][subIndex].timeStamp;
  //             let toTimeStamp = this.checkedGraphData[mainIndex][subIndex + 1].timeStamp;

  //             let fromTime = new Date(fromTimeStamp * 1000);
  //             let toTime = new Date(toTimeStamp * 1000);

  //             //一つ目のデータの最初の時間はタイムスパンを記録するだけ
  //             if (subIndex == 0 && mainIndex == 0) {
  //               graphTimespan = (toTime.getTime() - fromTime.getTime()) / (60 * 1000);
  //             }
  //             //二回目以降は保存されたタイムスパンとの最大公約数を求める
  //             else {
  //               let currentTimespan = (toTime.getTime() - fromTime.getTime()) / (60 * 1000);

  //               let pre = graphTimespan;
  //               let post = currentTimespan;

  //               while (post != 0) {
  //                 let result = pre % post;
  //                 pre = post;
  //                 post = result;
  //               }

  //               graphTimespan = pre;

  //               if (graphTimespan == 1) {
  //                 break;
  //               }
  //             }
  //           }
  //         }
  //       }

  //       if(drawCount == 0){
  //         this.spinner.hide();
  //         return;
  //       }

  //       //既存の時間軸をクリア
  //       this.lineChartLabels.length = 0;

  //       //時間軸作成
  //       while (formedFromTimeAxis < formedToTimeAxis) {

  //         //最初に始点となる日時を入れる
  //         if (this.lineChartLabels.length == 0) {
  //           this.lineChartLabels.push(formedFromTimeAxis);
  //         }
  //         else {
  //           let calcTimespan = timeStampStart.setMinutes(timeStampStart.getMinutes() + this.timespan);
  //           let timestamp = moment(new Date(calcTimespan)).format("MM-DD HH:mm");
  //           //時間軸に追加
  //           this.lineChartLabels.push(timestamp);
  //           formedFromTimeAxis = timestamp;
  //         }
  //       }
  //     }

  //     //データソースのインデックス
  //     let dataSourceIndex = 0;

  //     this.complementedGraphData.length = 0;

  //     //補完回数のカウント
  //     let correctCount = 0;

  //     for (let mainIndex = 0; mainIndex < this.checkedGraphData.length; mainIndex++) {
  //       complementedArray.length = 0;
  //       dataSourceIndex = 0;

  //       if (this.checkedGraphData[mainIndex].length >= 2) {
  //         let sensorId = this.checkedGraphData[mainIndex][0].mainSensorId;

  //         for (let subIndex = 0; subIndex < this.lineChartLabels.length; subIndex++) {

  //           //データソースにあるデータを全て入力し終えた場合、時間軸の足りない部分にデータを追加する
  //           if (this.checkedGraphData[mainIndex].length == dataSourceIndex) {
  //             correctCount = this.lineChartLabels.length - subIndex;

  //             if (correctCount !== 0) {

  //               for (let correctIndex = 0; correctIndex < correctCount; correctIndex++) {

  //                 const data = {
  //                   waterLevel: null,
  //                   waterQuantity: null,
  //                   freshwaterConductivity: null,
  //                   saltwaterConductivity: null,
  //                   batteryVoltage: null,
  //                   waterTemprature: null,
  //                   turbidity: null,
  //                   mainSensorId: sensorId,
  //                   datetime: this.lineChartLabels[subIndex]
  //                 }
  //                 complementedArray.push(data);
  //                 subIndex++;
  //               }
  //               correctCount = 0;
  //               break;
  //             }
  //           }

  //           //ラベルの時間軸とデータソースの時間が等しい場合にデータを配列に追加
  //           if (this.lineChartLabels[subIndex] == moment(new Date(this.checkedGraphData[mainIndex][dataSourceIndex].timeStamp * 1000))
  //             .format("MM-DD HH:mm")) {

  //             //一つ目のデータのときはただ配列に追加するのみ
  //             if (dataSourceIndex == 0) {

  //               const data = {
  //                 waterLevel: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterLevel),
  //                 waterQuantity: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterQuantity),
  //                 freshwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].freshwaterConductivity,
  //                 saltwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].saltwaterConductivity,
  //                 batteryVoltage: this.checkedGraphData[mainIndex][dataSourceIndex].batteryVoltage,
  //                 waterTemprature: this.checkedGraphData[mainIndex][dataSourceIndex].waterTemprature,
  //                 turbidity: this.checkedGraphData[mainIndex][dataSourceIndex].turbidity,
  //                 mainSensorId: sensorId,
  //                 datetime: this.lineChartLabels[subIndex]
  //               }

  //               complementedArray.push(data);
  //               dataSourceIndex++;
  //               correctCount = 0;
  //             }

  //             //カウントしていた飛ばした回数をもとにデータを補完する
  //             else {
  //               dataComplement(subIndex, mainIndex, dataSourceIndex, this.checkedGraphData, correctCount, graphTimespan, complementedArray, sensorId, this.lineChartLabels, false);

  //               //補完後に実際の順番のデータを配列に追加してデータソース側のインデックスを1増やす
  //               const data = {
  //                 waterLevel: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterLevel),
  //                 waterQuantity: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterQuantity),
  //                 freshwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].freshwaterConductivity,
  //                 saltwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].saltwaterConductivity,
  //                 batteryVoltage: this.checkedGraphData[mainIndex][dataSourceIndex].batteryVoltage,
  //                 waterTemprature: this.checkedGraphData[mainIndex][dataSourceIndex].waterTemprature,
  //                 turbidity: this.checkedGraphData[mainIndex][dataSourceIndex].turbidity,
  //                 mainSensorId: sensorId,
  //                 datetime: this.lineChartLabels[subIndex]
  //               }

  //               complementedArray.push(data);
  //               dataSourceIndex++;
  //               correctCount = 0;
  //             }
  //           }

  //           //ラベルの時間軸とデータソースの時間が等しくない場合
  //           else {
  //             //二つ目以降はタイムスパンを飛ばした回数をカウントする
  //             if (dataSourceIndex !== 0) {
  //               correctCount++;
  //             }
  //             //一つ目が見つかるまでnullを入れ続ける
  //             else {

  //               const data = {
  //                 waterLevel: null,
  //                 waterQuantity: null,
  //                 freshwaterConductivity: null,
  //                 saltwaterConductivity: null,
  //                 batteryVoltage: null,
  //                 waterTemprature: null,
  //                 turbidity: null,
  //                 mainSensorId: sensorId,
  //                 datetime: this.lineChartLabels[subIndex]
  //               }
  //               complementedArray.push(data);
  //             }
  //           }
  //         }
  //         const copy = _.cloneDeep(complementedArray);
  //         this.complementedGraphData.push(copy);
  //         dataSourceIndex = 0;
  //       }

  //       //データが存在しない場合
  //       else {

  //         for (let i = 0; i < this.lineChartLabels.length; i++) {

  //           const data = {
  //             waterLevel: null,
  //             waterQuantity: null,
  //             freshwaterConductivity: null,
  //             saltwaterConductivity: null,
  //             batteryVoltage: null,
  //             waterTemprature: null,
  //             turbidity: null,
  //             mainSensorId: this.checkedGraphData[mainIndex][0].mainSensorId,
  //             datetime: this.lineChartLabels[i]
  //           }
  //           complementedArray.push(data);
  //         }
  //         const copy = _.cloneDeep(complementedArray);
  //         this.complementedGraphData.push(copy);
  //       }
  //     }

  //     for (let i = 0; i < this.complementedGraphData.length; i++) {
  //       this.wlDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterLevel));
  //       this.wqDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterQuantity));
  //       this.fwcDataArray.push(this.complementedGraphData[i].map((x: any) => x.freshwaterConductivity));
  //       this.swcDataArray.push(this.complementedGraphData[i].map((x: any) => x.saltwaterConductivity));
  //       this.bvDataArray.push(this.complementedGraphData[i].map((x: any) => x.batteryVoltage));
  //       this.wtDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterTemprature));
  //       this.tDataArray.push(this.complementedGraphData[i].map((x: any) => x.turbidity));
  //       this.checkCount = this.checkCount + 1;
  //     }

  //     this.sensorsFilteredData.map(element => {

  //       this.lineChartOptions = {
  //         legend: false,
  //         showpoint: false,
  //         responsive: false,
  //         elements: {
  //           point: {
  //             radius: 0
  //           }
  //         },
  //         plugins: {
  //         },
  //         scales: {
  //           xAxes: [{
  //             ticks: {
  //               autoSkip: true,
  //               maxTicksLimit: this.blockCount,
  //               maxRotation: 0,
  //               minRotation: 0
  //             },
  //             gridLines: {
  //               color: '#FFF'
  //             },
  //             scaleLabel: {
  //               display: true,
  //               labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : this.translate.instant('日時'),
  //             },
  //           }],
  //           yAxes: [{
  //             scaleLabel: {
  //               display: true,
  //             },
  //             ticks: {
  //               display: false
  //             }
  //           }]
  //         }
  //       };

  //       //Y軸だけのグラフ用
  //       this.subLineChartOptions = {
  //         legend: false,
  //         showpoint: false,
  //         responsive: false,
  //         elements: {
  //           point: {
  //             radius: 0
  //           }
  //         },
  //         plugins: {
  //         },
  //         scales: {
  //           xAxes: [{
  //             ticks: {
  //               autoSkip: true,
  //               maxTicksLimit: this.blockCount,
  //             },
  //             grid: {
  //               drawBorder: false
  //             },
  //             scaleLabel: {
  //               display: false,
  //               labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : this.translate.instant('日時'),
  //             },
  //           }],
  //           yAxes: [{
  //             gridLines: {
  //               display: false
  //             },
  //             scaleLabel: {
  //               display: true,
  //               labelString: this.cookieService.get('language') === 'en' ? 'Value' : this.translate.instant('値'),
  //               padding:{
  //                 top: 0,
  //                 bottom: 40
  //               }
  //             },
  //             ticks: {
  //               showLabelBackdrop: true,
  //               backdropColor: "#fff",
  //               padding: -35 //-30
  //             },
  //           }]
  //         }
  //       };
  //       this.widgetsDataArray = element;
  //     });

  //     if(drawCount == 0){
  //       this.spinner.hide();
  //     }
  //     // else this.multipleChartData();
  //   }
  //   else this.spinner.hide();
  // }

  //画面下リストのソート(地区名と地点名のみ対応)  //20240620全項目対応
  mainSort(/*sortObject: string*/): void {
    //20240620コメントアウト
    // if (sortObject === 'areaName') {
    //   this.sampleSource3.sort = this.sort;
    // }
    // else if (sortObject === 'sensorName') {
    //   this.sampleSource3.sort = this.sort;
    // }
    //20240620コメントアウト終了
    this.sampleSource3.sort = this.sort;  //20240620追加
  }

  //ヘッダー部分のチェック動作
  isAllPointSelect(isChecked: boolean): void {
    // マーカー表示切り替え
    this.visibleMarker(isChecked);

    //チェックを付けた場合(全選択)
    if (isChecked == true) {

      for (let i = 0; i < this.pointSortData.length; i++) {
        this.pointSortData[i].checkedOrNot = true;
      }

      this.headerCheckBox = true;
      this.sampleSource3 = new MatTableDataSource<PointData>(this.pointSortData);
      this.sampleSource3.paginator = this.paginator1;       //20240620追加 
      this.sampleSource3Length = this.pointSortData.length; //20240620追加
      this.isMainSensorId = true;
      this.checkedGraphData = _.cloneDeep(this.graphDataSource);
      this.selectedMainSensorIds = _.cloneDeep(this.allMainSensorIds);
      // this.makeGraphData();
    }
    //チェックを外した場合(未選択)
    else {

      for (let i = 0; i < this.pointSortData.length; i++) {
        this.pointSortData[i].checkedOrNot = false;
      }

      this.sampleSource3 = new MatTableDataSource<PointData>(this.pointSortData);
      this.sampleSource3.paginator = this.paginator1;       //20240620追加 
      this.sampleSource3Length = this.pointSortData.length; //20240620追加
      this.isMainSensorId = false;
      // this.checkedGraphData.length = 0;
      // this.multipleLineChartData.length = 0;
      // this.multipleLineChartDataJP.length = 0;
      // this.wlDataArray.length = 0;
      // this.wqDataArray.length = 0;
      // this.fwcDataArray.length = 0;
      // this.swcDataArray.length = 0;
      // this.bvDataArray.length = 0;
      // this.wtDataArray.length = 0;
      // this.tDataArray.length = 0;

      this.selectedMainSensorIds.length = 0;
      this.headerCheckBox = false;
    }
  }

  //最新のデータを取得しグラフに追加プロットする
  getLatestData(): void {
    let sendDataArray = [];

    for (let baseDataIndex = 0; baseDataIndex < this.graphDataSource.length; baseDataIndex++) {

      if (this.graphDataSource[baseDataIndex].length > 0) {
        let data = this.graphDataSource[baseDataIndex];
        let lastData = data[data.length - 1];

        if (lastData.receivedDate == null) {
          lastData.receivedDate = moment(new Date()).format('yyyy-MM-DD HH:mm:ss');
        }

        //センサーIdと最後の受信日時を併せてバックエンドに送る
        let sendData = {
          date: lastData.receivedDate,
          mainSensorId: lastData.mainSensorId
        }
        sendDataArray.push(sendData);
      }
    }

    //最新データ取得
    this.todoService.getLatestData(sendDataArray).subscribe((response: any) => {
      let responseList = _.cloneDeep(response);

      //最新値を画面下へ反映する
      let isUpdate = false;

      for (let responseListIndex = 0; responseListIndex < responseList.length; responseListIndex++) {
        let element = responseList[responseListIndex].waterFlowResponse;
        if (element != null && element.length > 0) {
          let editData = this.graphDataSource.find((x: any) => x[0].mainSensorId == element[element.length - 1].mainSensorId);

          if (element.length > 0 && editData[editData.length - 1].timeStamp < element[element.length - 1].timeStamp) {
            let pastData = this.pointSortData.find((x: any) => x.mainSensorId == element[element.length - 1].mainSensorId);
            let dataIndex = this.pointSortData.findIndex((x: any) => x.mainSensorId == element[element.length - 1].mainSensorId);
            let isExistSensorId = this.selectedMainSensorIds.includes(element[element.length - 1].mainSensorId);

            element[element.length - 1]['waterLevel'] = isNull(element[element.length - 1]['waterLevel']);
            element[element.length - 1]['freshwaterConductivity'] = isNull(element[element.length - 1]['freshwaterConductivity']);
            element[element.length - 1]['saltwaterConductivity'] = isNull(element[element.length - 1]['saltwaterConductivity']);
            element[element.length - 1]['waterTemprature'] = isNull(element[element.length - 1]['waterTemprature']);
            element[element.length - 1]['batteryVoltage'] = isNull(element[element.length - 1]['batteryVoltage']);

            const pointData: PointData = {
              checkedOrNot: isExistSensorId,
              PointDetail: pastData['PointDetail'],
              cityName: pastData['cityName'],
              areaName: pastData['areaName'],
              sensorName: pastData['sensorName'],
              dateTime: element[element.length - 1]['dateTime'],
              waterQuantity: parseFloat(element[element.length - 1]['waterQuantity']).toFixed(3),
              waterLevel: parseFloat(element[element.length - 1]['waterLevel'].toString()).toFixed(3),
              freshwaterConductivity: parseFloat(element[element.length - 1]['freshwaterConductivity'].toString()).toFixed(1),
              saltwaterConductivity: parseFloat(element[element.length - 1]['saltwaterConductivity'].toString()).toFixed(2),
              waterTemprature: parseFloat(element[element.length - 1]['waterTemprature'].toString()).toFixed(2),
              batteryVoltage: parseFloat(element[element.length - 1]['batteryVoltage'].toString()).toFixed(3),
              turbidity: parseFloat(element[element.length - 1]['turbidity']).toFixed(1),
              mainSensorId: element[element.length - 1].mainSensorId
            }

            forGetDataByCity(pointData);

            this.pointSortData[dataIndex] = pointData;
            isUpdate = true;
          }
        }
      }

      //実際の更新処理部分
      if (isUpdate == true) {
        this.sampleSource3 = new MatTableDataSource<PointData>(this.pointSortData);
        this.sampleSource3.paginator = this.paginator1;       //20240620追加 
        this.sampleSource3Length = this.pointSortData.length; //20240620追加
      }

      //順に処理する
      for (let responseListIndex = 0; responseListIndex < responseList.length; responseListIndex++) {
        let latestDataList = _.cloneDeep(responseList[responseListIndex].waterFlowResponse);
        let editData: any;
        // console.log("data update:", this.graphDataSource, latestDataList, latestDataList.length);
        if (latestDataList != null && latestDataList.length > 0) {
          editData = this.graphDataSource.find((x: any) => x[0].mainSensorId == latestDataList[latestDataList.length - 1].mainSensorId);

          if (editData[editData.length - 1].timeStamp < latestDataList[latestDataList.length - 1].timeStamp) {
            //大元のデータソースからセンサーIdが同じデータを抽出する
            let dataIndex = this.graphDataSource.findIndex((x: any) => x[0].mainSensorId == latestDataList[0].mainSensorId);
            let selectedData = this.graphDataSource[dataIndex];

            //表示中のデータソースからセンサーIdが同じデータを抽出する
            dataIndex = this.checkedGraphData.findIndex((x: any) => x[0].mainSensorId == latestDataList[0].mainSensorId);
            selectedData = _.cloneDeep(this.checkedGraphData[dataIndex]);

            // ↓グラフ関連処理
            // if (dataIndex != -1) {
            //   //上書き防止
            //   let lastData = this.checkedGraphData[dataIndex][this.checkedGraphData[dataIndex].length - 1];

            //   for (let index = 0; index < latestDataList.length; index++) {

            //     //表示中のデータソースの最後のデータの受信日時より取得した受信日時が経過していた場合
            //     if (selectedData[selectedData.length - 1].receivedDate < latestDataList[index].receivedDate) {
            //       //表示中のデータソースに追加する
            //       this.checkedGraphData[dataIndex].push(latestDataList[index]);
            //     }
            //   }

            //   //グラフのx軸ラベルを書き換える必要があるかどうか
            //   let isRedrawLabel = false;

            //   //新規データのタイムスパン
            //   let newTimespan = 0;

            //   //個別のデータのタイムスパン
            //   let dataTimeSpan = 0;

            //   //新たにタイムスパンをチェックする
            //   //データが2個以上ある場合は各データ同士でタイムスパンを求める
            //   if (latestDataList.length >= 2) {

            //     for (let index = 0; index < latestDataList.length - 1; index++) {
            //       let fromTimeStamp = latestDataList[index].timeStamp;
            //       let toTimeStamp = latestDataList[index + 1].timeStamp;

            //       let retVal = this.calcTimespan(fromTimeStamp, toTimeStamp);
            //       isRedrawLabel = retVal.isRedrawLabel;
            //       newTimespan = retVal.currentTimespan;

            //       let fromTime = new Date(fromTimeStamp * 1000);
            //       let toTime = new Date(toTimeStamp * 1000);
            //       dataTimeSpan = (toTime.getTime() - fromTime.getTime()) / (60 * 1000);
            //     }
            //   }
            //   //データが1個の場合は既存のデータソースの最後の測定日時と比較する
            //   else {
            //     let fromTimeStamp = lastData.timeStamp;
            //     let toTimeStamp = latestDataList[0].timeStamp;

            //     let retVal = this.calcTimespan(fromTimeStamp, toTimeStamp);
            //     isRedrawLabel = retVal.isRedrawLabel;
            //     newTimespan = retVal.currentTimespan;

            //     let fromTime = new Date(fromTimeStamp * 1000);
            //     let toTime = new Date(toTimeStamp * 1000);
            //     dataTimeSpan = (toTime.getTime() - fromTime.getTime()) / (60 * 1000);
            //   }

            //   //x軸ラベルの再描画が必要な場合
            //   if (isRedrawLabel == true) {
            //     //x軸ラベルの再描画イベント
            //     this.makeXAxisLabel();
            //     //グラフデータ作成イベント
            //     this.organizeGraphData();
            //     //y軸ラベルの再描画イベント(ベースのグラフ)
            //     this.setCanvasOptions();
            //     //y軸ラベルの再描画イベント(移動可能なy軸ラベル用)
            //     this.setYAxisOptions();
            //     //データの格納イベント
            //     this.multipleChartData();
            //   }
            //   else {

            //     //既存のx軸ラベルから時間が合致するもののインデックスを探す
            //     for (let index = 0; index < latestDataList.length; index++) {
            //       let timeStamp = latestDataList[index].timeStamp;
            //       let time = new Date(timeStamp * 1000);
            //       let date = moment(new Date(time)).format("MM-DD HH:mm");
            //       let bigScaleIndex = this.complementedGraphData.findIndex((x: any) => x[0].mainSensorId == latestDataList[index].mainSensorId);
            //       let mainSensorId = this.complementedGraphData[bigScaleIndex][0].mainSensorId;

            //       if (bigScaleIndex != -1) {
            //         //書き換えるデータのインデックス
            //         let smallScaleIndex = this.complementedGraphData[bigScaleIndex].findIndex((x: any) => x.datetime == date);
            //         //書き換えるデータの一つ前のインデックス
            //         let preIndex = 0;

            //         //最新データのタイムスパンが既存のデータソースのタイムスパンよりワイドな場合
            //         if (newTimespan > this.timespan || dataTimeSpan != newTimespan) {
            //           //置き換える回数を算出
            //           let correctCount = 0;

            //           if (newTimespan > this.timespan) {
            //             correctCount = (newTimespan / this.timespan) - 1;
            //             preIndex = smallScaleIndex - (newTimespan / this.timespan);
            //           }

            //           if (dataTimeSpan != newTimespan) {
            //             correctCount = (dataTimeSpan / newTimespan) - 1;
            //             preIndex = smallScaleIndex - (dataTimeSpan / newTimespan);
            //           }

            //           //整数かどうかチェック
            //           if (Number.isInteger(correctCount)) {
            //             //前後データの差分
            //             let diffWaterLevel = 0;
            //             let diffWaterQuantity = 0;
            //             let diffFreshwaterConductivity = 0;
            //             let diffSeawaterConductivity = 0;
            //             let diffBatteryVoltage = 0;
            //             let diffWaterTemperature = 0;
            //             let diffTurbidity = 0;

            //             //数値としてデータを補完するかの判定(nullや空文字の場合はnullを入れてデータを生成する)
            //             let isNullorEmptyWaterLevel = false;
            //             let isNullorEmptyWaterQuantity = false;
            //             let isNullorEmptyFreshwater = false;
            //             let isNullorEmptySeawater = false;
            //             let isNullorEmptyBatteryVoltage = false;
            //             let isNullorEmptyWaterTemperature = false;
            //             let isNullorEmptyTurbidity = false;

            //             //各項目の置き換えるデータ(計算したのちここに格納する)
            //             let correctWaterLevel = null;
            //             let correctWaterQuantity = null;
            //             let correctFreshwater = null;
            //             let correctSeawater = null;
            //             let correctWaterTemperature = null;
            //             let correctBatteryVoltage = null;
            //             let correctTurbidity = null;

            //             //書き換えるデータの一つ前のデータ
            //             let lastWaterLevel = this.complementedGraphData[bigScaleIndex][preIndex].waterLevel;
            //             let lastWaterQuantity = this.complementedGraphData[bigScaleIndex][preIndex].waterQuantity;
            //             let lastFreshwaterConductivity = this.complementedGraphData[bigScaleIndex][preIndex].freshwaterConductivity;
            //             let lastSeawaterConductivity = this.complementedGraphData[bigScaleIndex][preIndex].saltwaterConductivity;
            //             let lastWaterTemperature = this.complementedGraphData[bigScaleIndex][preIndex].waterTemprature;
            //             let lastBatteryVoltage = this.complementedGraphData[bigScaleIndex][preIndex].batteryVoltage;
            //             let lastTurbidity = this.complementedGraphData[bigScaleIndex][preIndex].turbidity;

            //             //流量と水位を文字列から数値に変換する作業
            //             //前の水位データ
            //             let convertedFormerWaterLevel = parseFloat(lastWaterLevel);
            //             //前の流量データ
            //             let convertedFormerWaterQuantity = parseFloat(lastWaterQuantity);
            //             //後の水位データ
            //             let convertedLatterWaterLevel = parseFloat(latestDataList[index].waterLevel);
            //             //後の流量データ
            //             let convertedLatterWaterQuantity = parseFloat(latestDataList[index].waterQuantity);

            //             //変換した流量と水位がNANになるのであれば置き換えるデータにはnullを入れる
            //             isNullorEmptyWaterLevel = isNanEqualNull(convertedFormerWaterLevel, convertedLatterWaterLevel, diffWaterLevel);
            //             isNullorEmptyWaterQuantity = isNanEqualNull(convertedFormerWaterQuantity, convertedLatterWaterQuantity, diffWaterQuantity);

            //             //その他項目のnullまたは空文字のチェック

            //             isNullorEmptyFreshwater = isNullorEmpty(latestDataList[index].freshwaterConductivity, lastFreshwaterConductivity, diffFreshwaterConductivity);
            //             isNullorEmptySeawater = isNullorEmpty(latestDataList[index].saltwaterConductivity, lastSeawaterConductivity, diffSeawaterConductivity);
            //             isNullorEmptyBatteryVoltage = isNullorEmpty(latestDataList[index].batteryVoltage, lastBatteryVoltage, diffBatteryVoltage);
            //             isNullorEmptyWaterTemperature = isNullorEmpty(latestDataList[index].waterTemprature, lastWaterTemperature, diffWaterTemperature);
            //             isNullorEmptyTurbidity = isNullorEmpty(latestDataList[index].turbidity, lastTurbidity, diffTurbidity);

            //             let dataSourceTimespan = newTimespan;

            //             //データを置き換える
            //             for (let correctIndex = 0; correctIndex < correctCount; correctIndex++) {
            //               let separatedNum = parseFloat((this.timespan / dataSourceTimespan).toFixed(2));

            //               //補完するデータを生成
            //               correctWaterLevel = isNullorCorrect(isNullorEmptyWaterLevel, convertedFormerWaterLevel, diffWaterLevel, separatedNum, correctIndex);
            //               correctWaterQuantity = isNullorCorrect(isNullorEmptyWaterQuantity, convertedFormerWaterQuantity, diffWaterQuantity, separatedNum, correctIndex);
            //               correctFreshwater = isNullorCorrect(isNullorEmptyFreshwater, lastFreshwaterConductivity, diffFreshwaterConductivity, separatedNum, correctIndex);
            //               correctSeawater = isNullorCorrect(isNullorEmptySeawater, lastSeawaterConductivity, diffSeawaterConductivity, separatedNum, correctIndex);
            //               correctBatteryVoltage = isNullorCorrect(isNullorEmptyBatteryVoltage, lastBatteryVoltage, diffBatteryVoltage, separatedNum, correctIndex);
            //               correctWaterTemperature = isNullorCorrect(isNullorEmptyWaterTemperature, lastWaterTemperature, diffWaterTemperature, separatedNum, correctIndex);
            //               correctTurbidity = isNullorCorrect(isNullorEmptyTurbidity, parseFloat(lastTurbidity), diffTurbidity, separatedNum, correctIndex);

            //               let num = dataSourceTimespan * (correctIndex + 1);

            //               //置き換えるデータを配列にセットする
            //               const data = {
            //                 waterLevel: correctWaterLevel,
            //                 waterQuantity: correctWaterQuantity,
            //                 freshwaterConductivity: correctFreshwater,
            //                 saltwaterConductivity: correctSeawater,
            //                 batteryVoltage: correctBatteryVoltage,
            //                 waterTemprature: correctWaterTemperature,
            //                 turbidity: correctTurbidity,
            //                 mainSensorId: mainSensorId,
            //                 datetime: moment(this.complementedGraphData[bigScaleIndex][preIndex].datetime)
            //                 .add(num, 'm').format("MM-DD HH:mm")
            //               }
            //               //既存のデータソースと置き換える
            //               let num2 = preIndex + correctIndex + 1;
            //               this.complementedGraphData[bigScaleIndex].splice(num2, 1, data);
            //             }

            //             //補完分を置き換え、最後に対応する時間のデータを置き換える
            //             let data = {
            //               waterLevel: parseFloat(latestDataList[index].waterLevel),
            //               waterQuantity: parseFloat(latestDataList[index].waterQuantity),
            //               freshwaterConductivity: latestDataList[index].freshwaterConductivity,
            //               saltwaterConductivity: latestDataList[index].saltwaterConductivity,
            //               batteryVoltage: latestDataList[index].batteryVoltage,
            //               waterTemprature: latestDataList[index].waterTemprature,
            //               turbidity: latestDataList[index].turbidity,
            //               mainSensorId: latestDataList[index].mainSensorId,
            //               datetime: date
            //             }
            //             //既存のデータソースと置き換える
            //             this.complementedGraphData[bigScaleIndex].splice(smallScaleIndex, 1, data);
            //           }
            //         }
            //         else {

            //           //対応する時間のデータを置き換える
            //           let data = {
            //             waterLevel: parseFloat(latestDataList[index].waterLevel),
            //             waterQuantity: parseFloat(latestDataList[index].waterQuantity),
            //             freshwaterConductivity: latestDataList[index].freshwaterConductivity,
            //             saltwaterConductivity: latestDataList[index].saltwaterConductivity,
            //             batteryVoltage: latestDataList[index].batteryVoltage,
            //             waterTemprature: latestDataList[index].waterTemprature,
            //             turbidity: latestDataList[index].turbidity,
            //             mainSensorId: latestDataList[index].mainSensorId,
            //             datetime: date
            //           }
            //           //既存のデータソースと置き換える
            //           this.complementedGraphData[bigScaleIndex].splice(smallScaleIndex, 1, data);
            //         }
            //       }
            //     }
            //   }
            // }
            // ↑グラフ関連処理

          }
        }
      }

      // ↓グラフ関連処理
      // if (isUpdate == true) {

      //   //各データ配列の初期化
      //   this.wlDataArray.length = 0;
      //   this.wqDataArray.length = 0;
      //   this.fwcDataArray.length = 0;
      //   this.swcDataArray.length = 0;
      //   this.bvDataArray.length = 0;
      //   this.wtDataArray.length = 0;
      //   this.tDataArray.length = 0;

      //   //各データ配列にデータを登録
      //   for (let i = 0; i < this.complementedGraphData.length; i++) {
      //     this.wlDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterLevel));
      //     this.wqDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterQuantity));
      //     this.fwcDataArray.push(this.complementedGraphData[i].map((x: any) => x.freshwaterConductivity));
      //     this.swcDataArray.push(this.complementedGraphData[i].map((x: any) => x.saltwaterConductivity));
      //     this.bvDataArray.push(this.complementedGraphData[i].map((x: any) => x.batteryVoltage));
      //     this.wtDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterTemprature));
      //     this.tDataArray.push(this.complementedGraphData[i].map((x: any) => x.turbidity));
      //   }

      //   // this.multipleChartData();
      // }
      // ↑グラフ関連処理

      this.spinner.hide();
    }), (error: any) => {
      console.log(error);
      this.spinner.hide();
    }
  }

  // ↓グラフ関連処理
  // //最適なタイムスパン算出イベント
  // calcTimespan(fromTimeStamp: any, toTimeStamp: any) {
  //   let isRedrawLabel = false;
  //   let newTimespan = 0;
  //   let fromTime = new Date(fromTimeStamp * 1000);
  //   let toTime = new Date(toTimeStamp * 1000);

  //   let currentTimespan = (toTime.getTime() - fromTime.getTime()) / (60 * 1000);

  //   //タイムスタンプが前後で異なっている場合
  //   if (currentTimespan != this.timespan) {
  //     let pre = _.cloneDeep(this.timespan);
  //     let post = _.cloneDeep(currentTimespan);

  //     while (post != 0) {
  //       let result = pre % post;
  //       pre = post;
  //       post = result;
  //     }
  //     newTimespan = pre;
  //   }
  //   else {
  //     newTimespan = this.timespan;
  //   }

  //   return { isRedrawLabel: isRedrawLabel, currentTimespan: newTimespan };
  // }
  // ↑グラフ関連処理

  // ↓グラフ関連処理
  // //x軸ラベル作成イベント
  // makeXAxisLabel() {

  //   //日付指定がされていない場合
  //   if (this.dateFilterMode == false) {
  //     let today = new Date();
  //     let compareToday = new Date();

  //     //時間軸の始点格納用変数
  //     let fromTimeAxis = new Date(today.setHours(0, 0, 0, 0));
  //     //時間軸の終点格納用変数
  //     let toTimeAxis = new Date(today.setHours(0, 0, 0, 0));

  //     //ブロック数を操作するため
  //     let timeAxisCount = 0;

  //     //0時から6時間ずつ追加して表示ブロックの終点を決める
  //     while (compareToday > toTimeAxis) {
  //       toTimeAxis = new Date(toTimeAxis.setHours(toTimeAxis.getHours() + 6));
  //       timeAxisCount++;
  //     }

  //     timeAxisCount = INITIALBLOCKVALUE - timeAxisCount;

  //     //0時から6時間ずつ減算して表示ブロックの始点を決める
  //     for (let i = 0; i < timeAxisCount; i++) {
  //       fromTimeAxis = new Date(fromTimeAxis.setHours(fromTimeAxis.getHours() - 6))
  //     }

  //     //タイムスパンの整形(始点)
  //     let formedFromTimeAxis = moment(fromTimeAxis).format("MM-DD HH:mm");
  //     //タイムスパンの整形(終点)
  //     let formedToTimeAxis = moment(toTimeAxis).format("MM-DD HH:mm");

  //     //既存の時間軸をクリア
  //     this.lineChartLabels.length = 0;

  //     //時間軸作成
  //     while (formedFromTimeAxis < formedToTimeAxis) {

  //       //最初に始点となる日時を入れる
  //       if (this.lineChartLabels.length == 0) {
  //         this.lineChartLabels.push(formedFromTimeAxis);
  //       }
  //       else {
  //         let calcTimespan = fromTimeAxis.setMinutes(fromTimeAxis.getMinutes() + this.timespan);
  //         let timestamp = moment(new Date(calcTimespan)).format("MM-DD HH:mm");
  //         //時間軸に追加
  //         this.lineChartLabels.push(timestamp);
  //         formedFromTimeAxis = timestamp;
  //       }
  //     }
  //   }
  //   //日付指定がされている場合
  //   else {
  //     let timeStampStart = _.cloneDeep(this.dataFormGroup.value.fromdatetime);
  //     //タイムスパンの整形(始点)
  //     let formedFromTimeAxis = moment(this.dataFormGroup.value.fromdatetime).format("MM-DD HH:mm");
  //     //タイムスパンの整形(終点)
  //     let formedToTimeAxis = moment(this.dataFormGroup.value.todatetime).add(1, 'd').format("MM-DD HH:mm");

  //     //既存の時間軸をクリア
  //     this.lineChartLabels.length = 0;

  //     //時間軸作成
  //     while (formedFromTimeAxis < formedToTimeAxis) {

  //       //最初に始点となる日時を入れる
  //       if (this.lineChartLabels.length == 0) {
  //         this.lineChartLabels.push(formedFromTimeAxis);
  //       }
  //       else {
  //         let calcTimespan = timeStampStart.setMinutes(timeStampStart.getMinutes() + this.timespan);
  //         let timestamp = moment(new Date(calcTimespan)).format("MM-DD HH:mm");
  //         //時間軸に追加
  //         this.lineChartLabels.push(timestamp);
  //         formedFromTimeAxis = timestamp;
  //       }
  //     }
  //   }
  // }
  // ↑グラフ関連処理

  // ↓グラフ関連処理
  // //データソースを元にグラフデータを作成する
  // organizeGraphData() {

  //   if (this.checkedGraphData.length != 0) {
  //     this.wlDataArray.length = 0;
  //     this.wqDataArray.length = 0;
  //     this.fwcDataArray.length = 0;
  //     this.swcDataArray.length = 0;
  //     this.bvDataArray.length = 0;
  //     this.wtDataArray.length = 0;
  //     this.tDataArray.length = 0;
  //     this.checkCount = 0;
  //     let complementedArray: any[] = [];
  //     this.complementedGraphData.length = 0;

  //     if (this.dateFilterMode == false) {
  //       this.blockCount = INITIALBLOCKVALUE;
  //     }

  //     //データソースのインデックス
  //     let dataSourceIndex = 0;

  //     this.complementedGraphData.length = 0;

  //     //補完回数のカウント
  //     let correctCount = 0;

  //     for (let mainIndex = 0; mainIndex < this.checkedGraphData.length; mainIndex++) {
  //       complementedArray.length = 0;
  //       dataSourceIndex = 0;

  //       if (this.checkedGraphData[mainIndex].length >= 2) {
  //         let sensorId = this.checkedGraphData[mainIndex][0].mainSensorId;

  //         for (let subIndex = 0; subIndex < this.lineChartLabels.length; subIndex++) {

  //           //データソースにあるデータを全て入力し終えた場合、時間軸の足りない部分にデータを追加する
  //           if (this.checkedGraphData[mainIndex].length == dataSourceIndex) {
  //             correctCount = this.lineChartLabels.length - subIndex;

  //             if (correctCount !== 0) {

  //               for (let correctIndex = 0; correctIndex < correctCount; correctIndex++) {

  //                 const data = {
  //                   waterLevel: null,
  //                   waterQuantity: null,
  //                   freshwaterConductivity: null,
  //                   saltwaterConductivity: null,
  //                   batteryVoltage: null,
  //                   waterTemprature: null,
  //                   turbidity: null,
  //                   mainSensorId: sensorId,
  //                   datetime: this.lineChartLabels[subIndex]
  //                 }
  //                 complementedArray.push(data);
  //                 subIndex++;
  //               }
  //               correctCount = 0;
  //               break;
  //             }
  //           }

  //           //ラベルの時間軸とデータソースの時間が等しい場合にデータを配列に追加
  //           if (this.lineChartLabels[subIndex] == moment(new Date(this.checkedGraphData[mainIndex][dataSourceIndex].timeStamp * 1000))
  //             .format("MM-DD HH:mm")) {

  //             //一つ目のデータのときはただ配列に追加するのみ
  //             if (dataSourceIndex == 0) {

  //               const data = {
  //                 waterLevel: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterLevel),
  //                 waterQuantity: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterQuantity),
  //                 freshwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].freshwaterConductivity,
  //                 saltwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].saltwaterConductivity,
  //                 batteryVoltage: this.checkedGraphData[mainIndex][dataSourceIndex].batteryVoltage,
  //                 waterTemprature: this.checkedGraphData[mainIndex][dataSourceIndex].waterTemprature,
  //                 turbidity: this.checkedGraphData[mainIndex][dataSourceIndex].turbidity,
  //                 mainSensorId: sensorId,
  //                 datetime: this.lineChartLabels[subIndex]
  //               }
  //               complementedArray.push(data);
  //               dataSourceIndex++;
  //               correctCount = 0;
  //             }

  //             //カウントしていた飛ばした回数をもとにデータを補完する
  //             else {
  //               dataComplement(subIndex, mainIndex, dataSourceIndex, this.checkedGraphData, correctCount, this.timespan, complementedArray, sensorId, this.lineChartLabels, false);

  //               //補完後に実際の順番のデータを配列に追加してデータソース側のインデックスを1増やす
  //               const data = {
  //                 waterLevel: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterLevel),
  //                 waterQuantity: parseFloat(this.checkedGraphData[mainIndex][dataSourceIndex].waterQuantity),
  //                 freshwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].freshwaterConductivity,
  //                 saltwaterConductivity: this.checkedGraphData[mainIndex][dataSourceIndex].saltwaterConductivity,
  //                 batteryVoltage: this.checkedGraphData[mainIndex][dataSourceIndex].batteryVoltage,
  //                 waterTemprature: this.checkedGraphData[mainIndex][dataSourceIndex].waterTemprature,
  //                 turbidity: this.checkedGraphData[mainIndex][dataSourceIndex].turbidity,
  //                 mainSensorId: sensorId,
  //                 datetime: this.lineChartLabels[subIndex]
  //               }
  //               complementedArray.push(data);
  //               dataSourceIndex++;
  //               correctCount = 0;
  //             }
  //           }

  //           //ラベルの時間軸とデータソースの時間が等しくない場合
  //           else {

  //             //二つ目以降はタイムスパンを飛ばした回数をカウントする
  //             if (dataSourceIndex !== 0) {
  //               correctCount++;
  //             }
  //             //一つ目が見つかるまでnullを入れ続ける
  //             else {
  //               const data = {
  //                 waterLevel: null,
  //                 waterQuantity: null,
  //                 freshwaterConductivity: null,
  //                 saltwaterConductivity: null,
  //                 batteryVoltage: null,
  //                 waterTemprature: null,
  //                 turbidity: null,
  //                 mainSensorId: sensorId,
  //                 datetime: this.lineChartLabels[subIndex]
  //               }
  //               complementedArray.push(data);
  //             }
  //           }
  //         }
  //         const copy = _.cloneDeep(complementedArray);
  //         this.complementedGraphData.push(copy);
  //         dataSourceIndex = 0;
  //       }
  //     }

  //     for (let i = 0; i < this.complementedGraphData.length; i++) {
  //       this.wlDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterLevel));
  //       this.wqDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterQuantity));
  //       this.fwcDataArray.push(this.complementedGraphData[i].map((x: any) => x.freshwaterConductivity));
  //       this.swcDataArray.push(this.complementedGraphData[i].map((x: any) => x.saltwaterConductivity));
  //       this.bvDataArray.push(this.complementedGraphData[i].map((x: any) => x.batteryVoltage));
  //       this.wtDataArray.push(this.complementedGraphData[i].map((x: any) => x.waterTemprature));
  //       this.tDataArray.push(this.complementedGraphData[i].map((x: any) => x.turbidity));
  //       this.checkCount = this.checkCount + 1;
  //     }
  //   }
  // }
  // ↑グラフ関連処理

  //グラフ描画についての詳細設定
  // setCanvasOptions() {

  //   this.lineChartOptions = {
  //     legend: false,
  //     showpoint: false,
  //     responsive: false,
  //     elements: {
  //       point: {
  //         radius: 0
  //       }
  //     },
  //     plugins: {
  //     },
  //     scales: {
  //       xAxes: [{
  //         ticks: {
  //           autoSkip: true,
  //           maxTicksLimit: this.blockCount,
  //           maxRotation: 0,
  //           minRotation: 0
  //         },
  //         gridLines: {
  //           color: '#FFF'
  //         },
  //         scaleLabel: {
  //           display: true,
  //           labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : this.translate.instant('日時'),
  //         },
  //       }],
  //       yAxes: [{
  //         scaleLabel: {
  //           display: true,
  //         },
  //         ticks: {
  //           display: false
  //         }
  //       }]
  //     }
  //   };
  // }

  //独立したy軸部分に関する詳細設定
  // setYAxisOptions() {

  //   this.subLineChartOptions = {
  //     legend: false,
  //     showpoint: false,
  //     responsive: false,
  //     elements: {
  //       point: {
  //         radius: 0
  //       }
  //     },
  //     plugins: {
  //     },
  //     scales: {
  //       xAxes: [{
  //         ticks: {
  //           autoSkip: true,
  //           maxTicksLimit: this.blockCount,
  //         },
  //         grid: {
  //           drawBorder: false
  //         },
  //         scaleLabel: {
  //           display: false,
  //           labelString: this.cookieService.get('language') === 'en' ? 'Time Stamp' : this.translate.instant('日時'),
  //         },
  //       }],
  //       yAxes: [{
  //         gridLines: {
  //           display: false
  //         },
  //         scaleLabel: {
  //           display: true,
  //           labelString: this.cookieService.get('language') === 'en' ? 'Value' : this.translate.instant('値'),
  //           padding:{
  //             top: 0,
  //             bottom: 40
  //           }
  //         },
  //         ticks: {
  //           showLabelBackdrop: true,
  //           backdropColor: "#fff",
  //           padding: -35 //-30
  //         },
  //       }]
  //     }
  //   };
  // }

  //既存のグラフに追記する
  // postscriptData(bigScaleIndex: number, smallScaleIndex: number, data: any) {

  //   if (bigScaleIndex >= 0 && smallScaleIndex >= 0) {
  //     let baseIndex = 0;

  //     if (bigScaleIndex != 0) {
  //       baseIndex = ((bigScaleIndex + 1) * 7) - 1;
  //     }
  //     else {
  //       baseIndex = 6
  //     }

  //     //型の関係でfor文化断念
  //     forPostscriptData(this.isWaterQuantitySelect, this.multipleLineChartData, this.multipleLineChartDataJP, this.subLineChartData, this.subLineChartDataJP, (baseIndex - 6), smallScaleIndex, data.waterQuantity);
  //     forPostscriptData(this.isWaterLevelSelect, this.multipleLineChartData, this.multipleLineChartDataJP, this.subLineChartData, this.subLineChartDataJP, (baseIndex - 5), smallScaleIndex, data.WaterLevel);
  //     forPostscriptData(this.isFreshwaterConductivitySelect, this.multipleLineChartData, this.multipleLineChartDataJP, this.subLineChartData, this.subLineChartDataJP, (baseIndex - 4), smallScaleIndex, data.freshwaterConductivity);
  //     forPostscriptData(this.isSaltwaterConductivitySelect, this.multipleLineChartData, this.multipleLineChartDataJP, this.subLineChartData, this.subLineChartDataJP, (baseIndex - 3), smallScaleIndex, data.saltwaterConductivity);
  //     forPostscriptData(this.isBatteryVoltageSelect, this.multipleLineChartData, this.multipleLineChartDataJP, this.subLineChartData, this.subLineChartDataJP, (baseIndex - 2), smallScaleIndex, data.batteryVoltage);
  //     forPostscriptData(this.isWaterTemperatureSelect, this.multipleLineChartData, this.multipleLineChartDataJP, this.subLineChartData, this.subLineChartDataJP, (baseIndex - 1), smallScaleIndex, data.waterTemprature);
  //     forPostscriptData(this.isTurbiditySelect, this.multipleLineChartData, this.multipleLineChartDataJP, this.subLineChartData, this.subLineChartDataJP, (baseIndex), smallScaleIndex, data.turbidity);
  //   }
  // }

  // updateWidth() {
  //   (document.getElementById('chart') as HTMLCanvasElement).width = ONEBLOCKPX * this.blockCount;
  //   let a = (document.getElementById('chart') as HTMLCanvasElement);
  //   let cssText = a.style.cssText;
  //   a.style.cssText = cssText + `width: ${ONEBLOCKPX * this.blockCount}px !important`;
  //   a.style.setProperty('width', `${ONEBLOCKPX * this.blockCount}px`, 'important');
  // }
}

//スコープ外で用いる定数の宣言用
function define(name: any, value: any) {

  Object.defineProperty(window, name, {
    get: function () { return value; },
    set: function () { throw (name + ' is already defined !!'); },
  });
}

//変換した流量と水位がNANになるのであれば補完データにはnullを入れる
function isNanEqualNull(convertedFormerValue: number, convertedLatterValue: number, diffValue: number): boolean {

  if (Number.isNaN(convertedFormerValue) || Number.isNaN(convertedLatterValue)) {
    return true;
  }
  else {
    diffValue = convertedLatterValue - convertedFormerValue;
    return false;
  }
}

//その他項目のnullまたは空文字のチェック
function isNullorEmpty(checkedGraphData1: null | string, checkedGraphData2: null | string, diffValue: number): boolean {

  if (checkedGraphData1 == null || checkedGraphData1 == ""
    || checkedGraphData2 == null || checkedGraphData2 == "") {
    return true;
  }
  else {
    diffValue = parseFloat(checkedGraphData1) - parseFloat(checkedGraphData2);
    return false;
  }
}

//補完するデータを生成
function isNullorCorrect(isNullorEmpty: boolean, convertedValue: number, diffValue: number, separetedNum: number, correctIndex: number): number | null {

  if (isNullorEmpty == true) {
    return null;
  }
  else {
    return convertedValue + diffValue * separetedNum * (correctIndex + 1);
  }
}

//any箇所の型不明
function forPostscriptData(isSelect: boolean, mlcd: any[], mlcdJP: any[], slcd: any[], slcdJP: any[], baseIndex: number, smallScaleIndex: number, data: number): void {

  if (isSelect == true) {
    mlcd[baseIndex].data[smallScaleIndex] = data;
    mlcdJP[baseIndex].data[smallScaleIndex] = data;
    slcd[baseIndex].data[smallScaleIndex] = data;
    slcdJP[baseIndex].data[smallScaleIndex] = data;
  }
}

//フラグ管理による分岐が困難な為、自動更新との共通化断念
function dataComplement(subIndex: number, mainIndex: number, dataSourceIndex: number, checkedGraphData: any[][], correctCount: number, graphTimespan: number, complementedArray: Array<number | null | any>, sensorId: any, lineChartLabels: any, isFilter: boolean): void {
  //前後のデータのタイムスタンプ(UNIX)を取得
  let fromTimeStamp = checkedGraphData[mainIndex][dataSourceIndex - 1].timeStamp;
  let toTimeStamp = checkedGraphData[mainIndex][dataSourceIndex].timeStamp;

  //タイムスタンプ(UNIX)を実際の日時に変更
  let fromTime = new Date(fromTimeStamp * 1000);
  let toTime = new Date(toTimeStamp * 1000);

  //前後のデータのタイムスパンを取得
  let dataSourceTimespan = (toTime.getTime() - fromTime.getTime()) / (60 * 1000);

  //前後データの差分
  let diffWaterLevel = 0;
  let diffWaterQuantity = 0;
  let diffFreshwaterConductivity = 0;
  let diffSeawaterConductivity = 0;
  let diffBatteryVoltage = 0;
  let diffWaterTemperature = 0;
  let diffTurbidity = 0;

  //数値としてデータを補完するかの判定(nullや空文字の場合はnullを入れてデータを生成する)
  let isNullorEmptyWaterLevel = false;
  let isNullorEmptyWaterQuantity = false;
  let isNullorEmptyFreshwater = false;
  let isNullorEmptySeawater = false;
  let isNullorEmptyBatteryVoltage = false;
  let isNullorEmptyWaterTemperature = false;
  let isNullorEmptyTurbidity = false;

  //各項目の補完データ(計算したのちここに格納する)
  let correctWaterLevel = null;
  let correctWaterQuantity = null;
  let correctFreshwater = null;
  let correctSeawater = null;
  let correctWaterTemperature = null;
  let correctBatteryVoltage = null;
  let correctTurbidity = null;

  //流量と水位を文字列から数値に変換する作業
  //前の水位データ
  let convertedFormerWaterLevel = parseFloat(checkedGraphData[mainIndex][dataSourceIndex - 1].waterLevel);
  //前の流量データ
  let convertedFormerWaterQuantity = parseFloat(checkedGraphData[mainIndex][dataSourceIndex - 1].waterQuantity);
  //後の水位データ
  let convertedLatterWaterLevel = parseFloat(checkedGraphData[mainIndex][dataSourceIndex].waterLevel);
  //後の流量データ
  let convertedLatterWaterQuantity = parseFloat(checkedGraphData[mainIndex][dataSourceIndex].waterLevel);

  //変換した流量と水位がNANになるのであれば補完データにはnullを入れる
  isNullorEmptyWaterLevel = isNanEqualNull(convertedFormerWaterLevel, convertedLatterWaterLevel, diffWaterLevel);
  isNullorEmptyWaterQuantity = isNanEqualNull(convertedFormerWaterQuantity, convertedLatterWaterQuantity, diffWaterQuantity);

  //その他項目のnullまたは空文字のチェック
  isNullorEmptyFreshwater = isNullorEmpty(checkedGraphData[mainIndex][dataSourceIndex].freshwaterConductivity, checkedGraphData[mainIndex][dataSourceIndex - 1].freshwaterConductivity, diffFreshwaterConductivity);
  isNullorEmptySeawater = isNullorEmpty(checkedGraphData[mainIndex][dataSourceIndex].saltwaterConductivity, checkedGraphData[mainIndex][dataSourceIndex - 1].saltwaterConductivity, diffSeawaterConductivity);
  isNullorEmptyBatteryVoltage = isNullorEmpty(checkedGraphData[mainIndex][dataSourceIndex].batteryVoltage, checkedGraphData[mainIndex][dataSourceIndex - 1].batteryVoltage, diffBatteryVoltage);
  isNullorEmptyWaterTemperature = isNullorEmpty(checkedGraphData[mainIndex][dataSourceIndex].waterTemprature, checkedGraphData[mainIndex][dataSourceIndex - 1].waterTemprature, diffWaterTemperature);
  isNullorEmptyTurbidity = isNullorEmpty(checkedGraphData[mainIndex][dataSourceIndex].turbidity, checkedGraphData[mainIndex][dataSourceIndex - 1].turbidity, diffTurbidity);

  if (correctCount > 0) {

    for (let correctIndex = 0; correctIndex < correctCount; correctIndex++) {
      let separatedNum = parseFloat((graphTimespan / dataSourceTimespan).toFixed(2));

      //補完するデータを生成
      correctWaterLevel = isNullorCorrect(isNullorEmptyWaterLevel, convertedFormerWaterLevel, diffWaterLevel, separatedNum, correctIndex);
      correctWaterQuantity = isNullorCorrect(isNullorEmptyWaterQuantity, convertedFormerWaterQuantity, diffWaterQuantity, separatedNum, correctIndex);
      correctFreshwater = isNullorCorrect(isNullorEmptyFreshwater, parseFloat(checkedGraphData[mainIndex][dataSourceIndex - 1].freshwaterConductivity), diffFreshwaterConductivity, separatedNum, correctIndex);
      correctSeawater = isNullorCorrect(isNullorEmptySeawater, parseFloat(checkedGraphData[mainIndex][dataSourceIndex - 1].saltwaterConductivity), diffSeawaterConductivity, separatedNum, correctIndex);
      correctBatteryVoltage = isNullorCorrect(isNullorEmptyBatteryVoltage, parseFloat(checkedGraphData[mainIndex][dataSourceIndex - 1].batteryVoltage), diffBatteryVoltage, separatedNum, correctIndex);
      correctWaterTemperature = isNullorCorrect(isNullorEmptyWaterTemperature, parseFloat(checkedGraphData[mainIndex][dataSourceIndex - 1].waterTemprature), diffWaterTemperature, separatedNum, correctIndex);
      correctTurbidity = isNullorCorrect(isNullorEmptyTurbidity, parseFloat(checkedGraphData[mainIndex][dataSourceIndex - 1].turbidity), diffTurbidity, separatedNum, correctIndex);

      //補完するデータを配列に追加する
      const data: any = {
        waterLevel: correctWaterLevel,
        waterQuantity: correctWaterQuantity,
        freshwaterConductivity: correctFreshwater,
        saltwaterConductivity: correctSeawater,
        batteryVoltage: correctBatteryVoltage,
        waterTemprature: correctWaterTemperature,
        turbidity: correctTurbidity
      }

      if (isFilter = false) {
        data.mainSensorId = sensorId;
        data.datetime = lineChartLabels[subIndex];
      }

      complementedArray.push(data);
    }
  }

  // plotSensorData(sensorId: any) {
  //   if (this.sensorFinal.length == 0) {
  //     this.comparePoint.sensorProperty = 4;
  //     this.propertyAccessor = 'waterLevel';
  //   }

  //   this.dataArray = [];
  //   this.filteredDate = this.customDate;
  //   const index = this.mainArray.findIndex((x: { id: any; }) => x.id == sensorId);
  //   const response = this.mainArray[index].data.waterFlowResponse;
  //   if (response != null) {
  //     this.allData = response;
  //     this.drawWidgets(this.currentPointName);
  //     response.map((element: any) => {
  //       const index = this.barChartLabels.findIndex((x: any) => x == element.dateTime);
  //       if (index == -1) {
  //         element.dateTime = new Date(element.dateTime);
  //         this.barChartLabels.push(element.dateTime.toLocaleString());
  //       }
  //       this.labelsTrack.push({ sensorId, dateTime: element.dateTime.toLocaleString() });
  //       this.dataArray.push(element[this.propertyAccessor]);
  //       this.propertyArray.push({
  //         waterLevel: element.waterLevel, batteryVoltage: element.batteryVoltage, freshwaterConductivity: element.freshwaterConductivity,
  //         waterQuantity: element.waterQuantity, turbidity: element.turbidity
  //       });
  //     });

  //     this.sensorFinal.push({ sensorId: this.translate.instant(this.pointName), id: sensorId });
  //     this.barChartData.push({ data: this.dataArray, label: this.pointName });
  //   } else {
  //     this.allData = '';
  //     this.sensorFinal.push({ sensorId: this.translate.instant(this.pointName), id: sensorId });
  //   }
  //   this.setChartTranslations();
  // }
}

function isNull(value: any): any {

  if (null == value || value == "NaN") {
    return "-";
  }
  else {
    return value;
  }
}

function forGetDataByCity(pointData:PointData): void {
  pointData.PointDetail = isNull(pointData.PointDetail);
  pointData.cityName = isNull(pointData.cityName);
  pointData.areaName = isNull(pointData.areaName);
  pointData.sensorName = isNull(pointData.sensorName);
  pointData.dateTime = isNull(pointData.dateTime);
  pointData.waterQuantity = isNull(pointData.waterQuantity);
  pointData.waterLevel = isNull(pointData.waterLevel);
  pointData.freshwaterConductivity = isNull(pointData.freshwaterConductivity);
  pointData.saltwaterConductivity = isNull(pointData.saltwaterConductivity);
  pointData.waterTemprature = isNull(pointData.waterTemprature);
  pointData.batteryVoltage = isNull(pointData.batteryVoltage);
  pointData.turbidity = isNull(pointData.turbidity);
  pointData.mainSensorId = isNull(pointData.mainSensorId);
}

