import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
 selector: 'app-sidebar',
 templateUrl: './sidebar.component.html',
 styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit{
 isNSCAdmin: boolean = true;
 enableMap: boolean = false;
 enableTurbidity: boolean = false; //20240417追加
 enableTurbidityMap: boolean = false; // 20240423 濁度地図
 constructor(private renderer: Renderer2){
 }

 ngOnInit(): void{
   const role = sessionStorage.getItem('role');
   if(role == "nscadmin"){
     this.isNSCAdmin = true;
    }
   else{
     this.isNSCAdmin = false;
   }

   // 設定ファイル情報
   this.enableMap = sessionStorage.getItem('enableMap') == 'true';
   this.enableTurbidity = sessionStorage.getItem('enableTurbidity') == 'true'; //20240417追加
   this.enableTurbidityMap = sessionStorage.getItem('enableTurbidityMap') == 'true'; // 20240423 濁度地図
  }

 toggleMenu(): void{
   if(document.body.classList.contains('show')){
     this.renderer.removeClass(document.body, 'show');
    }
   else{
     this.renderer.addClass(document.body, 'show');
    }

   if(document.body.classList.contains('show')){
     this.renderer.removeClass(document.body, 'aside-fixed');
    }
   else{
     return
    }
  }
}
