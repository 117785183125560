<!--SAMPLE CODE STARTS-->
<div class="subheader subheader-solid" id="kt_subheader">
  <div class="container-fluid">
    <div class="loaderDiv" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div class="top-bar">
      <div class="dbWrapper">
        <div class="dbMenuStrip">
          <div class="dbLeftCol">
            <div class="filterDate">
              <div class="datePicker">
                <div class="sensorData">
                  <form [formGroup]="dataFormGroup" (ngSubmit)="dateFilter()" class="form-filter-date">
                    <div class="dropdwnInput">
                      <input #fromdatetime id="fromdatetime" name="fromdatetime" class="fromdatetime" formControlName="fromdatetime"
                        [owlDateTimeTrigger]="fromDatTime" [owlDateTime]="fromDatTime" placeholder="{{'From Date' | translate}}"
                        readonly />
                      <owl-date-time #fromDatTime pickerType="calendar"></owl-date-time>
                    </div>

                    <div class="dropdwnInput">
                      <input #todatetime id="todatetime" name="todatetime" class="todatetime" formControlName="todatetime"
                        [owlDateTimeTrigger]="toDateTime" [owlDateTime]="toDateTime" placeholder="{{'To Date'| translate}}"
                        readonly />
                      <owl-date-time #toDateTime pickerType="calendar"></owl-date-time>
                    </div>
                    <button class="submitBtn" type="submit">{{'Submit' | translate}}</button>
                    <button class="clearBtn" type="reset" (click)="dateFilterClear()">{{'Clear' | translate}}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--地図-->
<div class="mapFlow">
  <google-map width="100%" height="60vh" [options]="options">
    <map-marker #somemarker="mapMarker" *ngFor="let mp of markers; let i = index"
      [position]="mp.position" [visible]="mp.visible" (mapClick)="openInfo(somemarker, markerInfos[i])">
      <map-info-window>
        <strong>センサー情報</strong>
        <div [innerHtml]="infoContent"></div>
        <div style="margin-top: 1rem; width: 100%; text-align: right;">
          <button mat-raised-button color="primary" (click)="jumpPointDetail(markerInfos[i].mainSensorId)">{{'Detail' | translate}}</button>
        </div>
      </map-info-window>
    </map-marker>
  </google-map>
</div>

<!-- リスト -->
<div class="mat-elevation-z8">
  <div class="example-container mat-elevation-z8">
    <mat-table [dataSource]="sampleSource3" matSort class="example-class">

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="cb">
          <mat-checkbox (change)="isAllPointSelect($event.checked)" [checked]="headerCheckBox"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          <mat-checkbox (change)="checkedOrNot($event.checked, i, element.mainSensorId)" class="check"
            [checked]="element.checkedOrNot">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PointDetail">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="detailbtn"> {{'Detail' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="action-link">
          <button mat-raised-button color="primary" (click)="onClick(element.mainSensorId)">{{'Detail' | translate}}</button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="cityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'City' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.cityName | translate}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="areaName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Area' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.areaName | translate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sensorName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Point' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.sensorName | translate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Date & Time' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dateTime}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waterQuantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Water quantity' | translate}}(㎥/sec) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.waterQuantity}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waterLevel">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Water level' | translate}}(m) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.waterLevel}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="freshwaterConductivity">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Freshwater conductivity EC25' | translate}}(μS/cm)
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.freshwaterConductivity}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="saltwaterConductivity">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Saltwater conductivity EC25' | translate}}(mS/cm)
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.saltwaterConductivity}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waterTemprature">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Water temprature' | translate}}(℃) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.waterTemprature}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="batteryVoltage">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Battery voltage' | translate}}(V) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.batteryVoltage}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidity">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity' | translate}}(FTU) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidity}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns2;"></mat-row>

    </mat-table>
    <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]"
                    showFirstLastButtons [length]="sampleSource3Length"></mat-paginator>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.6)" size="large" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{'Loading'}}</p>
</ngx-spinner>
<!-- ↓Add 2024/05/07 -->
<ng-snotify></ng-snotify>
<!-- ↑Add 2024/05/07 -->
