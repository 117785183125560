<!-- <h1 class="title">{{'Point detail' | translate}}</h1> -->


<div class="subheader subheader-solid" id="kt_subheader">
  <div class="container-fluid">
    <div class="loaderDiv" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div class="top-bar">
      <div class="dbWrapper">
        <div class="dbMenuStrip">
          <div class="dbLeftCol">
            <div class="filterDate">
              <div class="datePicker">
                <div class="sensorData">
                  <div class="titleandBackbtn">
                    <div class="backbtn"><button mat-raised-button box-shadow="none" (click)="onClick()">
                        <svg class="backbtn2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path
                            d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.4,16.6L10.8,12L15.4,7.4L14,6L8,12L14,18L15.4,16.6Z" />
                        </svg>
                      </button></div>
                    <div class="title1">{{sessionstorageCityNameData | translate}}</div>
                    <div class="title2">{{sessionstorageAreaNameData | translate}}</div>
                    <div class="title3">{{sessionstorageSensorNameData | translate}}</div>
                  </div>
                  <form [formGroup]="dataFormGroup" (ngSubmit)="dateFilter()" class="form-filter-date">
                    <div class="dropdwnInput">
                      <!-- <input #fromdatetime id="fromdatetime" name="fromdatetime" class="fromdatetime"
                        formControlName="fromdatetime" [owlDateTimeTrigger]="fromDatTime" [owlDateTime]="fromDatTime"
                        [(ngModel)]="fdtime" readonly> -->
                      <input #fromdatetime id="fromdatetime" name="fromdatetime" class="fromdatetime" formControlName="fromdatetime"
                        [owlDateTimeTrigger]="fromDatTime" [owlDateTime]="fromDatTime" readonly />
                      <owl-date-time #fromDatTime pickerType="calendar"></owl-date-time>
                    </div>

                    <div class="dropdwnInput">
                      <!-- <input #todatetime id="todatetime" name="todatetime" class="todatetime"
                        formControlName="todatetime" [owlDateTimeTrigger]="toDateTime" [owlDateTime]="toDateTime"
                        [(ngModel)]="tdtime" readonly> -->
                      <input #todatetime id="todatetime" name="todatetime" class="todatetime" formControlName="todatetime"
                        [owlDateTimeTrigger]="toDateTime" [owlDateTime]="toDateTime" readonly />
                      <owl-date-time #toDateTime pickerType="calendar"></owl-date-time>
                    </div>
                    <button class="submitBtn" type="submit">{{'Submit' | translate}}</button>
                    <button class="clearBtn" type="reset" (click)="dateFilterClear()">{{'Clear' | translate}}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.6)" size="large" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{'Loading'}}</p>
</ngx-spinner>

<!-- <div class="selectdialog">
  <dialog id="dialog" class="dialog">
    <div matDialogContent>
      <mat-form-field class="mff">
        <input matInput [matDatepicker]="csvFromDate">
        <mat-datepicker-toggle matSuffix [for]="csvFromDate" class="fd"></mat-datepicker-toggle>
        <mat-datepicker #csvFromDate class="fd"></mat-datepicker>
      </mat-form-field>
    </div>
  </dialog>
</div> -->

<!-- <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date Time">
        <owl-date-time #dt1></owl-date-time> -->

<!-- CSV出力用のダイアログ -->
<div class="csvdialog">
  <details class="details-dialog" *ngIf="isExportBtn" id="csvSummary">
    <summary data-open="" data-close="" (click)="selectOutputDate()" class="exportbtn">{{"export" | translate}}
    </summary>
    <div class="details-body">
      <div class="details-body__inner">
        <p>{{'Period' | translate}}</p>
        <form [formGroup]="csvDataFormGruop" ng-submit="">
          <div class="csvDate">
            <div class="csvDate">
              <input #csvFromDate [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
                placeholder="{{'From Date' | translate}}" class="csvFromDate" [value]="csvFromDate" id="csvdt1">
              <owl-date-time #dt1 pickerType="calendar"></owl-date-time>
            </div>
            <div class="wave">～</div>
            <div class="csvDate">
              <input csvtToDate [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="{{'To Date' | translate}}"
                class="csvToDate" [value]="csvToDate" id="csvdt2">
              <owl-date-time #dt2 pickerType="calendar"></owl-date-time>
            </div>
          </div>
          <button class="cancelAtCsvDialog" type="button" (click)="closeSelectOutputDate()"> {{'Cancel' | translate}}
          </button>
          <button class="btnAtCsvDialog" type="submit" (click)="OutputCSVData()"> {{'OK' | translate}} </button>
        </form>
        <!-- <button class="executebtn" type="submit"> {{'OK' | translate}} </button> -->
      </div>
    </div>
  </details>
</div>

<!-- <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date Time">
<owl-date-time #dt1></owl-date-time> -->

<mat-tab-group (selectedTabChange)="tabChanged($event)" class="tab-group">
  <mat-tab [label]="'List view'|translate">
    <!-- <button class="exportbtn" (click)="listDataCSVOutput()"> {{'export'|translate}} </button> -->
    <mat-paginator #listPaginator="matPaginator" [pageSizeOptions]="listPagesize" showFirstLastButtons
      [length]="listDataLength" [pageSize]="perPage" aria-label="Select listpage of periodic elements">
    </mat-paginator>
    <div class="tableData1">
      <div class="example-container mat-elevation-z8">
        <mat-table #table matSort matSortStart="desc" (scroll)="onTableScroll($event)" [dataSource]="listDataSource">
          <ng-container matColumnDef="dateTime">
            <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('dateTime')" class="dateheader">
              {{'Date & Time'|translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" style="width: 100%"> {{element.dateTime}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="batteryVoltage">
            <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('batteryVoltage')" class="bvheader">
              {{'Battery voltage'|translate}}(V)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.batteryVoltage}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="freshwaterConductivity">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="fwcheader"> {{'Freshwater conductivity
              EC25'|translate}}(μS/cm) </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.freshwaterConductivity}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="waterQuantity">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="wqheader"> {{'Water quantity'|translate}}(㎥/sec)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.waterQuantity}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="waterLevel">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="wlheader"> {{'Water level'|translate}}(m)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.waterLevel}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="turbidity">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="theader"> {{'Turbidity'|translate}}(FTU)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.turbidity}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="saltwaterConductivity">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="swcheader"> {{'Saltwater conductivity
              EC25'|translate}}(mS/cm) </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.saltwaterConductivity}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="waterTemprature">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="wtheader"> {{'Water temprature'|translate}}(°C)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.waterTemprature}}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="listDisplayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: listDisplayedColumns;"></mat-row>
        </mat-table>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
          aria-label="Select page of periodic elements"
          [pageSize]="perPage">
        </mat-paginator> -->
      </div>
    </div>
    <!-- <mat-paginator #listPaginator="matPaginator" [pageSizeOptions]="listPagesize"
      showFirstLastButtons
      [length]="listDataLength"
      [pageSize]="perPage"
      aria-label="Select listpage of periodic elements">
    </mat-paginator> -->
  </mat-tab>

  <mat-tab [label]="'Extra threshold'|translate">
    <div class="loaderDiv" *ngIf="loadingSetting">
      <mat-spinner></mat-spinner>
    </div>
    <!-- <button class="exportbtn"> {{'export'|translate}} </button> -->
    <mat-paginator #thresholdPaginator="matPaginator" [pageSizeOptions]="thresholdPagesize" showFirstLastButtons
      [length]="thresholdDataLength" [pageSize]="perPage" aria-label="Select thresholdpage of periodic elements">
    </mat-paginator>
    <div class="tableData2">
      <div class="example-container mat-elevation-z8">
        <mat-table #table matSort matSortStart="desc" (scroll)="onTableScroll($event)"
          [dataSource]="extraThresholdDataSource">
          <ng-container matColumnDef="dateTime">
            <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('dateTime')">{{'Date &
              Time'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.dateTime}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sensor">
            <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('sensor')"> {{'Sensor'|translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="sensorItem"> {{element.sensor |translate}}{{element.unit}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="alertLevel">
            <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('alertLevel')"> {{'Alert
              level'|translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" class="alertVal"> {{element.alertLevel}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="thresholdValue">
            <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('thresholdValue')"
              class="thresholdHead"> {{'Threshold value'|translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" class="val"> {{element.thresholdValue | number:'1.3-3'}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="rawValue">
            <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('rawValue')" class="valHead"> {{'Raw
              value'|translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element" class="val"> {{element.value | number:'1.3-3'}} </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="extraThresholdColumns sticky:true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: extraThresholdColumns;"></mat-row>
        </mat-table>
      </div>
    </div>
    <!-- <mat-paginator #thresholdPaginator="matPaginator" [pageSizeOptions]="thresholdPagesize"
      showFirstLastButtons
      [length]="thresholdDataLength"
      [pageSize]="perPage"
      aria-label="Select thresholdpage of periodic elements">
    </mat-paginator> -->
  </mat-tab>

  <mat-tab [label]="'Machine irregular'|translate">
    <div>
      <div class="loaderDiv" *ngIf="loadingSetting">
        <mat-spinner></mat-spinner>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-12">
              <!-- <button class="exportbtn"> {{'export'|translate}} </button> -->
              <mat-paginator #machineIrregularPaginator="matPaginator" [pageSizeOptions]="machineIrregularPagesize"
                showFirstLastButtons [length]="machineIrregularDataLength" [pageSize]="perPage"
                aria-label="Select machineIrregular page of periodic elements">
              </mat-paginator>
              <div class="tableData3">
                <div class="example-container mat-elevation-z8">
                  <mat-table #table matSort matSortStart="desc" (scroll)="onTableScroll($event)"
                    [dataSource]="machineIrregularDataSource">
                    <ng-container matColumnDef="dateTime">
                      <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('dateTime')">{{'Date &
                        Time'|translate}}
                      </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.Datetime}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="sensor">
                      <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('sensor')">
                        {{'Sensor'|translate}} </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.Sensor |translate}}{{element.Unit}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="thresholdValue">
                      <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('ThresholdValue')"
                        class="thresholdHead"> {{'Threshold value'|translate}} </mat-header-cell>
                      <mat-cell *matCellDef="let element" class="threshold"> {{element.ThresholdValue}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="rawValue">
                      <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('RawValue')" class="valHead">
                        {{'Raw value'|translate}} </mat-header-cell>
                      <mat-cell *matCellDef="let element" class="val"> {{element.Value}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="alertDetail">
                      <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sorting('Detail')" class="details">
                        {{'Alert detail'|translate}} </mat-header-cell>
                      <mat-cell *matCellDef="let element" class="details"> {{element.Detail |translate}} </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="deviceFaultColumns sticky:true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: deviceFaultColumns;"></mat-row>
                  </mat-table>
                </div>
              </div>
              <!-- <mat-paginator #machineIrregularPaginator="matPaginator" [pageSizeOptions]="machineIrregularPagesize"
                showFirstLastButtons
                [length]="machineIrregularDataLength"
                [pageSize]="perPage"
                aria-label="Select machineIrregular page of periodic elements">
              </mat-paginator> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'Threshold setting'|translate">
    <div>
      <div class="loaderDiv" *ngIf="loadingSetting">
        <mat-spinner></mat-spinner>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-12">
              <div class="correctionValue">
                <h6>{{'Correction value'| translate}}</h6>
                <div class="correctionValueitems">
                  <div class="item">
                    <mat-label class="data-property-header data-label">{{'Water quantity'|translate}}(㎥/sec)</mat-label>
                  </div>
                  <div class="item">
                    <input type="number" class="form-control data-property-header" id="waterQuantityCorrectionValue"
                      value="{{waterQuantityCorrectionValue.toFixed(3)}}" #rateofchange
                      [disabled]="disableThresholdEdit">
                  </div>
                  <div class="item">
                    <mat-label class="data-property-header data-label">{{'Water level'|translate}}(m)</mat-label>
                  </div>
                  <div class="item">
                    <input type="number" class="form-control data-property-header" id="waterLevelCorrectionValue"
                      value="{{waterLevelCorrectionValue.toFixed(3)}}" [disabled]="disableThresholdEdit">
                  </div>
                </div>
              </div>

              <div class="alertLevel">
                <h6>{{'Alert level'| translate}}</h6>
                <div class="alertLevelitems">
                  <div class="row">
                    <table class="alertleveltable ">
                      <thead class="custom-thead">
                        <tr>
                          <th scope="col">{{'Alert level'|translate}}</th>
                          <th scope="col">{{'Water quantity'|translate}}(㎥/sec)</th>
                          <th scope="col">{{'Water level'|translate}}(m)</th>
                        </tr>
                      </thead>

                      <tbody class="alertLevelitem">
                        <td name="alertLevel" class="alnum">{{1}}</td>
                        <td name="waterQuantity">
                          <input type="number" value="{{(waterQuantityAlertLevel1 == null) ? '' : waterQuantityAlertLevel1.toFixed(3)}}"
                            [disabled]="disableThresholdEdit" id="waterQuantityAlertLevel1" class="ALevel">
                        </td>
                        <td name="waterLevel">
                          <input type="number" value="{{(waterLevelAlertLevel1 == null) ? '' : waterLevelAlertLevel1.toFixed(3)}}"
                            [disabled]="disableThresholdEdit" id="waterLevelAlertLevel1" class="ALevel">
                        </td>
                      </tbody>

                      <tbody class="alertLevelitem">
                        <td name="alertLevel" class="alnum">{{2}}</td>
                        <td name="waterQuantity">
                          <input type="number" value="{{(waterQuantityAlertLevel2 == null) ? '' : waterQuantityAlertLevel2.toFixed(3)}}"
                            [disabled]="disableThresholdEdit" id="waterQuantityAlertLevel2" class="ALevel">
                        </td>
                        <td name="waterLevel">
                          <input type="number" value="{{(waterLevelAlertLevel2 == null) ? '' : waterLevelAlertLevel2.toFixed(3)}}"
                            [disabled]="disableThresholdEdit" id="waterLevelAlertLevel2" class="ALevel">
                        </td>
                      </tbody>

                      <tbody class="alertLevelitem">
                        <td name="alertLevel" class="alnum">{{3}}</td>
                        <td name="waterQuantity">
                          <input type="number" value="{{(waterQuantityAlertLevel3 == null) ? '' : waterQuantityAlertLevel3.toFixed(3)}}"
                            [disabled]="disableThresholdEdit" id="waterQuantityAlertLevel3" class="ALevel">
                        </td>
                        <td name="waterLevel">
                          <input type="number" value="{{(waterLevelAlertLevel3 == null) ? '' : waterLevelAlertLevel3.toFixed(3)}}"
                            [disabled]="disableThresholdEdit" id="waterLevelAlertLevel3" class="ALevel">
                        </td>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="abnormityMachineValue">
                <h6>{{'Machine irregular value'| translate}}</h6>
                <div class="abnormityMachineValueitems">
                  <div class="row">
                    <table class="table">
                      <thead class="custom-thead">
                        <tr>
                          <th scope="col">{{'Sensor name'|translate}}</th>
                          <th scope="col">{{'Current figure'|translate}}</th>
                          <th scope="col">{{'Upper upper limit'|translate}}</th>
                          <th scope="col">{{'Upper limit'|translate}}</th>
                          <th scope="col">{{'Lower limit'|translate}}</th>
                          <th scope="col">{{'Lower lower limit'|translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let prop of thresholdSettingDataSource; let i = index">
                          <td name="dataPointName">{{prop.dataPointNameToDisplay |translate}}{{prop.unit}}</td>

                          <td name="currentFigure">
                            <input class="form-control currentFigure-{{i}}" id="currentFigure" name="currentFigure"
                              value="{{prop.presentValue}}" data-toggle="tooltip" data-placement="top"
                              title="{{prop.dataPointNameToDisplay|translate}}" disabled>
                          </td>

                          <!-- 淡水電気伝導度以外はこちらを使用(上限値) -->
                          <td name="upperUpperLimit"
                            *ngIf="prop.dataPointNameToDisplay.trim() !='Freshwater Conductivity'">
                            <input id="upperUpperLimit-{{i}}" name="upperUpperLimit"
                              class="form-control upperUpperLimit-{{i}}" value="{{prop.upperUpperLimit}}"
                              data-toggle="tooltip" data-placement="top"
                              title="{{prop.dataPointNameToDisplay|translate}}" [disabled]="prop.isNotConnected">
                          </td>

                          <!-- 淡水電気伝導度のみこちらを使用(上限値) -->
                          <td name="upperUpperLimit"
                            *ngIf="prop.dataPointNameToDisplay.trim() =='Freshwater Conductivity'">
                            <input id="upperUpperLimit-{{i}}" name="upperUpperLimit"
                              class="form-control upperUpperLimit-{{i}}" value="{{prop.upperUpperLimit}}"
                              data-toggle="tooltip" data-placement="top"
                              title="{{prop.dataPointNameToDisplay|translate}}" disabled>
                          </td>

                          <!-- 淡水電気伝導度以外はこちらを使用(最大値) -->
                          <td name="upperLimit" *ngIf="prop.dataPointNameToDisplay.trim() !='Freshwater Conductivity'">
                            <input id="upperLimit-{{i}}" name="upperLimit" class="form-control upperLimit-{{i}}"
                              value="{{prop.upperLimit}}" data-toggle="tooltip" data-placement="top"
                              title="{{prop.dataPointNameToDisplay|translate}}" disabled>
                          </td>

                          <!-- 淡水電気伝導度のみこちらを使用(最大値) -->
                          <td name="upperLimit" *ngIf="prop.dataPointNameToDisplay.trim() =='Freshwater Conductivity'">
                            <input id="upperLimit-{{i}}" name="upperLimit" class="form-control upperLimit-{{i}}"
                              value="{{prop.upperLimit}}" data-toggle="tooltip" data-placement="top"
                              title="{{prop.dataPointNameToDisplay|translate}}" disabled>
                          </td>

                          <!-- 海水電気伝導度以外はこちらを使用(最小値) -->
                          <td id="lowerLimit" name="lowerLimit"
                            *ngIf="prop.dataPointNameToDisplay.trim() !='Saltwater Conductivity'">
                            <input class="form-control" id="lowerLimit-{{i}}" value="{{prop.lowerLimit}}"
                              data-toggle="tooltip" data-placement="top"
                              title="{{prop.dataPointNameToDisplay|translate}}" disabled>
                          </td>

                          <!-- 海水電気伝導度のみこちらを使用(最小値) -->
                          <td id="lowerLimit" name="lowerLimit"
                            *ngIf="prop.dataPointNameToDisplay.trim() =='Saltwater Conductivity'">
                            <input class="form-control" id="lowerLimit-{{i}}" value="{{prop.lowerLimit}}"
                              data-toggle="tooltip" data-placement="top"
                              title="{{prop.dataPointNameToDisplay|translate}}" disabled>
                          </td>

                          <!-- 海水電気伝導度以外はこちらを使用(下限値) -->
                          <td id="lowerLowerLimit" name="lowerLowerLimit"
                            *ngIf="prop.dataPointNameToDisplay.trim() !='Saltwater Conductivity'">
                            <input class="form-control" id="lowerLowerLimit-{{i}}" value="{{prop.lowerLowerLimit}}"
                              data-toggle="tooltip" data-placement="top"
                              title="{{prop.dataPointNameToDisplay|translate}}" [disabled]="prop.isNotConnected">
                          </td>

                          <!-- 海水電気伝導度のみこちらを使用(下限値) -->
                          <td id="lowerLowerLimit" name="lowerLowerLimit"
                            *ngIf="prop.dataPointNameToDisplay.trim() =='Saltwater Conductivity'">
                            <input class="form-control" id="lowerLowerLimit-{{i}}" value="{{prop.lowerLowerLimit}}"
                              data-toggle="tooltip" data-placement="top"
                              title="{{prop.dataPointNameToDisplay|translate}}" disabled>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="batteryManage">
                <h6>{{'Battery management'| translate}}</h6>
                <div class="batteryManageItems">
                  <div class="row">
                    <table class="bvmanagetable">
                      <thead class="custom-thead">
                        <tr>
                          <th scope="col" class="bmtitle">{{'Manage item'|translate}}</th>
                          <th scope="col" class="bmtitle">{{'Current value'|translate}}</th>
                          <th scope="col" class="bmtitle">{{'Capacity'|translate}}</th>
                          <th scope="col" class="bmtitle">{{'Lower lower limit'|translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td name="ManageItem" class="bvitem">{{"Voltage" | translate}}(V)</td>
                          <td name="CurrentValue"><input value="{{currentBatteryVoltage}}" class="bvCurrentValue"
                              disabled></td>
                          <td name="Capacity">
                            <select name="Capacity" id="Capacity" class="bvCapacity"
                              [disabled]="isNotConnectedBatteryVoltage" [value]="defaultCapacity">
                              <option value="12">12</option>
                              <option value="24">24</option>
                            </select>
                          </td>
                          <td name="Lower Lower Limit">
                            <input [value]="batteryVoltageLowerLowerVal" class="bvlll"
                              [disabled]="isNotConnectedBatteryVoltage" id="batteryVoltageLowerLowerVal">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="threebtns">
                <div class="editbtn"><button mat mat-raised-button color="primary" id="edit" *ngIf="viewThresholdMode"
                    (click)="setEditThresholdDataMode()">{{'Edit'|translate}}</button></div>
                <div class="cancel"><button mat mat-raised-button id="cancel" *ngIf="editThresholdMode"
                    (click)="editThresholdDataCancel();" class="cancelbtn">{{'Cancel'|translate}}</button></div>
                <div class="keepbtn"><button mat mat-raised-button color="primary" id="keep" *ngIf="editThresholdMode"
                    (click)="keepEditedThresholdData()">{{'Save'|translate}}</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'Cycle setting'|translate">
    <div>
      <div class="sensorSettings">
        <div class="row">
          <div class="col-sm-12 col-sm-12">
            <h2>{{ currentPointName }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-12">
              <div class="settings">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'Recording cycle(initial value) in min'|translate}}</label>
                      <input [value]="recordingCycleInitial" class="form-control"
                        placeholder="Recording cycle (initial value)" OnlyPositiveNumbers="true" type="number"
                        #recording_cycle_initial disabled id="recordingCycleInitial">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'Transmission cycle (initial value) in min'|translate}}</label>
                      <input [value]="transmissionCycleInitial" class="form-control"
                        placeholder="Transmission cycle (initial value)" OnlyPositiveNumbers="true" type="number"
                        #transmission_cycle_initial disabled id="transmissionCycleInitial">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'Recording cycle in min'|translate}}</label>
                      <input [value]="recordingCycle" class="form-control" placeholder="Recording cycle" type="number"
                        min="1" max="1440" OnlyPositiveNumbers="true" #recording_cycle [disabled]="disableCycleEdit"
                        id="recordingCycle">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'Transmission cycle in min'|translate}}</label>
                      <input [value]="transmissionCycle" class="form-control" placeholder="Transmission cycle"
                        type="number" min="1" max="1440" OnlyPositiveNumbers="true" #transmission_cycle
                        [disabled]="disableCycleEdit" id="transmissionCycle">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 text-right">
              <div class="fourbtns">
                <div class="editbtn"><button mat mat-raised-button color="primary" id="edit" *ngIf="viewSensorCycleMode"
                    (click)="setEditCycleDataMode()">{{'Edit'|translate}}</button></div>
                <div class="cancel"><button mat mat-raised-button id="cancel" *ngIf="editSensorCycleMode"
                    (click)="editCycleDataCancel()" class="cancelbtn">{{'Cancel'|translate}}</button></div>
                <div class="restorebtn"><button mat mat-raised-button color="primary" id="reset"
                    *ngIf="editSensorCycleMode" (click)="setDefaultCycleData()">{{'Restore default'|translate}}</button>
                </div>
                <div class="keepbtn"><button mat mat-raised-button color="primary" id="keep" *ngIf="editSensorCycleMode"
                    (click)="keepEditedCycleData()">{{'Save'|translate}}</button></div>
              </div>
              <!-- <button (click)="returnInitial(recording_cycle.value,transmission_cycle.value)"
                [disabled]="pointName == ''" class="btn commonBtn mr-2" type="text">{{'Return to previous value'|translate}}
              </button>

              <button class="btn commonBtn" type="text" [disabled]="pointName == ''"
                (click)="updateMaintenance(transmission_cycle_initial.value ,recording_cycle_initial.value,recording_cycle.value,transmission_cycle.value)">{{'Save'|translate}}
              </button> -->
            </div>
            <div class="col-sm-12 text-right"></div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
<!-- ↓Add 2024/05/07 -->
<ng-snotify></ng-snotify>
<!-- ↑Add 2024/05/07 -->
