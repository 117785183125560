import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//自前モジュール
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // 接続先自動切替対応
  baseurl = environment.BACKEND_BASE_URL + '/api';
  //baseurl = 'https://d1rh4b0dx4fns2.cloudfront.net/api';
  //baseurl = 'https://localhost:44392/api';
  headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

  constructor(public http: HttpClient) {

  }
  logout(userName: string): Observable<any> {
    return this.logout(userName);
  }

  logIn(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + '/User/LoginUser', data, {headers:this.headers});
  }

  /* logIn(data: any): Observable<any> {
    return this.http.post<any>('https://d1rh4b0dx4fns2.cloudfront.net/api/User/LoginUser?userid=${}');
  } */




}
