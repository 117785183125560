<!-- <h1 class="title">{{'Alert list' | translate}}</h1> -->


<div class="subheader subheader-solid" id="kt_subheader">
  <div class="container-fluid">
    <div class="top-bar">
      <div class="dbWrapper">
        <div class="dbMenuStrip">
          <div class="dbLeftCol">
            <div class="dropMenu">
              <mat-form-field appearance="fill">
                <mat-label *ngIf="currentArea != ''"> {{currentArea | translate}} </mat-label>
                <mat-label *ngIf="currentArea == ''"> {{'Area' | translate}} </mat-label>
                <mat-select name="cityArea" id="areaSelect">
                  <mat-option (click)="handleSelection('area', 'not-selected');"> {{'Select' | translate}} </mat-option>
                  <mat-option (click)="handleSelection(area, 'area')" *ngFor='let area of areasArray' [value]="area.areaName">
                   {{area.areaName | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="dropMenu">
              <mat-form-field appearance="fill">
                <mat-label *ngIf="currentSensor != ''"> {{currentSensor | translate}} </mat-label>
                <mat-label *ngIf="currentSensor == ''"> {{'Point' | translate}} </mat-label>
                <mat-select name="areaSensor" [disabled]="isDisablePointSelect" id="pointSelect" [(ngModel)]="defaultSensorVal">
                  <mat-option (click)="handleSelection('point', 'not-selected');"> {{'Select' | translate}} </mat-option>
                  <mat-option (click)="handleSelection(point, 'point');" *ngFor='let point of sensorsArray' [value]="point.sensorName">
                    {{point.sensorName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="filterDate">
              <div class="datePicker">
                <div class="sensorData">
                  <form [formGroup]="dataFormGroup" (ngSubmit)="dateFilter()" class="form-filter-date">
                    <div class="dropdwnInput">
                      <!-- <input #fromdatetime id="fromdatetime" name="fromdatetime" class="fromdatetime"
                      formControlName="fromdatetime" [owlDateTimeTrigger]="fromDatTime" [owlDateTime]="fromDatTime"
                      [(ngModel)]="fdtime" placeholder="{{'From Date' | translate}}" readonly> -->
                      <input #fromdatetime id="fromdatetime" name="fromdatetime" class="fromdatetime" formControlName="fromdatetime"
                        [owlDateTimeTrigger]="fromDatTime" [owlDateTime]="fromDatTime"                    placeholder="{{'From Date' | translate}}" readonly />
                      <owl-date-time  #fromDatTime pickerType="calendar"></owl-date-time>
                    </div>
                    <div class="dropdwnInput">
                      <!-- <input #todatetime id="todatetime" name="todatetime" class="todatetime"
                      formControlName="todatetime" [owlDateTimeTrigger]="toDateTime" [owlDateTime]="toDateTime"
                      [(ngModel)]="tdtime" placeholder="{{'To Date' | translate}}" readonly> -->
                      <input #todatetime id="todatetime" name="todatetime" class="todatetime" formControlName="todatetime"
                        [owlDateTimeTrigger]="toDateTime" [owlDateTime]="toDateTime"                        placeholder="{{'To Date' | translate}}" readonly>
                      <owl-date-time  #toDateTime pickerType="calendar"></owl-date-time>
                    </div>
                    <button class="submitBtn" type="submit"> {{'Submit' | translate}} </button>
                    <button class="clearBtn" type="reset"  (click)="dateFilterClear()"> {{'Clear' | translate}} </button>
                  </form>
                </div>
              </div>
            </div>
            <!-- <button class="exportbtn"> {{'export' | translate}} </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.6)" size="large" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{'Loading'}}</p>
</ngx-spinner>

<!-- CSV出力用のダイアログ -->
<div class="csvdialog">
  <details class="details-dialog" *ngIf="isExportBtn" id="csvSummary">
    <summary data-open="" data-close="" (click)="selectOutputDate()" class="exportbtn"> {{"export" | translate}} </summary>
    <div class="details-body">
      <div class="details-body__inner">
        <p>{{'Period' | translate}}</p>
        <form [formGroup]="csvDataFormGruop" ng-submit="">
          <div class="csvDate">
            <div class="csvDate">
              <input #csvFromDate [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="{{'From Date' | translate}}" class="csvFromDate" [value]="csvFromDate" id="csvdt1">
              <owl-date-time #dt1 pickerType="calendar"></owl-date-time>
            </div>
            <div class="wave">～</div>
            <div class="csvDate">
              <input csvtToDate [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="{{'To Date' | translate}}" class="csvToDate" [value]="csvToDate" id="csvdt2">
              <owl-date-time #dt2 pickerType="calendar"></owl-date-time>
            </div>
          </div>
          <button class="cancelAtCsvDialog" type="button" (click)="closeSelectOutputDate()"> {{'Cancel' | translate}} </button>
          <button class="btnAtCsvDialog" type="submit" (click)="OutputCSVData()"> {{'OK' | translate}} </button>
        </form>
      </div>
    </div>
  </details>
</div>

<mat-tab-group class="tab-group" (selectedTabChange)="tabChanged($event)">
  <mat-tab label="{{'Extra threshold' | translate}}">
    <mat-paginator #thresholdPaginator="matPaginator" [pageSizeOptions]="thresholdPagesize"
      showFirstLastButtons
      [length]="thresholdDataLength"
      [pageSize]="perPage"
      aria-label="Select listpage of periodic elements">
    </mat-paginator>
    <div class="alertListitems">
      <mat-table matSort matSortStart="desc" [dataSource]="extraThresholdDataSource" #thresholdSort="matSort">
        <ng-container matColumnDef="Datetime">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingThreshold()"> {{'Date & Time' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Datetime}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="City">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingThreshold()"> {{'City' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.City | translate}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Area">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingThreshold()"> {{'Area' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Area | translate}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Point">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingThreshold()"> {{'Point' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Point | translate}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Sensor">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingThreshold()"> {{'Sensor' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Sensor | translate}}{{element.Unit}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="AlertLevel">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingThreshold()"> {{'Alert level' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element" class="alertVal"> {{element.AlertLevel | translate}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ThresholdValue">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingThreshold()" class="thresholdHead"> {{'Threshold value' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element" class="alertVal"> {{element.ThresholdValue}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="RawValue">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingThreshold()" class="valHead"> {{'Raw value' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element" class="alertVal"> {{element.Value}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="thresholdColumn; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: thresholdColumn;"></mat-row>
      </mat-table>
    </div>
    <!-- <mat-paginator #thresholdPaginator="matPaginator" [pageSizeOptions]="thresholdPagesize"
      showFirstLastButtons
      [length]="thresholdDataLength"
      [pageSize]="perPage"
      aria-label="Select listpage of periodic elements">
    </mat-paginator> -->
  </mat-tab>

  <mat-tab label="{{'Machine irregular' | translate}}">
    <mat-paginator #machineIrregularPaginator="matPaginator" [pageSizeOptions]="machineIrregularPagesize"
      showFirstLastButtons
      [length]="machineIrregularDataLength"
      [pageSize]="perPage"
      aria-label="Select listpage of periodic elements">
    </mat-paginator>
    <div class="machineIrregularitems">
      <mat-table matSort matSortStart="desc" [dataSource]="machineIrregularDataSource" #machineIrregularSort="matSort">
        <ng-container matColumnDef="Datetime">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingMachineIrregular()" class="date"> {{'Date & Time' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Datetime}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="City">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingMachineIrregular()"> {{'City' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.City | translate}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Area">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingMachineIrregular()"> {{'Area' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Area | translate}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Point">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingMachineIrregular()"> {{'Point' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Point | translate}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Sensor">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingMachineIrregular()"> {{'Sensor' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Sensor | translate}}{{element.Unit}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ThresholdValue">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingMachineIrregular()" class="thresholdHead"> {{'Threshold value' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.ThresholdValue}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="RawValue">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingMachineIrregular()" class="valHead"> {{'Raw value' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Value}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Detail">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortingMachineIrregular()" class="detailHead"> {{'Alert detail' | translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element" class="detailVal"> {{element.Detail | translate}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnName2; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnName2;"></mat-row>
      </mat-table>
    </div>
    <!-- <mat-paginator #machineIrregularPaginator="matPaginator" [pageSizeOptions]="machineIrregularPagesize"
      showFirstLastButtons
      [length]="machineIrregularDataLength"
      [pageSize]="perPage"
      aria-label="Select listpage of periodic elements">
    </mat-paginator> -->
  </mat-tab>
</mat-tab-group>
<!-- ↓Add 2024/05/07 -->
<ng-snotify></ng-snotify>
<!-- ↑Add 2024/05/07 -->
