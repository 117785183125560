<!-- <h1 class="title">{{displayedMode | translate}}</h1> -->


<div class="subheader subheader-solid" id="kt_subheader">
  <div class="container-fluid">
    <div class="top-bar">
      <div class="dbWrapper">
        <div class="dbMenuStrip">
          <div class="dbLeftCol">
            <button class="backbtn" (click)="backUserListPage()" mat-raised-button >
              <svg class="backbtn2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.4,16.6L10.8,12L15.4,7.4L14,6L8,12L14,18L15.4,16.6Z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="userAddorEditTitle">
  <h1>{{displayedMode | translate}}</h1>
  <div class="userID"> <label> {{'User ID' | translate}} </label>
    <input type="text" class="inputUserId" [value]="userId" id="userid" required pattern="^[a-zA-Z0-9!-/:-@¥[-`{-~]*$"
    autocomplete="username" >
  </div>
  <div class="userName"><label> {{'User name' | translate}} </label>
    <input type="text" class="inputUserName" [value]="userName" id="username" required autocomplete="username">
  </div>
  <div class="password"><label> {{'Password' | translate}} </label>
    <input type="password" class="inputPassword" [value]="password" id="password" required pattern="^[a-zA-Z0-9!-/:-@¥[-`{-~]*$" autocomplete="new-password">
  </div>
  <div class="password2"><label> {{'Password(again)' | translate}} </label>
    <input type="password" class="inputPassword2" [value]="password" id="password2" required pattern="^[a-zA-Z0-9!-/:-@¥[-`{-~]*$" autocomplete="new-password">
  </div>
  <div class="accessAuthority"><label> {{'Access authority' | translate}} </label>
    <select name="accessAuthority" id="accessAuthority" class="inputAccessAuthority" [selectedIndex]="role" id="role" [(ngModel)] ="defaultAuthority">
      <option value="nscadmin" *ngIf="isNSCAdmin"> {{'nscadmin' | translate}} </option>
      <option value="admin" *ngIf="isNSCAdmin || isAdminUser"> {{'admin' | translate}} </option>
      <option value="normal"> {{'normal' | translate}} </option>
    </select>
  </div>
  <div class="mailAddress"><label> {{'E-mail address1' | translate}} </label>
    <input type="email" class="inputEmail" [value]="mailAddress1" id="mailaddress1">
  </div>
  <div class="mailAddress"><label> {{'E-mail address2' | translate}} </label>
    <input type="email" class="inputEmail" [value]="mailAddress2" id="mailaddress2">
  </div>

  <div class="checkBoxSet">
    <h2 class="title"> {{'Stream setting' | translate}} </h2>
    <div class="overThreshold">
      <label>{{'Extra threshold' | translate}}</label>
      <label class="levellabel"> {{'Level1' | translate}} </label><input type="checkbox" class="inputlevel1" [checked]="level1" id="level1" >
      <label class="levellabel"> {{'Level2' | translate}} </label><input type="checkbox" class="inputlevel2" [checked]="level2" id="level2" >
      <label class="levellabel"> {{'Level3' | translate}} </label><input type="checkbox" class="inputlevel3" [checked]="level3" id="level3" >
    </div>
    <div class="machineIrregular">
      <label> {{'Machine irregular' | translate}} </label>
      <input type="checkbox" class="inputMachineIrregular" [checked]="machineIrregular" id="machineirregular" >
    </div>
  </div>

  <div class="keepbtn">
    <button class="btnoption" (click)="keepUserData()" type="submit"> {{'Save' | translate}} </button>
  </div>
</div>
<!-- ↓Add 2024/05/07 -->
<ng-snotify></ng-snotify>
<!-- ↑Add 2024/05/07 -->

