import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TodoService } from 'src/app/services/todo.service';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as signalR from '@microsoft/signalr';
// @ts-ignore
import { SnotifyService, SnotifyToast } from 'ng-snotify';
import { MatSort } from '@angular/material/sort';
import { BaseChartDirective } from 'ng2-charts';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { SingleDataSet } from 'ng2-charts';
import { Label } from 'ng2-charts';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { now } from 'lodash';
import { number } from 'echarts';
import { Routes, RouterModule } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NodeWithI18n } from '@angular/compiler';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import * as _ from "lodash";
import { ExportToCsv } from 'export-to-csv-file';
import { MatPaginatorModule } from '@angular/material/paginator';

//データ一覧表示用のインタフェース
export interface ListData {
  dateTime: string;
  waterQuantity: number | string;
  waterLevel: number | string;
  freshwaterConductivity: number | string;
  saltwaterConductivity: number | string;
  waterTemprature: number | string;
  batteryVoltage: number | string;
  turbidity: number | string;
}

export interface NSCUserView {
  dateTime: string;
  waterQuantity: number | string;
  rawWaterQuantity: number | string;
  waterLevel: number | string;
  rawWaterLevel: number | string;
  freshwaterConductivity: number | string;
  rawFreshwaterConductivity: number | string;
  seawaterConductivity: number | string;
  rawSeawaterConductivity: number | string;
  waterTemperature: number | string;
  rawWaterTemperature: number | string;
  batteryVoltage: number | string;
  rawBatteryVoltage: number | string;
  turbidity: number | string;
  rawTurbidity: number | string;
}

//閾値超過表示用のインタフェース
export interface ExtraThresholdData {
  dateTime: string;
  sensor: string;
  unit: string;
  alertLevel: number | string;
  thresholdValue: number | string;
  value: number | string;
}

//閾値超過表示用のインタフェース
export interface thresholdAlertsData {
  dateTime: string;
  sensor: string;
  unit: string;
  alertLevel: number | string;
  thresholdValue: number | string;
  value: number | string;
}

//機器異常用のインタフェース
export interface MachineIrregularData {
  Datetime: string;
  Sensor: string;
  Unit: string;
  ThresholdValue: number | string;
  Value: number | string;
  Detail: string;
}

export interface SampleData {
  Datetime: number;
  Sensor: string;
  AlertLevel: number;
  ThresholdValue: number;
  RawValue: number;
}

const sortObjectsArray = require('sort-objects-array');
const ELEMENT_DATA: SampleData[] = [{ Datetime: 2022 / 11 / 24, Sensor: "水深", AlertLevel: 3, ThresholdValue: 0, RawValue: 0 }];

@Component({
  selector: 'app-point-detail',
  templateUrl: './point-detail.component.html',
  styleUrls: ['./point-detail.component.scss']
})

export class PointDetailComponent implements OnInit {
  /*testing code starts*/
  pointValue: any | undefined;
  dropDownData: any | undefined;
  sensorMeasurementValue = 0; /*testing*/
  rateOfChangeValue = 0; /*testing*/
  alarmDeadZoneValue = 0; /*testing*/
  displayValue = 0; /*testing*/
  alarmSetValueUpper = 0; /*testing*/
  alarmSetValueLower = 0; /*testing*/
  rc = 0;
  charCode: any;
  statusBoolean = true;
  thresholdRateOfChange: any = 0;
  thresholdDeadZone: any = 0;
  thresholdCorrectionWidth: any = 0;
  dataPropertyGridArray: any = [];
  updateToggle = false;
  addToggle = false;
  selectedPropertyValue = '';
  thresholdAPIResponse: any;
  selectedSensorPropertyArray: any = [];
  /*testing code ends*/
  dataFormGroup = new FormGroup({});
  fdtime: any = [];
  tdtime: any = [];
  currentTime = new Date();
  csvDataFormGruop = new FormGroup({});//csv用
  currentCityId: any;
  selectedSensor: any = '';
  handleSelectionData: any;
  handleSelectionType: any;
  selectedAreaData: any;
  isPointSelected = false;
  globalAreaId: any;
  isArrowDown = true;
  globalAreaName: string | null = '';
  currentLang = ''; // SAMPLE TEST CODE
  //@ViewChild(MatSort) sort?: MatSort;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  /* View child properties */
  @ViewChild(BaseChartDirective)
  chart!: BaseChartDirective;
  /*Settings properties initialised */
  presentMin = 0;
  presentMax = 0;
  dataPointName: any;
  flag = true;
  upperUpper = 0;
  upper = 0;
  lower = 0;
  lowerLower = 0;
  changeOfRate = 0;
  alarmDeadMin = 0;
  alarmDeadMax = 0;
  upperLower = 0;
  upperLowerMax = 0;
  presentValueArray: any;
  deadZoneArray: any;
  updateThresholdArray: any = [];
  updateThresholdArray1: any = [];
  @ViewChild('listPaginator', { read: MatPaginator }) listPaginator?: MatPaginator;
  @ViewChild('thresholdPaginator', { read: MatPaginator }) thresholdPaginator?: MatPaginator;
  @ViewChild('machineIrregularPaginator', { read: MatPaginator }) machineIrregularPaginator?: MatPaginator;
  /*Table properties and initialisations */
  dataSource?: any;//リストの表示はこちらを使用
  alertDataSource?: any;//機器異常と閾値超過はこちらを使用
  displayedColumns0 = ['Time Stamp', 'Battery Voltage', 'Freshwater Electrical Conductivity', 'Water Quantity', 'Water Level',
    'Turbidity', 'Seawater Electrical Conductivity', 'Water Temperature'];//サンプルのカラム(のちに削除)
  listDisplayedColumns = ['dateTime', 'waterQuantity', 'waterLevel', 'freshwaterConductivity', 'saltwaterConductivity',
    'waterTemprature', 'batteryVoltage', 'turbidity'];//データ一覧表示用カラム
  extraThresholdColumns = ['dateTime', 'sensor', 'alertLevel', 'thresholdValue', 'rawValue'];//閾値超過表示用カラム
  deviceFaultColumns = ['dateTime', 'sensor', 'thresholdValue', 'rawValue', 'alertDetail'];//機器異常表示用カラム
  start = 0;
  limit = 10;
  public pointSelection = false;
  end: number = this.limit + this.start;
  sensorPrimaryId = '';
  pointName: any = '';
  currentMaintenaceid: any = '';
  recordingCycle = 10;
  recordingCycleInitial: any = 10;
  transmissionCycle = 10;
  transmissionCycleInitial: any = 10;
  alertLevel = 0;
  upperUpperLimit = '';
  upperLimit = '';
  lowerLowerLimit = '';
  lowerLimit = '';
  rateOfChange = '';
  deadZone = '';
  upperLowerLimitCorrectionWidth = '';
  sensorSettingsArray: any = [];
  clickEVentSubscription?: Subscription;
  langEVentSubscription?: Subscription;
  dt: any;
  selectedHour = '1 Day';
  batteryVoltageAverage = 0;
  freshwaterConductivityAverage: any = 0;
  waterQuantityAverage: any = 0;
  waterLevelAverage = 0;
  turbidityAverage: any = 0
  currentSensorId: any;
  currentArea = 0;
  saltwaterConductivityAverage: any = 0; /*testing*/
  seaWaterElectricalConductivityArray: any = []; /*testing*/
  waterTemperatureAvearage: any = 0
  waterTemperatureArray: any = []; /*testing*/
  mainArray: any = [];
  turbidityArray: any = [];
  waterLevelArray: any = [];
  waterQuantityArray: any = [];
  freshwaterConductivityArray: any = [];
  batteryVoltageArray: any = [];
  batteryVoltageDataArray: any = [];
  freshwaterConductivityDataArray: any = [];
  waterQuantityDataArray: any = [];
  waterLevelDataArray: any = [];
  turbidityDataArray: any = [];
  sensorsFilteredData: any[] = [];
  widgetsData: any = [];
  alertsArray: any = [];
  points: any[] = [];
  areas: any[] = [];
  namePack: any;
  sessionDataArray: any;

  filterationObject: any = {
    dateFilter: null
  };
  filteredDate: any;

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  options: any;
  viewType = 'graph';
  areaName = 'Selected Area';
  currentAreaName = '';
  currentPointName = '';
  currentView = 'graph'; /*testing code*/
  selectedArea = false;
  selectedPoint = false;
  loading = false;
  loadingSetting = false;
  cityChanged = false;
  browserLang: any;
  cityArray: any = [];//以下追記分プロパティ
  areaArray: any = [];
  mainSensorArray: any = [];
  displayedColumns1 = ['Datetime', 'Sensor', 'AlertLevel', 'ThresholdValue', 'RawValue'];
  sampleSource = new MatTableDataSource<SampleData>(ELEMENT_DATA);
  sessionstorageData: any;
  sessionstorageNameData: any;
  sessionstorageCityNameData: any;
  sessionstorageAreaNameData: any;
  sessionstorageSensorNameData: any;
  sessionstorageMainsensorData: any;
  nameArray: any;
  mainSensorIdArray: any;
  pointDataList: ListData[] = [];//データ一覧表示用データの格納場所
  extraThresholdDataList: ExtraThresholdData[] = [];//閾値超過表示用データの格納場所
  thresholdAlertArray: ExtraThresholdData[] = []; //閾値超過表示用データの格納場所(新)
  listDataSource: any;//データ一覧表示用データソース
  extraThresholdDataSource: any;//閾値超過表示用データソース
  deviceFaultDataSource: any;//機器異常表示用データソース
  dataArray: any[] = [];
  fromDate: any;
  toDate: any;
  filterFromDate: any;
  filterToDate: any;
  startAt: any;
  waterQuantityCorrectionValue: any = 0;
  waterLevelCorrectionValue: any = 0;
  waterLevelAlertLevel1: any = 0;
  waterLevelAlertLevel2: any = 0;
  waterLevelAlertLevel3: any = 0;
  waterQuantityAlertLevel1: any = 0;
  waterQuantityAlertLevel2: any = 0;
  waterQuantityAlertLevel3: any = 0;
  perPage: number = 10;
  listViewPerPage: number = 50;
  thresholdPerPage: number = 10;
  machineIrregularPerPage: number = 10;
  viewThresholdMode: boolean = true;//閾値設定のボタン切り替え
  editThresholdMode: boolean = false;//閾値設定のボタン切り替え
  viewSensorCycleMode: boolean = true;//周期設定のボタン切り替え
  editSensorCycleMode: boolean = false;//周期設定のボタン切り替え
  disableThresholdEdit: boolean = true;//閾値設定の編集の可否
  disableCycleEdit: boolean = true;//周期設定の編集の可否
  correctionValue: any;
  alertLevel1: any;
  alertLevel2: any;
  alertLevel3: any;
  searchMode: boolean = false;//12/27
  machineIrregularSendData: any;
  thresholdSendData: any;
  allAlertData: any;
  pickedPointData: any;
  irregularThresholdValue: any;
  irregularValue: any;
  machineIrregularDataList: MachineIrregularData[] = [];
  machineIrregularDataSource: any;
  csvArray: any = [];
  csvFromDate: any;
  csvToDate: any;
  listDataLength: any;
  thresholdDataLength: any;
  machineIrregularDataLength: any;
  listPagesize: any = [];
  thresholdPagesize: any = [];
  machineIrregularPagesize: any = [];
  tabIndex: number = 0;
  displayThresholdAPIResponse: any;
  listViewSendData: any;
  currentBatteryVoltage: any = 0;//バッテリー電圧の現在地
  batteryVoltageLowerLowerVal: number = 0;//バッテリー電圧の下下限
  isCancelorKeepBtnClicked: boolean = false;//キャンセルボタンが押された直後のみtrueになる
  thresholdSettingDataSource: any;//閾値設定表示用データ
  defaultCapacity: number = 12;//バッテリー管理の性能値
  keptBatteryVoltageData: any;//バッテリー電圧のDataPointMappingデータ
  batteryVoltageThreshold: any;//バッテリー電圧の閾値設定データ
  thresholdEditedData: any;//編集後の閾値設定データ
  freshwaterConductivityUpperUpperVal: any;//淡水電気伝導度の上上限
  freshwaterConductivityUpperVal: any;//淡水電気伝導度の上限
  seawaterConductivityLowerLowerVal: any;//海水電気伝導度の下下限
  seawaterConductivityLowerVal: any;//海水電気伝導度の下限
  isExportBtn: boolean = true;
  role: any;
  viewFdtime: any;
  viewTdtime: any;
  isNotConnectedBatteryVoltage: boolean = true;

  tabFlag = 0;

  etDtFlag = 0;
  etSFlag = 0;
  etAlFlag = 0;
  etThvFlag = 0;
  etRvFlag = 0;

  miDtFlag = 0;
  miSFlag = 0;
  miThvFlag = 0;
  miRvFlag = 0;
  miDFlag = 0;

  thresholdCopy: any;

  isCompleteProcess: boolean = false; //閾値超過または機器異常のデータ処理が完了したかを把握するフラグ

  locationHistory: string | null = 'db/point-selection'; //20240619追加　戻るボタンページ遷移元用

  constructor(private translate: TranslateService,
    public cookieService: CookieService,
    private snotifyService: SnotifyService,
    private spinner: NgxSpinnerService,
    public todoService: TodoService,
    private detailRouter: Router,
    private datePipe: DatePipe,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private activatedRoute: ActivatedRoute) {
      this.activatedRoute.queryParams.pipe().subscribe(params => { this.dataSource = params.pointDataSource })
      this.dataFormGroup = new FormGroup({
        fromdatetime: new FormControl(),
        todatetime: new FormControl()
      });

      this.csvDataFormGruop = new FormGroup({
        csvFromDate: new FormControl(),
        csvToDate: new FormControl()
      });

      //追加記述(リリース時はコメントアウト)
      this.datePickerlang();
  }

  //追加記述(リリース時はコメントアウト)
  datePickerlang() {
    let lang = sessionStorage.getItem("lang");

    if(lang == null || lang == "" || lang == undefined){
      lang = this.cookieService.get('language');
    }

    this.todoService.languageData.subscribe((data: any) => {

      if (data == 'jp') {
        this.dateTimeAdapter.setLocale('ja-JP');
      }
      else if (data == 'en') {
        this.dateTimeAdapter.setLocale('en');
      }
      else {

        if (lang) {

          if (lang == 'en') {
            this.dateTimeAdapter.setLocale('en');
          }

          if (lang == 'jp') {
            this.dateTimeAdapter.setLocale('ja-JP');
          }
        }
      }
    });
  }

  ngOnInit(): void {
    const token = sessionStorage.getItem('token');

    if(token == null){
      this.detailRouter.navigateByUrl('/auth');
    }

    this.spinner.show();
    this.sessionstorageCityNameData = sessionStorage.getItem('sessionCityNameData');
    this.sessionstorageAreaNameData = sessionStorage.getItem('sessionAreaNameData');
    this.sessionstorageSensorNameData = sessionStorage.getItem('sessionSensorNameData');
    this.role = sessionStorage.getItem('role');

    if (this.role == "normal") {
      this.viewThresholdMode = false;
      this.viewSensorCycleMode = false;
    }

    this.sessionstorageMainsensorData = sessionStorage.getItem('sessionSensorData');
    this.filterFromDate = sessionStorage.getItem('filterFromDate');
    this.filterToDate = sessionStorage.getItem('filterToDate');
    this.currentSensorId = this.sessionstorageMainsensorData;

    this.thresholdSetting();
    this.cycleSetting();
  }

  //一覧表示のためのデータ取得＆加工イベント
  listview(): void {
    this.pointDataList.length = 0;

    if (this.searchMode == true) {

      this.listViewSendData = {
        mainSensorID: this.sessionstorageMainsensorData,
        fromDate: moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.dataFormGroup.value.todatetime)).format('YYYY-MM-DD')
      }
    }
    else {

      this.listViewSendData = {
        mainSensorID: this.sessionstorageMainsensorData,
        fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.filterToDate)).add(1, 'd').format('YYYY-MM-DD')
      };

      this.viewFdtime = document.getElementById('fromdatetime');
      this.viewFdtime.placeholder;
      this.viewFdtime.placeholder = sessionStorage.getItem('filterFromDate');

      this.viewTdtime = document.getElementById('todatetime');
      this.viewTdtime.placeholder;
      this.viewTdtime.placeholder = sessionStorage.getItem('filterToDate');
    }

    this.todoService.getAllByMainSensorIdAndMultipleDates(this.listViewSendData).subscribe((response: any) => {
      this.alertDataSource = _.cloneDeep(response);
      this.dataSource = [];
      this.dataSource.length = 0;

      for (let index = 0; index < response.waterFlowResponse.length; index++) {
        var tmpVal: string;
        tmpVal = response.waterFlowResponse[index]["dateTime"];

        if (tmpVal >= this.filterFromDate) {

          if (tmpVal <= this.filterToDate) {
            this.dataSource.push(response.waterFlowResponse[index]);
          }
        }
      }

      if (this.dataSource !== null && this.dataSource !== undefined) {

        for (let i = 0; i < this.dataSource.length; i++) {

          const pointData: ListData = {
            dateTime: this.dataSource[i]['dateTime'],
            waterQuantity: parseFloat(this.dataSource[i]['waterQuantity']).toFixed(3),
            waterLevel: parseFloat(this.dataSource[i]['waterLevel']).toFixed(3),
            freshwaterConductivity: parseFloat(this.dataSource[i]['freshwaterConductivity']).toFixed(1),
            saltwaterConductivity: parseFloat(this.dataSource[i]['saltwaterConductivity']).toFixed(2),
            waterTemprature: parseFloat(this.dataSource[i]['waterTemprature']).toFixed(2),
            batteryVoltage: parseFloat(this.dataSource[i]['batteryVoltage']).toFixed(3),
            turbidity: parseFloat(this.dataSource[i]['turbidity']).toFixed(1),
          }

          pointData.dateTime = pointData.dateTime;
          pointData.waterQuantity = isNull(pointData.waterQuantity);
          pointData.waterLevel = isNull(pointData.waterLevel);
          pointData.freshwaterConductivity = isNull(pointData.freshwaterConductivity);
          pointData.saltwaterConductivity = isNull(pointData.saltwaterConductivity);
          pointData.waterTemprature = isNull(pointData.waterTemprature);
          pointData.batteryVoltage = isNull(pointData.batteryVoltage);
          pointData.turbidity = isNull(pointData.turbidity);

          this.pointDataList.push(pointData);
        }
        this.listDataSource = new MatTableDataSource<ListData>(this.pointDataList);

        //閾値設定画面に最新値を表示する
        this.thresholdAPIResponse.forEach((item: any) => {

          if (this.dataSource.length !== 0){

            if (item.dataPointNameToDisplay == "Water Quantity") item.presentValue = (parseFloat(this.dataSource[this.dataSource.length - 1].waterQuantity)).toFixed(3);
            else if (item.dataPointNameToDisplay == "Water Level") item.presentValue = (parseFloat(this.dataSource[this.dataSource.length - 1].waterLevel)).toFixed(3);
            else if (item.dataPointNameToDisplay == "Freshwater Conductivity") item.presentValue = (parseFloat(this.dataSource[this.dataSource.length - 1].freshwaterConductivity)).toFixed(1);
            else if (item.dataPointNameToDisplay == "Saltwater Conductivity") item.presentValue = (parseFloat(this.dataSource[this.dataSource.length - 1].saltwaterConductivity)).toFixed(2);
            else if (item.dataPointNameToDisplay == "Water Temperature") item.presentValue = (parseFloat(this.dataSource[this.dataSource.length - 1].waterTemprature)).toFixed(2);
            else if (item.dataPointNameToDisplay == "Turbidity") item.presentValue = (parseFloat(this.dataSource[this.dataSource.length - 1].turbidity)).toFixed(1);
            else if (item.dataPointNameToDisplay == "Battery Voltage") item.presentValue = (parseFloat(this.dataSource[this.dataSource.length - 1].batteryVoltage)).toFixed(3);
          }
          else{
            item.presentValue = "-";
            this.currentBatteryVoltage = "-";
          }

          if (item.dataPointNameToDisplay == "Water Quantity") item.unit = "(㎥/sec)";
          else if (item.dataPointNameToDisplay == "Water Level") item.unit = "(m)";
          else if (item.dataPointNameToDisplay == "Freshwater Conductivity") item.unit = "(μS/cm)";
          else if (item.dataPointNameToDisplay == "Saltwater Conductivity") item.unit = "(mS/cm)";
          else if (item.dataPointNameToDisplay == "Water Temperature") item.unit = "(℃)";
          else if (item.dataPointNameToDisplay == "Turbidity") item.unit = "(FTU)";
          else if (item.dataPointNameToDisplay == "Battery Voltage"){
            this.batteryVoltageLowerLowerVal = item.lowerLowerLimit;
            item.unit = "(V)";
            this.batteryVoltageThreshold = item;
          };

          item.presentValue = isNull(item.presentValue);
        })

        this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.extraThresholdDataSource);

        //並び替えのベースとなる配列(この順番でソート)
        const order = ["Water Quantity", "Water Level", "Freshwater Conductivity", "Saltwater Conductivity", "Water Temperature", "Turbidity", "Battery Voltage"];

        //ソート
        this.thresholdAPIResponse.sort((x: any, y: any) => {
          return order.indexOf(x.dataPointNameToDisplay) - order.indexOf(y.dataPointNameToDisplay);
        })

        if (this.dataSource.length !== 0) this.currentBatteryVoltage = (parseFloat(this.dataSource[this.dataSource.length - 1].batteryVoltage)).toFixed(3);
        this.currentBatteryVoltage = isNull(this.currentBatteryVoltage);

        //バッテリー電圧を機器異常値表示から除外
        this.thresholdSettingDataSource = this.thresholdAPIResponse.filter((x: any) => x.dataPointNameToDisplay !== "Battery Voltage");

        //20240517追加
        //濁度予測を機器異常値表示から除外
        this.thresholdSettingDataSource = this.thresholdAPIResponse.filter((x: any) => x.dataPointNameToDisplay !== "Turbidity Predict");
        //20240517追加終了

        this.listDataLength = this.pointDataList.length;
        this.listDataSource.paginator = this.listPaginator;//ページング
        this.listPagesize = [10, 50, 100];
        //this.ExtraThreshold();
        this.showThresholdAlerts();
        this.machineIrregular();

        if(this.isCompleteProcess == false){
          this.isCompleteProcess = true;
        }
        else this.spinner.hide();
      }
    });
  }

  returnInitial(recordingCycle: string, transmissionCycle: string): void {

    if ((Number(this.recordingCycleInitial) === Number(recordingCycle)) && (Number(this.transmissionCycleInitial) === Number(transmissionCycle))) {
    }
    else {
      const data: any = sessionStorage.getItem('initialValues');
      const initialValues = JSON.parse(data);
      this.recordingCycle = initialValues.initialRecording;
      this.transmissionCycle = initialValues.initialTransmission;

      const maintenanceData = {
        id: this.sensorPrimaryId,
        mainSensorId: this.currentSensorId.toString(),
        number: 0,
        name: this.pointName,
        recordingCycle: this.recordingCycleInitial,
        transmissionCycle: this.transmissionCycleInitial,
        recordingCycleInitial: this.recordingCycleInitial,
        transmissionCycleInitial: this.transmissionCycleInitial,
        mode: 'auto',
        updatedDate: moment(new Date()).format('dd-mm-YYYY'),
      };

      this.todoService.getMaintenanceByMainSensorId(this.currentSensorId.toString()).subscribe((response: any) => {
        this.recordingCycle = Number(response[0].recordingCycleInitial);
        this.transmissionCycle = Number(response[0].transmissionCycleInitial);
        this.currentMaintenaceid = response[0].id;

        const maintenanceData = {
          id: this.currentMaintenaceid,
          mainSensorId: this.currentSensorId.toString(),
          number: 0,
          name: this.pointName,
          recordingCycle: this.recordingCycleInitial,
          transmissionCycle: this.transmissionCycleInitial,
          recordingCycleInitial: this.recordingCycleInitial,
          transmissionCycleInitial: this.transmissionCycleInitial,
          mode: 'auto',
          updatedDate: moment(new Date()).format('dd-mm-YYYY'),
        };

        this.todoService.updateMaintenance(this.currentMaintenaceid, maintenanceData).subscribe((response: any) => {

          this.snotifyService.success(this.translate.instant('Values set to initial successfully'), '', {
            timeout: 7000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true
          });
        });
      });
    }
  }

  //周期設定保存イベント
  updateMaintenance(transmissionCycleInitial: string, recordingCycleInitial: string, recordingCycle: string, transmissionCycle: string): void {
    this.loadingSetting = true;

    if (Number(recordingCycle) == null) {

      this.snotifyService.success(this.translate.instant('Recording Cycle cannot be empty'), '', {
        timeout: 7000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true
      });
    }

    if (Number(transmissionCycle) == null) {

      this.snotifyService.success(this.translate.instant('Transmission Cycle cannot be empty'), '', {
        timeout: 7000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true
      });
    }

    if (Number(transmissionCycle) == null) {

      this.snotifyService.error(this.translate.instant('Alert Level cannot be empty'), '', {
        timeout: 7000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true
      });
    }

    if (Number(transmissionCycle) !== null && Number(recordingCycle) !== null) {

      if (Number(transmissionCycle) < 1 || Number(transmissionCycle) > 1440) {

        this.snotifyService.error(this.translate.instant('Please enter Transmission Cycle minutes from 1 to 1440'), '', {
          timeout: 7000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true
        });
      }

      if (Number(recordingCycle) < 1 || Number(recordingCycle) > 1440) {

        this.snotifyService.error(this.translate.instant('Please enter Recording Cycle minutes from 1 to 1440'), '', {
          timeout: 7000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true
        });
      }

      if ((Number(transmissionCycle) >= 1 && Number(transmissionCycle) <= 1440) && (Number(recordingCycle) >= 1 && Number(recordingCycle) <= 1440)) {

        const maintenanceData = {
          id: this.currentMaintenaceid,
          mainSensorId: this.currentSensorId.toString(),
          number: 0,
          name: this.pointName,
          recordingCycle,
          transmissionCycle,
          recordingCycleInitial,
          transmissionCycleInitial,
          mode: 'auto',
          updatedDate: new Date(),
        };

        this.todoService.updateMaintenance(this.currentMaintenaceid, maintenanceData).subscribe((response: any) => {

          this.loadingSetting = false;
          this.snotifyService.success(this.translate.instant('Data updated successfully'), '', {
            timeout: 7000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true
          });

          this.todoService.getMaintenanceByMainSensorId(this.currentSensorId).subscribe((response: any) => {
            this.recordingCycleInitial = response[0].recordingCycleInitial;
            this.currentMaintenaceid = response[0].id;
            this.transmissionCycleInitial = response[0].transmissionCycleInitial;
            this.recordingCycle = response[0].recordingCycle;
            this.transmissionCycle = response[0].transmissionCycle;
            this.alertLevel = response[0].alertLevel;
            this.pointName = response[0].name;
          },
          error => {
            this.loadingSetting = false;
          });
        },
        error => {
          this.loadingSetting = false;
        });
      }
    }
  }

  //日付による絞り込みイベント(一覧表示用)
  dateFilter(): any {

    if (this.pointDataList != undefined) {
      this.pointDataList.length = 0;
    }
    this.spinner.show();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1500);
    const data = {
      mainSensorID: this.sessionstorageMainsensorData,
      fromDate: moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD'),
      toDate: moment(new Date(this.dataFormGroup.value.todatetime)).add(1, 'd').format('YYYY-MM-DD')
    };

    //日付入力欄のチェック(リリース時コメントアウト)
    if(data.fromDate == 'Invalid date' || data.toDate == 'Invalid date' || this.dataFormGroup.value.fromdatetime == null || this.dataFormGroup.value.todatetime == null){

      if(data.fromDate == 'Invalid date' || this.dataFormGroup.value.fromdatetime == null){
        alert("日付(From)が入力されていません。");
      }
      else if(data.toDate == 'Invalid date' || this.dataFormGroup.value.todatetime == null){
        alert("日付(To)が入力されていません。");
      }
      this.spinner.hide();
      return;
    }

    this.searchMode = true;

    this.todoService.getAllByMainSensorIdAndMultipleDates(data).subscribe((response: any) => {
      this.dataSource.length = 0;

      for (let index = 0; index < response.waterFlowResponse.length; index++) {
        var tmpVal: string;
        tmpVal = response.waterFlowResponse[index]["dateTime"];

        if (tmpVal >= moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD')) {

          if (tmpVal < moment(new Date(this.dataFormGroup.value.todatetime)).add(1, 'd').format('YYYY-MM-DD HH:mm')) {
            this.dataSource.push(response.waterFlowResponse[index]);
          }
        }
      }

      //データ一覧表示用処理
      if (this.dataSource !== null && this.dataSource !== undefined) {

        for (let i = 0; i < this.dataSource.length; i++) {

          const pointData: ListData = {
            dateTime: this.dataSource[i]['dateTime'],
            waterQuantity: this.dataSource[i]['waterQuantity'],
            waterLevel: this.dataSource[i]['waterLevel'],
            freshwaterConductivity: this.dataSource[i]['freshwaterConductivity'],
            saltwaterConductivity: this.dataSource[i]['saltwaterConductivity'],
            waterTemprature: this.dataSource[i]['waterTemprature'],
            batteryVoltage: this.dataSource[i]['batteryVoltage'],
            turbidity: this.dataSource[i]['turbidity'],
          }
          this.pointDataList.push(pointData);
        }
        this.listDataSource = new MatTableDataSource<ListData>(this.pointDataList);
      }

      this.listDataLength = this.pointDataList.length;
      this.listDataSource.paginator = this.listPaginator;//ページング
      this.listPagesize = [10, 50, 100];

      //閾値超過表示用処理
      //this.ExtraThreshold();
      this.showThresholdAlerts();
      this.machineIrregular();
    }, (err: any) => {
      this.spinner.hide();
    });
  }

  //日付絞り込みの解除イベント
  dateFilterClear(): void {
    this.spinner.show();
    this.searchMode = false;
    this.listview();
  }

  //メイン画面に戻るボタン押下イベント
  onClick() {

    this.locationHistory = sessionStorage.getItem('locationHistory') ? sessionStorage.getItem('locationHistory') : this.locationHistory;  //20240619追加　戻るボタン対応
    this.detailRouter.navigate([this.locationHistory]), { //20240619変更　/*'db/point-selection'*/

      queryParams: {
        pointDataSource: this.pointDataList
      }
    }
    
  }

  get isSensor() {
    return this.currentSensorId > 0 ? false : true;
  }

  onTableScroll(e: any): void {
    const tableViewHeight = e.target.offsetHeight; // viewport
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;

    if (scrollLocation > limit) {
    }
  }

  //タブ切り替え時のページングイベントとエクスポートボタンの表示／非表示
  tabChanged($event: any): void {
    this.tabIndex = $event.index;

    if ($event.index == 0) {
      this.listPagesize = [10, 50, 100];
      this.listDataSource.paginator = this.listPaginator;
      this.isExportBtn = true;
      this.tabFlag = 0;
    }
    else if ($event.index == 1) {
      this.thresholdPagesize = [10, 20, 30];
      this.thresholdDataLength = this.thresholdAlertArray.length;
      this.extraThresholdDataSource.paginator = this.thresholdPaginator;
      this.isExportBtn = true;
      this.tabFlag = 1;
    }
    else if ($event.index == 2) {
      this.machineIrregularPagesize = [10, 20, 30];
      this.machineIrregularDataLength = this.machineIrregularDataList.length;
      this.machineIrregularDataSource.paginator = this.machineIrregularPaginator;
      this.isExportBtn = true;
      this.tabFlag = 2;
    }
    else {
      this.isExportBtn = false;
    }
  }

  //ソートイベント
  sorting(propertyName: string): void {

    if (this.tabFlag == 0) {

      if (propertyName === 'dateTime') {
        this.listDataSource.sort = this.sort;
      }
    }
    else if (this.tabFlag == 1) {

      if (propertyName === 'dateTime') {

        if (this.etSFlag != 0 || this.etAlFlag != 0 || this.etThvFlag != 0 || this.etRvFlag != 0) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });

          this.etSFlag = 0;
          this.etAlFlag = 0;
          this.etThvFlag = 0;
          this.etRvFlag = 0;

          this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.extraThresholdDataSource);
        }

        if (this.etDtFlag == 0) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });
          this.etDtFlag++;
        }
        else if (this.etDtFlag == 1) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime > b.dateTime) ? -1 : 1;
          });
          this.etDtFlag++;
        }
        else if (this.etDtFlag == 2) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });
          this.etDtFlag = 0;
        }
      }
      else if (propertyName === 'sensor') {

        if (this.etDtFlag != 0 || this.etAlFlag != 0 || this.etThvFlag != 0 || this.etRvFlag != 0) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });

          this.etDtFlag = 0;
          this.etAlFlag = 0;
          this.etThvFlag = 0;
          this.etRvFlag = 0;

          this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.extraThresholdDataSource);
        }

        if (this.etSFlag == 0) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.sensor < b.sensor) ? -1 : 1;
          });
          this.etSFlag++;
        }
        else if (this.etSFlag == 1) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.sensor > b.sensor) ? -1 : 1;
          });
          this.etSFlag++;
        }
        else if (this.etSFlag == 2) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });
          this.etSFlag = 0;
        }
      }
      else if (propertyName === 'alertLevel') {

        if (this.etDtFlag != 0 || this.etSFlag != 0 || this.etThvFlag != 0 || this.etRvFlag != 0) {
          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });

          this.etDtFlag = 0;
          this.etSFlag = 0;
          this.etThvFlag = 0;
          this.etRvFlag = 0;

          this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.extraThresholdDataSource);
        }

        if (this.etAlFlag == 0) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.alertLevel < b.alertLevel) ? -1 : 1;
          });
          this.etAlFlag++;
        }
        else if (this.etAlFlag == 1) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.alertLevel > b.alertLevel) ? -1 : 1;
          });
          this.etAlFlag++;
        }
        else if (this.etAlFlag == 2) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });
          this.etAlFlag = 0;
        }
      }
      else if (propertyName === 'thresholdValue') {

        if (this.etDtFlag != 0 || this.etSFlag != 0 || this.etAlFlag != 0 || this.etRvFlag != 0) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });

          this.etDtFlag = 0;
          this.etSFlag = 0;
          this.etAlFlag = 0;
          this.etRvFlag = 0;

          this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.extraThresholdDataSource);
        }

        if (this.etThvFlag == 0) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.thresholdValue < b.thresholdValue) ? -1 : 1;
          });
          this.etThvFlag++;
        }
        else if (this.etThvFlag == 1) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.thresholdValue > b.thresholdValue) ? -1 : 1;
          });
          this.etThvFlag++;
        }
        else if (this.etThvFlag == 2) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });
          this.etThvFlag = 0;
        }
      }
      else if (propertyName === 'RawValue') {

        if (this.etDtFlag != 0 || this.etSFlag != 0 || this.etAlFlag != 0 || this.etThvFlag != 0) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });
          this.etDtFlag = 0;
          this.etSFlag = 0;
          this.etAlFlag = 0;
          this.etThvFlag = 0;

          this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.extraThresholdDataSource);
        }

        if (this.etRvFlag == 0) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.rawValue < b.rawValue) ? -1 : 1;
          });
          this.etRvFlag++;
        }
        else if (this.etRvFlag == 1) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.rawValue > b.rawValue) ? -1 : 1;
          });
          this.etRvFlag++;
        }
        else if (this.etRvFlag == 2) {

          this.extraThresholdDataSource = this.extraThresholdDataSource.alertData.sort(function (a: any, b: any) {
            return (a.dateTime < b.dateTime) ? -1 : 1;
          });
          this.etRvFlag = 0;
        }
      }

      this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.extraThresholdDataSource);
    }
    else if (this.tabFlag == 2) {

      if (propertyName === 'dateTime') {

        if (this.miSFlag != 0 || this.miThvFlag != 0 || this.miRvFlag != 0 || this.miDFlag != 0) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });

          this.miSFlag = 0;
          this.miThvFlag = 0;
          this.miRvFlag = 0;
          this.miDFlag = 0;

          this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(this.machineIrregularDataSource);
        }

        if (this.miDtFlag == 0) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });
          this.miDtFlag++;
        }
        else if (this.miDtFlag == 1) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime > b.Datetime) ? -1 : 1;
          });
          this.miDtFlag++;
        }
        else if (this.miDtFlag == 2) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });
          this.miDtFlag = 0;
        }
      }
      else if (propertyName === 'sensor') {

        if (this.miDtFlag != 0 || this.miThvFlag != 0 || this.miRvFlag != 0 || this.miDFlag != 0) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });

          this.miDtFlag = 0;
          this.miThvFlag = 0;
          this.miRvFlag = 0;
          this.miDFlag = 0;

          this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(this.machineIrregularDataSource);
        }

        if (this.miSFlag == 0) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Sensor < b.Sensor) ? -1 : 1;
          });
          this.miSFlag++;
        }
        else if (this.miSFlag == 1) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Sensor > b.Sensor) ? -1 : 1;
          });
          this.miSFlag++;
        }
        else if (this.miSFlag == 2) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });
          this.miSFlag = 0;
        }
      }
      else if (propertyName === 'ThresholdValue') {

        if (this.miDtFlag != 0 || this.miSFlag != 0 || this.miRvFlag != 0 || this.miDFlag != 0) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });

          this.miDtFlag = 0;
          this.miSFlag = 0;
          this.miRvFlag = 0;
          this.miDFlag = 0;

          this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(this.machineIrregularDataSource);
        }

        if (this.miThvFlag == 0) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.ThresholdValue < b.ThresholdValue) ? -1 : 1;
          });
          this.miThvFlag++;
        }
        else if (this.miThvFlag == 1) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.ThresholdValue > b.ThresholdValue) ? -1 : 1;
          });
          this.miThvFlag++;
        }
        else if (this.miThvFlag == 2) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });
          this.miThvFlag = 0;
        }
      }
      else if (propertyName === 'RawValue') {

        if (this.miDtFlag != 0 || this.miSFlag != 0 || this.miThvFlag != 0 || this.miDFlag != 0) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });

          this.miDtFlag = 0;
          this.miSFlag = 0;
          this.miThvFlag = 0;
          this.miDFlag = 0;

          this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(this.machineIrregularDataSource);
        }

        if (this.miRvFlag == 0) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.RawValue < b.RawValue) ? -1 : 1;
          });
          this.miRvFlag++;
        }
        else if (this.miRvFlag == 1) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.RawValue > b.RawValue) ? -1 : 1;
          });
          this.miRvFlag++;
        }
        else if (this.miRvFlag == 2) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });
          this.miRvFlag = 0;
        }
      }
      else if (propertyName === 'Detail') {

        if (this.miDtFlag != 0 || this.miSFlag != 0 || this.miThvFlag != 0 || this.miRvFlag != 0) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });

          this.miDtFlag = 0;
          this.miSFlag = 0;
          this.miThvFlag = 0;
          this.miRvFlag = 0;

          this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(this.machineIrregularDataSource);
        }

        if (this.miDFlag == 0) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Detail < b.Detail) ? -1 : 1;
          });
          this.miDFlag++;
        }
        else if (this.miDFlag == 1) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Detail > b.Detail) ? -1 : 1;
          });
          this.miDFlag++;
        }
        else if (this.miDFlag == 2) {

          this.machineIrregularDataSource = this.machineIrregularDataSource.filteredData.sort(function (a: any, b: any) {
            return (a.Datetime < b.Datetime) ? -1 : 1;
          });
          this.miDFlag = 0;
        }
      }

      this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(this.machineIrregularDataSource);
    }
  }

  //閾値超過一覧用データ取得＆加工イベント
  ExtraThreshold(): void {
    this.extraThresholdDataList.length = 0;

    if (this.searchMode == true) {

      this.thresholdSendData = {
        mainSensorID: this.sessionstorageMainsensorData,
        fromDate: moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.dataFormGroup.value.todatetime)).format('YYYY-MM-DD')
      }
    }
    else {

      this.thresholdSendData = {
        mainSensorID: this.sessionstorageMainsensorData,
        fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.filterToDate)).add(1, 'd').format('YYYY-MM-DD')
      };
    }

    this.todoService.getAllByMainSensorIdAndMultipleDates(this.thresholdSendData).subscribe((response: any) => {

      if (this.dataSource !== null && this.dataSource !== undefined) {

        //水位の閾値超過をチェック
        for (let index = 0; index < this.dataSource.length; index++) {

          //警戒レベル3以上を抽出
          if (parseFloat(this.dataSource[index]['waterLevel']) >= this.waterLevelAlertLevel3) {

            const filteredData: ExtraThresholdData = {
              dateTime: this.dataSource[index]['dateTime'],
              sensor: "Water level",
              unit: "(m)",
              alertLevel: 3,
              thresholdValue: this.waterLevelAlertLevel3,
              value: this.dataSource[index]['waterLevel']
            }

            forExtraThreshold(filteredData);

            this.extraThresholdDataList.push(filteredData);
          }

          //警戒レベル2以上3未満を抽出
          else if (parseFloat(this.dataSource[index]['waterLevel']) >= this.waterLevelAlertLevel2
            && parseFloat(this.dataSource[index]['waterLevel']) < this.waterLevelAlertLevel3) {

            const filteredData: ExtraThresholdData = {
              dateTime: this.dataSource[index]['dateTime'],
              sensor: "Water level",
              unit: "(m)",
              alertLevel: 2,
              thresholdValue: this.waterLevelAlertLevel2,
              value: this.dataSource[index]['waterLevel']
            }

            forExtraThreshold(filteredData);

            this.extraThresholdDataList.push(filteredData);
          }

          //警戒レベル1以上2未満を抽出
          else if (parseFloat(this.dataSource[index]['waterLevel']) >= this.waterLevelAlertLevel1
            && parseFloat(this.dataSource[index]['waterLevel']) < this.waterLevelAlertLevel2) {

            const filteredData: ExtraThresholdData = {
              dateTime: this.dataSource[index]['dateTime'],
              sensor: "Water level",
              unit: "(m)",
              alertLevel: 1,
              thresholdValue: this.waterLevelAlertLevel1,
              value: this.dataSource[index]['waterLevel']
            }

            forExtraThreshold(filteredData);

            this.extraThresholdDataList.push(filteredData);
          }
        }

        //流量の閾値超過をチェック
        for (let index = 0; index < this.dataSource.length; index++) {

          //警戒レベル3以上を抽出
          if (parseFloat(this.dataSource[index]['waterQuantity']) >= this.waterQuantityAlertLevel3) {

            const filteredData: ExtraThresholdData = {
              dateTime: this.dataSource[index]['dateTime'],
              sensor: "Water quantity",
              unit: "(㎥/sec)",
              alertLevel: 3,
              thresholdValue: this.waterQuantityAlertLevel3,
              value: this.dataSource[index]['waterQuantity']
            }

            forExtraThreshold(filteredData);

            this.extraThresholdDataList.push(filteredData);
          }

          //警戒レベル2以上3未満を抽出
          else if (parseFloat(this.dataSource[index]['waterQuantity']) >= this.waterQuantityAlertLevel2
            && parseFloat(this.dataSource[index]['waterQuantity']) < this.waterQuantityAlertLevel3) {

            const filteredData: ExtraThresholdData = {
              dateTime: this.dataSource[index]['dateTime'],
              sensor: "Water quantity",
              unit: "(㎥/sec)",
              alertLevel: 2,
              thresholdValue: this.waterQuantityAlertLevel2,
              value: this.dataSource[index]['waterQuantity']
            }

            forExtraThreshold(filteredData);

            this.extraThresholdDataList.push(filteredData);
          }

          //警戒レベル1以上2未満を抽出
          else if (parseFloat(this.dataSource[index]['waterQuantity']) >= this.waterQuantityAlertLevel1
            && parseFloat(this.dataSource[index]['waterQuantity']) < this.waterQuantityAlertLevel2) {

            const filteredData: ExtraThresholdData = {
              dateTime: this.dataSource[index]['dateTime'],
              sensor: "Water quantity",
              unit: "(㎥/sec)",
              alertLevel: 1,
              thresholdValue: this.waterQuantityAlertLevel1,
              value: this.dataSource[index]['waterQuantity']
            }

            forExtraThreshold(filteredData);

            this.extraThresholdDataList.push(filteredData);
          }
        }
        this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.extraThresholdDataList);

        if(this.isCompleteProcess == false){
          this.isCompleteProcess = true;
        }
        else this.spinner.hide();

        //ページング処理
        if (this.tabIndex == 1) {
          this.thresholdPagesize = [10, 20, 30];
          this.thresholdDataLength = this.extraThresholdDataList.length;
          this.extraThresholdDataSource.paginator = this.thresholdPaginator;
        }
      }
    }, (err: any) => {
      this.spinner.hide();
    });
  }

  //閾値設定用データ取得＆加工イベント
  thresholdSetting(): void {

    this.todoService.getAllSensorTypes().subscribe((response: any) => {

      this.selectedSensorPropertyArray = response.sensorTypeResponses;

      this.todoService.getThresholdValuesByMainSensorId(this.sessionstorageMainsensorData).subscribe((response: any) => {

        this.thresholdAPIResponse = response;
        this.listview();
        const copyDataSource = _.cloneDeep(this.dataSource);

        this.thresholdAPIResponse.forEach((item: any) => {
          const data = this.selectedSensorPropertyArray.filter((x: any) => x.sensorTypeId === item.dataPointName);

          if (data.length > 0) {
            item.dataPointNameToDisplay = data[0].dataPointNameToDisplay;
          }
          else {
            item.dataPointNameToDisplay = '';
          }
        });

        this.thresholdCopy = _.cloneDeep(this.thresholdAPIResponse);

        const wqcorrectionValueData = this.thresholdAPIResponse.filter((x: any) => x.dataPointName == "S005");//流量の項目をフィルター

        if (wqcorrectionValueData.length > 0) {
          this.waterQuantityCorrectionValue = wqcorrectionValueData[0].correctionValue;
          this.waterQuantityAlertLevel1 = wqcorrectionValueData[0].alertLevel1;
          this.waterQuantityAlertLevel2 = wqcorrectionValueData[0].alertLevel2;
          this.waterQuantityAlertLevel3 = wqcorrectionValueData[0].alertLevel3;
        }

        const wlcorrectionValueData = this.thresholdAPIResponse.filter((x: any) => x.dataPointName == "S001");//水位の項目をフィルター

        if (wlcorrectionValueData.length > 0) {
          this.waterLevelCorrectionValue = wlcorrectionValueData[0].correctionValue;
          this.waterLevelAlertLevel1 = wlcorrectionValueData[0].alertLevel1;
          this.waterLevelAlertLevel2 = wlcorrectionValueData[0].alertLevel2;
          this.waterLevelAlertLevel3 = wlcorrectionValueData[0].alertLevel3;
        }

        if (this.thresholdAPIResponse.length > 0) {
          this.thresholdCorrectionWidth = this.thresholdAPIResponse[0].upperAndLowerLimitCorrectionWidth;
          this.thresholdDeadZone = this.thresholdAPIResponse[0].deadZone;
          this.thresholdRateOfChange = this.thresholdAPIResponse[0].rateOfChange;
          this.sensorMeasurementValue = this.thresholdAPIResponse[0].sensorMeasurementValue; /*testing code*/
          this.rateOfChangeValue = this.thresholdAPIResponse[0].rateOfChangeValue; /*testing code*/
          this.alarmDeadZoneValue = this.thresholdAPIResponse[0].deadZoneValue; /*testing code*/
        }

        if (this.isCancelorKeepBtnClicked == true) {
          // this.listview(); //20240514コメントアウト
          this.isCancelorKeepBtnClicked = false;
        }

        //todo 記述場所要検討
        this.todoService.getDataPointMappingByMainSensorId(this.currentSensorId).subscribe((response: any) => {

          var dataPointMappingRes = response;
          let decimal = 0;

          this.thresholdAPIResponse.forEach((item: any) => {

            let dataName = item.dataPointNameToDisplay; //センサー項目名
            item.isNotConnected = true; //センサー接続フラグ(初期表示は編集不可)
            this.isNotConnectedBatteryVoltage = true;
            const filteredItems = dataPointMappingRes.filter((x: any) => x.mappedTo == item.dataPointName);

            if (filteredItems.length >= 1) {

              if (dataName == "Water Quantity" || dataName == "Water Level") {  //流量・水位の場合は小数点以下3桁
                decimal = 3;
              }
              else if (dataName == "Saltwater Conductivity" || dataName == "Water Temperature" || dataName == "Battery Voltage") { //海水電気伝導度・水温・バッテリー電圧の場合は小数点以下2桁
                decimal = 2;
              }
              else {  //淡水電気伝導度・濁度の場合は小数点以下1桁
                decimal = 1;
              }

              //最大V値
              if (filteredItems[0].maxMeasurable != null && item.upperLimit != null && item.upperUpperLimit != null) {
                item.upperLimit = (filteredItems[0].maxMeasurable).toFixed(decimal);
                item.upperUpperLimit = (item.upperUpperLimit).toFixed(decimal);
              }
              else {  //センサーが未接続の場合は「ー」にする
                item.upperLimit = "-";
                item.upperUpperLimit = "-";
              }

              //最小V値
              if (filteredItems[0].minMeasurable != null && item.lowerLimit != null && item.lowerLowerLimit != null) {
                item.lowerLimit = (filteredItems[0].minMeasurable).toFixed(decimal);
                item.lowerLowerLimit = (item.lowerLowerLimit).toFixed(decimal);
              }
              else {  //センサーが未接続の場合は「ー」にする
                item.lowerLimit = "-";
                item.lowerLowerLimit = "-";
              }
              // //最大V値
              // if (filteredItems[0].maxVValue != null && item.upperLimit != null && item.upperUpperLimit != null) {
              //   item.upperLimit = (filteredItems[0].maxVValue).toFixed(decimal);
              //   item.upperUpperLimit = (item.upperUpperLimit).toFixed(decimal);
              // }
              // else {  //センサーが未接続の場合は「ー」にする
              //   item.upperLimit = "-";
              //   item.upperUpperLimit = "-";
              // }

              // //最小V値
              // if (filteredItems[0].minVValue != null && item.lowerLimit != null && item.lowerLowerLimit != null) {
              //   item.lowerLimit = (filteredItems[0].minVValue).toFixed(decimal);
              //   item.lowerLowerLimit = (item.lowerLowerLimit).toFixed(decimal);
              // }
              // else {  //センサーが未接続の場合は「ー」にする
              //   item.lowerLimit = "-";
              //   item.lowerLowerLimit = "-";
              // }

              //淡水電気伝導度の場合は上限・上上限を「ー」にする
              if (dataName == "Freshwater Conductivity") {
                this.freshwaterConductivityUpperUpperVal = _.cloneDeep(item.upperUpperLimit);
                this.freshwaterConductivityUpperVal = _.cloneDeep(item.upperLimit);
                item.upperUpperLimit = "-";
                item.upperLimit = "-";
              }

              //海水電気伝導度の場合は下限・下下限を「ー」にする
              if (dataName == "Saltwater Conductivity") {
                this.seawaterConductivityLowerLowerVal = _.cloneDeep(item.lowerLowerLimit);
                this.seawaterConductivityLowerVal = _.cloneDeep(item.lowerLimit);
                item.lowerLimit = "-";
                item.lowerLowerLimit = "-";
              }

              if (dataName == "Battery Voltage") {
                this.defaultCapacity = _.cloneDeep(filteredItems[0].maxMeasurable);
                this.keptBatteryVoltageData = dataPointMappingRes.filter((x: any) => x.mappedTo == "S003");
              }
            }
            //センサーが未接続の場合は「ー」にする
            else {
              item.upperLimit = "-";
              item.lowerLimit = "-";
              item.upperUpperLimit = "-";
              item.lowerLowerLimit = "-";
            }
          });
        });
      });
    });
  }

  //削除予定
  callThresholdFunc(): void {

    this.todoService.getThresholdValuesByMainSensorId(this.sessionstorageMainsensorData).subscribe((response: any) => {
      this.upperLower = 0;

      if (response.length > 0) {
        this.loading = false;
        this.updateThresholdArray = response[0];
        this.updateThresholdArray1 = response;
        this.presentValueArray = (response[0].presentValue.toString()).split('-');
        this.deadZoneArray = (response[0].deadZone.toString()).split('-');
        this.upperUpper = response[0].upperUpperLimit;
        this.upper = 0;
        this.upper = response[0].upperLimit;
        this.lower = response[0].lowerLimit;
        this.lowerLower = response[0].lowerLowerLimit;
        this.changeOfRate = response[0].rateOfChange;
        this.presentMin = this.presentValueArray[0];
        this.presentMax = this.presentValueArray[1];
        this.alarmDeadMin = this.deadZoneArray[0];
        this.alarmDeadMax = this.deadZoneArray[1];
        this.upperLower = response[0].upperAndLowerLimitCorrectionWidth;
        this.dataPointName = response[0].dataPointName;
        this.sensorPrimaryId = response[0].id;
      }
      else if (response.length === 0) {
      }
    },
    error => {

      this.snotifyService.error(this.translate.instant('Threshold Settings not available'), '', {
        timeout: 7000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true
      });
    });
  }

  //閾値設定画面を編集モードにするイベント
  setEditThresholdDataMode(): void {
    this.viewThresholdMode = false;
    this.editThresholdMode = true;

    this.todoService.getDataPointMappingByMainSensorId(this.currentSensorId).subscribe((response: any) => {

      var dataPointMappingRes = response;

      this.thresholdAPIResponse.forEach((item: any) => {
        let dataName = item.dataPointNameToDisplay; //センサー項目名
        const filteredItems = dataPointMappingRes.filter((x: any) => x.mappedTo == item.dataPointName);

        //センサー接続フラグ
        if (filteredItems.length >= 1) {
          item.isNotConnected = false;

          //バッテリー電圧だけ別で接続フラグを持つ
          if (dataName == "Battery Voltage") {
            this.isNotConnectedBatteryVoltage = false;
          }
        }
        else {
          item.isNotConnected = true;

          //バッテリー電圧だけ別で接続フラグを持つ
          if (dataName == "Battery Voltage") {
            this.isNotConnectedBatteryVoltage = true;
          }
        }
      });
    });

    this.disableThresholdEdit = false;
  }

  //閾値設定の編集キャンセルイベント
  editThresholdDataCancel(): void {
    this.viewThresholdMode = true;
    this.editThresholdMode = false;
    this.isCancelorKeepBtnClicked = true;
    this.thresholdSetting();

    if (typeof this.batteryVoltageLowerLowerVal === 'string') {
      let convertedVal = parseFloat(this.batteryVoltageLowerLowerVal).toFixed(3);
      (document.getElementById(`batteryVoltageLowerLowerVal`) as HTMLInputElement).value = convertedVal;
    }
    else {
      let val = (this.batteryVoltageLowerLowerVal).toFixed(3);
      (document.getElementById(`batteryVoltageLowerLowerVal`) as HTMLInputElement).value = val;
    }

    this.disableThresholdEdit = true;
  }

  //閾値設定の編集データ保存と再取得イベント
  keepEditedThresholdData(): void {
    this.viewThresholdMode = true;
    this.editThresholdMode = false;
    let statusCode = 0;

    //値が一つでも入っていなかったら保存できない
    for (let i = 0; i < this.thresholdSettingDataSource.length; i++) {

      if ((document.getElementById(`upperUpperLimit-${i}`) as HTMLInputElement).value === null ||
        (document.getElementById(`upperUpperLimit-${i}`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`upperLimit-${i}`) as HTMLInputElement).value === null ||
        (document.getElementById(`upperLimit-${i}`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`lowerLowerLimit-${i}`) as HTMLInputElement).value === null ||
        (document.getElementById(`lowerLowerLimit-${i}`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`lowerLimit-${i}`) as HTMLInputElement).value === null ||
        (document.getElementById(`lowerLimit-${i}`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`waterQuantityAlertLevel1`) as HTMLInputElement).value === null ||
        (document.getElementById(`waterQuantityAlertLevel1`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`waterQuantityAlertLevel2`) as HTMLInputElement).value === null ||
        (document.getElementById(`waterQuantityAlertLevel2`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`waterQuantityAlertLevel3`) as HTMLInputElement).value === null ||
        (document.getElementById(`waterQuantityAlertLevel3`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`waterLevelAlertLevel1`) as HTMLInputElement).value === null ||
        (document.getElementById(`waterLevelAlertLevel1`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`waterLevelAlertLevel2`) as HTMLInputElement).value === null ||
        (document.getElementById(`waterLevelAlertLevel2`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`waterLevelAlertLevel3`) as HTMLInputElement).value === null ||
        (document.getElementById(`waterLevelAlertLevel3`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`waterQuantityCorrectionValue`) as HTMLInputElement).value === null ||
        (document.getElementById(`waterQuantityCorrectionValue`) as HTMLInputElement).value === undefined ||
        (document.getElementById(`waterLevelCorrectionValue`) as HTMLInputElement).value === null ||
        (document.getElementById(`waterLevelCorrectionValue`) as HTMLInputElement).value === undefined) {

        this.snotifyService.error(this.translate.instant('Input cannot be empty'), '', {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      }
      else {
        this.rateOfChangeValue = 0;
        this.displayValue = 0;
        this.alarmSetValueUpper = 0;
        this.alarmSetValueUpper = 0;
        this.alarmSetValueLower = 0;

        //計算後の変化率(本来こちらを使用)
        this.rateOfChangeValue = ((this.thresholdSettingDataSource[i].upperLimit - this.thresholdSettingDataSource[i].lowerLimit) / 100) * (this.thresholdSettingDataSource[i].rateOfChange / 100);
        //上限幅補正値(使用しない)
        this.displayValue = this.thresholdSettingDataSource[i].ullcwDisplayValue + (this.thresholdSettingDataSource[i].rateOfChange * ((this.thresholdSettingDataSource[i].upperAndLowerLimitCorrectionWidth / 100) * 100));

        //アラートを出す上限・下限を決める(計算に誤りあり)
        this.alarmSetValueUpper = this.thresholdSettingDataSource[i].upperUpperLimit + (this.thresholdSettingDataSource[i].rateOfChange * ((this.thresholdSettingDataSource[i].deadZone / 100) * 100));
        this.alarmSetValueLower = this.thresholdSettingDataSource[i].upperUpperLimit - (this.thresholdSettingDataSource[i].rateOfChange * ((this.thresholdSettingDataSource[i].deadZone / 100) * 100));

        if (this.thresholdSettingDataSource[i].dataPointName == "S005") {
          this.correctionValue = parseFloat((document.getElementById(`waterQuantityCorrectionValue`) as HTMLInputElement).value);
          this.alertLevel1 = parseFloat((document.getElementById(`waterQuantityAlertLevel1`) as HTMLInputElement).value);
          this.alertLevel2 = parseFloat((document.getElementById(`waterQuantityAlertLevel2`) as HTMLInputElement).value);
          this.alertLevel3 = parseFloat((document.getElementById(`waterQuantityAlertLevel3`) as HTMLInputElement).value);
        }
        else if (this.thresholdSettingDataSource[i].dataPointName == "S001") {
          this.correctionValue = parseFloat((document.getElementById(`waterLevelCorrectionValue`) as HTMLInputElement).value);
          this.alertLevel1 = parseFloat((document.getElementById(`waterLevelAlertLevel1`) as HTMLInputElement).value);
          this.alertLevel2 = parseFloat((document.getElementById(`waterLevelAlertLevel2`) as HTMLInputElement).value);
          this.alertLevel3 = parseFloat((document.getElementById(`waterLevelAlertLevel3`) as HTMLInputElement).value);
        }
        else {
          this.correctionValue = null;
          this.alertLevel1 = null;
          this.alertLevel2 = null;
          this.alertLevel3 = null;
        }

        if (this.thresholdSettingDataSource[i].dataPointNameToDisplay == "Freshwater Conductivity") {

          this.thresholdEditedData = {
            mainSensorId: this.thresholdSettingDataSource[i].mainSensorId,
            dataPointName: this.thresholdSettingDataSource[i].dataPointName,
            presentValue: this.thresholdSettingDataSource[i].presentValue,
            unit: 'NA',
            monitoringLevel: this.thresholdSettingDataSource[i].monitoringLevel,
            upperUpperLimit: null,
            upperLimit: null,
            lowerLowerLimit: (document.getElementById(`lowerLowerLimit-${i}`) as HTMLInputElement).value,
            lowerLimit: (document.getElementById(`lowerLimit-${i}`) as HTMLInputElement).value,
            alertLevel1: this.alertLevel1,
            alertLevel2: this.alertLevel2,
            alertLevel3: this.alertLevel3,
            CorrectionValue: this.correctionValue,
            rateOfChange: 24,
            rateOfChangeValue: 0,
            ullcwDisplayValue: 27673,
            deadZoneUpperValue: -4860,
            deadZoneLowerValue: -5340,
            upperAndLowerLimitCorrectionWidth: 6,//CorrectionValueと入れ替え
            deadZone: 10, //アラーム不感帯は画面では使用しない
            location: 'Tokyo'
          };

          if((document.getElementById(`lowerLowerLimit-${i}`) as HTMLInputElement).value == "-"){
            this.thresholdEditedData.lowerLowerLimit = null;
          }
          else{
            this.thresholdEditedData.lowerLowerLimit = parseFloat(this.thresholdEditedData.lowerLowerLimit);
          }

          if((document.getElementById(`lowerLimit-${i}`) as HTMLInputElement).value == "-"){
            this.thresholdEditedData.lowerLimit = null;
          }
          else{
            this.thresholdEditedData.lowerLimit = parseFloat(this.thresholdEditedData.lowerLimit);
          }

          if(this.thresholdEditedData.presentValue == "-"){
            this.thresholdEditedData.presentValue = 0;
          }
          else{
            this.thresholdEditedData.presentValue = parseFloat(this.thresholdEditedData.presentValue);
          }
        }

        else if (this.thresholdSettingDataSource[i].dataPointNameToDisplay == "Saltwater Conductivity") {

          this.thresholdEditedData = {
            mainSensorId: this.thresholdSettingDataSource[i].mainSensorId,
            dataPointName: this.thresholdSettingDataSource[i].dataPointName,
            presentValue: this.thresholdSettingDataSource[i].presentValue,
            unit: 'NA',
            monitoringLevel: this.thresholdSettingDataSource[i].monitoringLevel,
            upperUpperLimit: (document.getElementById(`upperUpperLimit-${i}`) as HTMLInputElement).value,
            upperLimit: (document.getElementById(`upperLimit-${i}`) as HTMLInputElement).value,
            lowerLowerLimit: null,
            lowerLimit: null,
            alertLevel1: this.alertLevel1,
            alertLevel2: this.alertLevel2,
            alertLevel3: this.alertLevel3,
            CorrectionValue: this.correctionValue,
            rateOfChange: 0,
            rateOfChangeValue: 0,
            ullcwDisplayValue: 26809,
            deadZoneUpperValue: 305,
            deadZoneLowerValue: -175,
            upperAndLowerLimitCorrectionWidth: this.thresholdSettingDataSource[i].upperAndLowerLimitCorrectionWidth,//CorrectionValueと入れ替え
            deadZone: this.thresholdAPIResponse[i].deadZone, //アラーム不感帯は画面では使用しない
            location: 'Tokyo'
          };

          if((document.getElementById(`upperUpperLimit-${i}`) as HTMLInputElement).value == "-"){
            this.thresholdEditedData.upperUpperLimit = null;
          }
          else{
            this.thresholdEditedData.upperUpperLimit = parseFloat(this.thresholdEditedData.upperUpperLimit);
          }

          if((document.getElementById(`upperLimit-${i}`) as HTMLInputElement).value == "-"){
            this.thresholdEditedData.upperLimit = null;
          }
          else{
            this.thresholdEditedData.upperLimit = parseFloat(this.thresholdEditedData.upperLimit);
          }

          if(this.thresholdEditedData.presentValue == "-"){
            this.thresholdEditedData.presentValue = 0;
          }
          else{
            this.thresholdEditedData.presentValue = parseFloat(this.thresholdEditedData.presentValue);
          }
        }

        else {

          this.thresholdEditedData = {
            mainSensorId: this.thresholdSettingDataSource[i].mainSensorId,
            dataPointName: this.thresholdSettingDataSource[i].dataPointName,
            presentValue: this.thresholdSettingDataSource[i].presentValue,
            unit: 'NA',
            monitoringLevel: this.thresholdSettingDataSource[i].monitoringLevel,
            upperUpperLimit: (document.getElementById(`upperUpperLimit-${i}`) as HTMLInputElement).value,
            upperLimit: (document.getElementById(`upperLimit-${i}`) as HTMLInputElement).value,
            lowerLowerLimit: (document.getElementById(`lowerLowerLimit-${i}`) as HTMLInputElement).value,
            lowerLimit: (document.getElementById(`lowerLimit-${i}`) as HTMLInputElement).value,
            alertLevel1: this.alertLevel1,
            alertLevel2: this.alertLevel2,
            alertLevel3: this.alertLevel3,
            CorrectionValue: this.correctionValue,
            rateOfChange: this.thresholdSettingDataSource[i].rateOfChange,
            rateOfChangeValue: 0,
            ullcwDisplayValue: this.displayValue,
            deadZoneUpperValue: 0,
            deadZoneLowerValue: 0,
            upperAndLowerLimitCorrectionWidth: this.thresholdSettingDataSource[i].upperAndLowerLimitCorrectionWidth,//CorrectionValueと入れ替え
            deadZone: this.thresholdAPIResponse[i].deadZone, //アラーム不感帯は画面では使用しない
            location: 'Tokyo'
          };

          if((document.getElementById(`upperUpperLimit-${i}`) as HTMLInputElement).value == "-"){
            this.thresholdEditedData.upperUpperLimit = null;
          }
          else{
            this.thresholdEditedData.upperUpperLimit = parseFloat(this.thresholdEditedData.upperUpperLimit);
          }

          if((document.getElementById(`upperLimit-${i}`) as HTMLInputElement).value == "-"){
            this.thresholdEditedData.upperLimit = null;
          }
          else{
            this.thresholdEditedData.upperLimit = parseFloat(this.thresholdEditedData.upperLimit);
          }

          if((document.getElementById(`lowerLowerLimit-${i}`) as HTMLInputElement).value == "-"){
            this.thresholdEditedData.lowerLowerLimit = null;
          }
          else{
            this.thresholdEditedData.lowerLowerLimit = parseFloat(this.thresholdEditedData.lowerLowerLimit);
          }

          if((document.getElementById(`lowerLimit-${i}`) as HTMLInputElement).value == "-"){
            this.thresholdEditedData.lowerLimit = null;
          }
          else{
            this.thresholdEditedData.lowerLimit = parseFloat(this.thresholdEditedData.lowerLimit);
          }

          if(this.thresholdEditedData.presentValue == "-"){
            this.thresholdEditedData.presentValue = 0;
          }
          else{
            this.thresholdEditedData.presentValue = parseFloat(this.thresholdEditedData.presentValue);
          }
        }

        this.todoService.addOrUpdateThresholdValues(this.thresholdSettingDataSource[i].id, this.thresholdEditedData).subscribe((response: any) => {
          response.httpStatusCode;

          if (response.httpStatusCode === 200) {

            this.snotifyService.success(this.translate.instant('Data saved successfully'), '', {
              timeout: 2000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            });
          }
          else {
            console.log(response.httpStatusCode);
          }

          //全てのデータを保存した後に再読み込みを行う
          if (i == this.thresholdSettingDataSource.length - 1) {
            this.isCancelorKeepBtnClicked = true;
            this.thresholdValueCustom();
          }
        },
        (error: any) => {
          this.statusBoolean = false;
        });
      }
    }

    this.disableThresholdEdit = true;
  }

  //周期設定用データ取得イベント
  cycleSetting(): void {

    this.todoService.getMaintenanceByMainSensorId(this.sessionstorageMainsensorData).subscribe((response: any) => {

      if (response.length > 0) {

        for (let i = 0; i < response.length; i++) {
          this.recordingCycle = response[i].recordingCycle;
          this.recordingCycleInitial = response[i].recordingCycleInitial;
          this.transmissionCycle = response[i].transmissionCycle;
          this.transmissionCycleInitial = response[i].transmissionCycleInitial;
          this.sensorPrimaryId = response[i].id;
        }
      }
      else if (response.length === 0) {

        this.snotifyService.error(this.translate.instant('Maintenance Setting not available'), '', {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      }
    },
    error => {
      this.loadingSetting = false;
    });
  }

  //周期設定画面を編集モードにするイベント
  setEditCycleDataMode(): void {
    this.disableCycleEdit = false;
    this.viewSensorCycleMode = false;
    this.editSensorCycleMode = true;
  }

  //周期設定の編集キャンセルイベント
  editCycleDataCancel(): void {
    this.disableCycleEdit = true;
    this.viewSensorCycleMode = true;
    this.editSensorCycleMode = false;
  }

  //周期設定の編集データ保存と再取得イベント
  keepEditedCycleData(): void {
    var now = new Date();
    this.disableCycleEdit = true;
    this.viewSensorCycleMode = true;
    this.editSensorCycleMode = false;

    const updateData = {
      mainSensorId: this.sessionstorageMainsensorData,
      number: 0,
      name: "S002",
      recordingCycle: (document.getElementById(`recordingCycle`) as HTMLInputElement).value,
      transmissionCycle: (document.getElementById(`transmissionCycle`) as HTMLInputElement).value,
      recordingCycleInitial: (document.getElementById(`recordingCycleInitial`) as HTMLInputElement).value,
      transmissionCycleInitial: (document.getElementById(`transmissionCycleInitial`) as HTMLInputElement).value,
      mode: 'auto',
      updatedDate: now,
      alertLevel: 5,
    }

    this.todoService.updateMaintenance(this.sensorPrimaryId, updateData).subscribe((response: any) => {

      this.snotifyService.success(this.translate.instant('Values set to initial successfully'), '', {
        timeout: 7000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true
      });

      if(response.httpStatusCode == 200) window.alert("データの更新が完了しました。");
      else window.alert("データの更新に失敗しました。");

      this.cycleSetting();
    });
    // this.cycleSetting();
  }

  //各周期設定をデフォルト値に戻すイベント
  setDefaultCycleData(): void {
    this.disableCycleEdit = true;
    this.recordingCycle = 10;
    this.recordingCycleInitial = 10;
    this.transmissionCycle = 10;
    this.transmissionCycleInitial = 10;
    this.viewSensorCycleMode = true;
    this.editSensorCycleMode = false;
  }

  //機器異常用データ取得＆加工部分
  machineIrregular(): void {
    const today = new Date();
    this.machineIrregularDataList.length = 0;

    if (this.searchMode == true) {

      this.machineIrregularSendData = {
        mainSensorId: this.sessionstorageMainsensorData,
        fromDate: moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.dataFormGroup.value.todatetime)).format('YYYY-MM-DD')
      }
    }

    //日付が選択されていないときの送信データ
    else {

      this.machineIrregularSendData = {
        mainSensorId: this.sessionstorageMainsensorData,
        fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.filterToDate)).add(1, 'd').format('YYYY-MM-DD')
      };
    }

    this.todoService.getAlertsByFilterDate(this.machineIrregularSendData).subscribe((response: any) => {
      this.allAlertData = [];

      //絞り込みなしの場合はデフォルトの表示日時のデータだけになるよう抽出する
      if(this.searchMode == false){

        for (let index = 0; index < response.alertResponses.length; index++) {
          var tmpVal: string;
          let date = new Date(response.alertResponses[index].dataTimeStamp * 1000);
          tmpVal = moment(new Date(date)).format("YYYY-MM-DD HH:mm");

          if (tmpVal >= this.filterFromDate) {

            if (tmpVal <= this.filterToDate) {
              this.allAlertData.push(response.alertResponses[index]);
            }
          }
        }
      }
      else{

        for (let index = 0; index < response.alertResponses.length; index++) {
          var tmpVal: string;
          let date = new Date(response.alertResponses[index].dataTimeStamp * 1000);
          tmpVal = moment(new Date(date)).format("YYYY-MM-DD HH:mm");
          let tmpFrom = this.machineIrregularSendData.fromDate;
          let tmpTo = moment.utc(new Date(this.machineIrregularSendData.toDate)).add(1, 'd').format("YYYY-MM-DD HH:mm");

          if (tmpVal >= tmpFrom) {

            if (tmpVal < tmpTo) {
              this.allAlertData.push(response.alertResponses[index]);
            }
          }
        }
      }

      for (let totalIndex = 0; totalIndex < this.allAlertData.length; totalIndex++) {

        let date = new Date(this.allAlertData[totalIndex].dataTimeStamp * 1000);
        let displayedDate = moment(new Date(date)).format("YYYY-MM-DD HH:mm");
        let dataUnit = "";

        //吉原対応_項番123
        switch (this.allAlertData[totalIndex]['dataName']){

          case 'turbidity':
            dataUnit = "(FTU)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(1);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(1);
            }

            break;

          case 'waterLevel':
            dataUnit = "(m)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(3);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(3);
            }

            break;

          case 'waterQuantity':
            dataUnit = "(㎥/sec)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(3);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(3);
            }

            break;

          case 'freshwaterConductivity':
            dataUnit = "(μS/cm)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(1);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(1);
            }

            break;

          case 'seawaterConductivity':
            dataUnit = "(mS/cm)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(2);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(2);
            }

            break;

          case 'waterTemperature':
            dataUnit = "(℃)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(2);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(2);
            }

            break;

          case 'batteryVoltage':
            dataUnit = "(V)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(3);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(3);
            }

            break;

          default:

            break;
        }

        const alertInfo: MachineIrregularData = {
          Datetime: displayedDate,
          Sensor: this.allAlertData[totalIndex]['dataNameToDisplay'],
          Unit: dataUnit,
          ThresholdValue: this.irregularThresholdValue,
          Value: this.irregularValue,
          Detail: this.allAlertData[totalIndex]['status'],
        }

        //データのnullチェック(nullの場合は'-'にする)
        alertInfo.Value = isNull(alertInfo.Value);

        this.machineIrregularDataList.push(alertInfo);
        //20231116
      }
      this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(this.machineIrregularDataList);

      if(this.isCompleteProcess == false){
        this.isCompleteProcess = true;
      }
      else this.spinner.hide();

      //ページング処理
      if (this.tabIndex == 2) {
        this.machineIrregularPagesize = [10, 20, 30];
        this.machineIrregularDataLength = this.machineIrregularDataList.length;
        this.machineIrregularDataSource.paginator = this.machineIrregularPaginator;
      }
    }
    )
  }

  //バッテリー管理のデータの保存イベント
  thresholdValueCustom(): void {

    //バッテリー管理の性能値を「DataPointMapping」テーブルに保存する
    const dataPointMappingData = {
      MainSensorId: this.keptBatteryVoltageData[0].mainSensorId,
      DataPointName: this.keptBatteryVoltageData[0].dataPointName,
      MappedTo: this.keptBatteryVoltageData[0].mappedTo,
      MaxMeasurable: parseFloat((document.getElementById(`Capacity`) as HTMLInputElement).value),
      MinMeasurable: 0,
      MaxVVal: this.keptBatteryVoltageData[0].maxVValue,
      MinVVal: this.keptBatteryVoltageData[0].minVValue,
      Rs232c: "",
      OutputVal: "",
      Capacity: ""
    };

    const id = this.keptBatteryVoltageData[0].id;

    this.todoService.updateDataPointMapping(id, dataPointMappingData).subscribe((response: any) => {

      //バッテリー管理の下下限を「ThresholdValues」テーブルに保存する
      const thresholdData = {
        mainSensorId: this.batteryVoltageThreshold.mainSensorId,
        dataPointName: this.batteryVoltageThreshold.dataPointName,
        presentValue: this.batteryVoltageThreshold.presentValue,
        unit: 'NA',
        monitoringLevel: this.batteryVoltageThreshold.monitoringLevel,
        upperUpperLimit: (document.getElementById(`batteryVoltageLowerLowerVal`) as HTMLInputElement).value,
        upperLimit: (document.getElementById(`batteryVoltageLowerLowerVal`) as HTMLInputElement).value,
        lowerLowerLimit: (document.getElementById(`batteryVoltageLowerLowerVal`) as HTMLInputElement).value,
        lowerLimit: (document.getElementById(`batteryVoltageLowerLowerVal`) as HTMLInputElement).value,
        alertLevel1: this.alertLevel1,
        alertLevel2: this.alertLevel2,
        alertLevel3: this.alertLevel3,
        CorrectionValue: this.correctionValue,
        rateOfChange: 0,
        rateOfChangeValue: 0,
        ullcwDisplayValue: 0,
        deadZoneUpperValue: 0,
        deadZoneLowerValue: 0,
        upperAndLowerLimitCorrectionWidth: 0,
        deadZone: 0,
        location: 'Tokyo'
      }

      this.todoService.addOrUpdateThresholdValues(this.batteryVoltageThreshold.id, thresholdData).subscribe((response: any) => {
        this.thresholdSetting();

        if(response.httpStatusCode == 200) window.alert("データの更新が完了しました。");
        else window.alert("データの更新に失敗しました。");
      });
    });

    // //バッテリー管理の下下限を「ThresholdValues」テーブルに保存する
    // const thresholdData = {
    //   mainSensorId: this.batteryVoltageThreshold.mainSensorId,
    //   dataPointName: this.batteryVoltageThreshold.dataPointName,
    //   presentValue: this.batteryVoltageThreshold.presentValue,
    //   unit: 'NA',
    //   monitoringLevel: this.batteryVoltageThreshold.monitoringLevel,
    //   upperUpperLimit: (document.getElementById(`batteryVoltageLowerLowerVal`) as HTMLInputElement).value,
    //   upperLimit: (document.getElementById(`batteryVoltageLowerLowerVal`) as HTMLInputElement).value,
    //   lowerLowerLimit: (document.getElementById(`batteryVoltageLowerLowerVal`) as HTMLInputElement).value,
    //   lowerLimit: (document.getElementById(`batteryVoltageLowerLowerVal`) as HTMLInputElement).value,
    //   alertLevel1: this.alertLevel1,
    //   alertLevel2: this.alertLevel2,
    //   alertLevel3: this.alertLevel3,
    //   CorrectionValue: this.correctionValue,
    //   rateOfChange: this.batteryVoltageThreshold.rateOfChange,
    //   rateOfChangeValue: this.rateOfChangeValue,
    //   ullcwDisplayValue: this.displayValue,
    //   deadZoneUpperValue: this.alarmSetValueUpper,
    //   deadZoneLowerValue: this.alarmSetValueLower,
    //   upperAndLowerLimitCorrectionWidth: this.batteryVoltageThreshold.upperAndLowerLimitCorrectionWidth,
    //   deadZone: this.batteryVoltageThreshold.deadZone,
    //   location: 'Tokyo'
    // }

    // this.todoService.addOrUpdateThresholdValues(this.batteryVoltageThreshold.id, thresholdData).subscribe((response: any) => {
    //   this.thresholdSetting();
    // });
  };

  //CSV出力用ダイアログを開く
  selectOutputDate(): void {
    // const details = document.querySelector<HTMLDetailsElement>('.details-dialog');
    // const summary = document.querySelector<HTMLElement>('.exportbtn');
    // summary?.addEventListener("click", (event) => {
    //   event.preventDefault();
    //   //(document.getElementById(`csvSummary`) as HTMLDetailsElement).open = true;
    // });
    // const isSummaryClosed = (document.getElementById(`csvSummary`) as HTMLDetailsElement).open;
    // (document.getElementById(`csvSummary`) as HTMLDetailsElement).open = true;

    document.querySelector<HTMLElement>('.exportbtn')?.addEventListener("click", function(event){
      (document.getElementById(`csvSummary`) as HTMLDetailsElement).open = true;
      event.preventDefault();
    }, false);
  }

  //CSV出力用ダイアログを閉じる
  closeSelectOutputDate(): void {
    const isSummaryClosed = (document.getElementById(`csvSummary`) as HTMLDetailsElement).closest;
    (document.getElementById(`csvSummary`) as HTMLDetailsElement).open = false;
  }

  //CSV出力を実際に行う
  OutputCSVData(): void {
    (document.getElementById(`csvSummary`) as HTMLDetailsElement).open = false;
    const fd = (document.getElementById(`csvdt1`) as HTMLInputElement).value;
    const td = (document.getElementById(`csvdt2`) as HTMLInputElement).value;
    const today = new Date();
    let fileName = "";
    let lang = sessionStorage.getItem("lang");

    if(lang == null || lang == "" || lang == undefined){
      lang = this.cookieService.get('language');
    }

    if (this.tabIndex == 0) {

      if(lang == "jp"){
        fileName = "データ一覧";
      }
      else if(lang == "en"){
        fileName = "ListView";
      }
      //fileName = "ListView";
    }
    else if (this.tabIndex == 1) {

      if(lang == "jp"){
        fileName = "閾値超過";
      }
      else if(lang == "en"){
        fileName = "Extrathreshold";
      }
      //fileName = "ExtraThreshold";
    }
    else {

      if(lang == "jp"){
        fileName = "機器異常";
      }
      else if(lang == "en"){
        fileName = "Machineirregular";
      }
      //fileName = "MachineIrregular"
    }

    this.options = {
      filename: `${fileName} - ${today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2)
        + '-' + ('0' + today.getHours()).slice(-2) + ('0' + today.getMinutes()).slice(-2) + ('0' + today.getSeconds()).slice(-2)}`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false, //ヘッダーを自動でつけてくれる(リリース時はtrue)
    };

    //流量の各警戒レベルの値
    let csvWaterQuantityAlertLevel1 = 0;
    let csvWaterQuantityAlertLevel2 = 0;
    let csvWaterQuantityAlertLevel3 = 0;

    //水位の各警戒レベルの値
    let csvWaterLevelAlertLevel1 = 0;
    let csvWaterLevelAlertLevel2 = 0;
    let csvWaterLevelAlertLevel3 = 0;

    //↓↓↓ 20231004修正
    if (this.tabIndex == 0){
      //データ一覧を出力する
      this.makeListviewCsv(fd, td);
    }
    else if (this.tabIndex == 1){
      //閾値超過を出力する
      // ↓Mod 2023/12/22 課題No.90
      // this.makeThresholdCsv(fd, td);
      this.exportThresholdAlert(fd, td);
      // ↑Mod 2023/12/22 課題No.90
    }
    else{
      //機器異常を出力する
      // ↓Mod 2023/12/22 課題No.90
      // this.makeIrregularCsv(fd, td);
      this.exportAlert(fd, td);
      // ↑Mod 2023/12/22 課題No.90
    }
    //↑↑↑ 20231004修正

    return;

    // //以下修正前ソース(リリース時はこちらを使用)
    // //日付が選択されていた場合
    // if (fd !== "" && td !== "") {

    //   const listSendData = {
    //     mainSensorID: this.sessionstorageMainsensorData,
    //     fromDate: moment(new Date(fd)).format('YYYY-MM-DD'),
    //     toDate: moment(new Date(td)).add(1, 'd').format('YYYY-MM-DD')
    //   };

    //   this.todoService.getAllByMainSensorIdAndMultipleDates(listSendData).subscribe((response: any) => {
    //     const csvDataSource = response.waterFlowResponse;

    //     //データ一覧
    //     if (this.tabIndex == 0) {
    //       //データ一覧をCSV出力する
    //       //データソース
    //       this.csvListView(csvDataSource);
    //     }

    //     //閾値超過
    //     else if (this.tabIndex == 1) {

    //       //this.makeThresholdCsv(fd, td); //リリース時コメントアウト(下が修正前の記述なのでリリース時は下を使用)
    //       this.todoService.getThresholdValuesByMainSensorId(this.sessionstorageMainsensorData).subscribe((thresholdResponse: any) => {

    //         const waterQuantityThresholdVal = thresholdResponse.filter((x: any) => x.dataPointName == "S005");//流量の項目をフィルター

    //         if (waterQuantityThresholdVal.length > 0) {
    //           csvWaterQuantityAlertLevel1 = waterQuantityThresholdVal[0].alertLevel1;
    //           csvWaterQuantityAlertLevel2 = waterQuantityThresholdVal[0].alertLevel2;
    //           csvWaterQuantityAlertLevel3 = waterQuantityThresholdVal[0].alertLevel3;
    //         }

    //         const waterLevelThresholdVal = this.thresholdAPIResponse.filter((x: any) => x.dataPointName == "S001");//水位の項目をフィルター

    //         if (waterLevelThresholdVal.length > 0) {
    //           csvWaterLevelAlertLevel1 = waterLevelThresholdVal[0].alertLevel1;
    //           csvWaterLevelAlertLevel2 = waterLevelThresholdVal[0].alertLevel2;
    //           csvWaterLevelAlertLevel3 = waterLevelThresholdVal[0].alertLevel3;
    //         }

    //         //CSV用閾値超過判定
    //         //データソース、流量警戒レベル1, 2, 3、水位警戒レベル1, 2, 3
    //         this.csvThresholdCheck(csvDataSource, csvWaterQuantityAlertLevel1, csvWaterQuantityAlertLevel2,
    //           csvWaterQuantityAlertLevel3, csvWaterLevelAlertLevel1, csvWaterLevelAlertLevel2, csvWaterLevelAlertLevel3);
    //       })
    //     }

    //     //機器異常
    //     else {

    //       const machineIrregularSendData = {
    //         mainSensorId: this.sessionstorageMainsensorData,
    //         fromDate: moment(new Date(fd)).format('YYYY-MM-DD'),
    //         toDate: moment(new Date(td)).add(1, 'd').format('YYYY-MM-DD')
    //       };

    //       this.todoService.getAlertsByFilterDate(machineIrregularSendData).subscribe((machineIrregularResponse: any) => {

    //         const allAlertArray = machineIrregularResponse.alertResponses;

    //         //CSV用機器異常判定
    //         //データソース、全アラートデータ
    //         this.csvMachineIrregularCheck(csvDataSource, allAlertArray);
    //       })
    //     }
    //   })
    // }

    // //そうでない場合(全選択)
    // else {

    //   const listSendData = {
    //     mainSensorID: this.sessionstorageMainsensorData,
    //     fromDate: moment(new Date("2022-01-01")).format('YYYY-MM-DD'),
    //     toDate: moment(new Date(today)).add(1, 'd').format('YYYY-MM-DD')
    //   };

    //   this.todoService.getAllByMainSensorIdAndMultipleDates(listSendData).subscribe((response: any) => {

    //     const csvDataSource = response;

    //     //データ一覧
    //     if (this.tabIndex == 0) {
    //       //データ一覧をCSV出力する
    //       //データソース
    //       this.csvListView(csvDataSource);
    //     }

    //     //閾値超過
    //     else if (this.tabIndex == 1) {

    //       //this.makeThresholdCsv(fd, td); //リリース時コメントアウト(下が修正前の記述)
    //       this.todoService.getThresholdValuesByMainSensorId(this.sessionstorageMainsensorData).subscribe((thresholdResponse: any) => {

    //         const waterQuantityThresholdVal = thresholdResponse.filter((x: any) => x.dataPointName == "S005");//流量の項目をフィルター

    //         if (waterQuantityThresholdVal.length > 0) {
    //           csvWaterQuantityAlertLevel1 = waterQuantityThresholdVal[0].alertLevel1;
    //           csvWaterQuantityAlertLevel2 = waterQuantityThresholdVal[0].alertLevel2;
    //           csvWaterQuantityAlertLevel3 = waterQuantityThresholdVal[0].alertLevel3;
    //         }

    //         const waterLevelThresholdVal = this.thresholdAPIResponse.filter((x: any) => x.dataPointName == "S001");//水位の項目をフィルター

    //         if (waterLevelThresholdVal.length > 0) {
    //           csvWaterLevelAlertLevel1 = waterLevelThresholdVal[0].alertLevel1;
    //           csvWaterLevelAlertLevel2 = waterLevelThresholdVal[0].alertLevel2;
    //           csvWaterLevelAlertLevel3 = waterLevelThresholdVal[0].alertLevel3;
    //         }

    //         //CSV用閾値超過判定
    //         //データソース、流量警戒レベル1, 2, 3、水位警戒レベル1, 2, 3
    //         this.csvThresholdCheck(csvDataSource, csvWaterQuantityAlertLevel1, csvWaterQuantityAlertLevel2,
    //           csvWaterQuantityAlertLevel3, csvWaterLevelAlertLevel1, csvWaterLevelAlertLevel2, csvWaterLevelAlertLevel3);
    //       })
    //     }

    //     //機器異常
    //     else {

    //       const machineIrregularSendData = {
    //         mainSensorId: this.sessionstorageMainsensorData,
    //         fromDate: moment(new Date("2022-01-01")).format('YYYY-MM-DD'),
    //         toDate: moment(new Date(today)).add(1, 'd').format('YYYY-MM-DD')
    //       };

    //       this.todoService.getAlertsByFilterDate(machineIrregularSendData).subscribe((machineIrregularResponse: any) => {

    //         const allAlertArray = machineIrregularResponse.alertResponses;

    //         //CSV用機器異常判定
    //         //データソース、全アラートデータ
    //         this.csvMachineIrregularCheck(csvDataSource, allAlertArray);
    //       })
    //     }
    //   })
    // }
  }

  //データ一覧をCSV出力する
  //csvListViewDataSource = データソース
  csvListView(csvListDataSource: any): void {
    let csvOutputDataList: any[] = [];

    let lang = sessionStorage.getItem("lang");

    if(lang == null || lang == "" || lang == undefined){
      lang = this.cookieService.get('language');
    }

    //追加記述(リリース時はコメントアウト)
    // if (this.role == "nscadmin"){

    //   for (let i = 0; i < csvListDataSource.length; i++){

    //     let csvOutputData: NSCUserView = {
    //       dateTime: "",
    //       waterQuantity: "",
    //       rawWaterQuantity: "",
    //       waterLevel: "",
    //       rawWaterLevel: "",
    //       freshwaterConductivity: "",
    //       rawFreshwaterConductivity: "",
    //       seawaterConductivity: "",
    //       rawSeawaterConductivity: "",
    //       waterTemperature: "",
    //       rawWaterTemperature: "",
    //       batteryVoltage: "",
    //       rawBatteryVoltage: "",
    //       turbidity: "",
    //       rawTurbidity: ""
    //     };

    //     //最初の行はヘッダーとして使う
    //     if(i == 0){

    //       if(lang == "jp"){

    //         csvOutputData = {
    //           dateTime: "日時",
    //           waterQuantity: "流量",
    //           rawWaterQuantity: "流量(生値)",
    //           waterLevel: "水位",
    //           rawWaterLevel: "水位(生値)",
    //           freshwaterConductivity: "淡水電気伝導度",
    //           rawFreshwaterConductivity: "淡水電気伝導度(生値)",
    //           seawaterConductivity: "海水電気伝導度",
    //           rawSeawaterConductivity: "海水電気伝導度(生値)",
    //           waterTemperature: "水温",
    //           rawWaterTemperature: "水温(生値)",
    //           batteryVoltage: "バッテリー電圧",
    //           rawBatteryVoltage: "バッテリー電圧(生値)",
    //           turbidity: "濁度",
    //           rawTurbidity: "濁度(生値)"
    //         }
    //       }
    //       else if(lang == "en"){

    //         csvOutputData = {
    //           dateTime: "Datetime",
    //           waterQuantity: "Waterquantity",
    //           rawWaterQuantity: "Rawwaterquantity",
    //           waterLevel: "Waterlevel",
    //           rawWaterLevel: "Rawwaterlevel",
    //           freshwaterConductivity: "Freshwaterconductivity",
    //           rawFreshwaterConductivity: "Rawfreshwaterconductivity",
    //           seawaterConductivity: "Seawaterconductivity",
    //           rawSeawaterConductivity: "Rawseawaterconductivity",
    //           waterTemperature: "Watertemperature",
    //           rawWaterTemperature: "Rawwatertemperature",
    //           batteryVoltage: "Batteryvoltage",
    //           rawBatteryVoltage: "Rawbatteryvoltage",
    //           turbidity: "Turbidity",
    //           rawTurbidity: "Rawturbidity"
    //         }
    //       }
    //       csvOutputDataList.push(csvOutputData);
    //     }

    //     //一度数値型にして小数点を整理した後、文字列として返ってきた値を再び数値型にする
    //     csvOutputData = {
    //       dateTime: csvListDataSource[i]['dateTime'],
    //       waterQuantity: isNull(parseFloat((parseFloat(csvListDataSource[i]['waterQuantity'])).toFixed(3))),
    //       rawWaterQuantity: isNull(parseFloat((parseFloat(csvListDataSource[i]['rawWaterQuantity'])).toFixed(3))),
    //       waterLevel: isNull(parseFloat((parseFloat(csvListDataSource[i]['waterLevel'])).toFixed(3))),
    //       rawWaterLevel: isNull(parseFloat((parseFloat(csvListDataSource[i]['rawWaterLevel'])).toFixed(3))),
    //       freshwaterConductivity: isNull(parseFloat((parseFloat(csvListDataSource[i]['freshwaterConductivity'])).toFixed(1))),
    //       rawFreshwaterConductivity: isNull(parseFloat((parseFloat(csvListDataSource[i]['rawFreshwaterConductivity'])).toFixed(1))),
    //       seawaterConductivity: isNull(parseFloat((parseFloat(csvListDataSource[i]['saltwaterConductivity'])).toFixed(2))),
    //       rawSeawaterConductivity: isNull(parseFloat((parseFloat(csvListDataSource[i]['rawSaltwaterConductivity'])).toFixed(2))),
    //       waterTemperature: isNull(parseFloat((parseFloat(csvListDataSource[i]['waterTemprature'])).toFixed(2))),
    //       rawWaterTemperature: isNull(parseFloat((parseFloat(csvListDataSource[i]['rawWaterTemprature'])).toFixed(2))),
    //       batteryVoltage: isNull(parseFloat((parseFloat(csvListDataSource[i]['batteryVoltage'])).toFixed(3))),
    //       rawBatteryVoltage: isNull(parseFloat((parseFloat(csvListDataSource[i]['rawBatteryVoltage'])).toFixed(3))),
    //       turbidity: isNull(parseFloat((parseFloat(csvListDataSource[i]['turbidity'])).toFixed(1))),
    //       rawTurbidity: isNull(parseFloat((parseFloat(csvListDataSource[i]['rawTurbidity'])).toFixed(1)))
    //     }
    //     csvOutputDataList.push(csvOutputData);
    //   }
    // }
    // //それ以外のユーザ
    // else {

    //   for (let i = 0; i < csvListDataSource.length; i++) {

    //     let csvOutputData: ListData = {
    //       dateTime: "",
    //       waterQuantity: "",
    //       waterLevel: "",
    //       freshwaterConductivity: "",
    //       saltwaterConductivity: "",
    //       waterTemprature: "",
    //       batteryVoltage: "",
    //       turbidity: "",
    //     };

    //     //最初の行はヘッダーとして使う
    //     if(i == 0){

    //       if(lang == "jp"){

    //         csvOutputData = {
    //           dateTime: "日時",
    //           waterQuantity: "流量",
    //           waterLevel: "水位",
    //           freshwaterConductivity: "淡水電気伝導度",
    //           saltwaterConductivity: "海水電気伝導度",
    //           waterTemprature: "水温",
    //           batteryVoltage: "バッテリー電圧",
    //           turbidity: "濁度",
    //         }
    //       }
    //       else if(lang == "en"){

    //         csvOutputData = {
    //           dateTime: "Datetime",
    //           waterQuantity: "Waterquantity",
    //           waterLevel: "Waterlevel",
    //           freshwaterConductivity: "Freshwaterconductivity",
    //           saltwaterConductivity: "Seawaterconductivity",
    //           waterTemprature: "Watertemperature",
    //           batteryVoltage: "Batteryvoltage",
    //           turbidity: "Turbidity",
    //         }
    //       }
    //       csvOutputDataList.push(csvOutputData);
    //     }

    //     csvOutputData = {
    //       dateTime: csvListDataSource[i]['dateTime'],
    //       waterQuantity: isNull(parseFloat((parseFloat(csvListDataSource[i]['waterQuantity'])).toFixed(3))),
    //       waterLevel: isNull(parseFloat((parseFloat(csvListDataSource[i]['waterLevel'])).toFixed(3))),
    //       freshwaterConductivity: isNull(parseFloat((parseFloat(csvListDataSource[i]['freshwaterConductivity'])).toFixed(1))),
    //       saltwaterConductivity: isNull(parseFloat((parseFloat(csvListDataSource[i]['saltwaterConductivity'])).toFixed(2))),
    //       waterTemprature: isNull(parseFloat((parseFloat(csvListDataSource[i]['waterTemprature'])).toFixed(2))),
    //       batteryVoltage: isNull(parseFloat((parseFloat(csvListDataSource[i]['batteryVoltage'])).toFixed(3))),
    //       turbidity: isNull(parseFloat((parseFloat(csvListDataSource[i]['turbidity'])).toFixed(1))),
    //     }
    //     csvOutputDataList.push(csvOutputData);
    //   }
    // }

    //NSC管理者なら生値を追加で表示(以下旧処理でリリース時に復活)
    if (this.role == "nscadmin") {

      for (let i = 0; i < csvListDataSource.length; i++) {

        //一度数値型にして小数点を整理した後、文字列として返ってきた値を再び数値型にする
        const csvOutputData: NSCUserView = {
          dateTime: csvListDataSource[i]['dateTime'],
          waterQuantity: parseFloat((parseFloat(csvListDataSource[i]['waterQuantity'])).toFixed(3)),
          rawWaterQuantity: parseFloat((parseFloat(csvListDataSource[i]['rawWaterQuantity'])).toFixed(3)),
          waterLevel: parseFloat((parseFloat(csvListDataSource[i]['waterLevel'])).toFixed(3)),
          rawWaterLevel: parseFloat((parseFloat(csvListDataSource[i]['rawWaterLevel'])).toFixed(3)),
          freshwaterConductivity: parseFloat((parseFloat(csvListDataSource[i]['freshwaterConductivity'])).toFixed(1)),
          rawFreshwaterConductivity: parseFloat((parseFloat(csvListDataSource[i]['rawFreshwaterConductivity'])).toFixed(1)),
          seawaterConductivity: parseFloat((parseFloat(csvListDataSource[i]['saltwaterConductivity'])).toFixed(2)),
          rawSeawaterConductivity: parseFloat((parseFloat(csvListDataSource[i]['rawSaltwaterConductivity'])).toFixed(2)),
          waterTemperature: parseFloat((parseFloat(csvListDataSource[i]['waterTemprature'])).toFixed(2)),
          rawWaterTemperature: parseFloat((parseFloat(csvListDataSource[i]['rawWaterTemprature'])).toFixed(2)),
          batteryVoltage: parseFloat((parseFloat(csvListDataSource[i]['batteryVoltage'])).toFixed(3)),
          rawBatteryVoltage: parseFloat((parseFloat(csvListDataSource[i]['rawBatteryVoltage'])).toFixed(3)),
          turbidity: parseFloat((parseFloat(csvListDataSource[i]['turbidity'])).toFixed(1)),
          rawTurbidity: parseFloat((parseFloat(csvListDataSource[i]['rawTurbidity'])).toFixed(1))
        }
        csvOutputDataList.push(csvOutputData);
      }
    }

    //それ以外のユーザ
    else {

      for (let i = 0; i < csvListDataSource.length; i++) {

        const csvOutputData: ListData = {
          dateTime: csvListDataSource[i]['dateTime'],
          waterQuantity: parseFloat((parseFloat(csvListDataSource[i]['waterQuantity'])).toFixed(3)),
          waterLevel: parseFloat((parseFloat(csvListDataSource[i]['waterLevel'])).toFixed(3)),
          freshwaterConductivity: parseFloat((parseFloat(csvListDataSource[i]['freshwaterConductivity'])).toFixed(1)),
          saltwaterConductivity: parseFloat((parseFloat(csvListDataSource[i]['saltwaterConductivity'])).toFixed(2)),
          waterTemprature: parseFloat((parseFloat(csvListDataSource[i]['waterTemprature'])).toFixed(2)),
          batteryVoltage: parseFloat((parseFloat(csvListDataSource[i]['batteryVoltage'])).toFixed(3)),
          turbidity: parseFloat((parseFloat(csvListDataSource[i]['turbidity'])).toFixed(1)),
        }
        csvOutputDataList.push(csvOutputData);
      }
    }

    csvOutputDataList.splice(csvOutputDataList.length - 1, 1);
    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(csvOutputDataList);
    this.snotifyService.success(this.translate.instant('Data exported successfully'), '');
  }

  //CSV用閾値超過判定
  //csvThresholdDataSource = データソース
  //water_quantityAlertLevel1 = 流量警戒レベル1
  //water_quantityAlertLevel2 = 流量警戒レベル2
  //water_quantityAlertLevel3 = 流量警戒レベル3
  //water_levelAlertLevel1 = 水位警戒レベル1
  //water_levelAlertLevel2 = 水位警戒レベル2
  //water_levelAlertLevel3 = 水位警戒レベル3
  csvThresholdCheck(csvThresholdDataSource: any, water_quantityAlertLevel1: number, water_quantityAlertLevel2: number,
    water_quantityAlertLevel3: number, water_levelAlertLevel1: number, water_levelAlertLevel2: number, water_levelAlertLevel3: number): void {

    let csvOutputDataList: any[] = [];

    //一度数値型にして小数点を整理した後、文字列として返ってきた値を再び数値型にする

    //水位の閾値超過をチェック
    for (let index = 0; index < csvThresholdDataSource.length; index++) {

      //警戒レベル3以上を抽出
      if (parseFloat(csvThresholdDataSource[index]['waterLevel']) >= water_levelAlertLevel3) {

        const csvOutputData: ExtraThresholdData = {
          dateTime: csvThresholdDataSource[index]['dateTime'],
          sensor: "waterLevel",
          unit: "(m)",
          alertLevel: 3,
          thresholdValue: water_levelAlertLevel3,
          value: parseFloat((parseFloat(csvThresholdDataSource[index]['waterLevel'])).toFixed(3))
        }
        csvOutputDataList.push(csvOutputData);
      }

      //警戒レベル2以上3未満を抽出
      else if (parseFloat(csvThresholdDataSource[index]['waterLevel']) >= water_levelAlertLevel2
        && parseFloat(csvThresholdDataSource[index]['waterLevel']) < water_levelAlertLevel3) {

        const csvOutputData: ExtraThresholdData = {
          dateTime: csvThresholdDataSource[index]['dateTime'],
          sensor: "waterLevel",
          unit: "(m)",
          alertLevel: 2,
          thresholdValue: water_levelAlertLevel2,
          value: parseFloat((parseFloat(csvThresholdDataSource[index]['waterLevel'])).toFixed(3))
        }
        csvOutputDataList.push(csvOutputData);
      }

      //警戒レベル1以上2未満を抽出
      else if (parseFloat(csvThresholdDataSource[index]['waterLevel']) >= water_levelAlertLevel1
        && parseFloat(csvThresholdDataSource[index]['waterLevel']) < water_levelAlertLevel2) {

        const csvOutputData: ExtraThresholdData = {
          dateTime: csvThresholdDataSource[index]['dateTime'],
          sensor: "waterLevel",
          unit: "(m)",
          alertLevel: 1,
          thresholdValue: water_levelAlertLevel1,
          value: parseFloat((parseFloat(csvThresholdDataSource[index]['waterLevel'])).toFixed(3))
        }
        csvOutputDataList.push(csvOutputData);
      }
    }

    //流量の閾値超過をチェック
    for (let index = 0; index < csvThresholdDataSource.length; index++) {

      //警戒レベル3以上を抽出
      if (parseFloat(csvThresholdDataSource[index]['waterQuantity']) >= water_quantityAlertLevel3) {

        const csvOutputData: ExtraThresholdData = {
          dateTime: csvThresholdDataSource[index]['dateTime'],
          sensor: "waterQuantity",
          unit: "(㎥/sec)",
          alertLevel: 3,
          thresholdValue: water_quantityAlertLevel3,
          value: parseFloat((parseFloat(csvThresholdDataSource[index]['waterQuantity'])).toFixed(3))
        }
        csvOutputDataList.push(csvOutputData);
      }

      //警戒レベル2以上3未満を抽出
      else if (parseFloat(csvThresholdDataSource[index]['waterQuantity']) >= water_quantityAlertLevel2
        && parseFloat(csvThresholdDataSource[index]['waterQuantity']) < water_quantityAlertLevel3) {

        const csvOutputData: ExtraThresholdData = {
          dateTime: csvThresholdDataSource[index]['dateTime'],
          sensor: "waterQuantity",
          unit: "(㎥/sec)",
          alertLevel: 2,
          thresholdValue: water_quantityAlertLevel2,
          value: parseFloat((parseFloat(csvThresholdDataSource[index]['waterQuantity'])).toFixed(3))
        }
        csvOutputDataList.push(csvOutputData);
      }

      //警戒レベル1以上2未満を抽出
      else if (parseFloat(csvThresholdDataSource[index]['waterQuantity']) >= water_quantityAlertLevel1
        && parseFloat(csvThresholdDataSource[index]['waterQuantity']) < water_quantityAlertLevel2) {

        const csvOutputData: ExtraThresholdData = {
          dateTime: csvThresholdDataSource[index]['dateTime'],
          sensor: "waterQuantity",
          unit: "(㎥/sec)",
          alertLevel: 1,
          thresholdValue: water_quantityAlertLevel1,
          value: parseFloat((parseFloat(csvThresholdDataSource[index]['waterQuantity'])).toFixed(3))
        }
        csvOutputDataList.push(csvOutputData);
      }
    }
    csvOutputDataList.splice(csvOutputDataList.length - 1, 1);
    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(csvOutputDataList);
    this.snotifyService.success(this.translate.instant('Data exported successfully'), '');
  }

  //CSV用機器異常判定
  //csvDataSource = データソース
  //allAlertArray = 全アラートデータ
  csvMachineIrregularCheck(csvDataSource: any, allAlertArray: any): void {
    let csvOutputDataList: any[] = [];

    for (let totalIndex = 0; totalIndex < allAlertArray.length; totalIndex++) {
      let alertTimeStamp = allAlertArray[totalIndex].dataTimeStamp;

      let irregularThresholdVal: any;
      let irregularVal: any;

      let lowerAlertVal = 0;//下限値
      let upperAlertVal = 0;//上限値

      const sensorType = this.thresholdCopy.filter((x: any) => x.dataPointNameToDisplay == allAlertArray[totalIndex]['dataNameToDisplay']);

      if(sensorType.length > 0){
        lowerAlertVal = sensorType[0]['lowerLowerLimit'];
        upperAlertVal = sensorType[0]['upperUpperLimit'];
      }

      for (let i = 0; i < csvDataSource.length; i++) {

        if (csvDataSource[i].timeStamp == alertTimeStamp) {
          let forUnit = "";

          if(allAlertArray[totalIndex]['status'] == "Upper value  exceeded") irregularThresholdVal = upperAlertVal;
          else irregularThresholdVal =  lowerAlertVal;

          //データが上限値・下限値に引っかかった場合
          //一度数値型にして小数点を整理した後、文字列として返ってきた値を再び数値型にする
          if (allAlertArray[totalIndex]['dataName'] == 'turbidity') {
            irregularVal = parseFloat((parseFloat(csvDataSource[i]['turbidity'])).toFixed(1));
            //irregularRawVal = parseFloat((parseFloat(csvDataSource[i]['rawTurbidity'])).toFixed(1));
            irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(1);
            let rawVal = parseFloat((parseFloat(csvDataSource[i]['rawTurbidity'])).toFixed(1));
            forUnit = "(FTU)";

            if (rawVal <= 100) {
              allAlertArray[totalIndex]['status'] = "Battery dead";
            }
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'water_level') {
            irregularVal = parseFloat((parseFloat(csvDataSource[i]['waterLevel'])).toFixed(3));
            //irregularRawVal = parseFloat((parseFloat(csvDataSource[i]['rawWaterLevel'])).toFixed(3));
            irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(3);
            forUnit = "(m)";
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'water_quantity') {
            irregularVal = parseFloat((parseFloat(csvDataSource[i]['waterQuantity'])).toFixed(3));
            //irregularRawVal = parseFloat((parseFloat(csvDataSource[i]['rawWaterQuantity'])).toFixed(3));
            irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(3);
            let rawVal = parseFloat((parseFloat(csvDataSource[i]['rawWaterQuantity'])).toFixed(3));
            forUnit = "(㎥/sec)";

            if (rawVal <= 100) {
              allAlertArray[totalIndex]['status'] = "Battery dead";
            }
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'freshwater_conductivity') {
            irregularVal = parseFloat((parseFloat(csvDataSource[i]['freshwaterConductivity'])).toFixed(1));
            //irregularRawVal = parseFloat((parseFloat(csvDataSource[i]['rawFreshwaterConductivity'])).toFixed(1));
            irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(1);
            forUnit = "(μS/cm)";
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'saltwater_conductivity') {
            irregularVal = parseFloat((parseFloat(csvDataSource[i]['saltwaterConductivity'])).toFixed(2));
            //irregularRawVal = parseFloat((parseFloat(csvDataSource[i]['rawSaltwaterConductivity'])).toFixed(2));
            irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(2);
            forUnit = "(mS/cm)";
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'water_temprature') {
            irregularVal = parseFloat((parseFloat(csvDataSource[i]['waterTemprature'])).toFixed(2));
            //irregularRawVal = parseFloat((parseFloat(csvDataSource[i]['rawWaterTemprature'])).toFixed(2));
            irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(2);
            forUnit = "(℃)";
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'battery_voltage') {
            irregularVal = parseFloat((parseFloat(csvDataSource[i]['batteryVoltage'])).toFixed(3));
            //irregularRawVal = parseFloat((parseFloat(csvDataSource[i]['rawBatteryVoltage'])).toFixed(3));
            irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(2);
            forUnit = "(V)";
          }

          //データの形式に問題があった場合
          else if (allAlertArray[totalIndex]['dataName'] == 'timestamp') {
            irregularVal = "";
            irregularThresholdVal = "";
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'ch1') {
            irregularVal = "";
            irregularThresholdVal = "";
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'ch2') {
            irregularVal = "";
            irregularThresholdVal = "";
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'ch3') {
            irregularVal = "";
            irregularThresholdVal = "";
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'ch4') {
            irregularVal = "";
            irregularThresholdVal = "";
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'adc0') {
            irregularVal = "";
            irregularThresholdVal = "";
          }

          else if (allAlertArray[totalIndex]['dataName'] == 'adc1') {
            irregularVal = "";
            irregularThresholdVal = "";
          }

          else {
            irregularVal = "err";
            irregularThresholdVal = "err";
          }

          const csvOutputData: MachineIrregularData = {
            Datetime: csvDataSource[i]['dateTime'],
            Sensor: allAlertArray[totalIndex]['dataNameToDisplay'],
            Unit: forUnit,
            ThresholdValue: irregularThresholdVal,
            Value:  irregularVal,
            Detail: allAlertArray[totalIndex]['status'],
          }
          csvOutputDataList.push(csvOutputData);
        }
      }
    }

    csvOutputDataList.splice(csvOutputDataList.length - 1, 1);
    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(csvOutputDataList);
    this.snotifyService.success(this.translate.instant('Data exported successfully'), '');
  }

  //データ一覧をcsv出力する(新)
  makeListviewCsv(fromDate: string, toDate: string): void{
    let listSendData = {};
    let csvOutputDataList: any[] = [];
    let lang = sessionStorage.getItem("lang");

    if(lang == null || lang == "" || lang == undefined){
      lang = this.cookieService.get('language');
    }

    if(fromDate !== "" && fromDate !== undefined && fromDate !== null){

      if(toDate !== "" && toDate !== undefined && toDate !== null){

        listSendData = {
          mainSensorID: this.sessionstorageMainsensorData,
          fromDate: moment(new Date(fromDate)).format('YYYY-MM-DD'),
          toDate: moment(new Date(toDate)).format('YYYY-MM-DD')
        };
      }
      else{

        listSendData = {
          mainSensorID: this.sessionstorageMainsensorData,
          fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
          toDate: moment(new Date(this.filterToDate)).format('YYYY-MM-DD')
        };
      }
    }
    else{

      listSendData = {
        mainSensorID: this.sessionstorageMainsensorData,
        fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.filterToDate)).format('YYYY-MM-DD')
      };
    }

    this.todoService.getAllByMainSensorIdAndMultipleDates(listSendData).subscribe((response: any) =>{
      const csvDataSource = response.waterFlowResponse;

      if(csvDataSource.length != 0){

        if (this.role == "nscadmin"){

          let csvOutputData: NSCUserView = {
            dateTime: "",
            waterQuantity: "",
            rawWaterQuantity: "",
            waterLevel: "",
            rawWaterLevel: "",
            freshwaterConductivity: "",
            rawFreshwaterConductivity: "",
            seawaterConductivity: "",
            rawSeawaterConductivity: "",
            waterTemperature: "",
            rawWaterTemperature: "",
            batteryVoltage: "",
            rawBatteryVoltage: "",
            turbidity: "",
            rawTurbidity: ""
          };

          for (let i = 0; i < csvDataSource.length; i++){

            //最初の行はヘッダーとして使う
            if(i == 0){

              if(lang == "jp"){

                csvOutputData = {
                  dateTime: "日時",
                  waterQuantity: "流量",
                  rawWaterQuantity: "流量(生値)",
                  waterLevel: "水位",
                  rawWaterLevel: "水位(生値)",
                  freshwaterConductivity: "淡水電気伝導度",
                  rawFreshwaterConductivity: "淡水電気伝導度(生値)",
                  seawaterConductivity: "海水電気伝導度",
                  rawSeawaterConductivity: "海水電気伝導度(生値)",
                  waterTemperature: "水温",
                  rawWaterTemperature: "水温(生値)",
                  batteryVoltage: "バッテリー電圧",
                  rawBatteryVoltage: "バッテリー電圧(生値)",
                  turbidity: "濁度",
                  rawTurbidity: "濁度(生値)"
                }
              }
              else if(lang == "en"){

                csvOutputData = {
                  dateTime: "Datetime",
                  waterQuantity: "Waterquantity",
                  rawWaterQuantity: "Rawwaterquantity",
                  waterLevel: "Waterlevel",
                  rawWaterLevel: "Rawwaterlevel",
                  freshwaterConductivity: "Freshwaterconductivity",
                  rawFreshwaterConductivity: "Rawfreshwaterconductivity",
                  seawaterConductivity: "Seawaterconductivity",
                  rawSeawaterConductivity: "Rawseawaterconductivity",
                  waterTemperature: "Watertemperature",
                  rawWaterTemperature: "Rawwatertemperature",
                  batteryVoltage: "Batteryvoltage",
                  rawBatteryVoltage: "Rawbatteryvoltage",
                  turbidity: "Turbidity",
                  rawTurbidity: "Rawturbidity"
                }
              }
              csvOutputDataList.push(csvOutputData);
            }

            //一度数値型にして小数点を整理した後、文字列として返ってきた値を再び数値型にする
            csvOutputData = {
              dateTime: csvDataSource[i]['dateTime'],
              waterQuantity: isNull(parseFloat((parseFloat(csvDataSource[i]['waterQuantity'])).toFixed(3))),
              rawWaterQuantity: isNull(parseFloat((parseFloat(csvDataSource[i]['rawWaterQuantity'])).toFixed(3))),
              waterLevel: isNull(parseFloat((parseFloat(csvDataSource[i]['waterLevel'])).toFixed(3))),
              rawWaterLevel: isNull(parseFloat((parseFloat(csvDataSource[i]['rawWaterLevel'])).toFixed(3))),
              freshwaterConductivity: isNull(parseFloat((parseFloat(csvDataSource[i]['freshwaterConductivity'])).toFixed(1))),
              rawFreshwaterConductivity: isNull(parseFloat((parseFloat(csvDataSource[i]['rawFreshwaterConductivity'])).toFixed(1))),
              seawaterConductivity: isNull(parseFloat((parseFloat(csvDataSource[i]['saltwaterConductivity'])).toFixed(2))),
              rawSeawaterConductivity: isNull(parseFloat((parseFloat(csvDataSource[i]['rawSaltwaterConductivity'])).toFixed(2))),
              waterTemperature: isNull(parseFloat((parseFloat(csvDataSource[i]['waterTemprature'])).toFixed(2))),
              rawWaterTemperature: isNull(parseFloat((parseFloat(csvDataSource[i]['rawWaterTemprature'])).toFixed(2))),
              batteryVoltage: isNull(parseFloat((parseFloat(csvDataSource[i]['batteryVoltage'])).toFixed(3))),
              rawBatteryVoltage: isNull(parseFloat((parseFloat(csvDataSource[i]['rawBatteryVoltage'])).toFixed(3))),
              turbidity: isNull(parseFloat((parseFloat(csvDataSource[i]['turbidity'])).toFixed(1))),
              rawTurbidity: isNull(parseFloat((parseFloat(csvDataSource[i]['rawTurbidity'])).toFixed(1)))
            }
            csvOutputDataList.push(csvOutputData);
          }
        }
        //それ以外のユーザ
        else {

          let csvOutputData: ListData = {
            dateTime: "",
            waterQuantity: "",
            waterLevel: "",
            freshwaterConductivity: "",
            saltwaterConductivity: "",
            waterTemprature: "",
            batteryVoltage: "",
            turbidity: "",
          };

          for (let i = 0; i < csvDataSource.length; i++) {

            //最初の行はヘッダーとして使う
            if(i == 0){

              if(lang == "jp"){

                csvOutputData = {
                  dateTime: "日時",
                  waterQuantity: "流量",
                  waterLevel: "水位",
                  freshwaterConductivity: "淡水電気伝導度",
                  saltwaterConductivity: "海水電気伝導度",
                  waterTemprature: "水温",
                  batteryVoltage: "バッテリー電圧",
                  turbidity: "濁度",
                }
              }
              else if(lang == "en"){

                csvOutputData = {
                  dateTime: "Datetime",
                  waterQuantity: "Waterquantity",
                  waterLevel: "Waterlevel",
                  freshwaterConductivity: "Freshwaterconductivity",
                  saltwaterConductivity: "Seawaterconductivity",
                  waterTemprature: "Watertemperature",
                  batteryVoltage: "Batteryvoltage",
                  turbidity: "Turbidity",
                }
              }
              csvOutputDataList.push(csvOutputData);
            }

            csvOutputData = {
              dateTime: csvDataSource[i]['dateTime'],
              waterQuantity: isNull(parseFloat((parseFloat(csvDataSource[i]['waterQuantity'])).toFixed(3))),
              waterLevel: isNull(parseFloat((parseFloat(csvDataSource[i]['waterLevel'])).toFixed(3))),
              freshwaterConductivity: isNull(parseFloat((parseFloat(csvDataSource[i]['freshwaterConductivity'])).toFixed(1))),
              saltwaterConductivity: isNull(parseFloat((parseFloat(csvDataSource[i]['saltwaterConductivity'])).toFixed(2))),
              waterTemprature: isNull(parseFloat((parseFloat(csvDataSource[i]['waterTemprature'])).toFixed(2))),
              batteryVoltage: isNull(parseFloat((parseFloat(csvDataSource[i]['batteryVoltage'])).toFixed(3))),
              turbidity: isNull(parseFloat((parseFloat(csvDataSource[i]['turbidity'])).toFixed(1))),
            }
            csvOutputDataList.push(csvOutputData);
          }
        }
        //end for

        //csvOutputDataList.splice(csvOutputDataList.length - 1, 1);
        const csvExporter = new ExportToCsv(this.options);
        csvExporter.generateCsv(csvOutputDataList);
        //this.snotifyService.success(this.translate.instant('Data exported successfully'), '');
      }
      else{
        //出力するデータがない場合の表示を検討する
      }
    })
  }

  //閾値超過をcsv出力する_20231120
  exportThresholdAlert(fromDate: string, toDate: string): void{
    let unit = "";
    let formedThresholdVal = 0;
    let formedVal = 0;
    let csvOutputDataList: any[] = [];
    let sensor = "";
    // let tempArray: any[] = [];

    //現在選択中の言語を取得(csvファイルをどの言語で出力するか決めるため)
    let lang = sessionStorage.getItem("lang");

    if(lang == null || lang == "" || lang == undefined){
      lang = this.cookieService.get('language');
    }

    //日付が入力されているかのチェック
    if(fromDate !== "" && fromDate !== undefined && fromDate !== null){
      if(toDate !== "" && toDate !== undefined && fromDate !== null){
        this.thresholdSendData = {
          mainSensorId: this.sessionstorageMainsensorData,
          fromDate: moment(new Date(fromDate)).format('YYYY-MM-DD'),
          toDate: moment(new Date(toDate)).format('YYYY-MM-DD')
        };
      }
      else
      {
        alert("日付(To)が入力されていません。");
        return;
      }
    }
    else
    {
      alert("日付(From)が入力されていません。");
      return;
    }

    // リクエストデータ取得
    this.todoService.getThresholdAlertsByMainSensorId(this.thresholdSendData).subscribe((response: any) => {
      if(response == null)
      {
        this.spinner.hide();
        return;
      }

      let thresholdAlertsResponse = response.thresholdAlertList;

      //20240517追加
      //濁度予測のアラートを除外
      thresholdAlertsResponse = thresholdAlertsResponse.filter((x: any) => x.dataName !== "turbidity_predict");
      //20240517追加終了

      if(thresholdAlertsResponse.length != 0)
      {
        for(let index = 0; index < thresholdAlertsResponse.length; index++){
          let date = new Date((thresholdAlertsResponse[index]['dataTimeStamp']) * 1000);
          let convertedDate = moment(date).format("YYYY-MM-DD HH:mm");

          if(thresholdAlertsResponse[index].dataName == "water_quantity" || thresholdAlertsResponse[index].dataName == "waterQuantity")
          {
            if(lang == "jp")
            {
              sensor = "流量";
            }
            else if(lang == "en")
            {
              sensor = "Waterquantity";
            }

            unit = "(㎥/sec)";
          }
          else
          {
            if(lang == "jp")
            {
              sensor = "水位";
            }
            else if(lang == "en")
            {
              sensor = "Waterlevel";
            }

            unit = "(m)";
          }

          formedThresholdVal = (thresholdAlertsResponse[index]['threshold']).toFixed(3);

          if(thresholdAlertsResponse[index]['value'] != null)
          {
            formedVal = (thresholdAlertsResponse[index]['value']).toFixed(3);
          }

          if(index == 0){
            if(lang == "jp")
            {
              let headerData: ExtraThresholdData = {
                dateTime: "日時",
                sensor: "センサー",
                unit: "単位",
                alertLevel: "警戒レベル",
                thresholdValue: "閾値",
                value: "測定値"
              }
              csvOutputDataList.push(headerData);
            }
            else if(lang == "en")
            {
              let headerData: ExtraThresholdData = {
                dateTime: "Datetime",
                sensor: "Sensor",
                unit: "Unit",
                alertLevel: "Alertlevel",
                thresholdValue: "Thresholdvalue",
                value: "Value"
              }
              csvOutputDataList.push(headerData);
            }
          }

          let alertData: ExtraThresholdData = {
            dateTime: convertedDate,
            sensor: sensor,
            unit: unit,
            alertLevel: thresholdAlertsResponse[index].level,
            thresholdValue: formedThresholdVal,
            value: formedVal
          }
          forExtraThreshold(alertData);
          csvOutputDataList.push(alertData);
        }

        const csvExporter = new ExportToCsv(this.options);
        csvExporter.generateCsv(csvOutputDataList);
      }
      else{
        this.spinner.hide();
        //データがないときは「データがありません」等のメッセージを表示する
      }
    })
  }

  // //閾値超過をcsv出力する(新)(修正箇所)
  // makeThresholdCsv(fromDate: string, toDate: string): void{
  //   let unit = "";
  //   let formedThresholdVal = 0;
  //   let formedVal = 0;
  //   let lang = sessionStorage.getItem("lang");
  //   let csvOutputDataList: any[] = [];
  //   let sensor = "";

  //   if(lang == null || lang == "" || lang == undefined){
  //     lang = this.cookieService.get('language');
  //   }

  //   if(fromDate !== "" && fromDate !== undefined && fromDate !== null){

  //     if(toDate !== "" && toDate !== undefined && toDate !== null){

  //       this.thresholdSendData = {
  //         mainSensorId: this.sessionstorageMainsensorData,
  //         fromDate: moment(new Date(fromDate)).format('YYYY-MM-DD'),
  //         toDate: moment(new Date(toDate)).format('YYYY-MM-DD')
  //       };
  //     }
  //     else{
  //       // alert("日付(To)が入力されていません。");
  //       // return;

  //       this.thresholdSendData = {
  //         mainSensorId: this.sessionstorageMainsensorData,
  //         fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
  //         toDate: moment(new Date(this.filterToDate)).format('YYYY-MM-DD')
  //       };
  //     }
  //   }
  //   else{
  //     // alert("日付(From)が入力されていません。");
  //     // return;

  //     this.thresholdSendData = {
  //       mainSensorId: this.sessionstorageMainsensorData,
  //       fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
  //       toDate: moment(new Date(this.filterToDate)).format('YYYY-MM-DD')
  //     };
  //   }

  //   this.todoService.getThresholdAlertsByMainSensorId(this.thresholdSendData).subscribe((response: any) => {

  //     if(response == null){
  //       this.spinner.hide();
  //       return;
  //     }

  //     let thresholdAlertsResponse = response.thresholdAlertList;

  //     if(thresholdAlertsResponse.length != 0){

  //       for(let index = 0; index < thresholdAlertsResponse.length; index++){
  //         let date = new Date((thresholdAlertsResponse[index]['dataTimeStamp']) * 1000);
  //         let convertedDate = moment(date).format("YYYY-MM-DD HH:mm");

  //         if(thresholdAlertsResponse[index].dataName == "water_quantity"){

  //           if(lang == "jp"){
  //             sensor = "流量";
  //           }
  //           else if(lang == "en"){
  //             sensor = "Waterquantity";
  //           }

  //           unit = "(㎥/sec)";
  //         }
  //         else{

  //           if(lang == "jp"){
  //             sensor = "水位";
  //           }
  //           else if(lang == "en"){
  //             sensor = "Waterlevel";
  //           }

  //           unit = "(m)";
  //         }

  //         formedThresholdVal = (thresholdAlertsResponse[index]['threshold']).toFixed(3);

  //         if(thresholdAlertsResponse[index]['value'] != null){
  //           formedVal = (thresholdAlertsResponse[index]['value']).toFixed(3);
  //         }

  //         if(index == 0){

  //           if(lang == "jp"){

  //             let headerData: ExtraThresholdData = {
  //               dateTime: "日時",
  //               sensor: "センサー",
  //               unit: "単位",
  //               alertLevel: "警戒レベル",
  //               thresholdValue: "閾値",
  //               value: "測定値"
  //             }
  //             csvOutputDataList.push(headerData);
  //           }
  //           else if(lang == "en"){

  //             let headerData: ExtraThresholdData = {
  //               dateTime: "Datetime",
  //               sensor: "Sensor",
  //               unit: "Unit",
  //               alertLevel: "Alertlevel",
  //               thresholdValue: "Thresholdvalue",
  //               value: "Value"
  //             }
  //             csvOutputDataList.push(headerData);
  //           }
  //         }

  //         let alertData: ExtraThresholdData = {
  //           dateTime: convertedDate,
  //           sensor: sensor,
  //           unit: unit,
  //           alertLevel: thresholdAlertsResponse[index].level,
  //           thresholdValue: formedThresholdVal,
  //           value: formedVal
  //         }
  //         forExtraThreshold(alertData);
  //         csvOutputDataList.push(alertData);
  //       }

  //       const csvExporter = new ExportToCsv(this.options);
  //       csvExporter.generateCsv(csvOutputDataList);
  //       //this.snotifyService.success(this.translate.instant('Data exported successfully'), '');
  //       //else this.spinner.hide();
  //     }
  //     else{
  //       //this.spinner.hide();
  //       //データがないときは「データがありません」等のメッセージを表示する
  //     }
  //   })
  // }

  //機器異常をcsv出力する_20231120
  exportAlert(fromDate: string, toDate: string): void{
    let csvOutputDataList: any[] = [];
    let requestData = {};
    let tempArray: any[] = [];

    //現在選択中の言語を取得(csvファイルをどの言語で出力するか決めるため)
    let lang = sessionStorage.getItem("lang");

    if(lang == null || lang == "" || lang == undefined){
      lang = this.cookieService.get('language');
    }

    //日付チェック
    if(fromDate !== "" && fromDate !== undefined && fromDate !== null){

      if(toDate !== "" && toDate !== undefined && toDate !== null){

        requestData = {
          mainSensorId: this.sessionstorageMainsensorData,
          fromDate: moment(new Date(fromDate)).format('YYYY-MM-DD'),
          toDate: moment(new Date(toDate)).add(1, 'd').format('YYYY-MM-DD')
        }
      }
      else{
        alert("日付(To)が入力されていません。");
        return;
      }
    }
    else{
      alert("日付(From)が入力されていません。");
      return;
    }

    this.todoService.getAlertsByFilterDate(requestData).subscribe((response: any) => {
      //存在チェック
      if(response == null){
        return;
      }

      //存在チェック
      if(response.alertResponses.length == 0){
        return;
      }

      //表示日時のデータだけになるよう抽出する
      for (let index = 0; index < response.alertResponses.length; index++) {
        var tmpVal: string;
        let date = new Date(response.alertResponses[index].dataTimeStamp * 1000);
        tmpVal = moment(new Date(date)).format("YYYY-MM-DD HH:mm");

        let tmpFrom = moment(new Date(fromDate)).format('YYYY-MM-DD');
        let tmpTo = moment.utc(new Date(moment(new Date(toDate)).add(1, 'd').format('YYYY-MM-DD'))).add(1, 'd').format("YYYY-MM-DD HH:mm");

        if (tmpVal >= tmpFrom) {

          if (tmpVal < tmpTo) {
            tempArray.push(response.alertResponses[index]);
          }
        }
      }

      //存在チェック
      if(tempArray.length == 0){
        return;
      }

      for (let index = 0; index < tempArray.length; index++) {

        let date = new Date(tempArray[index].dataTimeStamp * 1000);
        let displayedDate = moment(new Date(date)).format("YYYY-MM-DD HH:mm");
        let irregularThresholdVal: any;
        let irregularVal: any;
        let dataUnit: string = "";
        let sensorName: string = "-";
        let detailMessage: string = "";
        let item = tempArray[index];

        //一番最初のループでヘッダーを入れる
        if(index == 0){

          if(lang == "jp"){

            let headerData: MachineIrregularData = {
              Datetime: "日時",
              Sensor: "センサー",
              Unit: "単位",
              ThresholdValue: "閾値",
              Value: "測定値",
              Detail: "詳細",
            }
            csvOutputDataList.push(headerData);
          }
          else if(lang == "en"){

            let headerData: MachineIrregularData = {
              Datetime: "Datetime",
              Sensor: "Sensor",
              Unit: "Unit",
              ThresholdValue: "ThresholdValue",
              Value: "Value",
              Detail: "Alertdetail",
            }
            csvOutputDataList.push(headerData);
          }
        }

        //アラートの内容チェック
        switch (item['status']){
          //上限値超過
          case "Upper value  exceeded":
            if(lang == "jp"){
              detailMessage = "上限値を超過しています"
            }
            else if(lang == "en"){
              detailMessage = "Upper limit exceeded"
            }
            break;
          //下限値超過
          case "Lower value  exceeded":
            if(lang == "jp"){
              detailMessage = "下限値を超過しています"
            }
            else if(lang == "en"){
              detailMessage = "Lower limit exceeded"
            }
            break;
          //電池切れ
          case "Battery dead":
            if(lang == "jp"){
              detailMessage = "電池切れ"
            }
            else if(lang == "en"){
              detailMessage = "Battery dead"
            }
            break;
          //その他
          default:
            break;
        }

        // データ種別チェック
        switch (item['dataName']){
          // 濁度
          case 'turbidity':
            dataUnit = "(FTU)";
            if(lang == "jp"){
              sensorName = "濁度";
            }
            else if(lang == "en"){
              sensorName = "Turbidity";
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(1);
              irregularThresholdVal = (item['threshold']).toFixed(1);
            }
            break;
          // 水位
          case 'waterLevel':
          case 'water_level':
            dataUnit = "(m)";
            if(lang == "jp"){
              sensorName = "水位";
            }
            else if(lang == "en"){
              sensorName = "Waterlevel";
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(3);
              irregularThresholdVal = (item['threshold']).toFixed(3);
            }
            break;
          // 流量
          case 'waterQuantity':
          case 'water_quantity':
            dataUnit = "(㎥/sec)";
            if(lang == "jp"){
              sensorName = "流量";
            }
            else if(lang == "en"){
              sensorName = item["dataNameToDisplay"];
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(3);
              irregularThresholdVal = (item['threshold']).toFixed(3);
            }
            break;

          // 海水電気伝導度
          case 'saltwater_conductivity':
          case 'seawaterConductivity':
            dataUnit = "(μS/cm)";
            if(lang == "jp"){
              sensorName = "海水電気伝導度";
            }
            else if(lang == "en"){
              sensorName = item["dataNameToDisplay"];
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(1);
              irregularThresholdVal = (item['threshold']).toFixed(1);
            }
            break;

          // 淡水電気伝導度
          case 'freshwaterConductivity':
          case 'freshwater_conductivity':
            dataUnit = "(mS/cm)";
            if(lang == "jp"){
              sensorName = "淡水電気伝導度";
            }
            else if(lang == "en"){
              sensorName = item["dataNameToDisplay"];
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(2);
              irregularThresholdVal = (item['threshold']).toFixed(2);
            }
            break;

          // 水温
          case 'waterTemperature':
          case 'water_temprature':
            dataUnit = "(℃)";
            if(lang == "jp"){
              sensorName = "水温";
            }
            else if(lang == "en"){
              sensorName = item["dataNameToDisplay"];
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(2);
              irregularThresholdVal = (item['threshold']).toFixed(2);
            }
            break;

          // バッテリー電圧
          case 'batteryVoltage':
          case 'battery_voltage':
            dataUnit = "(V)";
            if(lang == "jp"){
              sensorName = "バッテリー電圧";
            }
            else if(lang == "en"){
              sensorName = item["dataNameToDisplay"];
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(3);
              irregularThresholdVal = (item['threshold']).toFixed(3);
            }
            break;

          default:
              if(item['value'] != null){
              irregularVal = item['value'];
              irregularThresholdVal = item['threshold'];
            }
              break;
        }

        const alertInfo: MachineIrregularData = {
          Datetime: displayedDate,
          Sensor: sensorName,
          Unit: isNull(dataUnit),
          ThresholdValue: isNull(irregularThresholdVal),
          Value: isNull(irregularVal),
          Detail: detailMessage
        }

        //this.machineIrregularDataList.push(alertInfo);
        csvOutputDataList.push(alertInfo);
      }

      //存在チェック
      if(csvOutputDataList.length == 0){
        return;
      }

      const csvExporter = new ExportToCsv(this.options);
      csvExporter.generateCsv(csvOutputDataList);
    })
  }

  //機器異常をcsv出力する(新)(修正箇所)
  // makeIrregularCsv(fromDate: string, toDate: string): void{
  //   let listSendData = {};
  //   let irregularSendData = {};
  //   let csvOutputDataList: any[] = [];
  //   let lang = sessionStorage.getItem("lang");
  //   let irregularThresholdVal: any;
  //   let irregularVal: any;
  //   let sensorName: string;
  //   let detailMessage: string;

  //   if(lang == null || lang == "" || lang == undefined){
  //     lang = this.cookieService.get('language');
  //   }

  //   //日付が指定されているか判定する(指定されていなければデフォルトの日付)
  //   if(fromDate !== "" && fromDate !== undefined && fromDate !== null){

  //     if(toDate !== "" && toDate !== undefined && toDate !== null){

  //       //データ一覧取得用
  //       listSendData = {
  //         mainSensorID: this.sessionstorageMainsensorData,
  //         fromDate: moment(new Date(fromDate)).format('YYYY-MM-DD'),
  //         toDate: moment(new Date(toDate)).format('YYYY-MM-DD')
  //       };

  //       //アラート一覧取得用
  //       irregularSendData = {
  //         mainSensorId: this.sessionstorageMainsensorData,
  //         fromDate: moment(new Date(fromDate)).format('YYYY-MM-DD'),
  //         toDate: moment(new Date(toDate)).format('YYYY-MM-DD')
  //       };
  //     }
  //     else{

  //       //データ一覧取得用
  //       listSendData = {
  //         mainSensorID: this.sessionstorageMainsensorData,
  //         fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
  //         toDate: moment(new Date(this.filterToDate)).format('YYYY-MM-DD')
  //       };

  //       //アラート一覧取得用
  //       irregularSendData = {
  //         mainSensorId: this.sessionstorageMainsensorData,
  //         fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
  //         toDate: moment(new Date(this.filterToDate)).format('YYYY-MM-DD')
  //       };
  //     }
  //   }
  //   else{

  //     //データ一覧取得用
  //     listSendData = {
  //       mainSensorID: this.sessionstorageMainsensorData,
  //       fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
  //       toDate: moment(new Date(this.filterToDate)).format('YYYY-MM-DD')
  //     };

  //     //アラート一覧取得用
  //     irregularSendData = {
  //       mainSensorId: this.sessionstorageMainsensorData,
  //       fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
  //       toDate: moment(new Date(this.filterToDate)).format('YYYY-MM-DD')
  //     };
  //   }

  //   //データを取得する
  //   this.todoService.getAllByMainSensorIdAndMultipleDates(listSendData).subscribe((response: any) => {
  //     const csvDataSource = response.waterFlowResponse;

  //     //アラートを取得する
  //     this.todoService.getAlertsByFilterDate(irregularSendData).subscribe((alertResponse: any) => {

  //       const alertArray = alertResponse.alertResponses;

  //       //アラートとその当時の観測データ両方が揃っている場合
  //       if(csvDataSource.length != 0 && alertArray.length != 0){

  //         for (let alertIndex = 0; alertIndex < alertArray.length; alertIndex++) {
  //           let alertTimeStamp = alertArray[alertIndex].dataTimeStamp;

  //           let alertVal: any;
  //           let irregularVal: any;

  //           let lowerLimit = 0; //下限値
  //           let upperLimit = 0; //上限値

  //           const sensorType = this.thresholdCopy.filter((x: any) => x.dataPointNameToDisplay == alertArray[alertIndex]['dataNameToDisplay']);

  //           if(sensorType.length > 0){
  //             lowerLimit = sensorType[0]['lowerLowerLimit'];
  //             upperLimit = sensorType[0]['upperUpperLimit'];
  //           }

  //           for (let dataIndex = 0; dataIndex < csvDataSource.length; dataIndex++) {

  //             //一番最初のループでヘッダーを入れる
  //             if(alertIndex == 0 && dataIndex == 0){

  //               if(lang == "jp"){

  //                 let headerData: MachineIrregularData = {
  //                   Datetime: "日時",
  //                   Sensor: "センサー",
  //                   Unit: "単位",
  //                   ThresholdValue: "閾値",
  //                   Value: "測定値",
  //                   Detail: "詳細",
  //                 }
  //                 csvOutputDataList.push(headerData);
  //               }
  //               else if(lang == "en"){

  //                 let headerData: MachineIrregularData = {
  //                   Datetime: "Datetime",
  //                   Sensor: "Sensor",
  //                   Unit: "Unit",
  //                   ThresholdValue: "ThresholdValue",
  //                   Value: "Value",
  //                   Detail: "Alertdetail",
  //                 }
  //                 csvOutputDataList.push(headerData);
  //               }
  //             }

  //             if (csvDataSource[dataIndex].timeStamp == alertTimeStamp) {
  //               let forUnit = "";

  //               //アラートの内容チェック
  //               switch (alertArray[alertIndex]['status']){

  //                 //上限値超過
  //                 case "Upper value  exceeded":
  //                   irregularThresholdVal = upperLimit;

  //                   if(lang == "jp"){
  //                     detailMessage = "上限値を超過しています"
  //                   }
  //                   else if(lang == "en"){
  //                     detailMessage = "Upper limit exceeded"
  //                   }

  //                   break;
  //                 //下限値超過
  //                 case "Lower value  exceeded":
  //                   irregularThresholdVal =  lowerLimit;

  //                   if(lang == "jp"){
  //                     detailMessage = "下限値を超過しています"
  //                   }
  //                   else if(lang == "en"){
  //                     detailMessage = "Lower limit exceeded"
  //                   }

  //                   break;
  //                 //電池切れ
  //                 case "Battery dead":
  //                   irregularThresholdVal = 100;

  //                   if(lang == "jp"){
  //                     detailMessage = "電池切れ"
  //                   }
  //                   else if(lang == "en"){
  //                     detailMessage = "Battery dead"
  //                   }

  //                   break;
  //                 //その他
  //                 default:
  //                   break;
  //               }

  //               //アラートのデータ項目チェック
  //               switch (alertArray[alertIndex]['dataName']){

  //                 //濁度
  //                 case 'turbidity':
  //                   irregularVal = parseFloat((parseFloat(csvDataSource[dataIndex]['turbidity'])).toFixed(1));
  //                   irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(1);
  //                   forUnit = "(FTU)";

  //                   if(lang == "jp"){
  //                     sensorName = "濁度";
  //                   }
  //                   else if(lang == "en"){
  //                     sensorName = "Turbidity";
  //                   }

  //                   break;
  //                 //水位
  //                 case 'water_level':
  //                   irregularVal = parseFloat((parseFloat(csvDataSource[dataIndex]['waterLevel'])).toFixed(3));
  //                   irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(3);
  //                   forUnit = "(m)";

  //                   if(lang == "jp"){
  //                     sensorName = "水位";
  //                   }
  //                   else if(lang == "en"){
  //                     sensorName = "Waterlevel";
  //                   }

  //                   break;
  //                 //流量
  //                 case 'water_quantity':
  //                   irregularVal = parseFloat((parseFloat(csvDataSource[dataIndex]['waterQuantity'])).toFixed(3));
  //                   irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(3);
  //                   forUnit = "(㎥/sec)";

  //                   if(lang == "jp"){
  //                     sensorName = "流量";
  //                   }
  //                   else if(lang == "en"){
  //                     sensorName = "Waterquantity";
  //                   }

  //                   break;
  //                 //淡水電気伝導度
  //                 case 'freshwater_conductivity':
  //                   irregularVal = parseFloat((parseFloat(csvDataSource[dataIndex]['freshwaterConductivity'])).toFixed(1));
  //                   irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(1);
  //                   forUnit = "(μS/cm)";

  //                   if(lang == "jp"){
  //                     sensorName = "海水電気伝導度";
  //                   }
  //                   else if(lang == "en"){
  //                     sensorName = "Freshwaterconductivity";
  //                   }

  //                   break;
  //                 //海水電気伝導度
  //                 case 'saltwater_conductivity':
  //                   irregularVal = parseFloat((parseFloat(csvDataSource[dataIndex]['saltwaterConductivity'])).toFixed(2));
  //                   irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(2);
  //                   forUnit = "(mS/cm)";

  //                   if(lang == "jp"){
  //                     sensorName = "淡水電気伝導度";
  //                   }
  //                   else if(lang == "en"){
  //                     sensorName = "Saltwaterconductivity";
  //                   }

  //                   break;
  //                 //水温
  //                 case 'water_temprature':
  //                   irregularVal = parseFloat((parseFloat(csvDataSource[dataIndex]['waterTemprature'])).toFixed(2));
  //                   irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(2);
  //                   forUnit = "(℃)";

  //                   if(lang == "jp"){
  //                     sensorName = "水温";
  //                   }
  //                   else if(lang == "en"){
  //                     sensorName = "Watertemperature";
  //                   }

  //                   break;
  //                 //バッテリー電圧
  //                 case 'battery_voltage':
  //                   irregularVal = parseFloat((parseFloat(csvDataSource[dataIndex]['batteryVoltage'])).toFixed(3));
  //                   irregularThresholdVal = (parseFloat(irregularThresholdVal)).toFixed(2);
  //                   forUnit = "(V)";

  //                   if(lang == "jp"){
  //                     sensorName = "バッテリー電圧";
  //                   }
  //                   else if(lang == "en"){
  //                     sensorName = "Batteryvoltage";
  //                   }

  //                   break;
  //                 //その他
  //                 default:
  //                   irregularVal = "-";
  //                   irregularThresholdVal = "-";
  //                   forUnit = "-";
  //                   sensorName = alertArray[alertIndex]['dataNameToDisplay'];
  //                   detailMessage = "-";
  //                   break;
  //               }

  //               const csvOutputData: MachineIrregularData = {
  //                 Datetime: csvDataSource[dataIndex]['dateTime'],
  //                 Sensor: sensorName,
  //                 Unit: forUnit,
  //                 ThresholdValue: irregularThresholdVal,
  //                 Value: irregularVal,
  //                 Detail: detailMessage,
  //               }
  //               csvOutputDataList.push(csvOutputData);
  //             }
  //           }
  //         }

  //         const csvExporter = new ExportToCsv(this.options);
  //         csvExporter.generateCsv(csvOutputDataList);
  //         //this.snotifyService.success(this.translate.instant('Data exported successfully'), '');
  //       }
  //       else{
  //         //データがないときの表示を検討
  //       }
  //     })
  //   })
  // }

  //閾値超過表示(最新)
  showThresholdAlerts(): void{
    this.thresholdAlertArray.length = 0;
    let unit = "";
    let formedThresholdVal = 0;
    let formedVal;
    let tempArray: any[] = [];

    if (this.searchMode == true) {

      this.thresholdSendData = {
        mainSensorId: this.sessionstorageMainsensorData,
        fromDate: moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.dataFormGroup.value.todatetime)).format('YYYY-MM-DD')
      }
    }
    else {

      this.thresholdSendData = {
        mainSensorId: this.sessionstorageMainsensorData,
        fromDate: moment(new Date(this.filterFromDate)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.filterToDate)).add(1, 'd').format('YYYY-MM-DD')
      };
    }

    this.todoService.getThresholdAlertsByMainSensorId(this.thresholdSendData).subscribe((response: any) => {

      if(response == null){
        this.spinner.hide();
        return;
      }

      let thresholdAlertsResponse = response.thresholdAlertList;

      //20240517追加
      //濁度予測のアラートを除外
      thresholdAlertsResponse = thresholdAlertsResponse.filter((x: any) => x.dataName !== "turbidity_predict");
      //20240517追加終了

      if(thresholdAlertsResponse.length != 0){
        let tmpVal = "";

        //20231117
        //日時指定の有無で表示するデータを調整する
        if(this.searchMode == false){

          for(let i = 0; i < thresholdAlertsResponse.length; i++){
            let date = new Date(thresholdAlertsResponse[i].dataTimeStamp * 1000);
            tmpVal = moment(new Date(date)).format("YYYY-MM-DD HH:mm");

            if (tmpVal >= this.filterFromDate) {

              if (tmpVal <= this.filterToDate) {
                tempArray.push(thresholdAlertsResponse[i]);
              }
            }
          }
        }
        else{

          for(let i = 0; i < thresholdAlertsResponse.length; i++){
            let date = new Date(thresholdAlertsResponse[i].dataTimeStamp * 1000);
            tmpVal = moment(new Date(date)).format("YYYY-MM-DD HH:mm");
            let tmpFrom = moment.utc(new Date(this.thresholdSendData.fromDate)).format("YYYY-MM-DD HH:mm");;
            let tmpTo = moment.utc(new Date(this.thresholdSendData.toDate)).add(1, 'd').format("YYYY-MM-DD HH:mm");
            //let tmpTo = moment.utc(new Date(this.thresholdSendData.toDate)).format("YYYY-MM-DD HH:mm");

            if (tmpVal >= tmpFrom) {

              if (tmpVal < tmpTo) {
                tempArray.push(thresholdAlertsResponse[i]);
              }
            }
          }
        }

        for(let index = 0; index < tempArray.length; index++){
          let date = new Date((tempArray[index]['dataTimeStamp']) * 1000);
          let convertedDate = moment(date).format("YYYY-MM-DD HH:mm");

          if(tempArray[index].dataName == "water_quantity"){
            unit = "(㎥/sec)";
          }
          else{
            unit = "(m)";
          }

          formedThresholdVal = (tempArray[index]['threshold']).toFixed(3);

          if(tempArray[index]['value'] != null){
            formedVal = (tempArray[index]['value']).toFixed(3);
          }
          else{
            formedVal = null;
          }

          let alertData: ExtraThresholdData = {
            dateTime: convertedDate,
            sensor: tempArray[index].dataName,
            unit: unit,
            alertLevel: tempArray[index].level,
            thresholdValue: formedThresholdVal,
            value: formedVal
          }

          forExtraThreshold(alertData);

          this.thresholdAlertArray.push(alertData);
        }
        //20231117

        this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.thresholdAlertArray);

        //ページング処理
        if (this.tabIndex == 1) {
          this.thresholdPagesize = [10, 20, 30];
          this.thresholdDataLength = this.thresholdAlertArray.length;
          this.extraThresholdDataSource.paginator = this.thresholdPaginator;
        }

        if(this.isCompleteProcess == false){
          this.isCompleteProcess = true;
        }
        else this.spinner.hide();
      }
      else{
        this.spinner.hide();
        //データがないときは「データがありません」等のメッセージを表示する
      }
    })
  }
}

function forExtraThreshold(filteredData: ExtraThresholdData): void {
  filteredData.dateTime = filteredData.dateTime;
  filteredData.sensor = filteredData.sensor;
  filteredData.alertLevel = isNull(filteredData.alertLevel);
  filteredData.thresholdValue = isNull(filteredData.thresholdValue);
  filteredData.value = isNull(filteredData.value);
}

function isNull(value: any): any {

  if (null == value || value == "NaN" || isNaN(value)) {
    return "-";
  }
  else {
    return value;
  }
}

