import { Component, OnInit } from '@angular/core';
import { TodoService } from 'src/app/services/todo.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { SnotifyService, SnotifyToast } from 'ng-snotify';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DatePipe, DecimalPipe } from '@angular/common';
import { DateTimeAdapter } from 'ng-pick-datetime';
import * as _ from "lodash";

export interface UserManagementElement{
  no: number;
  userId: string;
  userName: string;
  accessAuthority: string;
  mailAddress: string;
  mailAddress2: string;
  level1: boolean;
  level2: boolean;
  level3: boolean;
  machineIrregular: boolean;
  option: string;//削除や編集項目
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})

export class UserManagementComponent implements OnInit {
  currentCityId: any;
  userDataSource: any;
  userId: string = "";
  userName: string = "";
  accessAuthority: string = "";
  mailAddress: string = "";
  mailAddress2: string = "";
  level1: boolean = false;
  level2: boolean = false;
  level3: boolean = false;
  machineIrregular: boolean = false;
  option: any;
  dataPack: any;
  deleteResult: any;
  hideMode: boolean = true;
  userManagementDataList: UserManagementElement[] = [];
  displayedColumns: string[] =
    ["no", "userID", "userName", "accessAuthority", "mailAddress", "level1", "level2", "level3", "machineIrregular", "editAndDelete"];  //"mailAddress2",  追記用
  role: any;
  myUserName: any;
  myUserId: any;

  constructor(private detailRouter: Router,
    private spinner: NgxSpinnerService, 
    public http: HttpClient,
    public todoService: TodoService,
    public cookieService: CookieService, 
    private snotifyService: SnotifyService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private dateTimeAdapter: DateTimeAdapter<any>)
  {}

  ngOnInit(): void {
    this.currentCityId = sessionStorage.getItem("cityId");
    this.role = sessionStorage.getItem('role');
    this.myUserId = sessionStorage.getItem('userId');
    this.myUserName = sessionStorage.getItem('userName');
    //this.currentCityId = "3a778e38-0dea-4e02-a476-9d43e2a9cbdc";
    //this.editOrAdd();
    this.getAllUsersDataByCityId();
  }

  addNewUser(): any{

    this.detailRouter.navigate(['userdata-Addor-Edit'], {

      queryParams: {
        mode: 'addMode',
        cityid: this.currentCityId
      },
      skipLocationChange: true
      //filterURLQuery: true
    });
  }

  getAllUsersDataByCityId(): any{

    this.todoService.getAllUsersByCityId(this.currentCityId).subscribe((response: any) => {

      this.dataPack = response.userResponses;

      for(let index = 0; index < response.userResponses.length; index++){

        const userdata: UserManagementElement = {
          no: index + 1,
          userId: response.userResponses[index]['userid'],
          userName: response.userResponses[index]['username'],
          accessAuthority: response.userResponses[index]['role'],
          mailAddress: response.userResponses[index]['email'],
          mailAddress2: response.userResponses[index]['email2'],
          level1: response.userResponses[index]['alertLevel1'],
          level2: response.userResponses[index]['alertLevel2'],
          level3: response.userResponses[index]['alertLevel3'],
          machineIrregular: response.userResponses[index]['alertMachineIrregular'],
          option: "",
        }
        this.userManagementDataList.push(userdata);
      }

      //表示するユーザを限定
      if(this.role != "nscadmin"){
        let copyUserDataList = _.cloneDeep(this.userManagementDataList);
        let limitedUserList = copyUserDataList.filter((x: any) => x.accessAuthority !== "nscadmin");
        this.dataPack = response.userResponses.filter((x: any) => x.role !== "nscadmin");

        if(this.role == "normal"){
          limitedUserList = limitedUserList.filter((x: any) => x.userId == this.myUserId && x.userName == this.myUserName);
          this.dataPack = response.userResponses.filter((x: any) => x.userid == this.myUserId && x.username == this.myUserName);
        }
        this.userDataSource = new MatTableDataSource<UserManagementElement>(limitedUserList);
      }
      //「nscadmin」なら全員表示
      else{
        this.userDataSource = new MatTableDataSource<UserManagementElement>(this.userManagementDataList);
        //this.dataPack = _.clone(this.userManagementDataList);
      }
    })
  }

  ClickEdit(index: number){
    var sessionUserData = this.dataPack[index];
    sessionStorage.setItem('editUserData', JSON.stringify(sessionUserData));

    this.detailRouter.navigate(['userdata-Addor-Edit'], {
      queryParams: {
        mode: 'editMode'
      }
    });
  }

  ClickDelete(index: number): void{
    var deleteUserName = this.dataPack[index]['username'];
    var answer = confirm(`ユーザ「${deleteUserName}」を削除します。よろしいですか？`);

    if(answer){

      this.todoService.deleteUser(this.dataPack[index]['id']).subscribe((response: any) => {
        this.deleteResult = response;
        this.userManagementDataList.length = 0;
        this.getAllUsersDataByCityId();
      })
    }
  }

  displayDropDown(): void{
    this.hideMode = false;
  }

  //selectEdit(): void{}

  //selectDelete(): void{}
}
