<!-- <h1 class="title">{{'User management' | translate}}</h1> -->


<div class="subheader subheader-solid" id="kt_subheader">
  <div class="container-fluid">
    <div class="top-bar">
      <div class="dbWrapper">
        <div class="dbMenuStrip">
          <div class="dbLeftCol">
            <button class="registrationbtn" (click)="addNewUser()">{{'New registration' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <p>user-management works!</p> -->
<div class="userList">
  <table mat-table [dataSource]="userDataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="no">
      <th mat-header-cell *matHeaderCellDef style='display:none;' class="no"> No. </th>
      <td mat-cell *matCellDef="let element" class="no_val"> {{element.no}} </td>
    </ng-container>

    <ng-container matColumnDef="no_header">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="no"> No. </th>
    </ng-container>

    <ng-container matColumnDef="userID">
      <th mat-header-cell *matHeaderCellDef style='display:none;'> {{'User ID' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="val"> {{element.userId}} </td>
    </ng-container>

    <ng-container matColumnDef="userID_header">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="idheader"> {{'User ID' | translate}} </th>
    </ng-container>

    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef style='display:none;'> {{'User name' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="val"> {{element.userName}} </td>
    </ng-container>

    <ng-container matColumnDef="userName_header">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="userName" class="nameheader"> {{'User name' | translate}} </th>
    </ng-container>

    <ng-container matColumnDef="accessAuthority">
      <th mat-header-cell *matHeaderCellDef style='display:none;'> {{'Access authority' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="val"> {{element.accessAuthority | translate}} </td>
    </ng-container>

    <ng-container matColumnDef="accessAuthority_header">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="accessAuthority"> {{'Access authority' | translate}} </th>
    </ng-container>

    <ng-container matColumnDef="mailAddress">
      <th mat-header-cell *matHeaderCellDef style='display:none;'> {{'E-mail address' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="val"> {{element.mailAddress}} <p><br> {{element.mailAddress2}} </td>
    </ng-container>

    <ng-container matColumnDef="mailAddress_header">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="mailAddress" class="mailheader"> {{'E-mail address' | translate}} </th>
    </ng-container>

    <!--<ng-container matColumnDef="mailAddress2">
    <th mat-header-cell *matHeaderCellDef style='display:none;'> {{'E-mail address2' | translate}} </th>
    <td mat-cell *matCellDef="let element" class="center"> {{element.mailAddress2}} </td>
  </ng-container>

  <ng-container matColumnDef="mailAddress_header2">
    <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="mailAddress2" class="mailheader2"> {{'E-mail address2' | translate}} </th>
  </ng-container>-->

    <ng-container matColumnDef="level1">
      <th mat-header-cell *matHeaderCellDef> {{'Level1' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="no">
        <mat-checkbox class="check1" [checked]="element.level1" [disabled]="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="level2">
      <th mat-header-cell *matHeaderCellDef> {{'Level2' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="no">
        <mat-checkbox class="check2" [checked]="element.level2" [disabled]="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="level3">
      <th mat-header-cell *matHeaderCellDef> {{'Level3' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="no">
        <mat-checkbox class="check3" [checked]="element.level3" [disabled]="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="overThreshold">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="thresholdheader"> {{'Extra threshold' | translate}} </th>
    </ng-container>

    <ng-container matColumnDef="machineIrregular">
      <th mat-header-cell *matHeaderCellDef style='display:none;'> {{'Machine irregular' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="no">
        <mat-checkbox class="checkMI" [checked]="element.machineIrregular" [disabled]="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="machineIrregular_header">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="machineIrregular" class="machineIrregularheader"> {{'Machine irregular' | translate}} </th>
    </ng-container>

    <ng-container matColumnDef="editAndDelete">
      <th mat-header-cell *matHeaderCellDef style='display:none;'> {{'editAndDelete' | translate}} </th>
      <td mat-cell *matCellDef="let element; let i = index" class="eord">
        <details>
          <summary>…</summary>
          <ul class="hiddencontent">
            <li><a href="javascript:void(0)" (click)="ClickEdit(i)"> {{'Edit' | translate}} </a></li>
            <li><a href="javascript:void(0)" (click)="ClickDelete(i)"> {{'Delete' | translate}} </a></li>
          </ul>
        </details>
      </td>
    </ng-container>

    <ng-container matColumnDef="editAndDelete_header">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="editAndDelete" class="editAndDeleteheader"></th>
    </ng-container>

    <!--'mailAddress_header2',--> <!--下に追記用-->
    <tr mat-header-row *matHeaderRowDef="['no_header', 'userID_header','userName_header', 'accessAuthority_header',
      'mailAddress_header',  'overThreshold', 'machineIrregular_header', 'editAndDelete_header']">
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<!-- ↓Add 2024/05/07 -->
<ng-snotify></ng-snotify>
<!-- ↑Add 2024/05/07 -->
