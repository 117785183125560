<!-- <h1 class="title">{{'Point management' | translate}}</h1> -->


<div class="subheader subheader-solid" id="kt_subheader">
  <div class="container-fluid">
    <div class="top-bar">
      <div class="dbWrapper">
        <div class="dbMenuStrip">
          <div class="dbLeftCol">
            <!-- <input type="button" class="importbtn" value="{{'import' | translate}}"> -->
            <form action="#" class="form">
              <div class="form__file">
                <input type="file" class="newimportbtn" id="01" accept="text/csv" (change)="importCSVFile($event)">
                <label for="01" class="importlabel" *ngIf="isNSCAdmin"> {{"import" | translate}} </label>
              </div>
            </form>
            <!-- <button class="importbtn"> {{'import' | translate}} </button> -->
            <button class="exportbtn" (click)="clickCSVExport()" *ngIf="isNSCAdmin"> {{'export' | translate}} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 地点管理 -->
<label class="pointManagementTitle">{{'Point management' | translate}}</label>
<mat-paginator #managementPaginator="matPaginator" [pageSizeOptions]="pointPagesize"
  showFirstLastButtons
  [length]="pointDataLength"
  [pageSize]="perPage"
  aria-label="Select listpage of periodic elements">
</mat-paginator>
<table mat-table [dataSource]="pointManagementDataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="no">
    <th mat-header-cell *matHeaderCellDef style='display:none;' class="no"> No. </th>
    <td mat-cell *matCellDef="let element let i = index" class="no"> {{i + 1}} </td>
  </ng-container>

  <ng-container matColumnDef="no_header">
    <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="no"> No. </th>
  </ng-container>

  <ng-container matColumnDef="blockName">
    <th mat-header-cell *matHeaderCellDef style='display:none;'> {{'Area' | translate}} </th>
    <td mat-cell *matCellDef="let element"  class="val"> {{element.blockName | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="blockName_header">
    <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"> {{'Area' | translate}} </th>
  </ng-container>

  <ng-container matColumnDef="pointName">
    <th mat-header-cell *matHeaderCellDef style='display:none;'> {{'Point' | translate}} </th>
    <td mat-cell *matCellDef="let element"  class="val"> {{element.pointName | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="pointName_header">
    <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="point"> {{'Point' | translate}} </th>
  </ng-container>

  <ng-container matColumnDef="simId">
    <th mat-header-cell *matHeaderCellDef class="msid">SIM ID</th>
  </ng-container>

  <ng-container matColumnDef="imsi">
    <th mat-header-cell *matHeaderCellDef> IMSI </th>
    <td mat-cell *matCellDef="let element" class="val"> {{element.imsi | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="adc0">
    <th mat-header-cell *matHeaderCellDef> {{'adc0(AN)' | translate}} </th>
    <td mat-cell *matCellDef="let element"  class="val"> {{element.adc0 | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="adc1">
    <th mat-header-cell *matHeaderCellDef> {{'adc1(AN)' | translate}} </th>
    <td mat-cell *matCellDef="let element"  class="val"> {{element.adc1 | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="ch1">
    <th mat-header-cell *matHeaderCellDef> ch1-1 </th>
    <td mat-cell *matCellDef="let element"  class="val"> {{element.ch1 | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="ch2">
    <th mat-header-cell *matHeaderCellDef> ch1-2 </th>
    <td mat-cell *matCellDef="let element"  class="val"> {{element.ch2 | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="ch3">
    <th mat-header-cell *matHeaderCellDef> ch1-3 </th>
    <td mat-cell *matCellDef="let element" class="val"> {{element.ch3 | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="voltage">
    <th mat-header-cell *matHeaderCellDef> {{'Voltage' | translate}} </th>
    <td mat-cell *matCellDef="let element"  class="val"> {{element.voltage | translate}} </td>
  </ng-container>

  <ng-container matColumnDef="rs232c">
    <th mat-header-cell *matHeaderCellDef> RS232C </th>
    <td mat-cell *matCellDef="let element"  class="val"> {{element.rs232c | translate}} </td>
  </ng-container>

  <!-- <ng-container matColumnDef="lat">
    <th mat-header-cell *matHeaderCellDef> Lat </th>
    <td mat-cell *matCellDef="let element" class="val"> {{element.lat | translate}} </td>
  </ng-container> -->

  <!-- <ng-container matColumnDef="lng">
    <th mat-header-cell *matHeaderCellDef> Lat </th>
    <td mat-cell *matCellDef="let element" class="val"> {{element.lng | translate}} </td>
  </ng-container> -->

  <ng-container matColumnDef="port-Management">
    <th mat-header-cell *matHeaderCellDef [attr.colspan]="7"> {{'Port management' | translate}} </th>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['no_header', 'blockName_header','pointName_header', 'simId', 'port-Management']"></tr><tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;" (click)="selectPoint(i, row)" id="rows" [ngClass]="{'highlight': selectedRowIndex == i}"></tr>
</table>
<!-- <mat-paginator #managementPaginator="matPaginator" [pageSizeOptions]="pointPagesize"
  showFirstLastButtons
  [length]="pointDataLength"
  [pageSize]="perPage"
  aria-label="Select listpage of periodic elements">
</mat-paginator> -->

<!-- ポート管理 -->
<label class="portManagementTitle" *ngIf="isNSCAdmin"> {{'Port management' | translate}} </label>
<table mat-table [dataSource]="portManagementDataSource" class="mat-elevation-z8" *ngIf="isNSCAdmin">

  <ng-container matColumnDef="port">
    <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"> {{'Port' | translate}} </th>
    <td mat-cell *matCellDef="let element"> {{element.port | translate}}</td>
  </ng-container>

  <ng-container matColumnDef="sensorName">
    <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"> {{'Sensor name' | translate}} </th>
    <td mat-cell *matCellDef="let element"> {{element.sensorName | translate}}</td>
  </ng-container>

  <ng-container matColumnDef="outputVal">
    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"> {{'Output value' | translate}} </th>
  </ng-container>

  <ng-container matColumnDef="minVVal">
    <th mat-header-cell *matHeaderCellDef> {{'Min' | translate}} </th>
    <td mat-cell *matCellDef="let element" style="text-align: right;"> {{element.minVVal}} </td>
  </ng-container>

  <ng-container matColumnDef="maxVVal">
    <th mat-header-cell *matHeaderCellDef> {{'Max' | translate}} </th>
    <td mat-cell *matCellDef="let element" style="text-align: right;"> {{element.maxVVal}} </td>
  </ng-container>

  <ng-container matColumnDef="outputValUnit">
    <th mat-header-cell *matHeaderCellDef> {{'Unit' | translate}} </th>
    <td mat-cell *matCellDef="let element"> {{element.outputValUnit}} </td>
  </ng-container>

  <ng-container matColumnDef="capacity">
    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"> {{'Capacity' | translate}} </th>
  </ng-container>

  <ng-container matColumnDef="minMeasurable">
    <th mat-header-cell *matHeaderCellDef> {{'Min' | translate}} </th>
    <td mat-cell *matCellDef="let element" style="text-align: right;"> {{element.minMeasurable}} </td>
  </ng-container>

  <ng-container matColumnDef="maxMeasurable">
    <th mat-header-cell *matHeaderCellDef> {{'Max' | translate}} </th>
    <td mat-cell *matCellDef="let element" style="text-align: right;"> {{element.maxMeasurable}} </td>
  </ng-container>

  <ng-container matColumnDef="capacityUnit">
    <th mat-header-cell *matHeaderCellDef> {{'Unit' | translate}} </th>
    <td mat-cell *matCellDef="let element"> {{element.capacityUnit}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['port', 'sensorName','outputVal', 'capacity']"></tr>
  <tr mat-header-row *matHeaderRowDef="['minVVal', 'maxVVal', 'outputValUnit', 'minMeasurable', 'maxMeasurable', 'capacityUnit']"></tr>
  <tr mat-row *matRowDef="let row; columns: portManagementColumns;"></tr>
</table>
<!-- ↓Add 2024/05/07 -->
<ng-snotify></ng-snotify>
<!-- ↑Add 2024/05/07 -->
