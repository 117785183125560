<div class="d-flex flex-column flex-root">
  <div class="d-flex flex-row flex-column-fluid page">
    <!--header-->
    <app-header></app-header>
    <!--sidebar-->
    <app-sidebar></app-sidebar>
    <!---->
    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <!---->
  </div>
</div>