<!-- <h1 class="title">{{'Graph' | translate}}</h1> -->


<div class="subheader subheader-solid" id="kt_subheader">
  <div class="container-fluid">
    <div class="loaderDiv" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div class="top-bar">
      <div class="dbWrapper">
        <div class="dbMenuStrip">
          <div class="dbLeftCol">
            <div class="filterDate">
              <div class="datePicker">
                <div class="sensorData">
                  <form [formGroup]="dataFormGroup" (ngSubmit)="dateFilter()" class="form-filter-date">
                    <div class="dropdwnInput">
                      <!-- <input #fromdatetime id="fromdatetime" name="fromdatetime" class="fromdatetime"
                             formControlName="fromdatetime" [owlDateTimeTrigger]="fromDatTime" [owlDateTime]="fromDatTime"
                             [(ngModel)]="fdtime" placeholder="{{'From Date' | translate}}" readonly> -->
                      <input #fromdatetime id="fromdatetime" name="fromdatetime" class="fromdatetime" formControlName="fromdatetime"
                        [owlDateTimeTrigger]="fromDatTime" [owlDateTime]="fromDatTime"                 placeholder="{{'From Date' | translate}}" readonly />
                      <owl-date-time #fromDatTime pickerType="calendar"></owl-date-time>
                    </div>

                    <div class="dropdwnInput">
                      <!-- <input #todatetime id="todatetime" name="todatetime" class="todatetime"
                             formControlName="todatetime" [owlDateTimeTrigger]="toDateTime" [owlDateTime]="toDateTime"
                             [(ngModel)]="tdtime"
                             placeholder="{{'To Date'| translate}}" readonly> -->
                      <input #todatetime id="todatetime" name="todatetime" class="todatetime" formControlName="todatetime"
                        [owlDateTimeTrigger]="toDateTime" [owlDateTime]="toDateTime"
                        placeholder="{{'To Date'| translate}}" readonly />
                      <owl-date-time #toDateTime pickerType="calendar"></owl-date-time>
                    </div>

                    <button class="submitBtn" type="submit" [disabled]="isSensor">{{'Submit' | translate}}</button>
                    <button class="clearBtn" type="reset" (click)="dateFilterClear()" [disabled]="isSensor">{{'Clear' | translate}}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(51,51,51,0.6)" size="large" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{'Loading'}}</p>
</ngx-spinner>


<div class="selectdialog">
  <dialog id="dialog" class="dialog">
    <ul class="displayelement">
      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="selectall" [checked]="isAllSelect" (change)="selectAllGraph()" /> {{'Select all' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="waterquantity" [checked]="isWaterQuantitySelect" (change)="selectDataGraph()" /> {{'Water quantity' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="waterlevel" [checked]="isWaterLevelSelect" (change)="selectDataGraph()" /> {{'Water level' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="freshwaterconductivity" [checked]="isFreshwaterConductivitySelect" (change)="selectDataGraph()" /> {{'Freshwater conductivity EC25' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="saltwaterconductivity" [checked]="isSaltwaterConductivitySelect" (change)="selectDataGraph()" /> {{'Saltwater conductivity EC25' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="watertemperature" [checked]="isWaterTemperatureSelect" (change)="selectDataGraph()" /> {{'Water temprature' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="batteryvoltage" [checked]="isBatteryVoltageSelect" (change)="selectDataGraph()" /> {{'Battery voltage' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="turbidity" [checked]="isTurbiditySelect" (change)="selectDataGraph()" /> {{'Turbidity' | translate}}
        </a>
      </li>
    </ul>
    <div class="okbtn">
      <button (click)="clickOKBtn()" class="okbtndesign">{{'OK' | translate}}</button>
    </div>
  </dialog>
</div>


<div class="legend">
  <img src="../../../../../assets/graph.component.img/waterquantitylabel.png"
       alt="表示できるラベルがありません" class="wqlabel" *ngIf="isWaterQuantitySelect">
  <p class="textspace" *ngIf="isWaterQuantitySelect">{{'Water quantity' | translate}}(㎥/sec)</p>
  <img src="../../../../../assets/graph.component.img/waterlevellabel.png"
       alt="表示できるラベルがありません" class="wllabel" *ngIf="isWaterLevelSelect">
  <p class="textspace" *ngIf="isWaterLevelSelect">{{'Water level' | translate}}(m)</p>
  <img src="../../../../../assets/graph.component.img/freshwaterconductivitylabel.png"
       alt="表示できるラベルがありません" class="fwclabel" *ngIf="isFreshwaterConductivitySelect">
  <p class="textspace" *ngIf="isFreshwaterConductivitySelect">{{'Freshwater conductivity EC25' | translate}}(μS/cm)</p>
  <img src="../../../../../assets/graph.component.img/seawaterconductivitylabel.png"
       alt="表示できるラベルがありません" class="swclabel" *ngIf="isSaltwaterConductivitySelect">
  <p class="textspace" *ngIf="isSaltwaterConductivitySelect">{{'Saltwater conductivity EC25' | translate}}(mS/cm)</p>
  <img src="../../../../../assets/graph.component.img/batteryvoltagelabel.png"
       alt="表示できるラベルがありません" class="bvlabel" *ngIf="isBatteryVoltageSelect">
  <p class="textspace" *ngIf="isBatteryVoltageSelect">{{'Battery voltage' | translate}}(V)</p>
  <img src="../../../../../assets/graph.component.img/watertemperaturelabel.png"
       alt="表示できるラベルがありません" class="wtlabel" *ngIf="isWaterTemperatureSelect">
  <p class="textspace" *ngIf="isWaterTemperatureSelect">{{'Water temprature' | translate}}(℃)</p>
  <img src="../../../../../assets/graph.component.img/turbiditylabel.png"
       alt="表示できるラベルがありません" class="tlabel" *ngIf="isTurbiditySelect">
  <p class="textspace" *ngIf="isTurbiditySelect">{{'Turbidity' | translate}}(FTU)</p>
</div>


<button (click)="selectGraph()" class="opendialogbtn">
  <img src="./assets/Icons/openDialogBtn.svg">
</button>


<!-- <div class="table-responsive"> -->
  <div class="graphscroll">
    <div class="wrapperCanvas">
      <canvas #canvas01 id="chart" [width]="graphWidth" [height]="graphHeight"
              [datasets]="cookieService.get('language')=='en'?multipleLineChartData:multipleLineChartDataJP"
              [labels]="lineChartLabels"
              [options]="lineChartOptions"
              [plugins]="lineChartPlugins" baseChart
              chartType="line">
      </canvas>
    </div>
    <canvas id="yAxis" [width]="yAxisWidth" #canvas02 height="360" class="y"
            [datasets]="cookieService.get('language')=='en'?subLineChartData:subLineChartDataJP"
            [options]="subLineChartOptions"
            [plugins]="subLineChartPlugins"
            baseChart
            chartType="line">
    </canvas>
  </div>
<!-- </div> -->


<div class="mat-elevation-z8">
  <div class="example-container mat-elevation-z8">
    <mat-table [dataSource]="sampleSource3" matSort class="example-class">

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="cb">
          <mat-checkbox (change)="isAllPointSelect($event.checked)" [checked]="headerCheckBox"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          <mat-checkbox (change)="checkedOrNot($event.checked, i, element.mainSensorId)" class="check"
            [checked]="element.checkedOrNot">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PointDetail">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="detailbtn"> {{'Detail' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="action-link">
          <button mat-raised-button color="primary" (click)="onClick(element.mainSensorId)">{{'Detail' | translate}}</button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="cityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'City' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.cityName | translate}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="areaName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Area' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.areaName | translate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sensorName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Point' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.sensorName | translate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Date & Time' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dateTime}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waterQuantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Water quantity' | translate}}(㎥/sec) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.waterQuantity}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waterLevel">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Water level' | translate}}(m) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.waterLevel}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="freshwaterConductivity">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Freshwater conductivity EC25' | translate}}(μS/cm) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.freshwaterConductivity}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="saltwaterConductivity">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Saltwater conductivity EC25' | translate}}(mS/cm) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.saltwaterConductivity}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waterTemprature">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Water temprature' | translate}}(℃) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.waterTemprature}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="batteryVoltage">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Battery voltage' | translate}}(V) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.batteryVoltage}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidity">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity' | translate}}(FTU) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidity}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns2;"></mat-row>
    </mat-table>
    <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]"
                    showFirstLastButtons [length]="sampleSource3Length"></mat-paginator>
  </div>
</div>

<ng-snotify></ng-snotify>
