import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[OnlyPositiveNumbers]'
})
export class OnlyNumbersDirective {
 
  private regex: RegExp = new RegExp(/^[1-9]\d*$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1 || this.specialKeys.indexOf(event.key) == 0) {
      return;
    }

    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
  
}
