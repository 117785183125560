import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TodoRoutingModule } from './todo-routing.module';
import { ListingComponent } from './components/listing/listing.component';
import { AddComponent } from './components/add/add.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GraphComponent } from './components/graph/graph.component';
import { GraphTurbidityComponent } from './components/graph-turbidity/graph-turbidity.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChartsModule } from 'ng2-charts';
import { TrendComponent } from './components/trend/trend.component';
import { MapComponent } from './components/map/map.component';
import { MapTurbidityComponent } from './components/map-turbidity/map-turbidity.component'; // 20240423 濁度地図
import { AlarmComponent } from './components/alarm/alarm.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SensorReportsComponent } from './components/sensor-reports/sensor-reports.component';
import { DatePipe } from '@angular/common';
import { NgxSpinnerModule} from 'ngx-spinner';
import { AreaComponent } from './components/area/area.component';
import { PointDetailComponent } from './components/point-detail/point-detail.component';
import { PointDetailTurbidityComponent } from './components/point-detail-turbidity/point-detail-turbidity.component';
import { PointManagementComponent } from './components/point-management/point-management.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UserdataEditorAddComponent } from './components/userdata-editor-add/userdata-editor-add.component';
import { HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from "@angular/google-maps";

@NgModule
({
  declarations: [ListingComponent, AddComponent, GraphComponent, GraphTurbidityComponent, TrendComponent, MapComponent,
    MapTurbidityComponent/* 20240423 濁度地図 */, AlarmComponent, SensorReportsComponent, AreaComponent, PointDetailComponent,
    PointDetailTurbidityComponent, PointManagementComponent, UserManagementComponent, UserdataEditorAddComponent],
  imports: [
    NgxPaginationModule,
    CommonModule,
    ReactiveFormsModule,
    TodoRoutingModule,
    SharedModule,
    ChartsModule,
    NgxSpinnerModule,
    HttpClientModule,
    GoogleMapsModule
  ],
  exports: [ListingComponent, AddComponent, AlarmComponent, GraphComponent, GraphTurbidityComponent, TrendComponent, MapComponent, SensorReportsComponent, PointManagementComponent, UserManagementComponent, UserdataEditorAddComponent],
  providers: [DatePipe]
})
export class TodoModule { }
