import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { env } from 'echarts';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// 環境変数の確認
console.log("production : " + environment.production);

fetch(environment.GOOGLE_MAPS_API_URL)
.then(function(response) {
  // レスポンスが返ってきた時の処理
  console.log("google map return ok");
  return response.text();
})
.then(function (responsetext) {
  //console.log("google map return ok2", responsetext);
  // ApiKeyを設定したscriptを生成
  const googleMapsApiKey = document.createElement("script");
  googleMapsApiKey.id = environment.GOORLE_MAPS_API_RESPONSE_NAME;
  googleMapsApiKey.textContent = responsetext;
  googleMapsApiKey.async = true;
  googleMapsApiKey.defer = true;

  // htmlにappend
  const html = document.getElementById("html");
  html!.appendChild(googleMapsApiKey);

  // htmlから削除
  const gMap = document.getElementById(environment.GOORLE_MAPS_API_RESPONSE_NAME);
  gMap!.remove();

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

});
